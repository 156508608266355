import axios from "axios";
import * as React from "react";
import { Link } from "react-router-dom";
import "../index.css";
import parse from "html-react-parser";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// const parser = new DOMParser();
export function ShowFAQs() {
  const [FAQs, setFAQs] = React.useState("");
  React.useEffect(() => {
    axios
      .get(`/api/faqs/`)
      .then((res) => {
        // // console.log(res);
        setFAQs(res);
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);
        }
      });
  }, [setFAQs]);
  if (!FAQs) return <div></div>;
  // console.log(FAQs);

  return (
    // <div>{FAQs["data"].map((q) => parse(q.content))}</div>
    <div>
      <Grid container spacing={{ xs: 3, sm: 3, md: 3 }} rowSpacing={6}>
        {FAQs["data"].map((q) => (
          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                minWidth: 275,
                backgroundColor: "#6dcef5",
              }}
              raised={false}
              elevation={0}
            >
              <CardContent>
                <h2>{q.title}</h2>

                <p> {parse(q.content)} </p>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
