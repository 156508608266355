import React from "react";
import ReactDOM from "react-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";
import "./fonts/Arial_Black.ttf";
import "./fonts/Arial_Bold_Italic.ttf";
import "./fonts/Arial_Bold.ttf";
import "./fonts/Arial_Italic.ttf";
import "./fonts/Arial.ttf";
const THEME = createTheme({
  typography: {
    fontFamily: `"Arial"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

ReactDOM.render(
  <ThemeProvider theme={THEME}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,

  document.getElementById("root")
);
