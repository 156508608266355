import axios from "axios";
import * as React from "react";
import { Link } from "react-router-dom";
import "../index.css";

export function ShowLinks() {
  const [links, setLinks] = React.useState("");
  React.useEffect(() => {
    axios
      .get(`/api/links/`)
      .then((res) => {
        // // console.log(res);
        setLinks(res);
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);
        }
      });
  }, [setLinks]);
  if (!links) return <div></div>;
  // console.log(links);
  var sortedLinks = links["data"].sort((a, b) => (a.order > b.order ? 1 : -1));
  return (
    <div>
      {sortedLinks.map((link) => (
        <p>
          <Link
            to={{ pathname: link.link_address }}
            className="menu-item darkblue"
            target="_blank"
          >
            {link.name}
          </Link>
        </p>
      ))}
    </div>
  );
}
