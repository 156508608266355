import React, { useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Image,
  Col,
  ListGroup,
  Card,
} from "react-bootstrap";
import "../index.css";
import { Grid } from "@mui/material";
import { setText } from "../utils/Text";
import EmbedVideo from "../components/VideoEmbed";
import NavbarColor from "../utils/NavBarColor";
import { ShowFAQs } from "../components/ShowFAQs";
export default function FAQ() {
  NavbarColor("#6dcef5");
  // // console.log(ShowFAQs());
  var sample = [
    `Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
    enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
    turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
    ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
    odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
    a sapien`,
    `Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
    enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
    turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
    ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
    odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
    a sapien`,
    `Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
    enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
    turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
    ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
    odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
    a sapien`,
    `Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
    enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
    turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
    ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
    odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
    a sapien`,
    `Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
    enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
    turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
    ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
    odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
    a sapien`,
  ];
  return (
    <Container
      fluid
      style={{
        // padding: "5%",
        paddingTop: "100px",
        minHeight: "100vh",
        height: "100%",
      }}
      className="card-blue"
    >
      <Row>
        <p className="sectionTitle" id="faq-header">
          Frequently Asked Questions
        </p>
      </Row>
      <Row>{ShowFAQs()}</Row>

      {/* <Row>
        <Grid
          container
          spacing={{ xs: 4, md: 3 }}
          rowSpacing={6}
          // columns={{ xs: 4, sm: 4, md: 4 }}
        >
          {sample.map((_, index) => (
            <Grid item xs={4}>
              {_}
            </Grid>
          ))}
        </Grid>
      </Row> */}
      {/* <Row>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
        <Col xs={3} lg={3} xl={3} md={3} xxl={3} sm={3}>
          <div className="landingPageSections">
            <p className="sectionTitle2">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim
            </p>
            <p className="bodyText">
              Quisque malesuada, leo at porta dictum, tortor dolor imperdiet
              enim, sit amet tempus sem diam vel ipsum. Vivamus aliquam lorem
              turpis, non volutpat nibh laoreet id. Mauris sodales purus nisi,
              ut hendrerit quam malesuada ut. Curabitur sem diam, viverra sed
              odio ut, tristique egestas sem. Etiam vel euismod nulla. Phasellus
              a sapien
            </p>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
}
