import React, { useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Image,
  Col,
  ListGroup,
  Card,
} from "react-bootstrap";
import "../index.css";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { CSSTransitionGroup, TransitionGroup } from "react-transition-group";
import { Waypoint } from "react-waypoint";
import { setText } from "../utils/Text";
import NavbarColor from "../utils/NavBarColor";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isDesktop } from "react-device-detect";
import NoticeSnackbar from "../components/Notice";
import EmbedVideo from "../components/VideoEmbed";

// makes sure section headers aren't cut off on menu navigation
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -89;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default function VideoTranscripts(props) {
  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;
  return (
    <div>
      <Waypoint
        onEnter={function () {
          NavbarColor("#6DCFF6");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="facilities-condition-assessment-transcript"
          className="card-blue"
          style={{
            // paddingTop: "50px",
            marginTop: "50px",
          }}
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/fca-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-fca-video"
                className="menu-item darkblue"
                to="/NeedToKnow#facilities-condition-assessment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_transcripts", "title2", selectedLanguage)}
              </p>
              {/* <p className="sectionTitle2">
                {setText("video_transcripts", "subtitle1", selectedLanguage)}
              </p> */}
              <p className="bodyText">
                {setText("video_transcripts", "body7", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body8", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body9", selectedLanguage)}
              </p>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#3aab49");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="educational-suitability-transcript"
          className="card-green"
          style={{ paddingTop: "100px" }}
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {/* Educational Suitability Assessment */}
                {setText("video_transcripts", "title1", selectedLanguage)}
              </p>
              {/* <p className="sectionTitle2">
                {setText("video_transcripts", "subtitle1", selectedLanguage)}
              </p> */}
              <p className="bodyText">
                {setText("video_transcripts", "body1", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body2", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body3", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body4", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body5", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body6", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/esa-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-esa-video"
                className="menu-item darkblue"
                to="/NeedToKnow#education-suitability-assessment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#FFAA30");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="weighted-combined-score-transcript"
          className="card-yellow"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_transcripts", "title3", selectedLanguage)}
              </p>
              {/* <p className="sectionTitle2">
                {setText("video_transcripts", "subtitle1", selectedLanguage)}
              </p> */}
              <p className="bodyText">
                {setText("video_transcripts", "body10", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body11", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body12", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body13", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body14", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body15", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/wcs-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-wcs-video"
                className="menu-item darkblue"
                to="/NeedToKnow#weighted-combined-score"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
          </Row>
        </Container>
      </Waypoint>

      <Waypoint
        onEnter={function () {
          NavbarColor("#e65e25");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="student-enrollment-transcript"
          className="card-orange"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/se-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-se-video"
                className="menu-item darkblue"
                to="NeedToKnow#student-enrollment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_transcripts", "title4", selectedLanguage)}
              </p>
              {/* <p className="sectionTitle2">
                {setText("video_transcripts", "subtitle1", selectedLanguage)}
              </p> */}

              <p className="bodyText">
                {setText("video_transcripts", "body16", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body17", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body18", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body19", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body20", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body21", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body22", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_transcripts", "body23", selectedLanguage)}
              </p>
            </Col>
          </Row>
        </Container>
      </Waypoint>
    </div>
  );
}
