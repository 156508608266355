import React from "react";
import { Accordion, IconButton } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
// import { GetCurrentEnrollment } from "../utils/CurrentEnrollment";
// import { Icon } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Collapse } from "@mui/material";
import { Paper } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import "../index.css";
// import { Colors } from "../utils/Colors";
import { ColorPicker, UtilColorPicker } from "./ColorPicker";
import { ExpandMore } from "@mui/icons-material";
import { LineChart } from "./LineChart";
import EdSuitability from "../utils/EdSuitabilityStructure";
import { Translator, Translate } from "react-auto-translate";
import FCALink from "./FCALink";
import { setText } from "../utils/Text";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "1.5rem",
    flexBasis: "50%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: "1.5rem",
  },
}));
let rows;

function checkForSpecialCodes(val) {
  if (val > 9990) {
    // "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building."
    return "Not Available";
  } else {
    return val;
  }
}

function categoryName(val) {
  if (val > 9990) {
    // "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building."
    return "";
  } else if (val >= 90) {
    return "(Excellent)";
  } else if (val >= 80) {
    return "(Good)";
  } else if (val >= 70) {
    return "(Fair)";
  } else if (val >= 60) {
    return "(Poor)";
  } else {
    return "(Unsatisfactory)";
  }
}

function utilCategoryName(val) {
  if (val > 110) {
    return "(Inadequate)";
  } else if (val >= 95) {
    return "(Approaching Inadequate)";
  } else if (val >= 75) {
    return "(Adequate)";
  } else if (val >= 60) {
    return "(Approaching Inefficient)";
  } else {
    return "(Inefficient)";
  }
}

export default function ControlledAccordions(data) {
  // // console.log(typeof data);
  const mySettings = data.data.properties;
  const feature = data.data.existingData;
  const suitability = data.data.edSuitability;
  const fcaDetails = data.data.fcaDetails;
  // // console.log(fcaDetails);
  const classes = useStyles();
  const selectedLanguage = mySettings.language;
  // // console.log("accordion language: ", mySettings.language);
  const [expanded, setExpanded] = React.useState(false);
  // // console.log(data.data.existingData);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // console.log(feature);

  var util = 0;
  var fca = 0;
  var esa = 0;
  var wcs = 0;
  var enr = 0;
  var utilColor = "#f0f0f0";
  var fcaColor = "#f0f0f0";
  var esaColor = "#f0f0f0";
  var wcsColor = "#f0f0f0";
  var enrColor = "#f0f0f0";

  var utilLabel = "Not Available";
  var fcaLabel = "Not Available";
  var esaLabel = "Not Available";
  var wcsLabel = "Not Available";
  var enrLabel = "Not Available";

  var utilCategory = "";
  var fcaCategory = "";
  var esaCategory = "";
  var wcsCategory = "";

  var disclaimer = "";
  if (feature["renovations"] == 1) {
    util = "Not Available";
    fca = "Not Available";
    esa = "Not Available";
    wcs = "Not Available";
    enr = "Not Available";

    utilColor = "#000";
    fcaColor = "#000";
    esaColor = "#000";
    wcsColor = "#000";
    enrColor = "#000";

    utilLabel = "Not Available";
    fcaLabel = "Not Available";
    esaLabel = "Not Available";
    wcsLabel = "Not Available";
    enrLabel = "Not Available";

    utilCategory = "";
    fcaCategory = "";
    esaCategory = "";
    wcsCategory = "";

    disclaimer = (
      <div className="bodyText accordionText">
        <b>
          This school is currently undergoing major renovations and therefore
          does not have any scores available.
        </b>
      </div>
    );

    var _fcaDetailsTable = "";
    var _esaDetails = "";
    var _enrollmentDetails = "";
  } else {
    if (feature["utilization_2122"]) {
      util = Math.round(feature["utilization_2122"] * 100) + "%";
      utilColor = UtilColorPicker(feature["utilization_2122"] * 100);
      utilCategory = utilCategoryName(feature["utilization_2122"] * 100);
    } else {
      util = "Not Available";
      utilColor = "#000";
      utilCategory = "";
    }

    if (feature["fca_2122"]) {
      fca = Math.round(feature["fca_2122"] * 100);
      fcaColor = ColorPicker(feature["fca_2122"] * 100);
      fcaCategory = categoryName(fca);
    } else {
      fca = "Not Available";
      fcaColor = "#000";
      fcaCategory = "";
    }

    if (feature["esa_2122"]) {
      esa = Math.round(feature["esa_2122"] * 100);
      esaColor = ColorPicker(feature["esa_2122"] * 100);
      esaCategory = categoryName(esa);
    } else {
      esa = "Not Available";
      esaColor = "#000";
      esaCategory = "";
    }

    if (feature["wcs_2122"]) {
      wcs = Math.round(feature["wcs_2122"] * 100);
      wcsColor = ColorPicker(feature["wcs_2122"] * 100);
      wcsCategory = categoryName(wcs);
    } else {
      wcs = "Not Available";
      wcsColor = "#000";
      wcsCategory = "";
    }

    if (feature["211001_enrollment"]) {
      enr = feature["211001_enrollment"];
    } else {
      enr = "Not Available";
    }
    enrColor = "#000";

    var _fcaDetailsTable = <FCADetailsTable props={fcaDetails} />;
    var _esaDetails = <CollapsibleTable props={mySettings} />;
    var _enrollmentDetails = <LineChart data={feature} />;
  }

  const esdata = formatSuitability(suitability);
  rows = esdata;
  // // console.log(esdata);
  return (
    <div
      id="accordions-div"
      className={classes.root}
      style={
        {
          // overflowY:'scroll'
        }
      }
    >
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        aria-label="Button to explore details of the facilities condition score"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          style={{
            backgroundColor: "#fff",
            color: "black",
          }}
        >
          <Typography className={classes.heading}>
            {/* Facility Conditions Assessment */}
            {setText("webtool", "accordion_title1", selectedLanguage)}
          </Typography>
          <Typography
            className={classes.secondaryHeading}
            style={{
              fontFamily: "Arial Black",
              color: fcaColor,
            }}
          >
            {fca}
            {"  "}
            {fcaCategory}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {disclaimer}
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body1", selectedLanguage)}
            </p>
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body2", selectedLanguage)}
            </p>
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body22", selectedLanguage)}
            </p>
            {/* <FCALink props={feature.ulcs} /> */}
            {_fcaDetailsTable}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        aria-label="Button to explore details of the educational suitability assessment"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={{ backgroundColor: "#fff", color: "black" }}
        >
          <Typography className={classes.heading}>
            {/* Educational Suitability Assessment */}
            {setText("webtool", "accordion_title2", selectedLanguage)}
          </Typography>
          <Typography
            className={classes.secondaryHeading}
            style={{
              fontSize: "1.5em",
              fontFamily: "Arial Black",
              color: esaColor,
            }}
          >
            {esa}
            {"  "}
            {esaCategory}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {disclaimer}
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body3", selectedLanguage)}
            </p>
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body4", selectedLanguage)}
            </p>
            {/* <br></br> */}
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body5", selectedLanguage)}
            </p>
          </Typography>

          {/* ESA Collapsible Table */}
          {_esaDetails}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        id="dataAccordion"
        aria-label="Button to explore details of the weighted combined score"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{
            backgroundColor: "#fff",
            color: "black",
            border: "3px",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              fontSize: "1.5em",
            }}
          >
            {/* Weighted Combined Score */}
            {setText("webtool", "accordion_title3", selectedLanguage)}
          </Typography>
          <Typography
            className={classes.secondaryHeading}
            style={{
              fontSize: "1.5em",
              fontFamily: "Arial Black",
              color: wcsColor,
            }}
          >
            {wcs}
            {"  "}
            {wcsCategory}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {disclaimer}
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body6", selectedLanguage)}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        aria-label="Button to explore details of the enrollment for the chosen school"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          style={{ backgroundColor: "#fff", color: "black" }}
        >
          <Typography className={classes.heading}>
            {/* Student Enrollment (2021-2022) */}
            {setText("webtool", "accordion_title4", selectedLanguage)}
          </Typography>
          <Typography
            className={classes.secondaryHeading}
            style={{
              fontFamily: "Arial Black",
              color: enrColor,
            }}
          >
            {enr}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {disclaimer}
            <p className="bodyText">
              <b>
                {" "}
                {setText("webtool", "accordion_disclaimer1", selectedLanguage)}
              </b>
            </p>
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body8", selectedLanguage)}
            </p>
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body9", selectedLanguage)}
            </p>
            <div>{_enrollmentDetails}</div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        aria-label="Button to explore details of the enrollment for the chosen school"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          style={{ backgroundColor: "#fff", color: "black" }}
        >
          <Typography className={classes.heading}>
            {/* School Utilization (2021-2022) */}
            {setText("webtool", "accordion_title5", selectedLanguage)}
          </Typography>
          <Typography
            className={classes.secondaryHeading}
            style={{
              fontFamily: "Arial Black",
              color: utilColor,
            }}
          >
            {util}
            {"  "}
            {utilCategory}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {disclaimer}
            <p className="bodyText accordionText">
              {setText("webtool", "accordion_body10", selectedLanguage)}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function formatSuitability(data) {
  const esd = [];
  Object.keys(EdSuitability);
  for (var k in EdSuitability) {
    const measure = {};
    // // console.log(k);
    measure["name"] = k;
    const dat = [];
    for (var ea in EdSuitability[k]) {
      for (var ev in EdSuitability[k][ea]) {
        // var sc = "";
        if (Number(data[EdSuitability[k][ea][ev].value]) > 0) {
          // sc = Number(data[EdSuitability[k][ea][ev].value]);
          dat.push({
            metric: EdSuitability[k][ea][ev].label,
            score: Number(data[EdSuitability[k][ea][ev].value]), //sc,
            comment: data[EdSuitability[k][ea][ev].comment],
          });
        }
      }
    }
    measure["data"] = dat;
    esd.push(measure);
  }
  return esd;
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  // // console.log(props.settings.props.language);
  return (
    <Translator
      // cacheProvider={cacheProvider}
      from="en"
      to={props.settings.props.language}
      googleApiKey="AIzaSyBziyHEzxncTF025NV0sXXOzR-hwtAxpgw"
    >
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand metric row to see scores that pertain to educational suitability"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <Remove /> : <Add />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table
                  size="small"
                  aria-label="educational suitability measures"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell aria-label="the name of the measure being displayed">
                        Metric
                      </TableCell>
                      <TableCell aria-label="the numeric score of the measure being displayed">
                        Score
                      </TableCell>
                      <TableCell
                        align="center"
                        aria-label="additional comments that describe the measure's score"
                      >
                        Comments
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.data.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.metric}
                        </TableCell>
                        <TableCell>
                          <b>{historyRow.score}</b>
                        </TableCell>
                        <TableCell align="left" className="comments-column">
                          <Translate>{historyRow.comment}</Translate>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </Translator>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        metric: PropTypes.string.isRequired,
        score: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function FCARow(row) {
  // console.log(row);
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
        <Box margin={1}>
          <Table size="small" aria-label="educational suitability measures">
            <TableHead>
              <TableRow>
                <TableCell aria-label="the name of the measure being displayed">
                  Type of Distress
                </TableCell>
                <TableCell aria-label="the numeric score of the measure being displayed">
                  Location
                </TableCell>
                {/* <TableCell
                  align="center"
                  aria-label="additional comments that describe the measure's score"
                >
                  Comments
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>{row.distress}</TableCell>
              <TableCell>{row.locations}</TableCell>
            </TableBody>
            {/* <TableBody>
              {row.data.map((historyRow) => (
                <TableRow key={historyRow.date}>
                  <TableCell component="th" scope="row">
                    {historyRow.metric}
                  </TableCell>
                  <TableCell>
                    <b>{historyRow.score}</b>
                  </TableCell>
                  <TableCell align="left" className="comments-column">
                    <Translate>{historyRow.comment}</Translate>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> */}
          </Table>
        </Box>
        {/* </Collapse> */}
      </TableCell>
    </TableRow>
  );
}

function FCADetailsTable(dets) {
  // console.log(dets.props);
  // det.toUpperCase();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b className="bodyText accordionText">Distress</b>
            </TableCell>
            <TableCell>
              <b className="bodyText accordionText">Location</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dets.props.map((det) => (
            // <FCARow key={det.distress} row={det} />
            <TableRow>
              <TableCell>
                {" "}
                <b className="bodyText accordionText">{det.distress}</b>
              </TableCell>
              <TableCell className="bodyText accordionText">
                <p className="bodyText accordionText">
                  {det.locations.split("|").join(", ")}
                </p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CollapsibleTable(props) {
  // console.log(rows);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow aria-label="my table row">
            <TableCell aria-label="my table cell" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} settings={props} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
