import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";

import { Navbar } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
// import MenuIcon from "@mui/icons-material/Menu";
import { slide as MenuIconBurger } from "react-burger-menu";
import "../index.css";
import MapController from "../views/Map";
import Landing from "../views/Landing";
import NeedToKnow from "../views/NeedToKnow";
import { Image } from "react-bootstrap";
import LanguageSelect from "./LanguageSelect";
import FAQ from "../views/FAQ";
import Schedule from "../views/Schedule";
import VideoTranscripts from "../views/VideoTranscripts";
import Links from "../views/Links";
// import { Box } from "@mui/system";
import ADAColors from "./ADAColor";
import VideoDescriptions from "../views/VideoDescriptions";
import { setText } from "../utils/Text";

export default function Navigation(props) {
  const mySettings = props.settings;
  // console.log();
  var selectedLanguage = mySettings.language;
  const [menuOpen, setMenuOpen] = useState(false);
  // // console.log(mySettings.language);
  const toggleMenu = () => {
    // console.log(menuOpen);
    if (menuOpen === true) {
      // // console.log("is open");
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // makes sure section headers aren't cut off on menu navigation
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -89;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  function removeModal() {
    if (document.contains(document.getElementById("more-info-dialog"))) {
      document.getElementById("more-info-dialog").remove();
    }
    document
      .getElementById("sdp-logo-image")
      .setAttribute("style", "visibility: visible");
  }

  const BurgerMenu = (
    <MenuIconBurger
      right
      isOpen={menuOpen}
      customBurgerIcon={
        <img
          src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/BurgerIcon.svg"
          alt="open-icon"
          aria-label="Button to open site navigation menu"
        />
      }
      customCrossIcon={
        <Image
          src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/CrossIcon.svg"
          alt="close-icon"
          aria-label="Button to close site navigation menu"
          // style={{ width: "75%", height: "75%" }}
        />
      }
      onOpen={() => {
        toggleMenu();
      }}
      onClose={() => {
        toggleMenu();
      }}
      noOverlay
      // width={400}
      // bodyClassName
      style={{
        overflow: "hidden",
        zIndex: "999999",
      }}
    >
      <div style={{ overflow: "hidden", zIndex: "1999999" }}>
        <HashLink
          // smooth
          id="facilities-planning-process"
          className="menu-item lightblue"
          to="/#landing-container"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
          scroll={scrollWithOffset}
          aria-label="Navigation button to return to top of homepage"
        >
          {/* <h1>FACILITIES PLANNING PROCESS</h1> */}
          <h1> {setText("navigation", "fpp_title", selectedLanguage)} </h1>
        </HashLink>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <HashLink
              // smooth
              id="link-what-is-the-process"
              className="menu-item lightblue"
              to="/#what-is-the-process"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to What is the Facilities Planning Process section of home page"
            >
              {/* What is the Facilities Planning Process? */}
              {setText("navigation", "fpp_what_is", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}
          <li>
            <HashLink
              // smooth
              id="link-whats-happening-now"
              className="menu-item lightblue"
              to="/#whats-happening-now"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to What's Happening Now section of homepage"
            >
              {/* Whats happening now */}
              {setText("navigation", "fpp_happening_now", selectedLanguage)}
            </HashLink>
          </li>
          <li>
            <HashLink
              // smooth
              id="link-why-now"
              className="menu-item lightblue"
              to="/#why-now"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Why Now section of homepage"
            >
              {/* Why Now? */}
              {setText("navigation", "fpp_why_now", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}

          <li>
            <HashLink
              // smooth
              id="link-whats-next"
              className="menu-item lightblue"
              to="/#whats-next"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to What's Next section of homepage"
            >
              {/* Goals and Timeline */}
              {setText("navigation", "fpp_whats_next", selectedLanguage)}
            </HashLink>
          </li>
        </ul>
        <HashLink
          // smooth
          id="link-need-to-know"
          className="menu-item redorange"
          to="/NeedToKnow#measuring-quality-school-facility"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
          scroll={scrollWithOffset}
          aria-label="Navigation button to return to top of What You'll Need to Know page"
        >
          {/* <h1>WHAT YOU'LL NEED TO KNOW</h1> */}
          <h1>{setText("navigation", "ntk_title", selectedLanguage)}</h1>
        </HashLink>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <HashLink
              // smooth
              id="link-measuring-quality-school-facility"
              className="menu-item redorange"
              to="NeedToKnow#measuring-quality-school-facility"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Measuring the Quality of a School Facility section of What You'll Need to Know page"
            >
              {/* Measuring the Quality of a School Facility */}
              {setText("navigation", "ntk_measuring", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}

          <li>
            <HashLink
              // smooth
              id="link-four-important-scores"
              className="menu-item redorange"
              to="NeedToKnow#measuring-quality-school-facility"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Four Important Scores to Know section of What You'll Need to Know page"
            >
              {/* Four Important Scores to Know */}
              {setText("navigation", "ntk_four_scores", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}
          <li>
            <HashLink
              // smooth
              id="link-fca"
              className="menu-item redorange"
              to="NeedToKnow#facilities-condition-assessment"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Facilities Condition Assessment section of What You'll Need to Know page"
            >
              {/* Facilities Conditions Assessment */}
              {setText("navigation", "ntk_fca", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}

          <li>
            <HashLink
              // smooth
              id="link-esa"
              className="menu-item redorange"
              to="NeedToKnow#education-suitability-assessment"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Educational Suitability Assessment section of What You'll Need to Know page"
            >
              {/* Educational Suitability Assessment */}
              {setText("navigation", "ntk_esa", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}

          <li>
            <HashLink
              // smooth
              id="link-wcs"
              className="menu-item redorange"
              to="NeedToKnow#weighted-combined-score"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Weighted Combined Score section of What You'll Need to Know page"
            >
              {/* Weighted Combined Score */}
              {setText("navigation", "ntk_wcs", selectedLanguage)}
            </HashLink>
          </li>
          {/* <br></br> */}

          <li>
            <HashLink
              // smooth
              id="link-student-enrollment"
              className="menu-item redorange"
              to="NeedToKnow#student-enrollment"
              onClick={() => {
                toggleMenu();
                removeModal();
              }}
              scroll={scrollWithOffset}
              aria-label="Navigation button to return to Student Enrollment section of What You'll Need to Know page"
            >
              {/* Enrollment Forecast & Utilization */}
              {setText("navigation", "ntk_enroll", selectedLanguage)}
            </HashLink>
          </li>
        </ul>
        <Link
          // smooth
          id="link-schedule"
          className="menu-item redorange"
          to={{
            pathname:
              "https://docs.google.com/forms/d/e/1FAIpQLSf4DlKkJ9dmpzKknKfd38aUICfso7YiR8DiXVyXRLp9rjIZUA/viewform",
          }}
          target="_blank"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
          scroll={scrollWithOffset}
          aria-label="Navigation button to return to top of What You'll Need to Know page"
        >
          <Link className="menu-item green" to="/Schedule">
            Meeting Schedule
          </Link>
          {/* <h1>JOIN A COMMUNITY CONVERSATION!</h1> */}
          <h1>
            {setText("navigation", "join_conversation", selectedLanguage)}
          </h1>
        </Link>
        <Link
          id="link-map"
          className="menu-item yellow"
          to="/Map"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
          scroll={scrollWithOffset}
          aria-label="Navigation button to return to Explore Your School page"
        >
          {/* <h1>EXPLORE YOUR SCHOOL</h1> */}
          <h1>{setText("navigation", "explore", selectedLanguage)}</h1>
        </Link>
        <Link
          id="link-faq"
          className="menu-item green"
          // to="/FAQ"
          to={{
            pathname:
              "https://www.philasd.org/strategicinitiatives/facilities-planning/#faqs",
          }}
          target="_blank"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
          scroll={scrollWithOffset}
          aria-label="Navigation button to return to Frequently Asked Questions page"
        >
          {/* <h1>COMMONLY ASKED QUESTIONS</h1> */}
          <h1>{setText("navigation", "questions", selectedLanguage)}</h1>
        </Link>
        <Link
          id="link-links"
          className="menu-item lightblue"
          to="/Links"
          onClick={() => {
            toggleMenu();
            removeModal();
          }}
        >
          {/* <h1>USEFUL LINKS</h1> */}
          <h1>{setText("navigation", "links", selectedLanguage)}</h1>
        </Link>
      </div>
    </MenuIconBurger>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ top: "89px" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <ADAColors settings={mySettings} />
      </MenuItem> */}
      <MenuItem>
        <LanguageSelect settings={mySettings} />
      </MenuItem>
    </Menu>
  );

  return (
    <Router>
      {/* navigation bar */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#3aab49" }}
          className="sticky-top"
          id="navbar"
          elevation={0}
        >
          <Toolbar>
            {/* logo */}
            <Image
              // src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/FPP_Bluelogo_monotone_path.svg"
              id="sdp-logo-image"
              style={{ height: "60px", lineHeight: "90px" }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                paddingRight: "100px",
              }}
            >
              <IconButton
                // size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                id="elipsis-button"
              >
                <MoreIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#0b315b",
                  }}
                  id="elipsis-button"
                />
              </IconButton>
            </Box>
            {/* <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                float: "right",
              }}
            >
              <ADAColors settings={mySettings} />
            </Box> */}
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                marginRight: "100px",
              }}
            >
              <LanguageSelect settings={mySettings} />
            </Box>
          </Toolbar>
        </AppBar>

        {/* the sidebar menu and icon */}
        {BurgerMenu}
        {renderMobileMenu}
      </Box>

      {/* page navigation and rendering, not the navigation */}
      <Switch>
        <Route exact path="/">
          <Landing settings={mySettings} />
        </Route>
        <Route exact path="/NeedToKnow">
          <NeedToKnow settings={mySettings} />
        </Route>
        <Route exact path="/Schedule">
          <Schedule />
        </Route>
        <Route exact path="/Map">
          <MapController settings={mySettings} />
        </Route>
        <Route exact path="/FAQ">
          <FAQ />
        </Route>
        <Route exact path="/VideoTranscripts">
          <VideoTranscripts settings={mySettings} />
        </Route>
        <Route exact path="/VideoDescriptions">
          <VideoDescriptions settings={mySettings} />
        </Route>
        <Route exact path="/Links">
          <Links settings={mySettings} />
        </Route>
      </Switch>
    </Router>
  );
}
