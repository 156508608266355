import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
// import { BarChart } from "./BarChart";
// import ControlledAccordions from "./DataTable";
// import { IconButton } from "@mui/material";
import { GetCurrentEnrollment } from "../utils/CurrentEnrollment";
import axios from "axios";
// dialog
import { experimentalStyled as styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import QualityLegend from "./QualityLegend";
import { Paper } from "@mui/material";
import { Slide } from "@mui/material";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";

// tabs
import FullWidthTabs from "./TableTabs";

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
const Item = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  elevation: "disabled",
  boxShadow: "none",
}));
const style = {
  position: "absolute",
  top: "150px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  zIndex: "100000000",
  margin: "20px",
};
function getEdSuitability(ulcs) {
  /////////////// axios call ///////////////
  axios
    .get(`/api/existing_data/`)
    .then((res) => {
      // console.log(res);
    })
    .catch(function (error) {
      if (error.response) {
        // console.log(error.response.data);
      }
    });
  axios
    .get(`/api/ed_suitability/${ulcs}/`)
    .then((res) => {
      // console.log(res);

      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
      // console.log(error.config);
    });
  /////////////// axios call ///////////////
}

export default function MuiModal(data) {
  // console.log(data);
  const mySettings = data.settings;

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [ed_suitability, set_ed_suitability] = React.useState(null);
  const [fcaDetails, setFcaDetails] = React.useState(null);
  const [localLanguage, setLocalLanguage] = React.useState(mySettings.language);
  // // console.log(ed_suitability);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const openOrClosed = () => {
    if (mySettings.modalOpen === true) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpen = () => {
    // React.useEffect(() => {
    //   mySettings.setModalOpen(true);
    //  })
    setOpen(true);
    // // console.log(mySettings.modalOpen);
  };

  const handleClose = () => {
    // mySettings.setModalOpen(false);
    setOpen(false);
    // // console.log(mySettings.modalOpen);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (mySettings.modalOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [mySettings.modalOpen]);

  React.useEffect(() => {
    axios.get(`/api/fcadetails/${data.data.ulcs}/`).then((res) => {
      // // console.log(res);
      setFcaDetails(res.data);
    });
  }, [data.data.ulcs]);

  React.useEffect(() => {
    axios
      .get(`/api/ed_suitability/${data.data.ulcs}/`)
      .then((res) => {
        // // console.log(res);
        set_ed_suitability(res.data);
        // return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        // console.log(error.config);
      });
  }, []);
  const xwalk = {
    "00-02": "Kingergarten-2nd Grade",
    "00-04": "Kindergarten-4th Grade",
    "00-05": "Kindergarten-5th Grade",
    "00-06": "Kindergarten-6th Grade",
    "00-08": "Kindergarten-8th Grade",
    "00-12": "Kindergaten-12th Grade",
    "03-05": "3rd-5th Grade",
    "05-08": "5th-8th Grade",
    "05-12": "5th-12th Grade",
    "06-08": "6th-8th Grade",
    "06-12": "6th-12th Grade",
    "07-08": "7th and 8th Grades",
    "09-10, 12": "9th, 10th, and 12th Grades",
    "09-12": "9th-12th Grade",
  };
  function cleanGrades(grade_span) {
    if (Object.keys(xwalk).includes(grade_span)) {
      return xwalk[grade_span];
    } else {
      return grade_span;
    }
  }

  // hack for naming in GetCurrentEnrollment()
  var dfe = {};
  dfe["properties"] = data.data;
  // end hack for naming in GetCurrentEnrollment()

  // React.useEffect(() => {
  //   // // console.log("in modal, the language is: " + mySettings.language);
  //   // console.log("modal state: " + open);
  //   // console.log(mySettings);
  // }, [open]);

  // this makes sure that the axios request is returned before rendering
  if (!ed_suitability) return <div></div>;

  return (
    <div id="dialog-container">
      <Button
        onClick={handleOpen}
        aria-label="Button to go to detailed information page for school"
      >
        Click here for more information
      </Button>
      <div>
        <Dialog
          // fullScreen={true}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth={"lg"}
          maxHeight={"md"}
          id="more-info-dialog"
          // sx={{ maxHeight: "75vh" }}
          // TransitionComponent={Transition}
        >
          <DialogTitle id="scroll-dialog-title" style={{ paddingLeft: "32px" }}>
            <h2>{data.data.publication_name}</h2>
            <Grid container spacing={{ xs: 1, md: 4 }}>
              <Grid item>
                <p className="modal-attribute-p bodyText">
                  Serves {cleanGrades(data.data.grade_span)} {"   "}
                </p>
              </Grid>
              <Grid item>
                <p className="modal-attribute-p bodyText">
                  {/* Total Enrollment: {GetCurrentEnrollment(dfe)} */}
                  Total Enrollment: {data.data["211001_enrollment"]}
                  {/* definitely want to swap this out ^^ */}
                </p>
              </Grid>
              <Grid item>
                <p className="modal-attribute-p bodyText">
                  Address: {data.data["address"]}
                </p>
              </Grid>
              <Grid item>
                <p className="modal-attribute-p bodyText">
                  <a
                    href={data.data["website"]}
                    className="darkblue menu-item"
                    aria-label="Button to visit the district's official website for this school."
                    target="_blank"
                  >
                    Visit school website
                  </a>
                </p>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"} id="modal-table-inner">
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Box>
                <div
                  style={{
                    position: "fixed",
                    paddingTop: "50px",
                    // width: "100%",
                    backgroundColor: "green",
                    zIndex: "20",
                  }}
                ></div>
                <Grid
                  item
                  xs
                  container
                  spacing={1}
                  style={{
                    position: "relative",
                    overflowY: "auto",
                  }}
                  id="fwtgrid"
                >
                  <FullWidthTabs
                    existingData={data.data}
                    edSuitability={ed_suitability}
                    fcaDetails={fcaDetails}
                    properties={mySettings}
                  />
                </Grid>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              aria-label="Button to close the detailed information panel and return to the map interface"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
