export const Colors = {
  // excellent: "#a6cee3",
  // good: "#b2df8a",
  // fair: "#fff63f",
  // poor: "#ff7f00",
  // unsatisfactory: "#ee3433",
  excellent: "#398635",
  good: "#3aab48",
  fair: "#facd44",
  poor: "#e65e25",
  unsatisfactory: "#c72432",
};

export const SchoolColors = {
  high: "#00539a",
  "middle-high": "#007d79",
  middle: "#1192e8",
  "elementary-middle": "#08bdba",
  elementary: "#82cfff",
  "elementary-middle-high": "#a56eff",
};

// export const SchoolColors = {
//   high: "#33b1ff",
//   "middle-high": "#fa4d56",
//   middle: "#4589ff",
//   "elementary-middle": "#08bdba",
//   elementary: "#ff7eb6",
//   "elementary-middle-high": "#007d79",
// };
