import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
// import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { propTypes } from "react-bootstrap/esm/Image";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import ControlledAccordions from "./DataTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full0width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    sx: { color: "black", "background-color": "white" },
  };
}

export default function FullWidthTabs(
  existingData,
  edSuitability,
  fcaDetails,
  properties
  // optionsData
) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "white", width: "100%" }} id="thebigbox">
      <SwipeableViews
        axis={ThemeConsumer.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        id="thebox"
      >
        <TabPanel value={value} index={0} dir={theme.direction} id="tabpanel">
          {/* Existing conditions info */}
          <ControlledAccordions
            data={existingData}
            edSuitability={edSuitability}
            fcaDetails={fcaDetails}
            properties={properties}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
