import React from "react";
import { BarChart } from "./BarChart";
import MuiModal from "./Modal";
import QualityLegend from "./QualityLegend";
import { GetCurrentEnrollment } from "../utils/CurrentEnrollment";
import { Box } from "@mui/system";
import { Row, Col } from "react-bootstrap";
import { Grid } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import BP from "./BoxPlot";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

const Item = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  elevation: "disabled",
  boxShadow: "none",
}));
// // console.log(feature.properties.ilucs);
const Tooltip = (props) => {
  // // console.log(props);
  const mySettings = props.settings;
  const feature = props.feature;
  const id = feature.ulcs;
  // // console.log(id);
  // console.log(feature);
  // // console.log(feature, mySettings);
  const xwalk = {
    "00-02": "Kingergarten-2nd Grade",
    "00-04": "Kindergarten-4th Grade",
    "00-05": "Kindergarten-5th Grade",
    "00-06": "Kindergarten-6th Grade",
    "00-08": "Kindergarten-8th Grade",
    "00-12": "Kindergaten-12th Grade",
    "03-05": "3rd-5th Grade",
    "05-08": "5th-8th Grade",
    "05-12": "5th-12th Grade",
    "06-08": "6th-8th Grade",
    "06-12": "6th-12th Grade",
    "07-08": "7th and 8th Grades",
    "09-10, 12": "9th, 10th, and 12th Grades",
    "09-12": "9th-12th Grade",
  };
  function cleanGrades(grade_span) {
    if (Object.keys(xwalk).includes(grade_span)) {
      return xwalk[grade_span];
    } else {
      return grade_span;
    }
  }
  return (
    <div
      id={`tooltip-${id}`}
      aria-label="Tooltip with school-level information"
    >
      <Box>
        <h2 style={{ fontFamily: "Arial Black", marginTop: "10px" }}>
          {feature["publication_name"]}
        </h2>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item borderColor={"white"}>
            <div>Serves {cleanGrades(feature.gradespan)}</div>
          </Grid>
          <Grid item>
            <div>Address: {feature["address"]}</div>
          </Grid>
        </Grid>
        <div style={{ maxHeight: "350px", minHeight: "150px" }} id="canvas">
          <BarChart data={feature} />
        </div>
      </Box>
      <Box>
        {/* <QualityLegend /> */}
        <MuiModal data={feature} settings={mySettings} />
      </Box>
    </div>
  );
};

export default Tooltip;
