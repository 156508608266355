// import ReactPlayer from "react-player/vimeo";
import { WindowOutlined } from "@mui/icons-material";
import ReactPlayer from "react-player/youtube";
const EmbedVideo = (props) => {
  // console.log(window.innerWidth);
  var Width = "440px";
  var Height = "250px";

  if (window.innerWidth > 1189) {
    var nw = window.innerWidth * 0.38;
    var nh = nw / 1.78779;
    Width = nw + "px";
    Height = nh + "px";
  } else if (window.innerWidth > 600) {
    Width = "640px";
    Height = "368px";
  } else {
    var nww = window.innerWidth * 0.9;
    var nhh = nww / 1.78779;
    Width = nww + "px";
    Height = nhh + "px";
  }
  // console.log(Width, Height);
  return (
    <ReactPlayer
      url={props.url}
      controls={true}
      light={true}
      // width="${width}px"
      height={Height}
      width={Width}
      // width="820px"
      // height="460px"
      // style={
      //   {
      //     // width=width
      //     // height=height
      //     // note that true video size is 1920x1080. might need to find a better
      //     // library for handling this.
      //     // maxHeight: "300px",
      //   }
      // }
    />
  );
};

export default EmbedVideo;
