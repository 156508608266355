import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FeedbackModal() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Router>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <h2 className="bodyText">
              Welcome to the Facilities Planning Process (FPP) website!
            </h2>
            <p className="bodyText">
              Throughout this website you will find details on the overall
              process, how the district measures the quality of school
              facilities, and facilities data of each school in the district.
            </p>
            <p className="bodyText">
              For an overview of this process,{" "}
              <HashLink
                className="menu-item"
                style={{ color: "#0b315b" }}
                to={{
                  pathname: "https://www.youtube.com/watch?v=BK8bscMm6WI",
                }}
                target="_blank"
              >
                click here
              </HashLink>{" "}
              for the recording of the May 2022 Community Conversations, where
              district leadership introduce the process.{" "}
            </p>
            <p className="bodyText">
              Explored the website and ready to share your thoughts and
              feedback? Use the{" "}
              <HashLink
                className="menu-item"
                style={{ color: "#0b315b" }}
                to={{
                  pathname:
                    "https://docs.google.com/forms/d/e/1FAIpQLSe_RJ6p2qo_b91JKeHMFZB6374MeK7SaGFCAnRsubN-LqTjCQ/viewform",
                }}
                target="_blank"
              >
                Online Feedback Form here
              </HashLink>
              {"."}
            </p>
            {/* <p className="bodyText">
              Looking for a recording of the first round of Community
              Conversations?{"  "}
              <HashLink
                to={{
                  pathname: "https://www.youtube.com/watch?v=BK8bscMm6WI",
                }}
                target="_blank"
                style={{ color: "#0b315b" }}
                className="menu-item"
              >
                Click here for the May 2022 English introduction.
              </HashLink>
            </p>
            <p className="bodyText">
              <HashLink
                to={{
                  pathname:
                    "https://wxy-sdp.s3.us-east-2.amazonaws.com/process_guide/220422_SDP_FPP_PlanningProcessGuide_Web.pdf",
                }}
                target="_blank"
                style={{ color: "#0b315b" }}
                className="menu-item"
              >
                You can also find a full guide to the Facilities Planning
                Process here.
              </HashLink>
            </p>
            <p className="bodyText">
              Ready to share your thoughts and feedback? {"  "}
              <HashLink
                to={{
                  pathname:
                    "https://docs.google.com/forms/d/e/1FAIpQLSe_RJ6p2qo_b91JKeHMFZB6374MeK7SaGFCAnRsubN-LqTjCQ/viewform",
                }}
                target="_blank"
                style={{ color: "#0b315b" }}
                className="menu-item"
              >
                Online Feedback Form here.
              </HashLink>
            </p> */}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Continue to Website
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Router>
    </div>
  );
}
