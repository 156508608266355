import ReactAudioPlayer from "react-audio-player";

export default function PlayAudio(file) {
  // console.log(file.file);
  return (
    <div>
      <br></br>
      <ReactAudioPlayer src={file.file} controls crossOrigin="" />
    </div>
  );
}
