import { Line } from "react-chartjs-2";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axios from "axios";
import Enrollment from "../utils/Enrollment";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import { useEffect, useState } from "react";
if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

Chart.register(ChartDataLabels);

const years = ["2015", "2016", "2017", "2018", "2019", "2020", "2021"];

export function GetSchoolEnrollment(dd) {
  const [enroll, setEnroll] = useState();
  const feature = dd.data;
  // // console.log(feature.ulcs);
  useEffect(() => {
    axios.get(`/api/enrollment/${feature.ulcs}/`).then((res) => {
      setEnroll(res);
    });
  }, [feature]);

  return enroll;
}

export function LineChart(dd) {
  var dataRow = GetSchoolEnrollment(dd);
  if (!dataRow) return <div></div>;

  // // console.log(dataRow);
  const enrollment = [
    dataRow.data["enr_2015"],
    dataRow.data["enr_2016"],
    dataRow.data["enr_2017"],
    dataRow.data["enr_2018"],
    dataRow.data["enr_2019"],
    dataRow.data["enr_2020"],
    dataRow.data["enr_2021"],
  ];

  const chartData = {
    labels: years,
    datasets: [
      {
        label: "Enrollment",
        data: enrollment,

        fill: false,
        spanGaps: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 5,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        // max: 2000,
        // min: 500,
      },
    },
    plugins: {
      datalabels: {
        // display: function (context){
        //   return
        // },
        align: "top",
        color: "black",
        font: {
          size: 16,
          family: "Arial Black",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <div>
      <Line
        height={300}
        data={chartData}
        options={options}
        plugins={ChartDataLabels}
      />
    </div>
  );
}
