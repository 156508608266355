import React, { useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Image,
  Col,
  ListGroup,
  Card,
} from "react-bootstrap";
import "../index.css";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { CSSTransitionGroup, TransitionGroup } from "react-transition-group";
import { Waypoint } from "react-waypoint";
import { setText } from "../utils/Text";
import NavbarColor from "../utils/NavBarColor";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isDesktop } from "react-device-detect";
import NoticeSnackbar from "../components/Notice";
import EmbedVideo from "../components/VideoEmbed";

// makes sure section headers aren't cut off on menu navigation
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -89;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default function VideoDescriptions(props) {
  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;
  return (
    <div>
      <Waypoint
        onEnter={function () {
          NavbarColor("#6DCFF6");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="facilities-condition-assessment-video-description"
          className="card-blue"
          style={{ marginTop: "50px" }}
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/fca-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-fca-video"
                className="menu-item darkblue"
                to="/NeedToKnow#facilities-condition-assessment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_descriptions", "title1", selectedLanguage)}
              </p>

              <p className="bodyText">
                {setText("video_descriptions", "text1", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text2", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text3", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text4", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text5", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text6", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text7", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text8", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text9", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text10", selectedLanguage)}
              </p>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#3aab49");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="educational-suitability-video-description"
          className="card-green"
          style={{ paddingTop: "100px" }}
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {/* Educational Suitability Assessment */}
                {setText("video_descriptions", "title2", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text11", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text12", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text13", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text14", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text15", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text16", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text17", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text18", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text19", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text20", selectedLanguage)}
              </p>
              <ul className="bodyText">
                <li>
                  {setText("video_descriptions", "text21", selectedLanguage)}
                </li>
                <li>
                  {setText("video_descriptions", "text211", selectedLanguage)}
                </li>
                <li>
                  {setText("video_descriptions", "text22", selectedLanguage)}
                </li>
                <li>
                  {setText("video_descriptions", "text23", selectedLanguage)}
                </li>
                <li>
                  {setText("video_descriptions", "text24", selectedLanguage)}
                </li>
                <li>
                  {setText("video_descriptions", "text25", selectedLanguage)}
                </li>
              </ul>
              <p className="bodyText">
                {setText("video_descriptions", "text26", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text27", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text28", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text29", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text30", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text31", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/esa-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-esa-video"
                className="menu-item darkblue"
                to="/NeedToKnow#education-suitability-assessment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#FFAA30");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="weighted-combined-score-video-description"
          className="card-yellow"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_descriptions", "title3", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text32", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text33", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text34", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text35", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text36", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text37", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text38", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text39", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text40", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text41", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text42", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text43", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text44", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text45", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text46", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/wcs-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-wcs-video"
                className="menu-item darkblue"
                to="/NeedToKnow#weighted-combined-score"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
          </Row>
        </Container>
      </Waypoint>

      <Waypoint
        onEnter={function () {
          NavbarColor("#e65e25");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="student-enrollment-video-description"
          className="card-orange"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/se-graphic.png"
                className="landing-image"
                aria-label="Video still"
              />
              <br></br>
              <br></br>
              <HashLink
                id="link-se-video"
                className="menu-item darkblue"
                to="NeedToKnow#student-enrollment"
                scroll={scrollWithOffset}
              >
                Return to video
              </HashLink>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {" "}
                {setText("video_descriptions", "title4", selectedLanguage)}
              </p>
              {/* <p className="sectionTitle2">
                {setText("video_transcripts", "subtitle1", selectedLanguage)}
              </p> */}
              <p className="bodyText">
                {setText("video_descriptions", "text47", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text48", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text49", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text50", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text51", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text52", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text53", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text54", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text55", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text56", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("video_descriptions", "text57", selectedLanguage)}
              </p>
            </Col>
          </Row>
        </Container>
      </Waypoint>
    </div>
  );
}
