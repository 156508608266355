import * as React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { Schools } from "../utils/Schools";
import { tooltipFromSelected } from "../views/Map";
import { createTheme } from "@mui/material";
import axios from "axios";

export default function SearchBar(props, map, ttr) {
  const styles = {
    false: {
      // width: "70%",
      width: "300px",
      top: "5.5em",
      // textAlign: "center",
      marginLeft: "calc((100% - 330px)/2)",
      position: "absolute",
      zIndex: "1000",
      backgroundColor: "white",
    },
    true: {
      // width: "70%",
      width: "300px",
      top: "40vh",
      marginLeft: "calc((100% - 330px)/2)",
      position: "absolute",
      zIndex: "100000",
      backgroundColor: "white",
    },
  };

  const theme = createTheme({
    typography: {
      fontFamily: ['"Montserrat"', "Open Sans"].join(","),
    },
  });

  const [selected, setSelected] = React.useState(0);
  const mySettings = props.settings;
  // // console.log(mySettings);
  function sortSchools(Schools) {
    var l = Schools.sort((a, b) =>
      a.order > b.order
        ? 1
        : a.order === b.order
        ? a.label > b.label
          ? 1
          : -1
        : -1
    );
    // console.log(Schools.length);
    return l;
  }
  const options = sortSchools(Schools);

  function storeSearch(school) {
    console.log(school);
    if (school !== 0 && school !== 9999 && school !== prevSchool) {
      axios.get(`/api/searched/${school}/`).then((res) => {
        console.log(res);
      });
    }
    var prevSchool = school;
  }

  return (
    <Autocomplete
      disablePortal
      id={"school-search-bar-" + mySettings.searchOpen}
      options={options}
      groupBy={(option) => option.Grades}
      getOptionLabel={(option) => option.label}
      aria-label="Search for a school to display detailed information"
      theme={theme}
      sx={{
        backgroundColor: "white",
        width: "300px",
        fontWeight: 500,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select your school"
          theme={theme}
          sx={{
            font: "Arial",
            color: "pink",
            fontSize: "30",
          }}
        />
      )}
      onChange={(event, val) => {
        if (val) {
          // console.log(val);
          storeSearch(val["ulcs"]);
          mySettings.setSelectedSchool(val["ulcs"]);
          mySettings.setSelectedSchoolCampus(val["campus"]);
          mySettings.setSearchOpen(false);
        }
      }}
    />
  );
}
