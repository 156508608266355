import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { ZoomControl } from "react-mapbox-gl";
import "../index.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../components/Tooltip";
import ReactDOM from "react-dom";
import { Colors, SchoolColors } from "../utils/Colors";
import axios from "axios";
import SearchModal from "../components/SchoolSearch";
import Legend from "../components/Legend";
import SearchBar from "../components/SearchBar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import AllDistrictSchools from "../utils/SchoolData";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFyaW8td3h5IiwiYSI6ImNrcjU3MzhkdzBkZjUzMnFsZHZ6NWFjenUifQ.td1t--75nPyYTPrN3mvaUw";
const markerHeight = 50;
const markerRadius = 10;
const linearOffset = 25;
const popupOffsets = {
  top: [0, 0],
  "top-left": [0, 0],
  "top-right": [0, 0],
  bottom: [0, -markerHeight],
  "bottom-left": [
    linearOffset,
    (markerHeight - markerRadius + linearOffset) * -1,
  ],
  "bottom-right": [
    -linearOffset,
    (markerHeight - markerRadius + linearOffset) * -1,
  ],
  left: [markerRadius, (markerHeight - markerRadius) * -1],
  right: [-markerRadius, (markerHeight - markerRadius) * -1],
};

function MapController(props) {
  const allSchools = AllDistrictSchools();
  const mySettings = props.settings;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-75.1199685850811);
  const [lat, setLat] = useState(40.00962790731245);
  const [zoom, setZoom] = useState(11);
  const tooltipRef = useRef(
    new mapboxgl.Popup({
      offset: popupOffsets,
    }) //offset: 15,
  );
  const [planningDistrict, togglePlanningDistricts] = useState(false);
  const [councilDistrict, toggleCouncilDistricts] = useState(false);
  const [bounds, setBounds] = useState([]);
  let southWest;
  let northEast;
  // const [allSchools, setAllSchools] = useState([]);
  // initialize the map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: "mapbox://styles/mario-wxy/ckt21xw6k12hz17pic2fb69ch", // Monochrome
      style: "mapbox://styles/mario-wxy/cl2gn7g1y000a15qnvhgzebih", // Monochrome-220426
      center: [lng, lat],
      zoom: zoom,
    });
    // setBounds(map.current.getBounds());
    map.current.on("load", () => {
      // setAllSchools(
      //   map.current.queryRenderedFeatures({ layers: ["schools_v3"] })
      // );
    });
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    // map.current.addControl(<Legend settings={mySettings} />, "bottom-left");
  });

  southWest = bounds["_sw"];
  northEast = bounds["_ne"];
  const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
  function handleUpdateBaseSchoolData(data) {
    // // console.log("handled");
    mySettings.setSelectedSchoolBaseData(data);
  }
  // // console.log(mySettings.language);

  function storeSearch(school) {
    console.log(school);
    if (school !== 0 && school !== 9999 && school !== prevSchool) {
      axios.get(`/api/searched/${school}/`).then((res) => {
        console.log(res);
      });
    }
    var prevSchool = school;
  }

  useEffect(() => {
    map.current.on("load", function () {
      // set up interaction for data points
      // // console.log("map loads");
      // console.log(map.current.getStyle());
      map.current.on("click", "schools_v3", (e) => {
        mySettings.setSelectedSchool(0);
        var ulcsCode = e.features[0].properties.ulcs;
        var schCampus = e.features[0].properties.campus;
        console.log(e.features[0].properties);
        mySettings.setSelectedSchool(ulcsCode);
        mySettings.setSelectedSchoolCampus(schCampus);
        storeSearch(ulcsCode);
        // console.log(e.features[0].properties.campus, mySettings.selectedCampus);
        // mySettings.setSelectedSchool();
        const tooltipNode = document.createElement("div");
        tooltipNode.setAttribute("style", "transform:(0,0,0,0)");
        // // console.log("just set ulcs code");

        handleUpdateBaseSchoolData(e.features[0].properties);
        tooltipFromSelected(map.current, ulcsCode, schCampus);
      });
    });
    // return () => map.current.remove();
  }, [mySettings.selectedSchool, mySettings.selectedCampus]);

  useEffect(() => {
    // // console.log("selected school changed: ", mySettings.selectedSchool);
    tooltipFromSelected(
      map.current,
      mySettings.selectedSchool,
      mySettings.selectedCampus
    );
    // console.log("did the thing?");
  }, [
    mySettings.selectedSchool,
    mySettings.language,
    mySettings.selectedCampus,
  ]);

  function tooltipFromSelected(map, school, campus) {
    mySettings.setSelectedSchool(school); // updates selected school on click
    // storeSearch(school);
    // mySettings.setSelectedSchoolCampus(campus);
    // console.log("click on " + school);
    // // console.log(campus);
    const popup = document.getElementsByClassName("mapboxgl-popup");
    // if (popup.length) {
    //   popup[0].remove();
    //   // console.log("removed a popup");
    // }
    const tooltipNode = document.createElement("div");
    // tooltipNode.setAttribute("style", "transform:(0,0,0,0)");
    var searched = [];
    var lngLat = { lng: 0, lat: 0 };
    const feats = allSchools;
    // console.log(mySettings.selectedSchool);
    for (var feat in feats) {
      if (
        feats[feat].properties["ulcs"] == school &&
        feats[feat].properties["campus"] == campus
      ) {
        // console.log(feats[feat]);
        searched.push(feats[feat]);

        lngLat["lng"] = feats[feat].geometry.coordinates[0];
        lngLat["lat"] = feats[feat].geometry.coordinates[1];
        map.flyTo({ center: lngLat, speed: 0.2, zoom: 14 }); //offset: [0, 300]
        // // console.log("setting 2");
        // mySettings.setSelectedSchoolBaseData();
        handleUpdateBaseSchoolData(searched[0].properties);
        // // console.log(searched);
      }
    }
    // // console.log(searched);
    if (searched.length > 0) {
      // now test to see if this takes care of the bug seen on ipad
      // console.log(searched[0].properties, mySettings);
      ReactDOM.render(
        <Tooltip feature={searched[0].properties} settings={mySettings} />,
        tooltipNode
      );
      // tooltipRef.current

      let popupgl = new mapboxgl.Popup({
        offset: popupOffsets,
      }) //{ width: "100vh" }
        .setLngLat(lngLat)
        .setDOMContent(tooltipNode)
        .addTo(map);
      popupgl.on("close", function () {
        // reset selected school so someone can click on the same school again
        // // console.log("closed the popup");
        mySettings.setSelectedSchool(9999);
      });
      // now add the fill/line for attendence zone
      var color =
        SchoolColors[searched[0].properties.school_level.toLowerCase()];

      map.setFilter("combined-catchment-areas-line", [
        "==",
        "ulcs",
        searched[0].properties["ulcs"],
      ]);
      map.setPaintProperty(
        "combined-catchment-areas-line",
        "line-color",
        color
      );
      map.setFilter("combined-catchment-areas-fill", [
        "==",
        "ulcs",
        searched[0].properties["ulcs"],
      ]);
      map.setPaintProperty(
        "combined-catchment-areas-fill",
        "fill-color",
        color
      );
    }
  }

  useEffect(() => {
    map.current.on("mousemove", (e) => {
      const feature = map.current.queryRenderedFeatures(e.point, {
        layers: ["schools_v3"],
      });

      if (feature.length > 0) {
        var color =
          SchoolColors[feature[0].properties.school_level.toLowerCase()];

        map.current.setFilter("combined-catchment-areas-line", [
          "==",
          "ulcs",
          feature[0].properties["ulcs"],
        ]);
        map.current.setPaintProperty(
          "combined-catchment-areas-line",
          "line-color",
          color
        );
        map.current.setFilter("combined-catchment-areas-fill", [
          "==",
          "ulcs",
          feature[0].properties["ulcs"],
        ]);
        map.current.setPaintProperty(
          "combined-catchment-areas-fill",
          "fill-color",
          color
        );
      }
    });
  });

  function LayerButtons() {
    const buttons = [
      <Button
        key="one"
        variant="contained"
        onClick={() => {
          togglePlanningDistricts((planningDistrict) => !planningDistrict);
        }}
        color={planningDistrict ? "primary" : "grey"}
      >
        Planning Districts
      </Button>,
      <Button
        key="two"
        variant="contained"
        onClick={() => {
          toggleCouncilDistricts((councilDistrict) => !councilDistrict);
        }}
        color={councilDistrict ? "primary" : "grey"}
      >
        City Council Districts
      </Button>,
    ];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
          position: "absolute",
          paddingTop: "75px",
          zIndex: "100",
        }}
      >
        <ButtonGroup
          size="large"
          aria-label="large button group"
          orientation="vertical"
        >
          {buttons}
        </ButtonGroup>
      </Box>
    );
  }

  useEffect(() => {
    map.current.on("idle", () => {
      if (
        !map.current.getLayer("planning-districts") ||
        !map.current.getLayer("council-districts")
      ) {
        return;
      } else {
        if (planningDistrict === false) {
          map.current.setLayoutProperty(
            "planning-districts",
            "visibility",
            "none"
          );
        } else {
          map.current.setLayoutProperty(
            "planning-districts",
            "visibility",
            "visible"
          );
        }
        if (councilDistrict === false) {
          map.current.setLayoutProperty(
            "council-districts",
            "visibility",
            "none"
          );
        } else {
          map.current.setLayoutProperty(
            "council-districts",
            "visibility",
            "visible"
          );
        }
      }
    });
  });
  return (
    <div id="all-map-container">
      <SearchModal settings={mySettings} />
      <div id="search-bar-map">
        <SearchBar
          settings={mySettings}
          map={map.current}
          tooltipRef={tooltipRef}
          style={{
            width: "75vw",
          }}
        />
      </div>

      <div>
        <div className="map-container" ref={mapContainer} />
        <Legend settings={mySettings} />
        {/* <LayerButtons /> */}
      </div>
    </div>
  );
}

export default MapController;
