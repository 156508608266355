import React from "react";
import { SchoolColors } from "../utils/Colors";
import { setText } from "../utils/Text";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// see https://www.carbondesignsystem.com/data-visualization/color-palettes/#categorical-palettes
import { Popper } from "@mui/material";
import { Fade } from "react-bootstrap";
import { Box } from "@mui/material";
const Legend = (props) => {
  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;

  // popover stuff
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  if (window.innerWidth < 1190) {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "1000",
          top: "87vh",
          // width: "px",
          backgroundColor: "white",
        }}
      >
        <Button
          aria-describedby={id}
          type="button"
          variant="primary"
          onClick={handleClick}
          sx={
            {
              // backgroundColor: "black",
              // color: "black",
              // fontWeight: "500",
              // paddingBottom: "25px",
            }
          }
        >
          <p className="darkblue menu-item">Toggle Legend</p>
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="top-start">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              {/* <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}> */}
              <div className="sidebar">
                <h4 className="legend-header">
                  {setText("webtool", "legend1", selectedLanguage)}
                </h4>
                <div>
                  <span
                    style={{ backgroundColor: SchoolColors["elementary"] }}
                  ></span>
                  {/* Elementary */}
                  {setText("webtool", "legend2", selectedLanguage)}
                </div>
                <div>
                  <span
                    style={{
                      backgroundColor: SchoolColors["elementary-middle"],
                    }}
                  ></span>
                  {/* Elementary-Middle */}
                  {setText("webtool", "legend3", selectedLanguage)}
                </div>
                <div>
                  <span
                    style={{ backgroundColor: SchoolColors["middle"] }}
                  ></span>
                  {/* Middle */}
                  {setText("webtool", "legend4", selectedLanguage)}
                </div>
                <div>
                  <span
                    style={{ backgroundColor: SchoolColors["middle-high"] }}
                  ></span>
                  {/* Middle-High */}
                  {setText("webtool", "legend5", selectedLanguage)}
                </div>

                <div>
                  <span
                    style={{ backgroundColor: SchoolColors["high"] }}
                  ></span>
                  {/* High */}
                  {setText("webtool", "legend6", selectedLanguage)}
                </div>

                <div>
                  <span
                    style={{
                      backgroundColor: SchoolColors["elementary-middle-high"],
                    }}
                  ></span>
                  {/* Elementary-Middle-High */}
                  {setText("webtool", "legend7", selectedLanguage)}
                </div>
                <br></br>
                <div>
                  <span
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "10%",
                      width: "20px",
                      height: "5px",
                      top: "50%",
                      verticalAlign: "middle",
                    }}
                  ></span>{" "}
                  <b>
                    {/* District Boundary */}
                    {setText("webtool", "legend8", selectedLanguage)}
                  </b>
                </div>
              </div>
              {/* </Box> */}
            </Fade>
          )}
        </Popper>
      </div>
    );
  } else {
    return (
      <div className="sidebar">
        <h4 className="legend-header">
          {setText("webtool", "legend1", selectedLanguage)}
        </h4>
        <div>
          <span style={{ backgroundColor: SchoolColors["elementary"] }}></span>
          {/* Elementary */}
          {setText("webtool", "legend2", selectedLanguage)}
        </div>
        <div>
          <span
            style={{ backgroundColor: SchoolColors["elementary-middle"] }}
          ></span>
          {/* Elementary-Middle */}
          {setText("webtool", "legend3", selectedLanguage)}
        </div>
        <div>
          <span style={{ backgroundColor: SchoolColors["middle"] }}></span>
          {/* Middle */}
          {setText("webtool", "legend4", selectedLanguage)}
        </div>
        <div>
          <span style={{ backgroundColor: SchoolColors["middle-high"] }}></span>
          {/* Middle-High */}
          {setText("webtool", "legend5", selectedLanguage)}
        </div>

        <div>
          <span style={{ backgroundColor: SchoolColors["high"] }}></span>
          {/* High */}
          {setText("webtool", "legend6", selectedLanguage)}
        </div>

        <div>
          <span
            style={{ backgroundColor: SchoolColors["elementary-middle-high"] }}
          ></span>
          {/* Elementary-Middle-High */}
          {setText("webtool", "legend7", selectedLanguage)}
        </div>
        <br></br>
        <div>
          <span
            style={{
              backgroundColor: "#000",
              borderRadius: "10%",
              width: "20px",
              height: "5px",
              top: "50%",
              verticalAlign: "middle",
            }}
          ></span>{" "}
          <b>
            {/* District Boundary */}
            {setText("webtool", "legend8", selectedLanguage)}
          </b>
        </div>
      </div>
    );
  }
};

export default Legend;
