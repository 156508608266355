import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function NoticeSnackbar() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    // <Stack spacing={2} sx={{ width: "100%" }}>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
        Note that this website contains information on the Facilities Planning
        Process and is distinct from{" "}
        <a className="inline-link" href="https://www.philasd.org/facilities/">
          www.philasd.org/facilities/
        </a>
        , which contains more general information about PhilaSD's facilities.
      </Alert>
    </Snackbar>
    // </Stack>
  );
}
