import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const languages = {
  en: "English",
  es: "Spanish",
  "zh-CN": "Chinese",
  vi: "Vietnamese",
  ru: "Russian",
  sq: "Albanian",
  km: "Khmer",
  fr: "French",
  ar: "Arabic",
};

export default function LanguageSelect(props) {
  const mySettings = props.settings;
  const [disabled, setDisabled] = useState(false);
  const handleChange = (event) => {
    mySettings.setLanguage(event.target.value);
  };

  return (
    <Box
      // sx={{ maxWidth: 160, marginLeft: "calc(100% - 640px)", top: "25px" }}
      arial-label="Dropdown menu that changes page language"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{ paddingTop: "1em" }}
    >
      <FormControl fullWidth>
        <Select
          labelId="Dropdown menu that changes page language"
          id="language-select"
          value={mySettings.language}
          onChange={handleChange}
          sx={{
            // left: "350px",
            // top: "-50px",
            borderRadius: "50px",
            height: "35px",
          }}
          disabled={mySettings.modalOpen}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Spanish</MenuItem>
          <MenuItem value={"zh-CN"}>Chinese</MenuItem>
          <MenuItem value={"vi"}>Vietnamese</MenuItem>
          <MenuItem value={"ru"}>Russian</MenuItem>
          <MenuItem value={"sq"}>Albanian</MenuItem>
          <MenuItem value={"km"}>Khmer</MenuItem>
          <MenuItem value={"fr"}>French</MenuItem>
          <MenuItem value={"ar"}>Arabic</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
