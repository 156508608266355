import { BoxPlotChart } from "@sgratzl/chartjs-chart-boxplot";

// Chart.register(BoxPlotController);
const ctx = document.getElementById("canvas"); //.getContext("2d");
export default function BP() {
  const d = {
    labels: [
      ["Educational Suitability", "Assessment (ESA)"],
      ["Facilities Condition", "Assessment (FCA)"],
      ["Weighted Combined Score", "(60% ESA & 40% FCA)"],
      ["Utilization"],
    ],
    datasets: [
      {
        label: "d1",
        data: [1, 2, 3, 4],
      },
      {
        label: "d2",
        data: [3, 2, 3, 5],
      },
    ],
  };
  const o = {
    indexAxis: "y",
    responsive: true,
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Box Plot Chart",
    },
  };
  return new BoxPlotChart(ctx, {
    data: d,
    options: o,
  });
}
