import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { BarChart } from "./BarChart";
import ControlledAccordions from "./DataTable";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/private-theming";
import SearchBar from "./SearchBar";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import NavbarColor from "../utils/NavBarColor";
const Item = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: "center",
  // color: theme.palette.text.secondary,
}));

const style = {
  // the background blue div
  position: "fixed",
  width: "100vw",
  height: "100vh",
  // bgcolor: "#3aab49B3",
  border: "0px solid #000",
  boxShadow: 0,
};

const theme = createTheme({
  palette: {
    bright: {
      main: "#000",
      contrastText: "#fff",
    },
  },
});

export default function SearchModal(props) {
  NavbarColor("#3aab49");
  //   const [open, setOpen] = React.useState(true);
  const mySettings = props.settings;
  // // console.log(mySettings);
  const handleOpen = () => mySettings.setSearchOpen(true);
  const handleClose = () => mySettings.setSearchOpen(false);

  //   if (mySettings.selectedSchool !== 0) {
  //     setOpen(false);
  //   }
  return (
    <div>
      <Modal
        open={mySettings.searchOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid id="search-modal-container" item xs={12}>
              <div id="search-search-bar-container">
                <SearchBar settings={mySettings} id={"search-screen-bar"} />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            id="map-view-button-container"
            // style={{ textAlign: "center" }}
          >
            <Button
              variant="outlined"
              className="styledButton"
              style={{ top: "50vh" }}
              id="map-view-button"
              onClick={handleClose}
              aria-label="Click this button to go to the map and see all district schools"
              sx={{ backgroundColor: "white" }}
            >
              Explore the Map
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
