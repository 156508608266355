const colors = {
  excellent: "#398635",
  good: "#3aab48",
  fair: "#facd44",
  poor: "#e65e25",
  unsatisfactory: "#c72432",
};

// original
// const utilColors = {
//   g110: "#92278f",
//   b95_110: "#22779f",
//   b75_95: "#3aab48",
//   b60_75: "#facd44",
//   l60: "#c72432",
// };

const utilColors = {
  g110: "#c72432",
  b95_110: "#e65e25",
  b75_95: "#3aab48",
  b60_75: "#e65e25",
  l60: "#c72432",
};

export function UtilColorPicker(val) {
  if (val > 9990) {
    return "#f0f0f0";
  } else if (val > 110) {
    return utilColors.g110;
  } else if (val > 95 && val <= 110) {
    return utilColors.b95_110;
  } else if (val > 75 && val <= 95) {
    return utilColors.b75_95;
  } else if (val > 60 && val <= 75) {
    return utilColors.b60_75;
  } else if (val <= 60) {
    return utilColors.l60;
  } else {
    return "#000";
  }
}

export function ColorPicker(val) {
  if (val > 9990) {
    return "#D3D3D3";
  } else if (val >= 90) {
    return colors.excellent;
  } else if (val >= 80) {
    return colors.good;
  } else if (val >= 70) {
    return colors.fair;
  } else if (val >= 60) {
    return colors.poor;
  } else {
    return colors.unsatisfactory;
  }
}
