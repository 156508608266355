import React, { useState } from "react";
// import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
// import Map from "./views/Map";
import Navigation from "./components/Navigation";
import CommunityConversations from "./components/CommConvoLink";
import NoticeSnackbar from "./components/Notice";
import FeedbackModal from "./components/FeedbackModal";
// import ReactGA from "react-ga";

// ReactGA.initialize("G-BD7MSFPNWV");
// ReactGA.pageview(window.location.pathname + window.location.search);
export default function App() {
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedSchoolBaseData, setSelectedSchoolBaseData] = useState({});
  const [searchOpen, setSearchOpen] = useState(true);
  const [edSuitability, setEdSuitability] = useState({});
  const [language, setLanguage] = useState("en");
  const [modalOpen, setModalOpen] = useState(false);
  const [adaColors, setAdaColors] = useState(false);
  const [selectedCampus, setSelectedSchoolCampus] = useState("A");

  const userSettings = {
    selectedSchool: selectedSchool,
    selectedSchoolBaseData: selectedSchoolBaseData,
    searchOpen: searchOpen,
    edSuitability: edSuitability,
    language: language,
    modalOpen: modalOpen,
    adaColors: adaColors,
    selectedCampus: selectedCampus,
    setSelectedSchool,
    setSelectedSchoolBaseData,
    setSearchOpen,
    setEdSuitability,
    setLanguage,
    setModalOpen,
    setAdaColors,
    setSelectedSchoolCampus,
  };

  return (
    <div id="app-container">
      <Navigation settings={userSettings} />
      <NoticeSnackbar />
      <FeedbackModal />
    </div>
  );
}
