import React, { useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Image,
  Col,
  ListGroup,
  Card,
} from "react-bootstrap";
import "../index.css";
import { setText } from "../utils/Text";
import EmbedVideo from "../components/VideoEmbed";
import NavbarColor from "../utils/NavBarColor";
import { ShowTargetSchools } from "../components/TargetSchools";

import axios from "axios";

export default function Schedule() {
  NavbarColor("#e65f26");

  axios.get(`api/fcadetails/1010/`).then((res) => {
    console.log(res);
  });

  return (
    <Container
      fluid
      className="card-orange"
      style={{ paddingTop: "100px", minHeight: "100vh" }}
    >
      <p className="sectionTitle">Outreach Schedule</p>
      <p className="bodyText">
        [UPDATE TEXT::The following meetings have been planned....::UPDATE TEXT]
      </p>
      {/* <div style={{ textAlign: "-webkit-center" }}>{ShowTargetSchools()}</div> */}
    </Container>
  );
}
