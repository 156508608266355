import React, { useEffect } from "react";
import { Container, Row, Button, Image, Col, ListGroup } from "react-bootstrap";
import "../index.css";
import { setText } from "../utils/Text";
import EmbedVideo from "../components/VideoEmbed";
import NavbarColor from "../utils/NavBarColor";
import { ShowLinks } from "../components/ShowLinks";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { HashLink } from "react-router-hash-link";
export default function Links(props) {
  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;
  NavbarColor("#6dcef5");
  return (
    <Container
      fluid
      className="card-white"
      style={{ paddingTop: "100px", minHeight: "82vh" }}
    >
      <Row xs={12} sm={6} md={6} lg={6}>
        <Col xs={12} sm={4} md={4} lg={4}>
          <p className="bodyText">
            {/* This page links to materials shared throughout the Facilities
            Planning Process and will continue to be updated throughout each
            phase. */}
            {setText("useful_links", "intro", selectedLanguage)}
          </p>
          <div style={{ textAlign: "-webkit-left" }}>{ShowLinks()}</div>
        </Col>
        <Col xs={12} />
      </Row>
      <Row>
        <div className="bodyText">
          <p>{setText("links_page", "header2", selectedLanguage)}</p>

          <p className="bodyText">
            <HashLink
              className="inline-link menu-item"
              to={{
                pathname:
                  "https://www.philasd.org/capitalprograms/technical-specifications/",
              }}
              target="_blank"
            >
              {/* Technical Specifications*/}
              {setText("links_page", "link1", selectedLanguage)}
            </HashLink>
            : {setText("links_page", "body1", selectedLanguage)}
          </p>

          <p className="bodyText">
            <HashLink
              className="inline-link menu-item"
              to={{
                pathname:
                  "https://www.philasd.org/capitalprograms/facilities-conditions-assessments-fca/",
              }}
              target="_blank"
            >
              {/* FCA- Facilities Conditions Assessment */}
              {setText("links_page", "link2", selectedLanguage)}
            </HashLink>
            : {setText("links_page", "body2", selectedLanguage)}
          </p>

          <p className="bodyText">
            <HashLink
              className="inline-link menu-item"
              to={{
                pathname:
                  "https://www.philasd.org/capitalprograms/wp-content/uploads/sites/18/2021/02/SDP_DesignGuidelines_02082021.pdf",
              }}
              target="_blank"
            >
              {/* Design Guidelines */}
              {setText("links_page", "link3", selectedLanguage)}
            </HashLink>
            : {setText("links_page", "body3", selectedLanguage)}
          </p>

          <p className="bodyText">
            <HashLink
              className="inline-link menu-item"
              to={{
                pathname:
                  "https://drive.google.com/file/d/1bpGD9CSCxCrfIXKt8TEpDz_9oCt-Hvau/view",
              }}
              target="_blank"
            >
              {/* Educational Specifications */}
              {setText("links_page", "link4", selectedLanguage)}
            </HashLink>
            : {setText("links_page", "body4", selectedLanguage)}
          </p>
        </div>
      </Row>
    </Container>
  );
}
