import React, { useEffect } from "react";
import { Container, Row, Button, Image, Col, ListGroup } from "react-bootstrap";
import "../index.css";
import { Grid, Typography } from "@mui/material";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { CSSTransitionGroup, TransitionGroup } from "react-transition-group";
import { Waypoint } from "react-waypoint";
import { setText } from "../utils/Text";
import NavbarColor from "../utils/NavBarColor";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isDesktop } from "react-device-detect";
import NoticeSnackbar from "../components/Notice";
import EmbedVideo from "../components/VideoEmbed";
// import NestedGrid from "../components/LandingNavigation";
export default function Landing(props) {
  // will have to take props as an argument to be able to swap out the text based on the language
  // // console.log(isDesktop, !isDesktop);

  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;
  // // console.log(mySettings);
  const width = document.body.clientWidth;
  // // console.log(width);
  var titleWidth = "";
  if (width < 600) {
    titleWidth = "titleSizeMobile";
  } else {
    titleWidth = "titleSize";
  }
  return (
    <ParallaxProvider>
      <Waypoint
        onEnter={function () {
          NavbarColor("#6dcef5"); //#6dcef5
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
        debug={false}
      >
        <Container
          fluid
          id="landing-container"
          className="card-blue"
          style={{ paddingTop: "100px" }}
        >
          <Row id="hero-row-div">
            <Col xs={4} s={4} md={8} lg={8} style={{}}>
              <Waypoint
                onEnter={() => {
                  document
                    .getElementById("sdp-logo-image")
                    .setAttribute("style", "visibility: hidden");
                }}
              >
                <Image
                  src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/FPP_Bluelogo_monotone_path.svg"
                  style={{ width: "65%" }}
                  id="landing-page-logo"
                  aria-label="Phila SD logo"
                />
              </Waypoint>

              {/* <Parallax y={[-20, 20]} x={[0, 0]} disabled={!isDesktop}> */}
              <p
                className="pageTitle"
                style={{
                  fontSize: setText("landing", titleWidth, selectedLanguage),
                }}
              >
                {/* FACILITIES PLANNING PROCESS */}
                {setText("landing", "title", selectedLanguage)}
              </p>
              {/* </Parallax> */}
            </Col>
            <Col xs={4} id="hero-image-div">
              <Row>
                <Image
                  src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/image-1.png"
                  // className="landing-image"
                  id="what-is-fpp-first-image"
                  aria-label="Image of students"
                ></Image>
              </Row>
              <br></br>
              <Row style={{ width: "30vw" }}>
                <Image
                  src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/image-2.png"
                  id="what-is-fpp-second-image"
                  aria-label="Image of school building"
                ></Image>
              </Row>
            </Col>
          </Row>
        </Container>
      </Waypoint>

      <Waypoint
        onEnter={function () {
          NavbarColor("#e65e25");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
      >
        <Container
          fluid
          style={
            {
              // minHeight: "90vh",
            }
          }
          className="card-orange"
          id="what-is-the-process"
        >
          <Row>
            <Col xs={4} order={{ xs: 2, sm: 2 }}>
              <p
                className="sectionTitle"
                id="mobile-what-is-process"
                // style={{ height: "0px !important" }}
              >
                {/* What is the Facilities Planning Process? */}
                {setText("what_is_fpp", "title", selectedLanguage)}
              </p>
              <div className="video-container">
                <EmbedVideo
                  url="https://www.youtube.com/watch?v=CcCRfxwjS8c"
                  aria-label=""
                />
              </div>
              <br></br>
              <div id="resource-links-container">
                <span>
                  <Button
                    variant="outlined"
                    className=" styledButton"
                    style={{ marginLeft: "2.5%" }}
                    aria-label="Button to navigate to the project's press release"
                  >
                    <HashLink
                      to={{
                        pathname:
                          "https://www.philasd.org/blog/2022/03/22/launchfacilitiesplanning/",
                      }}
                      target="_blank"
                      style={{ color: "#0b315b" }}
                      className="buttonLink"
                    >
                      READ THE PRESS RELEASE
                    </HashLink>
                  </Button>
                </span>
              </div>
            </Col>
            <Col xs={4} order={{ xs: 1, sm: 1 }}>
              <div key="t1" className="fpp-column-right">
                <p className="sectionTitle" id="desktop-what-is-process">
                  {setText("what_is_fpp", "title", selectedLanguage)}
                </p>

                <p className="bodyText">
                  {setText("what_is_fpp", "body1", selectedLanguage)}
                </p>
                <p className="bodyText">
                  {setText("what_is_fpp", "body2", selectedLanguage)}
                  <Link className="inline-link menu-item" to="/Map">
                    {setText("what_is_fpp", "link1", selectedLanguage)}
                  </Link>
                  {setText("what_is_fpp", "body3", selectedLanguage)}
                  <Link className="inline-link menu-item" to="/NeedToKnow">
                    {setText("what_is_fpp", "link2", selectedLanguage)}
                  </Link>
                  {setText("what_is_fpp", "body4", selectedLanguage)}
                </p>
                <p className="bodyText">
                  {setText("what_is_fpp", "body5", selectedLanguage)}
                </p>
                <p className="sectionTitle3">
                  {setText("what_is_fpp", "subtitle1", selectedLanguage)}
                </p>
                <p className="bodyText">
                  {setText("what_is_fpp", "body7", selectedLanguage)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#e65e25");
          document
            .getElementById("sdp-logo-image")
            .setAttribute("style", "visibility: visible");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"15%"}
        debug={false}
      >
        <Container
          fluid
          style={
            {
              // minHeight: "90vh",
            }
          }
          className="card-blue"
          id="whats-happening-now"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {/* What's happening Now? */}
                {setText("whats_happening_now", "title", selectedLanguage)}
              </p>
              <div className="landingPageSections" key="t2">
                <p className="bodyText">
                  {setText("whats_happening_now", "body1", selectedLanguage)}{" "}
                  <Link
                    className="inline-link menu-item"
                    to={{
                      pathname: "https://www.youtube.com/watch?v=BK8bscMm6WI",
                    }}
                    target="_blank"
                  >
                    {setText("whats_happening_now", "link1", selectedLanguage)}
                  </Link>{" "}
                  {setText("whats_happening_now", "body2", selectedLanguage)}{" "}
                  <Link
                    className="inline-link menu-item"
                    to={{
                      pathname:
                        "https://www.youtube.com/watch?v=FgZNz7KROiU&ab_channel=TheSchoolDistrictofPhiladelphia ",
                    }}
                    target="_blank"
                  >
                    {setText("whats_happening_now", "link2", selectedLanguage)}
                  </Link>{" "}
                  {setText("whats_happening_now", "body3", selectedLanguage)}{" "}
                </p>
                <ol>
                  <li className="bodyText">
                    {setText("whats_happening_now", "body4", selectedLanguage)}{" "}
                    <Link className="inline-link menu-item" to="/Map">
                      {setText(
                        "whats_happening_now",
                        "link3",
                        selectedLanguage
                      )}
                    </Link>{" "}
                    {setText("whats_happening_now", "body5", selectedLanguage)}{" "}
                    {/* buttons */}
                    <br></br>
                    <br></br>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={6}> */}
                      {/* <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{ marginRight: "2.5%", width: "100%" }}
                          aria-label="Button to navigate to the project's process guide"
                        >
                          <HashLink
                            to={{
                              pathname:
                                "https://wxy-sdp.s3.us-east-2.amazonaws.com/process_guide/220422_SDP_FPP_PlanningProcessGuide_Web.pdf",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button1",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button> */}
                      {/* </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{ marginRight: "2.5%", width: "100%" }}
                          aria-label="Button to navigate to the project's intro video"
                        >
                          <HashLink
                            to={{
                              pathname:
                                "https://www.youtube.com/watch?v=BK8bscMm6WI",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button2",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{
                            marginRight: "2.5%",
                            width: "100%",
                          }}
                          aria-label="Button to navigate to the project's community conversations document"
                        >
                          <HashLink
                            to={{
                              pathname:
                                "https://wxy-sdp.s3.us-east-2.amazonaws.com/presentations/220518_SDP_FPP_Phase1_CommunityConversations_WEB.pdf",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b", padding: "10px" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button3",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{ marginRight: "2.5%", width: "100%" }}
                          aria-label="Button to navigate to the web map"
                        >
                          <HashLink
                            to={{
                              pathname: "/Map",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button4",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{ marginRight: "2.5%", width: "100%" }}
                          aria-label="Button to navigate to the project's community conversations document in Spanish"
                        >
                          <HashLink
                            to={{
                              pathname:
                                "https://wxy-sdp.s3.us-east-2.amazonaws.com/presentations/220519_SDP_FPP_Phase1_CommunityConversations_Spanish_WEB.pdf",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button5",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          className=" styledButton"
                          style={{ marginRight: "2.5%", width: "100%" }}
                          aria-label="Button to navigate to the project's introductory video"
                        >
                          <HashLink
                            to={{
                              pathname:
                                "https://www.youtube.com/watch?v=FgZNz7KROiU",
                            }}
                            target="_blank"
                            style={{ color: "#0b315b" }}
                            className="buttonLink"
                          >
                            {setText(
                              "whats_happening_now",
                              "button6",
                              selectedLanguage
                            )}
                          </HashLink>
                        </Button>
                      </Grid>
                    </Grid>
                  </li>
                  <li className="bodyText">
                    <HashLink
                      className="inline-link menu-item"
                      to={{
                        pathname:
                          "https://docs.google.com/forms/d/e/1FAIpQLSe_RJ6p2qo_b91JKeHMFZB6374MeK7SaGFCAnRsubN-LqTjCQ/viewform",
                      }}
                      target="_blank"
                    >
                      {" "}
                      {setText(
                        "whats_happening_now",
                        "link4",
                        selectedLanguage
                      )}
                    </HashLink>{" "}
                    {setText("whats_happening_now", "body6", selectedLanguage)}{" "}
                  </li>
                  <li className="bodyText">
                    <b>
                      {setText("whats_happening_now", "bh1", selectedLanguage)}{" "}
                    </b>
                    {setText("whats_happening_now", "body7", selectedLanguage)}{" "}
                  </li>
                </ol>
              </div>
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                // src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/image-6.png"
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/Copy+of+066A1732a.jpg"
                // style={{ marginBottom: "3em" }}
                className="landing-image"
                id="why-now-second-img"
                aria-label="Image of school"
              />
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#ffaa30");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
      >
        <Container
          fluid
          style={{
            padding: "5%",
            height: "100%",
          }}
          id="why-now"
          className="card-yellow"
        >
          <Row>
            <Col xs={4} style={{ width: "100%" }}>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/image-6.png"
                className="landing-image"
                id="why-now-second-img"
                aria-label="Image of school"
              />
            </Col>
            <Col xs={4} style={{ width: "100%" }}>
              <p className="sectionTitle">
                {setText("why_now", "title", selectedLanguage)}
                {/* Why Now? */}
              </p>
              <div className="landingPageSections" key="t2">
                <p className="bodyText">
                  {setText("why_now", "body1", selectedLanguage)}
                </p>
                <p className="sectionTitle2">
                  {/* Why is this work important? */}
                  {setText("why_now", "subtitle1", selectedLanguage)}
                </p>

                <p className="bodyText">
                  {setText("why_now", "body2", selectedLanguage)}
                </p>
                <ul className="bodyText">
                  <li> {setText("why_now", "body3", selectedLanguage)} </li>
                  <li> {setText("why_now", "body4", selectedLanguage)} </li>
                  <li> {setText("why_now", "body5", selectedLanguage)} </li>
                </ul>
                <p className="bodyText">
                  {setText("why_now", "body6", selectedLanguage)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#fff");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"85%"}
      >
        <Container
          fluid
          style={{
            padding: "5%",
            minHeight: "90vh",
            height: "100%",
            backgroundColor: "#fff",
          }}
          id="whats-next"
          className="card-white"
        >
          {/* <Row>
            <p className="sectionTitle">
              {setText("whats_next", "title", selectedLanguage)}
            </p>
          </Row> */}
          <Row>
            <Col>
              {/* <Parallax y={[20, -10]} disabled={!isDesktop}> */}
              <div className="landingPageSections" id="goals-div">
                <p className="sectionTitle">
                  {/* goals */}
                  {setText("whats_next", "subtitle1", selectedLanguage)}
                </p>

                <div className="bodyText">
                  {setText("whats_next", "body1", selectedLanguage)}
                  <ul>
                    <li>{setText("whats_next", "body2", selectedLanguage)} </li>
                    <li>{setText("whats_next", "body3", selectedLanguage)} </li>
                    <li>{setText("whats_next", "body4", selectedLanguage)} </li>
                    <li>{setText("whats_next", "body5", selectedLanguage)} </li>
                  </ul>
                  <br></br>
                  {setText("whats_next", "body6", selectedLanguage)}
                  <HashLink
                    smooth
                    to="/#why-now"
                    className="inline-link menu-item"
                  >
                    {setText("whats_next", "link1", selectedLanguage)}
                  </HashLink>
                  {setText("whats_next", "body7", selectedLanguage)}
                </div>
              </div>
              {/* </Parallax> */}
            </Col>
            <br></br>
            <Col>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/image-3.png"
                style={{ width: "100%" }}
                className="landing-image"
                id="goals-image"
                aria-label="Image of students"
              />
            </Col>
          </Row>
          <br></br>
          <br></br>

          <Row style={{ paddingTop: "1em" }}>
            <b className="sectionTitle2">
              {setText("whats_next", "subtitle2", selectedLanguage)}
            </b>
            <br></br>
          </Row>
          <Row>
            <Col
              xs={4}
              className="paddingRight"
              style={{ width: "100%", paddingRight: "2em" }}
            >
              {/* left column */}
              <p className="bodyText">
                {setText("whats_next", "body15", selectedLanguage)}
              </p>

              <p className="bodyText">
                <b>{setText("whats_next", "subsubtitle9", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body16", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} className="paddingLeft">
              {/* right column */}
              <p className="bodyText">
                <b>
                  {setText("whats_next", "subsubtitle10", selectedLanguage)}
                </b>

                <br></br>
                {setText("whats_next", "body17", selectedLanguage)}
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "1em" }}>
            <b className="sectionTitle2">
              {setText("whats_next", "subsubtitle1", selectedLanguage)}
            </b>
            <br></br>
          </Row>
          <Row>
            <Col
              xs={4}
              className="paddingRight"
              style={{ width: "100%", paddingRight: "2em" }}
            >
              {/* left column */}
              <p className="bodyText">
                {setText("whats_next", "body8", selectedLanguage)}
              </p>
              <p className="bodyText">
                <b>{setText("whats_next", "subsubtitle2", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body9", selectedLanguage)}
              </p>
              <p className="bodyText">
                <b>{setText("whats_next", "subsubtitle3", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body10", selectedLanguage)}
              </p>

              <div className="bodyText">
                <b>{setText("whats_next", "subsubtitle4", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body11", selectedLanguage)}
              </div>
              <br></br>
            </Col>
            <Col xs={4} className="paddingLeft">
              {/* right column*/}
              <p className="bodyText">
                <b>{setText("whats_next", "subsubtitle5", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body12", selectedLanguage)}
              </p>
              <p className="bodyText">
                <b>{setText("whats_next", "subsubtitle6", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body13", selectedLanguage)}
                <br></br>
                <br></br>
                <b>{setText("whats_next", "subsubtitle7", selectedLanguage)}</b>
                <br></br>
                {setText("whats_next", "body14", selectedLanguage)}
              </p>
              <br></br>
              <br></br>
            </Col>
          </Row>

          <Row
            className="footerButtonContainer footerButtonContainerWhite"
            style={{ paddingBottom: "0" }}
          >
            <Button
              variant="outlined"
              className="footerButton styledButton"
              id="need-to-know-button"
              aria-label="Button to navigate to What You'll Need to Know page"
            >
              <HashLink
                to="NeedToKnow/#measuring-quality-school-facility"
                style={{ color: "#0b315b" }}
                className="buttonLink"
              >
                WHAT YOU'LL NEED TO KNOW
              </HashLink>
            </Button>
          </Row>
        </Container>
      </Waypoint>
    </ParallaxProvider>
  );
}
