function NavbarColor(color) {
  var bar = document.getElementById("navbar");
  // if ensures that page will load after refreshing- e.g. after refreshing on map page.
  if (bar) {
    bar.setAttribute(
      "style",
      "height:89px; width: 100vw; position:fixed; top:0; left:0; background-color:" +
        color
    );
  }
  // // console.log("changed colors");
}

export default NavbarColor;
