import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import NoticeSnackbar from "./Notice";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CommunityConversations() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Stack
      spacing={4}
      sx={{ width: "20%" }}
      direction="column"
      alignitems="center"
    >
      <Item>
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "40%" }}
            icon={false}
          >
            <a
              className="inline-link dark-blue menu-item"
              href="https://forms.gle/yb6y88TGB7iKGzAe7"
              target="_blank"
            >
              CLICK HERE
            </a>{" "}
            to join a community conversation!
            {/* update this so that it shows the process guide, and/or the feedback form? */}
            {/* maybe make it a modal instead? so that it's impossible to miss */}
          </Alert>
        </Snackbar>
      </Item>
      <Item>
        <NoticeSnackbar />
      </Item>
    </Stack>
  );
}
