import { Bar, Chart } from "react-chartjs-2";
import { ColorPicker, UtilColorPicker } from "./ColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
// import QualityLegend from "./QualityLegend";
import ScoreDefinitions from "../utils/ScoreDefinitions";
// import isMobile from "react-device-detect";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

// a bit of a hack but returns short names if on mobile. should
// probably do the same thing for the description line breaks tbh
if (window.screen.width < 956) {
  var labels = ["FCA", "ESA", "WCS", "SU"];
  var chartLabelSize = 18;
} else {
  var labels = [
    ["Facilities Condition", "Assessment (FCA)"],
    ["Educational Suitability", "Assessment (ESA)"],
    ["Weighted Combined Score", "(60% ESA & 40% FCA)"],
    ["Current Utilization", "(2021-2022)"],
  ];
  var chartLabelSize = 20;
}
Chart.register(ChartDataLabels);
function isOverUtilized(val) {
  if (val <= 100) {
    return 100;
  } else if (val > 100 && val <= 120) {
    return 120;
  } else if (val > 120 && val <= 140) {
    return 140;
  }
}

function checkForSpecialCodes(val) {
  if (val === 9997) {
    // "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building."
    return 99.9777;
  } else if (val === 9995) {
    // "Please note this facility's Assessment is in Progress."
    return 99.9555;
  } else if (val === 9993) {
    // "Please note this facility was not included in the 2020-2021 Forecast Report as it was in construction. An updated forecast will include this catchment information. "
    return 99.9333;
  } else {
    return val;
  }
}

export function BarChart(dd) {
  // for now reads in the feature data from mapbox; will have to update for django
  // // console.log(dd.data);
  // console.log(dd.data);
  // if (dd.data["fpp_Ed Suitability"] > 1000) {
  //   // // console.log("found one");
  //   // TODO: replace with logic that Kathy sent.
  // }

  var util = 0;
  var fca = 0;
  var esa = 0;
  var wcs = 0;
  var enr = 0;
  var utilColor = "#f0f0f0";
  var fcaColor = "#f0f0f0";
  var esaColor = "#f0f0f0";
  var wcsColor = "#f0f0f0";
  var enrColor = "#f0f0f0";

  var utilLabel = "Not Available";
  var fcaLabel = "Not Available";
  var esaLabel = "Not Available";
  var wcsLabel = "Not Available";
  var enrLabel = "Not Available";

  if (dd.data["renovations"] > 0) {
    util = 0;
    fca = 0;
    esa = 0;
    wcs = 0;
    // enr = 0;

    utilColor = "#f0f0f0";
    fcaColor = "#f0f0f0";
    esaColor = "#f0f0f0";
    wcsColor = "#f0f0f0";
    // enrColor = "#f0f0f0";

    utilLabel = "Not Available";
    fcaLabel = "Not Available";
    esaLabel = "Not Available";
    wcsLabel = "Not Available";
    // enrLabel = "Not Available";
  } else {
    util = dd.data["utilization_2122"] * 100;
    fca = dd.data["fca_2122"] * 100;
    esa = dd.data["esa_2122"] * 100;
    wcs = dd.data["wcs_2122"] * 100;
    // enr = dd.data["fca_2122"];

    utilColor = UtilColorPicker(dd.data["utilization_2122"] * 100);
    fcaColor = ColorPicker(dd.data["fca_2122"] * 100);
    esaColor = ColorPicker(dd.data["esa_2122"] * 100);
    wcsColor = ColorPicker(dd.data["wcs_2122"] * 100);
    // enrColor = "#f0f0f0";

    utilLabel = "Not Available";
    fcaLabel = "Not Available";
    esaLabel = "Not Available";
    wcsLabel = "Not Available";
    // enrLabel = "Not Available";
  }

  // var util = 100;
  // var utilColor = "#f0f0f0";
  // if (dd.data["utilization_2122"]) {
  //   util = dd.data["utilization_2122"] * 100;
  //   utilColor = UtilColorPicker(dd.data["utilization_2122"] * 100);
  // } else {
  //   util = 99.9777;
  //   utilColor = "#f0f0f0";
  // }
  // console.log(dd.data);
  const d = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: [
          fca,
          esa,
          wcs,
          util,
          // need to account for dummy data points (999*)
          // checkForSpecialCodes(dd.data["fpp_Facilities Condition Score"]),
          // checkForSpecialCodes(dd.data["fpp_Ed Suitability"]),

          // checkForSpecialCodes(dd.data["fpp_Combined Score"]),
          // checkForSpecialCodes(
          //   Number(dd.data["fpp_Utilization 2020-2021"].split("%")[0])
          // ),
          // dd.data["fca_2122"] * 100,
          // dd.data["esa_2122"] * 100,
          // dd.data["wcs_2122"] * 100,
          // // dd.data["utilization_2122"] * 100,
          // util,
        ],
        backgroundColor: [
          // ColorPicker(dd.data["fpp_Facilities Condition Score"]),
          // ColorPicker(dd.data["fpp_Ed Suitability"]),

          // ColorPicker(dd.data["fpp_Combined Score"]),
          // UtilColorPicker(
          //   Number(dd.data["fpp_Utilization 2020-2021"].split("%")[0])
          // ),
          fcaColor,
          esaColor,
          wcsColor,
          utilColor,
          // ColorPicker(dd.data["fca_2122"] * 100),
          // ColorPicker(dd.data["esa_2122"] * 100),
          // ColorPicker(dd.data["wcs_2122"] * 100),
          // // UtilColorPicker(util),
          // utilColor,
        ],
        hoverBackgroundColor: [
          fcaColor,
          esaColor,
          wcsColor,
          utilColor,
          // ColorPicker(dd.data["fpp_Facilities Condition Score"]),
          // ColorPicker(dd.data["fpp_Ed Suitability"]),

          // ColorPicker(dd.data["fpp_Combined Score"]),
          // UtilColorPicker(
          //   Number(dd.data["fpp_Utilization 2020-2021"].split("%")[0])
          // ),
          // ColorPicker(dd.data["fca_2122"] * 100),
          // ColorPicker(dd.data["esa_2122"] * 100),
          // ColorPicker(dd.data["wcs_2122"] * 100),
          // UtilColorPicker(dd.data["utilization_2122"] * 100),
        ],
        borderColor: [
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const opts = {
    maintainAspectRatio: true,
    responsive: true,
    onClick: (e) => {
      // // console.log(e);
    },
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    font: {
      size: 20,
      color: "#000",
    },

    plugins: {
      legend: {
        position: "",
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        color: "white",
        display: function (context) {
          // return context.dataset.data[context.dataIndex] > 15;
          return 10;
        },
        font: {
          weight: 600,
          size: chartLabelSize,
          family: "Arial Black", //was "Arial Black"
        },
        anchor: "center",
        align: "bottom",
        offset: -14, // QC on
        formatter: (value, c) => {
          if (value === 99.9333) {
            return "Not Available";
          } else if (value === 99.9777) {
            return "Not Available";
          } else if (value === 99.9555) {
            return "Not Available";
          } else {
            return Math.round(value);
          }
        }, //Math.round,
      },
      tooltip: {
        enabled: true,
        bodyFont: {
          size: 16,
        },
        callbacks: {
          label: function (context) {
            // // console.log(context);
            // var lab = context.tooltip.dataPoints[0].raw;
            // // console.log(lab);
            if (context.label === "Educational Suitability,Assessment (ESA)") {
              if (context.raw === 9997) {
                return "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building.";
              } else if (context.raw === 9995) {
                return "Please note this facility's Assessment is in Progress.";
              } else if (context.raw === 9993) {
                return "Please note this facility was not included in the 2020-2021 Forecast Report as it was in construction. An updated forecast will include this catchment information. ";
              } else if (context.raw >= 90) {
                return ScoreDefinitions.esa.excellent;
              } else if (context.raw >= 80) {
                return ScoreDefinitions.esa.good;
              } else if (context.raw >= 70) {
                return ScoreDefinitions.esa.fair;
              } else if (context.raw >= 60) {
                return ScoreDefinitions.esa.poor;
              } else {
                return ScoreDefinitions.esa.unsatisfactory;
              }
            } else if (
              context.label === "Facilities Condition,Assessment (FCA)"
            ) {
              if (context.raw === 99.97) {
                return "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building.";
              } else if (context.raw === 99.95) {
                return "Please note this facility's Assessment is in Progress.";
              } else if (context.raw === 99.93) {
                return "Please note this facility was not included in the 2020-2021 Forecast Report as it was in construction. An updated forecast will include this catchment information. ";
              } else if (context.raw >= 90) {
                return ScoreDefinitions.fci.new_like_new;
              } else if (context.raw >= 80) {
                return ScoreDefinitions.fci.good;
              } else if (context.raw >= 70) {
                return ScoreDefinitions.fci.fair;
              } else if (context.raw >= 60) {
                return ScoreDefinitions.fci.poor;
              } else {
                return ScoreDefinitions.fci.unsatisfactory;
              }
            } else if (
              context.label === "Weighted Combined Score,(60% ESA & 40% FCA)"
            ) {
              if (context.raw === 99.97) {
                return "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building.";
              } else if (context.raw === 99.95) {
                return "Please note this facility's Assessment is in Progress.";
              } else if (context.raw === 99.93) {
                return "Please note this facility was not included in the 2020-2021 Forecast Report as it was in construction. An updated forecast will include this catchment information. ";
              } else if (context.raw >= 90) {
                return ScoreDefinitions.combined_score.excellent;
              } else if (context.raw >= 80) {
                return ScoreDefinitions.combined_score.good;
              } else if (context.raw >= 70) {
                return ScoreDefinitions.combined_score.fair;
              } else if (context.raw >= 60) {
                return ScoreDefinitions.combined_score.poor;
              } else {
                return ScoreDefinitions.combined_score.unsatisfactory;
              }
            } else if (
              context.label === "Current Utilization,(2021-2022)" //"Projected Utilization,for 2025-2026"
            ) {
              if (Number(context.raw) === 99.97) {
                return "Please note this facility was not included in the Assessment because The School District of Philadelphia does not own the building.";
              } else if (Number(context.raw) === 99.95) {
                return "Please note this facility's Assessment is in Progress.";
              } else if (Number(context.raw) === 99.93) {
                return [
                  "Please note this facility was not included in the 2021-2022",
                  "Forecast Report as it was in construction. An updated forecast",
                  " will include this catchment information. ",
                ];
              } else if (Number(context.raw) > 110) {
                return ScoreDefinitions.utilization.g110;
              } else if (Number(context.raw) >= 95) {
                return ScoreDefinitions.utilization.b95_110;
              } else if (Number(context.raw) >= 75) {
                return ScoreDefinitions.utilization.b75_95;
              } else if (Number(context.raw) >= 60) {
                return ScoreDefinitions.utilization.b60_75;
              } else {
                return ScoreDefinitions.utilization.l60;
              }
            } else {
              return context.formattedValue;
            }
          },
          title: function () {},
        },
      },
    },
    scales: {
      // per https://www.chartjs.org/docs/latest/ (version 3.5.1 selected)
      x: {
        // will have to adjust if it's overutilized
        max: isOverUtilized(
          // Number(dd.data["fpp_Utilization 2020-2021"].split("%")[0])
          util
        ),
        ticks: {
          color: "#000",
          font: { size: 16, family: "Arial" },

          callback: function (e) {
            // // console.log(e);
            return e;
          },
        },
        // stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          color: "#000",
          font: { size: 14, family: "Arial" },
        },
        grid: {
          // https://www.chartjs.org/docs/latest/axes/styling.html#grid-line-configuration
          display: false,
          drawBorder: false,
        },
      },
    },
  };
  return (
    <div>
      <Bar data={d} options={opts} plugins={ChartDataLabels} id="bar-chart" />
    </div>
  );
}
