const EdSuitability = {
  "Safety and Security": {
    controlled_entrances: [
      {
        label: "Safety and Security: Controlled Entrances",
        value: "response_safety_and_security_controlled_entrances",
        comment: "comment_safety_and_security_controlled_entrances",
      },
    ],
    ease_of_supervision: [
      {
        label: "Safety and Security: Ease of Supervision",
        value: "response_safety_and_security_ease_of_supervision",
        comment: "comment_safety_and_security_ease_of_supervision",
      },
    ],
    fencing: [
      {
        label: "Safety and Security: Fencing",
        value: "response_safety_and_security_fencing",
        comment: "comment_safety_and_security_fencing",
      },
    ],
    signage_way_finding: [
      {
        label: "Safety and Security: Signage & Way Finding",
        value: "response_safety_and_security_signage_way_finding",
        comment: "comment_safety_and_security_signage_way_finding",
      },
    ],
  },
  "Support Spaces": {
    administration: [
      {
        label: "Support Spaces: Administration",
        value: "response_support_spaces_administration",
        comment: "comment_support_spaces_administration",
      },
    ],
    cafeteria: [
      {
        label: "Support Spaces: Cafeteria",
        value: "response_support_spaces_cafeteria",
        comment: "comment_support_spaces_cafeteria",
      },
    ],
    clinic: [
      {
        label: "Support Spaces: Clinic",
        value: "response_support_spaces_clinic",
        comment: "comment_support_spaces_clinic",
      },
    ],
    counseling: [
      {
        label: "Support Spaces: Counseling",
        value: "response_support_spaces_counseling",
        comment: "comment_support_spaces_counseling",
      },
    ],
    custodial_and_mainenance: [
      {
        label: "Support Spaces: Custodial and Maintenance",
        value: "response_support_spaces_custodial_and_maintenance",
        comment: "comment_support_spaces_custodial_and_maintenance",
      },
    ],
    food_service_and_prep: [
      {
        label: "Support Spaces: Food Service and Prep",
        value: "response_support_spaces_food_service_and_prep",
        comment: "comment_support_spaces_food_service_and_prep",
      },
    ],
    restrooms_student: [
      {
        label: "Support Spaces: Restrooms (Student)",
        value: "response_support_spaces_restrooms_student",
        comment: "comment_support_spaces_restrooms_student",
      },
    ],
    staff_wkrm_toilets: [
      {
        label: "Support Spaces: Staff WkRm/Toilets",
        value: "response_support_spaces_staff_wkrm_toilets",
        comment: "comment_support_spaces_staff_wkrm_toilets",
      },
    ],
  },
  "Learning Environment": {
    style_variety: [
      {
        label: "Learning Environment: Learning Style Variety",
        value: "response_learning_environment_learning_style_variety",
        comment: "comment_learning_environment_learning_style_variety",
      },
    ],
    interior_environment: [
      {
        label: "Learning Environment: Interior Environment",
        value: "response_learning_environment_interior_environment",
        comment: "comment_learning_environment_interior_environment",
      },
    ],
    exterior_environment: [
      {
        label: "Learning Environment: Exterior Environment",
        value: "response_learning_environment_exterior_environment",
        comment: "comment_learning_environment_exterior_environment",
      },
    ],
  },
  "Educational Facilities": {
    art: [
      {
        label: "Art: Environment",
        value: "response_art_environment",
        comment: "comment_art_environment",
      },
      {
        label: "Art: Location",
        value: "response_art_location",
        comment: "comment_art_location",
      },
      {
        label: "Art: Size",
        value: "response_art_size",
        comment: "comment_art_size",
      },
      {
        label: "Art: Storage/Fixed Equip",
        value: "response_art_storage_fixed_equip",
        comment: "comment_art_storage_fixed_equip",
      },
    ],
    automotive: [
      {
        label: "Automotive: Environment",
        value: "response_automotive_environment",
        comment: "comment_automotive_environment",
      },
      {
        label: "Automotive: Location",
        value: "response_automotive_location",
        comment: "comment_automotive_location",
      },
      {
        label: "Automotive: Size",
        value: "response_automotive_size",
        comment: "comment_automotive_size",
      },
      {
        label: "Automotive: StorageFixed Equip.",
        value: "response_automotive_storage_fixed_equip",
        comment: "comment_automotive_storage_fixed_equip",
      },
    ],
    career_tech_ed: [
      {
        label: "Career Tech Ed: Environment",
        value: "response_career_tech_ed_environment",
        comment: "comment_career_tech_ed_environment",
      },
      {
        label: "Career Tech Ed: Location",
        value: "response_career_tech_ed_location",
        comment: "comment_career_tech_ed_location",
      },
      {
        label: "Career Tech Ed: Size",
        value: "response_career_tech_ed_size",
        comment: "comment_career_tech_ed_size",
      },
      {
        label: "Career Tech Ed: Storage/Fixed Equip",
        value: "response_career_tech_ed_storage_fixed_equip",
        comment: "comment_career_tech_ed_storage_fixed_equip",
      },
    ],
    computer_labs: [
      {
        label: "Computer Labs: Environment",
        value: "response_computer_labs_environment",
        comment: "comment_computer_labs_environment",
      },
      {
        label: "Computer Labs: Location",
        value: "response_computer_labs_location",
        comment: "comment_computer_labs_location",
      },
      {
        label: "Computer Labs: Size",
        value: "response_computer_labs_size",
        comment: "comment_computer_labs_size",
      },
      {
        label: "Computer Labs: Storage/Fixed Equip",
        value: "response_computer_labs_storage_fixed_equip",
        comment: "comment_computer_labs_storage_fixed_equip",
      },
    ],
    ece: [
      // NOTE THAT only comment_ece_environment field exists, compared to response_ which has all four fields. will need to update the model.
      {
        label: "ECE: Environment",
        value: "response_ece_environment",
        comment: "comment_ece_environment",
      },
      {
        label: "ECE: Location",
        value: "response_ece_location",
        comment: "comment_ece_location",
      },
      {
        label: "ECE: Size",
        value: "response_ece_size",
        comment: "comment_ece_size",
      },
      {
        label: "ECE: Storage/Fixed Equip",
        value: "response_ece_storage_fixed_equip",
        comment: "comment_ece_storage_fixed_equip",
      },
    ],
    general_classrooms: [
      {
        label: "General Classrooms: Environment",
        value: "response_general_classrooms_environment",
        comment: "comment_general_classrooms_environment",
      },
      {
        label: "General Classrooms: Location",
        value: "response_general_classrooms_location",
        comment: "comment_general_classrooms_location",
      },
      {
        label: "General Classrooms: Size",
        value: "response_general_classrooms_size",
        comment: "comment_general_classrooms_size",
      },
      {
        label: "General Classrooms: Storage/Fixed Equip",
        value: "response_general_classrooms_storage_fixed_equip",
        comment: "comment_general_classrooms_storage_fixed_equip",
      },
    ],
    instructional_resource_rooms: [
      {
        label: "Instructional Resource Rooms: Environment",
        value: "response_instructional_resource_rooms_environment",
        comment: "comment_instructional_resource_rooms_environment",
      },
      {
        label: "Instructional Resource Rooms: Location",
        value: "response_instructional_resource_rooms_location",
        comment: "comment_instructional_resource_rooms_location",
      },
      {
        label: "Instructional Resource Rooms: Size",
        value: "response_instructional_resource_rooms_size",
        comment: "comment_instructional_resource_rooms_size",
      },
      {
        label: "Instructional Resource Rooms: Storage/Fixed Equip",
        value: "response_instructional_resource_rooms_storage_fixed_equip",
        comment: "comment_instructional_resource_rooms_storage_fixed_equip",
      },
    ],
    kindergarten: [
      {
        label: "Kindergarten: Environment",
        value: "response_kindergarten_environment",
        comment: "comment_kindergarten_environment",
      },
      {
        label: "Kindergarten: Location",
        value: "response_kindergarten_location",
        comment: "comment_kindergarten_location",
      },
      {
        label: "Kindergarten: Size",
        value: "response_kindergarten_size",
        comment: "comment_kindergarten_size",
      },
      {
        label: "Kindergarten: Storage/Fixed Equip",
        value: "response_kindergarten_storage_fixed_equip",
        comment: "comment_kindergarten_storage_fixed_equip",
      },
    ],
    maker_space: [
      {
        label: "Maker Space: Environment",
        value: "response_maker_space_environment",
        comment: "comment_maker_space_environment",
      },
      {
        label: "Maker Space: Location",
        value: "response_maker_space_location",
        comment: "comment_maker_space_location",
      },
      {
        label: "Maker Space: Size",
        value: "response_maker_space_size",
        comment: "comment_maker_space_size",
      },
      {
        label: "Maker Space: Storage/Fixed Equip",
        value: "response_maker_space_storage_fixed_equip",
        comment: "comment_maker_space_storage_fixed_equip",
      },
    ],
    media_center: [
      {
        label: "Media Center: Environment",
        value: "response_media_center_environment",
        comment: "comment_media_center_environment",
      },
      {
        label: "Media Center: Location",
        value: "response_media_center_location",
        comment: "comment_media_center_location",
      },
      {
        label: "Media Center: Size",
        value: "response_media_center_size",
        comment: "comment_media_center_size",
      },
      {
        label: "Media Center: Storage/Fixed Equip",
        value: "response_media_center_storage_fixed_equip",
        comment: "comment_media_center_storage_fixed_equip",
      },
    ],
    music: [
      {
        label: "Music: Environment",
        value: "response_music_environment",
        comment: "comment_music_environment",
      },
      {
        label: "Music: Location",
        value: "response_music_location",
        comment: "comment_music_location",
      },
      {
        label: "Music: Size",
        value: "response_music_size",
        comment: "comment_music_size",
      },
      {
        label: "Music: Storage/Fixed Equip",
        value: "response_music_storage_fixed_equip",
        comment: "comment_music_storage_fixed_equip",
      },
    ],
    outside: [
      {
        label: "Music: Environment",
        value: "response_music_environment",
        comment: "comment_music_environment",
      },
      {
        label: "Music: Location",
        value: "response_music_location",
        comment: "comment_music_location",
      },
      {
        label: "Music: Size",
        value: "response_music_size",
        comment: "comment_music_size",
      },
      {
        label: "Music: Storage/Fixed Equip",
        value: "response_music_storage_fixed_equip",
        comment: "comment_music_storage_fixed_equip",
      },
    ],
    pe: [
      {
        label: "P.E.: Environment",
        value: "response_pe_environment",
        comment: "comment_pe_environment",
      },
      {
        label: "P.E.: Location",
        value: "response_pe_location",
        comment: "comment_pe_location",
      },
      {
        label: "P.E.: Size",
        value: "response_pe_size",
        comment: "comment_pe_size",
      },
      {
        label: "P.E.: Storage/Fixed Equip",
        value: "response_pe_storage_fixed_equip",
        comment: "comment_pe_storage_fixed_equip",
      },
    ],
    performing_arts: [
      {
        label: "Performing Arts: Environment",
        value: "response_performing_arts_environment",
        comment: "comment_performing_arts_environment",
      },
      {
        label: "Performing Arts: Location",
        value: "response_performing_arts_location",
        comment: "comment_performing_arts_location",
      },
      {
        label: "Performing Arts: Size",
        value: "response_performing_arts_size",
        comment: "comment_performing_arts_size",
      },
      {
        label: "Performing Arts: Storage/Fixed Equip",
        value: "response_performing_arts_storage_fixed_equip",
        comment: "comment_performing_arts_storage_fixed_equip",
      },
    ],
    pre_k: [
      {
        label: "Pre: K: Environment",
        value: "response_pre_k_environment",
        comment: "comment_pre_k_environment",
      },
      {
        label: "Pre: K: Location",
        value: "response_pre_k_location",
        comment: "comment_pre_k_location",
      },
      {
        label: "Pre: K: Size",
        value: "response_pre_k_size",
        comment: "comment_pre_k_size",
      },
      {
        label: "Pre: K: Storage/Fixed Equip",
        value: "response_pre_k_storage_fixed_equip",
        comment: "comment_pre_k_storage_fixed_equip",
      },
    ],
    science: [
      {
        label: "Science: Environment",
        value: "response_science_environment",
        comment: "comment_science_environment",
      },
      {
        label: "Science: Location",
        value: "response_science_location",
        comment: "comment_science_location",
      },
      {
        label: "Science: Size",
        value: "response_science_size",
        comment: "comment_science_size",
      },
      {
        label: "Science: Storage/Fixed Equip",
        value: "response_science_storage_fixed_equip",
        comment: "comment_science_storage_fixed_equip",
      },
    ],
    self_contained_special_ed: [
      {
        label: "Self: Contained Special Ed: Environment",
        value: "response_self_contained_special_ed_environment",
        comment: "comment_self_contained_special_ed_environment",
      },
      {
        label: "Self: Contained Special Ed: Location",
        value: "response_self_contained_special_ed_location",
        comment: "comment_self_contained_special_ed_location",
      },
      {
        label: "Self: Contained Special Ed: Size",
        value: "response_self_contained_special_ed_size",
        comment: "comment_self_contained_special_ed_size",
      },
      {
        label: "Self: Contained Special Ed: Storage/Fixed Equip",
        value: "response_self_contained_special_ed_storage_fixed_equip",
        comment: "comment_self_contained_special_ed_storage_fixed_equip",
      },
    ],
  },
  "Technology Readiness": {
    comm_it_equipment_environment: [
      {
        label: "Technology Readiness: CommIT Equipment Environment",
        value: "response_technology_readiness_comm_it_equipment_environment",
        comment: "comment_technology_readiness_comm_it_equipment_environment",
      },
    ],
    cooling: [
      {
        label: "Technology Readiness: Cooling",
        value: "response_technology_readiness_cooling",
        comment: "comment_technology_readiness_cooling",
      },
    ],
    electrical_power: [
      {
        label: "Technology Readiness: Electrical Power",
        value: "response_technology_readiness_electrical_power",
        comment: "comment_technology_readiness_electrical_power",
      },
    ],
    equity_of_access: [
      {
        label: "Technology Readiness: Equity of Access",
        value: "response_technology_readiness_equity_of_access",
        comment: "comment_technology_readiness_equity_of_access",
      },
    ],
    faculty_staff_technology: [
      {
        label: "Technology Readiness: Faculty & Staff Technology",
        value: "response_technology_readiness_faculty_staff_technology",
        comment: "comment_technology_readiness_faculty_staff_technology",
      },
    ],
    lan_connectivity: [
      {
        label: "Technology Readiness: LAN Connectivity",
        value: "response_technology_readiness_lan_connectivity",
        comment: "comment_technology_readiness_lan_connectivity",
      },
    ],
    lan_wan_performance: [
      {
        label: "Technology Readiness: LAN: WAN Performance",
        value: "response_technology_readiness_lan_wan_performance",
        comment: "comment_technology_readiness_lan_wan_performance",
      },
    ],
    video_distribution: [
      {
        label: "Technology Readiness: Video Distribution",
        value: "response_technology_readiness_video_distribution",
        comment: "comment_technology_readiness_video_distribution",
      },
    ],
    voice_distribution: [
      {
        label: "Technology Readiness: Voice Distribution",
        value: "response_technology_readiness_voice_distribution",
        comment: "comment_technology_readiness_voice_distribution",
      },
    ],
    wan_backbone: [
      {
        label: "Technology Readiness: WAN Backbone",
        value: "response_technology_readiness_wan_backbone",
        comment: "comment_technology_readiness_wan_backbone",
      },
    ],
  },
};

export default EdSuitability;
