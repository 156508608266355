import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Container, Row, Image, Col, ListGroup, Card } from "react-bootstrap";
import "../index.css";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { CSSTransitionGroup, TransitionGroup } from "react-transition-group";
import { Waypoint } from "react-waypoint";
import { setText } from "../utils/Text";
import EmbedVideo from "../components/VideoEmbed";
import NavbarColor from "../utils/NavBarColor";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isBrowser } from "react-device-detect";
import { ShowTargetSchools } from "../components/TargetSchools";
import { Grid } from "@mui/material";
import PlayAudio from "../components/AudioPlayer";
// makes sure section headers aren't cut off on menu navigation
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -89;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default function NeedToKnow(props) {
  const mySettings = props.settings;
  const selectedLanguage = mySettings.language;

  const [visible, setVisible] = React.useState(false);
  const toggleHidden = () => {
    setVisible(!visible);
  };
  return (
    <ParallaxProvider>
      <Waypoint
        onEnter={function () {
          if (isBrowser) {
            NavbarColor("#fff");
            document
              .getElementById("sdp-logo-image")
              .setAttribute("style", "visibility: visible");
          } else {
            NavbarColor("#fff");
          }
        }}
        fireOnRapidScroll={true}
        bottomOffset={"95%"}
        debug={false}
      >
        <Container
          fluid
          className="card-white"
          id="measuring-quality-school-facility"
        >
          <Row>
            <Col className="ntk-column-left" id="need-to-know-top">
              <p className="sectionTitle">
                {setText("need_to_know", "title1", selectedLanguage)}
              </p>
              <p className="sectionTitle2">
                {setText("need_to_know", "subtitle1", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body1", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body2", selectedLanguage)}{" "}
                {/* <HashLink
                  id="home"
                  className="menu-item inline-link"
                  to="/"
                  scroll={scrollWithOffset}
                >
                  {setText("need_to_know", "link1", selectedLanguage)}
                </HashLink> */}
                {setText("need_to_know", "body3", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} className="fpp-column-right">
              <p className="sectionTitle2" id="four-important-scores">
                {setText("need_to_know", "subtitle2", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body4", selectedLanguage)}
              </p>
              <Image
                src="https://wxy-sdp.s3.us-east-2.amazonaws.com/img/220412_Icons-35-27.svg"
                // className="landing-image"
                id="fpp-diagram"
                // style={{ maxWidth: "30vw" }}
                aria-label="Diagram showing the relationship between Educational Suitability Assessment, Facility Condition Assessment, Weighted Combined Score, and Enrollment and Forecast measures"
              />
            </Col>
          </Row>
        </Container>
      </Waypoint>

      <Waypoint
        onEnter={function () {
          NavbarColor("#6dcef5");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"95%"}
        debug={false}
      >
        <Container
          fluid
          className="card-blue"
          id="facilities-condition-assessment"
        >
          <Row>
            <Col xs={4} className="showOnDesktop">
              <div className="video-container">
                <EmbedVideo
                  // url="https://vimeo.com/632968999/ad991e2f05"
                  url="https://www.youtube.com/watch?v=lypSl8CmRv8"
                  aria-label="Video explaining Facilities Condition Assessment measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#facilities-condition-assessment-transcript"
                    scroll={scrollWithOffset}
                    // style={{ paddingBottom: "10px" }}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_FCA+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#facilities-condition-assessment-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>{" "}
            </Col>

            <Col xs={4} className="fpp-column-right">
              <p className="sectionTitle">
                {setText("need_to_know", "title2", selectedLanguage)}
              </p>

              <p className="bodyText">
                {setText("need_to_know", "body5", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body6", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} className="showOnMobile">
              <div className="video-container">
                <EmbedVideo
                  // url="https://vimeo.com/632968999/ad991e2f05"
                  url="https://www.youtube.com/watch?v=lypSl8CmRv8"
                  aria-label="Video explaining Facilities Condition Assessment measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#facilties-condition-assessment-transcript"
                    scroll={scrollWithOffset}
                    // style={{ paddingBottom: "10px" }}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>
                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_FCA+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#facilities-condition-assessment-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#3AAB48");
        }}
        fireOnRapidScroll={true}
        topOffset={90}
        bottomOffset={"95%"}
      >
        <Container
          fluid
          className="card-green"
          id="education-suitability-assessment"
        >
          <Row>
            <Col xs={4} className="ntk-column-left order-xs-1" order="1">
              <p className="sectionTitle">
                {setText("need_to_know", "title3", selectedLanguage)}
              </p>

              <p className="bodyText">
                {setText("need_to_know", "body7", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body8", selectedLanguage)}
              </p>
            </Col>
            <Col xs={4} order="2" className="showOnDesktop">
              <div className="video-container">
                <EmbedVideo
                  class="video-embed-container"
                  // url="https://vimeo.com/632968943/b536c47a47"
                  url="https://www.youtube.com/watch?v=XHvVoMCibQI"
                  aria-label="Video explaining Educational Suitability Assessment Assessment measure"
                />
                <br></br>

                <div className="text-link-div">
                  <HashLink
                    id="link-esa-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#educational-suitability-transcript"
                    scroll={scrollWithOffset}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>

                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_ESA+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#educational-suitability-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>{" "}
            </Col>

            <Col xs={4} order="2" className="showOnMobile">
              <div className="video-container">
                <EmbedVideo
                  class="video-embed-container"
                  // url="https://vimeo.com/632968943/b536c47a47"
                  url="https://www.youtube.com/watch?v=XHvVoMCibQI"
                  aria-label="Video explaining Educational Suitability Assessment Assessment measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#facilties-condition-assessment-transcript"
                    scroll={scrollWithOffset}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>

                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_FCA+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#facilities-condition-assessment-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#ffaa30");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"95%"}
      >
        <Container fluid className="card-yellow" id="weighted-combined-score">
          <Row>
            <Col
              xs={4}
              // style={{ paddingTop: "10%" }}
              className="showOnDesktop"
            >
              <div className="video-container">
                <EmbedVideo
                  // url="https://vimeo.com/632969900/478d2f3d37"
                  // url="https://www.youtube.com/watch?v=8Wz2uxTq_pM"
                  url="https://www.youtube.com/watch?v=kT3pyAXObb0"
                  aria-label="Video explaining Weighted Combined Score measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-wcs-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#weighted-combined-score-transcript"
                    scroll={scrollWithOffset}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>
                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_WCS+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#weighted-combined-score-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>
            </Col>
            <Col xs={4} className="fpp-column-right">
              <p className="sectionTitle">
                {setText("need_to_know", "title4", selectedLanguage)}
              </p>
              <p className="bodyText">
                {setText("need_to_know", "body9", selectedLanguage)}{" "}
                <HashLink
                  className="inline-link menu-item"
                  to="NeedToKnow#education-suitability-assessment"
                  scroll={scrollWithOffset}
                >
                  {setText("need_to_know", "link2", selectedLanguage)}{" "}
                </HashLink>{" "}
                {setText("need_to_know", "body10", selectedLanguage)}{" "}
                <HashLink
                  className="inline-link menu-item"
                  to="NeedToKnow#facilities-condition-assessment"
                  scroll={scrollWithOffset}
                >
                  {setText("need_to_know", "link3", selectedLanguage)}{" "}
                </HashLink>
                {setText("need_to_know", "body11", selectedLanguage)}
              </p>
              {/* <p className="bodyText">
                {setText("need_to_know", "body12", selectedLanguage)}
              </p> */}
            </Col>
            <Col xs={4} className="showOnMobile">
              <div className="video-container">
                <EmbedVideo
                  // url="https://vimeo.com/632969900/478d2f3d37"
                  // url="https://www.youtube.com/watch?v=8Wz2uxTq_pM"
                  url="https://www.youtube.com/watch?v=kT3pyAXObb0"
                  aria-label="Video explaining Weighted Combined Score measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-se-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#student-enrollment-transcript"
                    scroll={scrollWithOffset}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>
                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_Student+Enrollment+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#student-enrollment-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Waypoint>
      <Waypoint
        onEnter={function () {
          NavbarColor("#e65e25");
        }}
        fireOnRapidScroll={true}
        bottomOffset={"95%"}
      >
        <Container fluid className="card-orange" id="student-enrollment">
          <Row>
            <Col xs={4} className="ntk-column-left">
              <p className="sectionTitle">
                {setText("need_to_know", "title5", selectedLanguage)}
              </p>

              <p className="bodyText">
                {setText("need_to_know", "body13", selectedLanguage)}
              </p>
              {/* <p className="bodyText">
                {setText("need_to_know", "body14", selectedLanguage)}
              </p> */}
            </Col>
            <Col xs={4}>
              <div className="video-container">
                <EmbedVideo
                  // url="https://vimeo.com/635075990/e9561f3ec6"
                  // url="https://www.youtube.com/watch?v=XUeyfjE_mV0"
                  url="https://www.youtube.com/watch?v=F-3bwmADdNM&t=7s&ab_channel=TheSchoolDistrictofPhiladelphia"
                  aria-label="Video explaining Student Enrollment measure"
                />
                <br></br>
                <div className="text-link-div">
                  <HashLink
                    id="link-se-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoTranscripts#student-enrollment-transcript"
                    scroll={scrollWithOffset}
                  >
                    Click here for video transcript
                  </HashLink>
                </div>

                {/* <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/"
                    scroll={scrollWithOffset}
                  >
                    Click here for the English audio description
                  </HashLink>
                </div> */}
                <div className="text-link-div">
                  <a
                    onClick={toggleHidden}
                    className="menu-item darkblue text-link"
                  >
                    Click here for the English audio description
                  </a>

                  {visible && (
                    <PlayAudio
                      file={
                        "https://wxy-sdp.s3.us-east-2.amazonaws.com/audio/220422_Student+Enrollment+%2B+Descriptions+Audio.m4a"
                      }
                    />
                  )}
                </div>
                <div className="text-link-div">
                  <HashLink
                    id="link-fca-transcript"
                    className="menu-item darkblue text-link"
                    to="/VideoDescriptions#student-enrollment-video-description"
                    scroll={scrollWithOffset}
                  >
                    Click here for audio description text
                  </HashLink>
                </div>
              </div>{" "}
            </Col>
          </Row>

          <Row className="footerButtonContainer">
            <Button
              variant="outlined"
              className="footerButton styledButton"
              id="explore-school-button"
              aria-label="Button to navigate to Explore Your School page"
            >
              <HashLink
                to="/Map"
                style={{
                  color: "#0b315b",
                }}
                className="buttonLink"
              >
                Explore Your School
              </HashLink>
            </Button>
          </Row>
        </Container>
      </Waypoint>
    </ParallaxProvider>
  );
}
