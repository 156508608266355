export const Schools = [
  {
    ulcs: 7200,
    label: "Barton, Clara School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8210,
    label: "Brown, Joseph H. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 7220,
    label: "Carnell, Laura H. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 1250,
    label: "Catharine, Joseph W. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5490,
    label: "Cayuga School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8370,
    label: "Comly, Watson School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5470,
    label: "Cramp, William School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 8230,
    label: "Crossan, Kennedy C. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5260,
    label: "Elkin, Lewis School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7260,
    label: "Ellwood School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 6220,
    label: "Emlen, Eleanor C. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7310,
    label: "Feltonville Intermediate School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8260,
    label: "Fox Chase School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 8250,
    label: "Forrest, Edwin School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8400,
    label: "Frank, Anne School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 2320,
    label: "Girard, Stephen School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5300,
    label: "Hackett, Horatio B. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8270,
    label: "Holme, Thomas School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7320,
    label: "Howe, Julia Ward School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 2520,
    label: "Jenks, Abram S. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 6470,
    label: "Kelly, John B. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 2540,
    label: "Key, Francis S. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7330,
    label: "Lawton, Henry W. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8440,
    label: "Loesche, William H. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 6300,
    label: "Logan, James School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7350,
    label: "Lowell, James R. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7360,
    label: "Marshall, John School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7380,
    label: "McClure, Alexander K. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5370,
    label: "Moffet, John School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8310,
    label: "Moore, J. Hampton School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 1380,
    label: "Morton, Thomas G. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 1400,
    label: "Patterson, John M. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 6340,
    label: "Pennell, Joseph School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 4380,
    label: "Peirce, Thomas M. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8410,
    label: "Pollock, Robert B. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 1390,
    label: "Powel, Samuel School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7490,
    label: "Prince Hall School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8360,
    label: "Rhawnhurst School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5400,
    label: "Richmond School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7530,
    label: "Rowen, William School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5410,
    label: "Sheppard, Isaac A. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5530,
    label: "Sheridan, Philip H. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 8340,
    label: "Solis-Cohen, Solomon School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7430,
    label: "Sullivan, James J. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7440,
    label: "Taylor, Bayard School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 7440,
    label: "Taylor, Bayard School (Annex)",
    Grades: "Elementary",
    order: 1,
    campus: "B",
  },
  {
    ulcs: 5590,
    label: "Webster, John H. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 5440,
    label: "Willard, Frances E. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },
  {
    ulcs: 4470,
    label: "Wright, Richard R. School",
    Grades: "Elementary",
    order: 1,
    campus: "A",
  },

  {
    ulcs: 4440,
    label: "Allen, Dr. Ethel School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8200,
    label: "Allen, Ethan School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1460,
    label: "Anderson, Add B. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2480,
    label: "Arthur, Chester A. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1200,
    label: "Barry, John Elementary School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7510,
    label: "Bethune, Mary M. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1490,
    label: "Blankenburg, Rudolph School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7470,
    label: "Bridesburg School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5210,
    label: "Brown, Henry A. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4240,
    label: "Cassidy, Lewis C. Academics Plus School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6410,
    label: "Cook-Wissahickon School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7100,
    label: "Cooke, Jay School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6200,
    label: "Day, Anna B. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5170,
    label: "De Burgos, Julia School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8420,
    label: "Decatur, Stephen School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4270,
    label: "Dick, William School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6450,
    label: "Dobson, James School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4460,
    label: "Duckrey, Tanner G. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6210,
    label: "Edmonds, Franklin S. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2190,
    label: "Fell, D. Newlin School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7270,
    label: "Finletter, Thomas K. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8390,
    label: "Fitzpatrick, A. L. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7280,
    label: "Franklin, Benjamin K8 School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4280,
    label: "Gompers, Samuel School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8430,
    label: "Greenberg, Joseph School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2470,
    label: "Greenfield, Albert M. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1290,
    label: "Hamilton, Andrew School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4300,
    label: "Heston, Edward School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7300,
    label: "Hopkinson, Francis School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6260,
    label: "Houston, Henry H. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5330,
    label: "Hunter, William H. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6270,
    label: "Jenks Academy for Arts and Sciences",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7150,
    label: "Juniata Park Academy",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4560,
    label: "Kelley, William D. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  // {
  //   ulcs: 4360,
  //   label: "Kenderton Elementary School",
  //   Grades: "Elementary-Middle",
  //   order: 2,
  //   campus: "A",
  // },
  {
    ulcs: 2580,
    label: "Kirkbride, Eliza B. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1340,
    label: "Lea, Henry C. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1470,
    label: "Locke, Alain School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1350,
    label: "Longstreth, William C. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5500,
    label: "Marshall, Thurgood School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2340,
    label: "McCall, General George A. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5350,
    label: "McKinley, William School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2380,
    label: "Meredith, William M. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7390,
    label: "Morrison, Andrew J. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5680,
    label: "Munoz-Marin, Honorable Luis School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4480,
    label: "Overbrook Educational Center",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1280,
    label: "Penn Alexander School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5390,
    label: "Potter-Thomas School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1410,
    label: "Rhoads, James School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2630,
    label: "Sharswood, George W. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6380,
    label: "Shawmont School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2640,
    label: "Southwark School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8350,
    label: "Spruance, Gilbert School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2450,
    label: "Stanton, Edwin M. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6390,
    label: "Steel, Edward T. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2690,
    label: "Taggart, John H. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2720,
    label: "Vare-Washington School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7460,
    label: "Ziegler, William H. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8130,
    label: "The Northeast Community Propel Academy",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6400,
    label: "Widener Memorial School",
    Grades: "Elementary-Middle-High",
    order: 6,
    campus: "A",
  },

  {
    ulcs: 2680,
    label: "Science Leadership Academy (SLA) at Beeber",
    Grades: "Middle-High",
    order: 4,
    campus: "A",
  },

  // {
  //   ulcs: 1580,
  //   label: "Middle Years Alternative (MYA) School",
  //   Grades: "Middle",
  //   order: 3,
  //   campus: "A",
  // },
  {
    ulcs: 2050,
    label: "Science Leadership Academy Middle School (SLAMS)",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },

  {
    ulcs: 2410,
    label: "Girard Academic Music Program (GAMP)",
    Grades: "Middle-High",
    order: 4,
    campus: "A",
  },
  {
    ulcs: 8160,
    label: "Baldi Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 7730,
    label: "Clemente, Roberto Middle School / The LINC",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 7500,
    label: "Feltonville School of Arts and Sciences",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 7110,
    label: "Harding, Warren G. Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 8120,
    label: "Wilson, Woodrow Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 6460,
    label: "Hill-Freedman World Academy",
    Grades: "Middle-High",
    order: 4,
    campus: "A",
  },
  {
    ulcs: 8140,
    label: "Meehan, Austin Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 4030,
    label: "Carver, George W. High School of Engineering and Science",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2620,
    label: "Academy at Palumbo",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 8040,
    label: "Arts Academy at Benjamin Rush",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5150,
    label: "Bodine, William W. High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6010,
    label: "Central High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2670,
    label: "Constitution High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2020,
    label: "Creative and Performing Arts (CAPA) High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 4060,
    label: "Dobbins, Murrell Career and Technical Education High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5020,
    label: "Edison, Thomas A. High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 7120,
    label: "Fels, Samuel High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 7010,
    label: "Frankford High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2290,
    label: "Franklin Learning Center (FLC)",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2160,
    label: "Furness, Horace High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 1030,
    label: "High School of the Future",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5550,
    label: "Kensington Health Sciences Academy",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5520,
    label: "Kensington High School for Creative and Performing Arts (CAPA)",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6060,
    label: "King, Martin Luther High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5060,
    label: "Mastbaum, Jules E. Area Vocational Technical High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5080,
    label: "Parkway Center City Middle College High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5070,
    label: "Parkway Northwest High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6090,
    label: "Randolph, A. Philip Career and Technical High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6030,
    label: "Roxborough High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6040,
    label: "Saul, Walter B. High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 1100,
    label: "Sayre, William L. High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  // {
  //   ulcs: 2650,
  //   label: "Science Leadership Academy (SLA)",
  //   Grades: "High",
  //   order: 5,
  //, campus: 'A'},
  {
    ulcs: 8090,
    label: "Swenson Arts and Technology High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  // { ulcs: 5660, label: "The LINC", Grades: "High", order: 5, campus: "A" },
  {
    ulcs: 8560,
    label: "The Workshop School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  // {
  //   ulcs: 5180,
  //   label: "Vaux High School - Big Picture",
  //   Grades: "High",
  //   order: 5,
  //   campus: "A",
  // },
  {
    ulcs: 8030,
    label: "Washington, George High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 1020,
    label: "West Philadelphia High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },

  {
    ulcs: 5200,
    label: "Adaire, Alexander School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2210,
    label: "Bache-Martin School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4220,
    label: "Blaine, James G. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2240,
    label: "Bregy, F. Amedee School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1230,
    label: "Bryant, William C. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2260,
    label: "Childs, George W. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1260,
    label: "Comegys, Benjamin B. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2510,
    label: "Coppin, Fanny Jackson",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8240,
    label: "Disston, Hamilton School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5250,
    label: "Dunbar, Paul L. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8380,
    label: "Farrell, Louis H. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6230,
    label: "Fitler Academics Plus School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4530,
    label: "Gideon, Edward School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1300,
    label: "Harrington, Avery D. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5320,
    label: "Hartranft, John F. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6250,
    label: "Henry, Charles W. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8770,
    label: "John Hancock Demonstration School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8770,
    label: "John Hancock Demonstration School (LaBrum)",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "B",
  },
  {
    ulcs: 5480,
    label: "Kearny, General Philip School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4320,
    label: "Lamberton, Robert E. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6440,
    label: "Lingelbach, Anna L. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5340,
    label: "Ludlow, James R. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 8300,
    label: "Mayfair School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6310,
    label: "McCloskey, John F. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2370,
    label: "McDaniel, Delaplaine School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1360,
    label: "McMichael, Morton School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4570,
    label: "Meade, General George G. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6320,
    label: "Mifflin, Thomas School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1370,
    label: "Mitchell, S. Weir Elementary School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2390,
    label: "Morris, Robert School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2590,
    label: "Nebinger, George W. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7400,
    label: "Olney School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4370,
    label: "Overbrook Elementary School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6350,
    label: "Pennypacker, Samuel School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1440,
    label: "Penrose School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4350,
    label: "Rhodes, E. Washington Elementary School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 6360,
    label: "Roosevelt Elementary School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5560,
    label: "Spring Garden School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 7290,
    label: "Stearne, Allen M. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 2490,
    label: "Waring, Laura W. School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 1420,
    label: "Washington, Martha School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 5420,
    label: "Welsh, John School",
    Grades: "Elementary-Middle",
    order: 2,
    campus: "A",
  },
  {
    ulcs: 4140,
    label: "Strawberry Mansion High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5160,
    label: "Penn Treaty High School",
    Grades: "Middle-High",
    order: 4,
    campus: "A",
  },
  {
    ulcs: 2140,
    label: "Masterman, Julia R. High School",
    Grades: "Middle-High",
    order: 4,
    campus: "A",
  },
  {
    ulcs: 1010,
    label: "Bartram, John High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  { ulcs: 5610, label: "Building 21", Grades: "High", order: 5, campus: "A" },
  {
    ulcs: 2010,
    label: "Franklin, Benjamin High School / Science Leadership Academy",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5700,
    label: "Kensington High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6540,
    label: "Lankenau High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 8010,
    label: "Lincoln, Abraham High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 1190,
    label: "Motivation High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 8020,
    label: "Northeast High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 4020,
    label: "Overbrook High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5090,
    label: "Parkway West High School / Middle Years Alternative",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 6050,
    label: "Philadelphia High School for Girls",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 5050,
    label: "Philadelphia Military Academy (PMA)",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 1050,
    label: "Robeson, Paul High School for Human Services",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  {
    ulcs: 2000,
    label: "South Philadelphia High School",
    Grades: "High",
    order: 5,
    campus: "A",
  },
  { ulcs: 5620, label: "The U School", Grades: "High", order: 5, campus: "A" },

  {
    ulcs: 6480,
    label: "Academy for the Middle Years (AMY) at Northwest",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 5430,
    label: "Alternative Middle Years (AMY) at James Martin",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 7130,
    label: "Wagner, General Louis Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 5230,
    label: "Conwell, Russell H. School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 1130,
    label: "Tilden, William T. Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
  {
    ulcs: 7370,
    label: "Washington, Grover Jr. Middle School",
    Grades: "Middle",
    order: 3,
    campus: "A",
  },
];
