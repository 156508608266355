export const ScoreDefinitions = {
  esa: {
    excellent: [
      "The facility is designed to provide for and",
      "support the educational/governmental programs, ",
      "offered. It may have minor suitability/",
      "functionality issues but overall, it meets",
      " the needs of the educational/governmental program.",
    ],
    good: [
      "The facility is designed to provide for",
      " and support a majority of the educational/",
      "governmental programs offered. It may",
      "have minor suitability/functionality issues",
      " but generally meets the needs of the ",
      " educational/governmental program.",
    ],
    fair: [
      "The facility has some problems meeting the",
      " needs of the educational/government program",
      " and will require remodeling/renovations.",
    ],
    poor: [
      "The facility has numerous problems meeting the needs",
      "of the educational/governmental program and needs",
      "significant remodeling, additions, or replacement.",
    ],
    unsatisfactory: [
      "The facility is unsuitable to support the",
      " educational/governmental program.",
    ],
  },
  fci: {
    new_like_new: [
      "The building and/or a majority of its systems",
      "are in good condition, and only require",
      " preventative maintenance.",
    ],
    good: [
      "The building and/or a majority of its systems ",
      "are in good condition and only require routine",
      " maintenance.",
    ],
    fair: [
      "The building and/or some of its systems are in fair",
      "condition and require minor to moderate repair.",
    ],
    poor: [
      "The building and/or a significant number of its",
      " systems are in poor condition and require major ",
      " repair, renovation, or replacement.",
    ],
    unsatisfactory: [
      "The building and/or a majority of its systems should",
      " be replaced.",
    ],
  },
  combined_score: {
    excellent: "Excellent",
    good: "Good",
    fair: "Fair",
    poor: "Poor",
    unsatisfactory: "Unsatisfactory",
  },
  utilization: {
    g110: "Inadequate Space",
    b95_110: "Approaching Inadequate Space",
    b75_95: "Adeqaute Space",
    b60_75: "Approaching Inefficient Use of Space",
    l60: "Inefficient Use of Space",
  },
};
// red, yellow, green, yellow, red
export default ScoreDefinitions;
