const Text = {
  useful_links: {
    intro: {
      es: `Esta página enlaza con materiales compartidos a lo largo del proceso de planificación de instalaciones y continuará actualizándose a lo largo de cada fase.`,
      "zh-CN": `此页面链接到整个设施规划过程中共享的材料，并将在每个阶段继续更新。`,
      vi: `Trang này liên kết đến các tài liệu được chia sẻ trong suốt Quy trình Lập kế hoạch Cơ sở vật chất và sẽ tiếp tục được cập nhật trong mỗi giai đoạn.`,
      ru: `На этой странице размещены ссылки на материалы, используемые в процессе планирования объектов, и они будут обновляться на каждом этапе.`,
      sq: `Kjo faqe lidhet me materialet e shpërndara gjatë procesit të planifikimit të objekteve dhe do të vazhdojë të përditësohet gjatë çdo faze.`,
      km: `ទំព័រនេះភ្ជាប់ទៅសម្ភារៈដែលបានចែករំលែកពេញដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ ហើយនឹងបន្តធ្វើបច្ចុប្បន្នភាពពេញមួយដំណាក់កាលនីមួយៗ។`,
      fr: `Cette page contient des liens vers des documents partagés tout au long du processus de planification des installations et continuera d'être mise à jour tout au long de chaque phase.`,
      ar: `ترتبط هذه الصفحة بالمواد التي تمت مشاركتها خلال عملية تخطيط المرافق وسيستمر تحديثها طوال كل مرحلة.`,
      en: "This page links to materials shared throughout the Facilities Planning Process and will continue to be updated throughout each phase.",
    },
  },
  snackbar1: {
    text1: {
      es: `PROCESO DE PLANIFICACIÓN DE INSTALACIONES`,
      "zh-CN": `设施规划流程`,
      vi: `QUY TRÌNH LẬP KẾ HOẠCH CƠ SỞ`,
      ru: `ПРОЦЕСС ПЛАНИРОВАНИЯ ОБЪЕКТОВ`,
      sq: `PROCESI I PLANIFIKIMIT TË OBJEKTEVE`,
      km: `ដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ`,
      fr: `PROCESSUS DE PLANIFICATION DES INSTALLATIONS`,
      ar: `عملية تخطيط المنشآت`,
      en: "FACILITIES PLANNING PROCESS",
    },
    link: {},
    text2: {},
  },
  snackbar2: {},
  navigation: {
    fpp_title: {
      es: `PROCESO DE PLANIFICACIÓN DE INSTALACIONES`,
      "zh-CN": `设施规划流程`,
      vi: `QUY TRÌNH LẬP KẾ HOẠCH CƠ SỞ`,
      ru: `ПРОЦЕСС ПЛАНИРОВАНИЯ ОБЪЕКТОВ`,
      sq: `PROCESI I PLANIFIKIMIT TË OBJEKTEVE`,
      km: `ដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ`,
      fr: `PROCESSUS DE PLANIFICATION DES INSTALLATIONS`,
      ar: `عملية تخطيط المنشآت`,
      en: "FACILITIES PLANNING PROCESS",
    },
    fpp_what_is: {
      es: `¿Qué es el proceso de planificación de instalaciones?`,
      "zh-CN": `设施规划流程是什么？`,
      vi: `Quy trình Lập kế hoạch Cơ sở vật chất là gì?`,
      ru: `Что такое процесс планирования объектов?`,
      sq: `Cili është procesi i planifikimit të objekteve?`,
      km: `តើដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារគឺជាអ្វី?`,
      fr: `Qu'est-ce que le processus de planification des installations ?`,
      ar: `ما هي عملية تخطيط المرافق؟`,
      en: "What is the Facilities Planning Process?",
    },
    fpp_why_now: {
      es: `¿Porqué ahora?`,
      "zh-CN": `为什么现在？`,
      vi: `Tại sao bây giờ?`,
      ru: `Почему сейчас?`,
      sq: `Pse tani?`,
      km: `ហេតុអ្វីឥឡូវនេះ?`,
      fr: `Pourquoi maintenant?`,
      ar: `لماذا الان؟`,
      en: "Why Now?",
    },
    fpp_happening_now: {
      es: `¿Qué ha pasado hasta ahora?`,
      "zh-CN": `到目前为止发生了什么？`,
      vi: `Điều gì đã xảy ra cho đến nay?`,
      ru: `Что произошло до сих пор?`,
      sq: `Çfarë ka ndodhur deri tani?`,
      km: `តើមានអ្វីកើតឡើងរហូតមកដល់ពេលនេះ?`,
      fr: `Que s'est-il passé jusqu'à présent ?`,
      ar: `ماذا حدث حتى الان؟`,
      en: "What's Happened So Far?",
    },
    fpp_whats_next: {
      es: `¿Que sigue?`,
      "zh-CN": `下一步是什么？`,
      vi: `Cái gì tiếp theo?`,
      ru: `Что дальше?`,
      sq: `Ç'pritet më tej?`,
      km: `មាន​អ្វី​បន្ទាប់?`,
      fr: `Et après?`,
      ar: `ماذا بعد؟`,
      en: "Goals & Timeline",
    },
    ntk_title: {
      es: `LO QUE NECESITAS SABER`,
      "zh-CN": `你需要知道什么`,
      vi: `NHỮNG GÌ BẠN CẦN BIẾT`,
      ru: `ЧТО ВАМ НУЖНО ЗНАТЬ`,
      sq: `ÇFARË DO TË DUHET TË DINI`,
      km: `អ្វីដែលអ្នកនឹងត្រូវដឹង`,
      fr: `CE QUE VOUS DEVEZ SAVOIR`,
      ar: `ما الذي ستحتاج إلى معرفته`,
      en: "WHAT YOU'LL NEED TO KNOW",
    },
    ntk_measuring: {
      es: `Medición de la calidad de una instalación escolar`,
      "zh-CN": `衡量学校设施的质量`,
      vi: `Đo lường Chất lượng của Cơ sở Trường học`,
      ru: `Измерение качества школьного учреждения`,
      sq: `Matja e cilësisë së një objekti shkollor`,
      km: `ការវាស់វែងគុណភាពនៃសម្ភារៈសិក្សា`,
      fr: `Mesurer la qualité d'une installation scolaire`,
      ar: `قياس جودة منشأة مدرسية`,
      en: "Measuring the Quality of a School Facility",
    },
    ntk_four_scores: {
      es: `Cuatro puntajes importantes que debe conocer`,
      "zh-CN": `要知道的四个重要分数`,
      vi: `Bốn điểm quan trọng cần biết`,
      ru: `Четыре важных показателя, которые нужно знать`,
      sq: `Katër pikë të rëndësishme për t'u ditur`,
      km: `ពិន្ទុសំខាន់ៗចំនួនបួនដែលត្រូវដឹង`,
      fr: `Quatre scores importants à connaître`,
      ar: `أربع درجات مهمة يجب معرفتها`,
      en: "Four Important Scores to Know",
    },
    ntk_fca: {
      es: `Evaluación del estado de las instalaciones`,
      "zh-CN": `设施状况评估`,
      vi: `Đánh giá tình trạng cơ sở vật chất`,
      ru: `Оценка состояния объектов`,
      sq: `Vlerësimi i gjendjes së objekteve`,
      km: `ការវាយតម្លៃស្ថានភាពគ្រឿងបរិក្ខារ`,
      fr: `Évaluation de l'état des installations`,
      ar: `تقييم حالة المرافق`,
      en: "Facilities Conditions Assessment",
    },
    ntk_esa: {
      es: `Evaluación de la idoneidad educativa`,
      "zh-CN": `教育适宜性评估`,
      vi: `Đánh giá tính phù hợp giáo dục`,
      ru: `Оценка пригодности для образования`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ`,
      fr: `Évaluation de la pertinence scolaire`,
      ar: `تقييم الملاءمة التعليمية`,
      en: "Educational Suitability Assessment",
    },
    ntk_wcs: {
      es: `Puntuación combinada ponderada`,
      "zh-CN": `加权综合得分`,
      vi: `Điểm kết hợp có trọng số`,
      ru: `Взвешенный комбинированный балл`,
      sq: `Rezultati i ponderuar i kombinuar`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដោយថ្លឹងថ្លែង`,
      fr: `Note combinée pondérée`,
      ar: `النتيجة المجمعة المرجحة`,
      en: "Weighted Combined Score",
    },
    ntk_enroll: {
      es: `Pronóstico de Inscripción y Utilización`,
      "zh-CN": `招生预测和利用率`,
      vi: `Dự báo tuyển sinh và sử dụng`,
      ru: `Прогноз регистрации и использование`,
      sq: `Parashikimi dhe shfrytëzimi i regjistrimit`,
      km: `ការព្យាករណ៍ និងការប្រើប្រាស់ការចុះឈ្មោះ`,
      fr: `Prévision d'inscription et utilisation`,
      ar: `توقعات التسجيل والاستخدام`,
      en: "Enrollment Forecast & Utilization",
    },
    join_conversation: {
      es: `¡ÚNETE A UNA CONVERSACIÓN COMUNITARIA!`,
      "zh-CN": `加入社区对话！`,
      vi: `THAM GIA CHUYỂN ĐỔI CỘNG ĐỒNG!`,
      ru: `ПРИСОЕДИНЯЙТЕСЬ К ОБЩЕСТВЕННОМУ ОБСУЖДЕНИЮ!`,
      sq: `BASHKONI NJË BISESË TË KOMUNITETIT!`,
      km: `ចូលរួមការសន្ទនាសហគមន៍!`,
      fr: `REJOIGNEZ UNE CONVERSATION COMMUNAUTAIRE !`,
      ar: `انضم إلى محادثة مجتمعية!`,
      en: "JOIN A COMMUNITY CONVERSATION!",
    },
    explore: {
      es: `EXPLORA TU ESCUELA`,
      "zh-CN": `探索你的学校`,
      vi: `KHÁM PHÁ TRƯỜNG CỦA BẠN`,
      ru: `ИЗУЧИТЕ СВОЮ ШКОЛУ`,
      sq: `Eksploroni SHKOLLA TUAJ`,
      km: `រុករកសាលារបស់អ្នក។`,
      fr: `EXPLOREZ VOTRE ÉCOLE`,
      ar: `اكتشف مدرستك`,
      en: "EXPLORE YOUR SCHOOL",
    },
    questions: {
      es: `PREGUNTAS COMUNES`,
      "zh-CN": `常见问题`,
      vi: `CÂU HỎI THƯỜNG GẶP`,
      ru: `ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ`,
      sq: `PYETJE TË BËRË TË ZAKONSHME`,
      km: `សំណួរដែលគេសួរញឹកញាប់`,
      fr: `QUESTIONS FRÉQUEMMENT POSÉES`,
      ar: `الأسئلة الشائعة`,
      en: "COMMONLY ASKED QUESTIONS",
    },
    links: {
      es: `ENLACES ÚTILES`,
      "zh-CN": `有用的链接`,
      vi: `LIÊN KẾT HỮU ÍCH`,
      ru: `ПОЛЕЗНЫЕ ССЫЛКИ`,
      sq: `LIDHJE TË DOBISHME`,
      km: `តំណ​ភ្ជាប់​ដែល​មានប្រយោជន៍`,
      fr: `LIENS UTILES`,
      ar: `روابط مفيدة`,
      en: "USEFUL LINKS",
    },
  },
  landing: {
    title: {
      es: `PROCESO DE PLANIFICACIÓN DE INSTALACIONES`,
      "zh-CN": `设施规划过程`,
      vi: `QUY TRÌNH LẬP KẾ HOẠCH CƠ SỞ`,
      ru: `ПРОЦЕСС ПЛАНИРОВАНИЯ ОБЪЕКТОВ`,
      sq: `PROCESI I PLANIFIKIMIT T F OBJEKTEVE`,
      km: `ដំណើរការរៀបចំគម្រោង`,
      fr: `PROCESSUS DE PLANIFICATION DES INSTALLATIONS`,
      ar: `عملية تخطيط المنشآت`,
      en: "FACILITIES PLANNING PROCESS",
    },
    titleSize: {
      es: `5.5vw`,
      "zh-CN": `8.5vw`,
      vi: `8.5vw`,
      ru: `5.5vw`,
      sq: `5.5vw`,
      km: `8.5vw`,
      fr: `5.5vw`,
      ar: `8.5vw`,
      en: "8.5vw",
    },
    titleSizeMobile: {
      es: `2.5em`,
      "zh-CN": `3em`,
      vi: `3em`,
      ru: `2.1em`,
      sq: `2.1em`,
      km: `3em`,
      fr: `2.1em`,
      ar: `3em`,
      en: "3em",
    },
  },
  whats_happening_now: {
    title: {
      es: `¿Qué ha pasado hasta ahora?`,
      "zh-CN": `到目前为止发生了什么？`,
      vi: `Điều gì đã xảy ra cho đến nay?`,
      ru: `Что произошло до сих пор?`,
      sq: `Çfarë ka ndodhur deri tani?`,
      km: `តើមានអ្វីកើតឡើងរហូតមកដល់ពេលនេះ?`,
      fr: `Que s'est-il passé jusqu'à présent ?`,
      ar: `ماذا حدث حتى الان؟`,
      en: "What's Happened So Far?",
    },
    body1: {
      es: `Durante la Fase 1: Introducción del Proceso de Planificación de Instalaciones, el Distrito Escolar de Filadelfia lanzó un sitio web del proyecto y realizó nueve conversaciones comunitarias en todo el distrito, siete en `,
      "zh-CN": `在第 1 阶段：引入设施规划流程期间，费城学区启动了一个项目网站并举行了九次全区社区对话，其中七次在 `,
      vi: `Trong Giai đoạn 1: Giới thiệu Quy trình Lập kế hoạch Cơ sở vật chất, Học khu Philadelphia đã khởi động một trang web dự án và tổ chức chín cuộc trò chuyện cộng đồng toàn học khu, bảy trong `,
      ru: `Во время Этапа 1: Внедрение процесса планирования объектов Школьный округ Филадельфии запустил веб-сайт проекта и провел девять бесед с населением в масштабах округа, семь в `,
      sq: `Gjatë Fazës 1: Prezantimi i Procesit të Planifikimit të Objekteve, Distrikti Shkollor i Filadelfias lançoi një faqe interneti të projektit dhe mbajti nëntë biseda me komunitetin në mbarë distriktin, shtatë në `,
      km: `កំឡុងដំណាក់កាលទី 1៖ ការណែនាំអំពីដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ សាលាស្រុក Philadelphia បានបើកដំណើរការគេហទំព័រគម្រោងមួយ និងបានធ្វើការសន្ទនាសហគមន៍ទូទាំងស្រុកចំនួនប្រាំបួន ដែលចំនួនប្រាំពីរនៅក្នុង `,
      fr: `Au cours de la phase 1 : Introduction du processus de planification des installations, le district scolaire de Philadelphie a lancé un site Web de projet et a tenu neuf conversations communautaires à l'échelle du district, sept en `,
      ar: `خلال المرحلة 1: مقدمة عن عملية تخطيط المرافق ، أطلقت منطقة فيلادلفيا التعليمية موقعًا إلكترونيًا للمشروع وعقدت تسع محادثات مجتمعية على مستوى المنطقة ، سبعة منها في `,
      en: "During Phase 1: Introduction of the Facilities Planning Process, the School District of Philadelphia launched a project website and held nine district-wide community conversations, seven in ",
    },
    link1: {
      es: `Inglés`,
      "zh-CN": `英语`,
      vi: `Tiếng Anh`,
      ru: `Английский`,
      sq: `anglisht`,
      km: `ភាសាអង់គ្លេស`,
      fr: `Anglais`,
      ar: `إنجليزي`,
      en: "English",
    },
    body2: {
      es: `y dos en `,
      "zh-CN": `和两个在 `,
      vi: `và hai trong `,
      ru: `и два в `,
      sq: `dhe dy në `,
      km: `និងពីរនៅក្នុង `,
      fr: `et deux dans `,
      ar: `واثنان في `,
      en: "and two in ",
    },
    link2: {
      es: `Español`,
      "zh-CN": `西班牙语`,
      vi: `người Tây Ban Nha`,
      ru: `испанский`,
      sq: `Spanjisht`,
      km: `ភាសាអេស្ប៉ាញ`,
      fr: `Espagnol`,
      ar: `الأسبانية`,
      en: "Spanish",
    },
    body3: {
      es: `. Estas reuniones presentaron una descripción general del FPP, explicaron los datos utilizados en este proceso, demostraron cómo navegar una herramienta web en línea para que las comunidades escolares vean los datos de su escuela y brindaron tiempo para que los miembros de la comunidad compartieran pensamientos y preguntas. A continuación, puede encontrar formas de participar y proporcionar comentarios.`,
      "zh-CN": `. 这些会议概述了 FPP，解释了此过程中使用的数据，演示了如何为学校社区导航在线网络工具以查看学校数据，并为社区成员提供时间来分享想法和问题。 您可以在下面找到参与和提供反馈的方法。`,
      vi: `. Các cuộc họp này đã trình bày tổng quan về FPP, giải thích dữ liệu được sử dụng trong quy trình này, trình bày cách điều hướng công cụ web trực tuyến để cộng đồng trường học xem dữ liệu về trường của họ và cung cấp thời gian để các thành viên cộng đồng chia sẻ suy nghĩ và câu hỏi. Dưới đây bạn có thể tìm thấy cách để tham gia và cung cấp thông tin phản hồi.`,
      ru: `. На этих встречах был представлен обзор FPP, объяснялись данные, используемые в этом процессе, демонстрировалось, как пользоваться онлайн-инструментом для школьных сообществ, чтобы просматривать данные о своей школе, и предоставлялось время для членов сообщества, чтобы поделиться мыслями и вопросами. Ниже вы можете найти способы взаимодействия и предоставления отзывов.`,
      sq: `. Këto takime prezantuan një përmbledhje të FPP-së, shpjeguan të dhënat e përdorura në këtë proces, demonstruan se si të lundrojnë në një vegël në internet për komunitetet e shkollave për të parë të dhënat për shkollën e tyre dhe u dhanë kohë anëtarëve të komunitetit për të ndarë mendimet dhe pyetjet. Më poshtë mund të gjeni mënyra për t'u angazhuar dhe për të dhënë komente.`,
      km: `. កិច្ចប្រជុំទាំងនេះបានបង្ហាញពីទិដ្ឋភាពទូទៅនៃ FPP ពន្យល់អំពីទិន្នន័យដែលបានប្រើក្នុងដំណើរការនេះ បង្ហាញពីរបៀបរុករកឧបករណ៍បណ្តាញអនឡាញសម្រាប់សហគមន៍សាលារៀនដើម្បីមើលទិន្នន័យនៅលើសាលារៀនរបស់ពួកគេ និងផ្តល់ពេលវេលាសម្រាប់សមាជិកសហគមន៍ដើម្បីចែករំលែកគំនិត និងសំណួរ។ ខាងក្រោមនេះ អ្នកអាចស្វែងរកវិធីដើម្បីចូលរួម និងផ្តល់មតិកែលម្អ។`,
      fr: `. Ces réunions ont présenté un aperçu du FPP, expliqué les données utilisées dans ce processus, démontré comment naviguer dans un outil Web en ligne permettant aux communautés scolaires de visualiser les données sur leur école et ont donné du temps aux membres de la communauté pour partager leurs réflexions et leurs questions. Vous trouverez ci-dessous des moyens de vous engager et de fournir des commentaires.`,
      ar: `. قدمت هذه الاجتماعات نظرة عامة على FPP ، وشرحت البيانات المستخدمة في هذه العملية ، وأوضحت كيفية التنقل في أداة الويب عبر الإنترنت لمجتمعات المدارس لعرض البيانات حول مدرستهم ، ووفرت وقتًا لأعضاء المجتمع لمشاركة الأفكار والأسئلة. يمكنك العثور أدناه على طرق للمشاركة وتقديم التعليقات.`,
      en: ". These meetings presented an overview of the FPP, explained the data used in this process, demonstrated how to navigate an online webtool for school communities to view data on their school, and provided time for community members to share thoughts and questions. Below you can find ways to engage and provide feedback.",
    },
    body4: {
      es: `Visita el `,
      "zh-CN": `访问 `,
      vi: `Tham quan `,
      ru: `Посетить `,
      sq: `Vizitoni `,
      km: `ទស្សនា `,
      fr: `Visiter le `,
      ar: `قم بزيارة `,
      en: "Visit the ",
    },
    link3: {
      es: `sitio web interactivo `,
      "zh-CN": `互动网站 `,
      vi: `trang web tương tác `,
      ru: `интерактивный сайт `,
      sq: `faqe interneti interaktive `,
      km: `គេហទំព័រអន្តរកម្ម `,
      fr: `site web interactif `,
      ar: `موقع متفاعل او فعال `,
      en: "interactive website ",
    },
    body5: {
      es: `donde puede encontrar actualizaciones sobre este proceso, revisar el contenido de reuniones anteriores y explorar datos.`,
      "zh-CN": `您可以在其中找到有关此过程的更新、重温过去会议的内容并探索数据。`,
      vi: `nơi bạn có thể tìm thấy thông tin cập nhật về quy trình này, xem lại nội dung từ các cuộc họp trước đây và khám phá dữ liệu.`,
      ru: `где вы можете найти обновления об этом процессе, вернуться к материалам прошлых встреч и изучить данные.`,
      sq: `ku mund të gjeni përditësime për këtë proces, të rishikoni përmbajtjen nga takimet e kaluara dhe të eksploroni të dhëna.`,
      km: `ដែលជាកន្លែងដែលអ្នកអាចស្វែងរកព័ត៌មានថ្មីៗអំពីដំណើរការនេះ ពិនិត្យមើលខ្លឹមសារឡើងវិញពីការប្រជុំកន្លងមក និងរុករកទិន្នន័យ។`,
      fr: `où vous pouvez trouver des mises à jour sur ce processus, revoir le contenu des réunions passées et explorer les données.`,
      ar: `حيث يمكنك العثور على تحديثات حول هذه العملية وإعادة زيارة المحتوى من الاجتماعات السابقة واستكشاف البيانات.`,
      en: "where you can find updates on this process, revisit content from past meetings, and explore data.",
    },
    button1: {
      es: `Guía de procesos`,
      "zh-CN": `工艺指南`,
      vi: `Hướng dẫn Quy trình`,
      ru: `Руководство по процессу`,
      sq: `Udhëzuesi i procesit`,
      km: `ការណែនាំអំពីដំណើរការ`,
      fr: `Guide de processus`,
      ar: `دليل العملية`,
      en: "Process Guide ",
    },
    button2: {
      es: `Grabación de conversaciones comunitarias`,
      "zh-CN": `社区对话录音`,
      vi: `Ghi âm cuộc trò chuyện cộng đồng`,
      ru: `Запись разговоров сообщества`,
      sq: `Regjistrimi i bisedës në komunitet`,
      km: `ការកត់ត្រាការសន្ទនាសហគមន៍`,
      fr: `Enregistrement de conversation communautaire`,
      ar: `تسجيل محادثة المجتمع`,
      en: "Community Conversation Recording",
    },
    button3: {
      es: `Presentación de la conversación comunitaria`,
      "zh-CN": `社区对话演示`,
      vi: `Bản trình bày cuộc trò chuyện cộng đồng`,
      ru: `Презентация беседы с сообществом`,
      sq: `Prezantimi i bashkëbisedimit në komunitet`,
      km: `បទបង្ហាញការសន្ទនាសហគមន៍`,
      fr: `Présentation de conversation communautaire`,
      ar: `عرض محادثة المجتمع`,
      en: "Community Conversation Presentation",
    },
    button4: {
      es: `Herramienta web interactiva: datos específicos de la escuela`,
      "zh-CN": `交互式网络工具：学校特定数据`,
      vi: `Webtool tương tác: Dữ liệu dành riêng cho trường học`,
      ru: `Интерактивный веб-инструмент: данные о школе`,
      sq: `Webtool interaktiv: Të dhëna specifike për shkollën`,
      km: `ឧបករណ៍បណ្ដាញអន្តរកម្ម៖ ទិន្នន័យជាក់លាក់របស់សាលា`,
      fr: `Outil Web interactif : Données spécifiques à l'école`,
      ar: `أداة ويب تفاعلية: بيانات خاصة بالمدرسة`,
      en: "Interactive Webtool: School-specific Data",
    },
    button5: {
      es: `Presentación en Español`,
      "zh-CN": `西班牙语介绍`,
      vi: `Bài thuyết trình bằng tiếng Tây Ban Nha`,
      ru: `Испанская презентация`,
      sq: `Prezantimi spanjoll`,
      km: `បទបង្ហាញភាសាអេស្ប៉ាញ`,
      fr: `Présentation en espagnol`,
      ar: `عرض إسباني`,
      en: "Presentación en Español",
    },
    button6: {
      es: `Grabación en Español`,
      "zh-CN": `西班牙语录音`,
      vi: `Ghi âm tiếng Tây Ban Nha`,
      ru: `Испанская запись`,
      sq: `Regjistrimi spanjoll`,
      km: `ការកត់ត្រាភាសាអេស្ប៉ាញ`,
      fr: `Enregistrement espagnol`,
      ar: `التسجيل الاسباني`,
      en: "Grabación en Español",
    },
    link4: {
      es: `Complete el formulario de comentarios en línea`,
      "zh-CN": `填写在线反馈表`,
      vi: `Điền vào biểu mẫu phản hồi trực tuyến`,
      ru: `Заполните онлайн-форму обратной связи`,
      sq: `Plotësoni formularin e komenteve në internet`,
      km: `បំពេញទម្រង់មតិយោបល់លើអ៊ីនធឺណិត`,
      fr: `Remplissez le formulaire de commentaires en ligne`,
      ar: `املأ نموذج الملاحظات عبر الإنترنت`,
      en: "Fill out the Online Feedback Form",
    },
    body6: {
      es: ` una vez que haya tenido la oportunidad de revisar la información anterior.`,
      "zh-CN": ` 一旦您有机会查看上述信息。`,
      vi: ` khi bạn đã có cơ hội xem lại thông tin trên.`,
      ru: ` после того, как у вас была возможность ознакомиться с приведенной выше информацией.`,
      sq: ` pasi të keni pasur mundësinë të rishikoni informacionin e mësipërm.`,
      km: ` នៅពេលដែលអ្នកមានឱកាសពិនិត្យមើលព័ត៌មានខាងលើ។`,
      fr: ` une fois que vous avez eu l'occasion d'examiner les informations ci-dessus.`,
      ar: ` بمجرد أن تتاح لك الفرصة لمراجعة المعلومات أعلاه.`,
      en: " once you have had a chance to review the information above.",
    },
    bh1: {
      es: `Difundir la palabra `,
      "zh-CN": `传播这个词 `,
      vi: `Thông báo `,
      ru: `Распространить слово `,
      sq: `Përhapni fjalën `,
      km: `ផ្សព្វផ្សាយពាក្យ `,
      fr: `Faire connaitre `,
      ar: `انشر الكلمة `,
      en: "Spread the word ",
    },
    body7: {
      es: ` compartiendo el sitio web y la herramienta web de FPP con su comunidad escolar. Anime a los líderes de su escuela y comunidades vecinales a participar en este proceso. Si hay formas en que el distrito puede ayudarlo a movilizar a su comunidad, envíe un correo electrónico a fpp@philasd.org.`,
      "zh-CN": ` 通过与您的学校社区共享 FPP 网站和网络工具。 鼓励您学校和邻里社区的领导参与这一过程。 如果学区有办法支持您动员社区，请发送电子邮件至 fpp@philasd.org。`,
      vi: ` bằng cách chia sẻ trang web FPP và webtool với cộng đồng trường học của bạn. Khuyến khích các nhà lãnh đạo của trường học và các cộng đồng lân cận của bạn tham gia vào quá trình này. Nếu có những cách mà học khu có thể hỗ trợ bạn trong việc huy động cộng đồng của mình, vui lòng gửi email tới fpp@philasd.org.`,
      ru: ` поделившись веб-сайтом FPP и веб-инструментом со своим школьным сообществом. Поощряйте лидеров вашей школы и местных сообществ участвовать в этом процессе. Если округ может помочь вам в мобилизации сообщества, отправьте электронное письмо по адресу fpp@philasd.org.`,
      sq: ` duke ndarë faqen e internetit të FPP dhe veglën e internetit me komunitetin tuaj të shkollës. Inkurajoni drejtuesit e shkollës suaj dhe komuniteteve të lagjes që të marrin pjesë në këtë proces. Nëse ka mënyra se si distrikti mund t'ju mbështesë në mobilizimin e komunitetit tuaj, ju lutemi dërgoni email në fpp@philasd.org.`,
      km: ` ដោយការចែករំលែកគេហទំព័រ FPP និង webtool ជាមួយសហគមន៍សាលារបស់អ្នក។ លើកទឹកចិត្តដល់អ្នកដឹកនាំសាលា និងសហគមន៍សង្កាត់របស់អ្នកឱ្យចូលរួមក្នុងដំណើរការនេះ។ ប្រសិនបើមានវិធីដែលស្រុកអាចជួយអ្នកក្នុងការប្រមូលផ្តុំសហគមន៍របស់អ្នក សូមផ្ញើអ៊ីមែល fpp@philasd.org ។`,
      fr: ` en partageant le site Web et l'outil Web du FPP avec votre communauté scolaire. Encouragez les dirigeants de votre école et des communautés de votre quartier à participer à ce processus. S'il existe des moyens par lesquels le district peut vous aider à mobiliser votre communauté, veuillez envoyer un e-mail à fpp@philasd.org.`,
      ar: ` من خلال مشاركة موقع ويب FPP و webtool مع مجتمع مدرستك. شجع قادة مدرستك ومجتمعات الحي على المشاركة في هذه العملية. إذا كانت هناك طرق يمكن للمنطقة أن تدعمك بها في تعبئة مجتمعك ، فيرجى إرسال بريد إلكتروني إلى fpp@philasd.org.`,
      en: " by sharing the FPP website and webtool with your school community. Encourage leaders of your school and neighborhood communities to participate in this process. If there are ways the district can support you in mobilizing your community, please email fpp@philasd.org.  ",
    },
  },
  what_is_fpp: {
    title: {
      es: `¿Qué es el proceso de planificación de instalaciones?`,
      "zh-CN": `什么是设施规划流程？`,
      vi: `Quy trình Lập kế hoạch Cơ sở vật chất là gì?`,
      ru: `Что такое процесс планирования помещений?`,
      sq: `Cili është procesi i planifikimit të objekteve?`,
      km: `តើដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារគឺជាអ្វី?`,
      fr: `Qu'est-ce que le processus de planification des installations ?`,
      ar: `ما هي عملية تخطيط المرافق؟`,
      en: "What is the Facilities Planning Process?",
    },
    body1: {
      es: `En marzo de 2022, el Distrito Escolar de Filadelfia anunció el lanzamiento de su Proceso de planificación de instalaciones (FPP), lo que marcó un paso fundamental hacia la realización de su visión de que todos los estudiantes tengan acceso a una gran escuela. Los edificios escolares juegan un papel crucial en el apoyo al rendimiento estudiantil, y el Distrito deberá planificar de manera cuidadosa y proactiva no solo los edificios escolares nuevos, sino también lo que se debe hacer con los edificios escolares existentes, para que los estudiantes tengan acceso a entornos de aprendizaje que despierten la creatividad, el apoyo programas académicos y satisfacer sus necesidades de aprendizaje del siglo XXI.`,
      "zh-CN": `2022 年 3 月，费城学区宣布启动其设施规划流程 (FPP)，这标志着朝着实现其让所有学生都能进入一所好学校的愿景迈出了关键一步。 校舍在支持学生取得成就方面发挥着至关重要的作用，学区不仅需要对新校舍进行深思熟虑和积极的规划，而且还需要对现有校舍必须做的事情进行规划，以便学生能够获得激发创造力的学习环境，支持 学术课程，并满足他们 21 世纪的学习需求。`,
      vi: `Vào tháng 3 năm 2022, Học khu Philadelphia thông báo khởi động Quy trình Lập kế hoạch Cơ sở vật chất (FPP), đánh dấu một bước quan trọng trong việc hiện thực hóa tầm nhìn của mình để tất cả học sinh được vào học tại một trường học tốt. Các tòa nhà trường học đóng một vai trò quan trọng trong việc hỗ trợ thành tích của học sinh, và Học khu sẽ cần phải lên kế hoạch chu đáo và chủ động không chỉ cho các tòa nhà trường học mới mà còn cho những gì phải thực hiện với các tòa nhà trường học hiện có, để học sinh được tiếp cận với môi trường học tập khơi dậy sự sáng tạo, hỗ trợ các chương trình học thuật, và đáp ứng nhu cầu học tập trong thế kỷ 21 của họ.`,
      ru: `В марте 2022 года школьный округ Филадельфии объявил о запуске своего Процесса планирования объектов (FPP), что стало важным шагом на пути к реализации его концепции предоставления всем учащимся доступа к отличной школе. Школьные здания играют решающую роль в поддержке успеваемости учащихся, и округ должен будет вдумчиво и активно планировать не только новые школьные здания, но и то, что должно быть сделано с существующими школьными зданиями, чтобы учащиеся имели доступ к учебной среде, которая пробуждает творческие способности, поддерживает академические программы и удовлетворить свои потребности в обучении 21-го века.`,
      sq: `Në mars 2022, Distrikti Shkollor i Filadelfias njoftoi nisjen e Procesit të Planifikimit të Objekteve (FPP), duke shënuar një hap kritik drejt realizimit të vizionit të tij që të gjithë nxënësit të kenë akses në një shkollë të shkëlqyer. Ndërtesat e shkollave luajnë një rol vendimtar në mbështetjen e arritjeve të nxënësve dhe Distrikti do të duhet të planifikojë në mënyrë të menduar dhe proaktive jo vetëm për ndërtesat e reja shkollore, por për atë që duhet bërë me ndërtesat ekzistuese të shkollave, në mënyrë që nxënësit të kenë akses në mjediset e të mësuarit që nxisin kreativitet, mbështetje. programet akademike dhe plotësojnë nevojat e tyre të të mësuarit të shekullit të 21-të.`,
      km: `នៅខែមីនា ឆ្នាំ 2022 សាលាសង្កាត់នៃទីក្រុង Philadelphia បានប្រកាសពីការចាប់ផ្តើមដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខាររបស់ខ្លួន (FPP) ដែលជាជំហានដ៏សំខាន់មួយឆ្ពោះទៅរកការសម្រេចបាននូវចក្ខុវិស័យរបស់ខ្លួនសម្រាប់សិស្សានុសិស្សទាំងអស់ក្នុងការចូលរៀនសាលាដ៏អស្ចារ្យមួយ។ អគារសិក្សាដើរតួនាទីយ៉ាងសំខាន់ក្នុងការគាំទ្រសមិទ្ធផលរបស់សិស្ស ហើយស្រុកនឹងត្រូវគិតគូរ និងរៀបចំផែនការយ៉ាងសកម្ម មិនត្រឹមតែសម្រាប់អគារសិក្សាថ្មីប៉ុណ្ណោះទេ ប៉ុន្តែសម្រាប់អ្វីដែលត្រូវធ្វើជាមួយអគារសិក្សាដែលមានស្រាប់ ដូច្នេះសិស្សមានលទ្ធភាពទទួលបានបរិយាកាសសិក្សាដែលជំរុញឱ្យមានការច្នៃប្រឌិត គាំទ្រ។ កម្មវិធីសិក្សា និងបំពេញតម្រូវការសិក្សានៅសតវត្សរ៍ទី 21 របស់ពួកគេ។`,
      fr: `En mars 2022, le district scolaire de Philadelphie a annoncé le lancement de son processus de planification des installations (FPP), marquant une étape cruciale vers la réalisation de sa vision pour que tous les élèves aient accès à une excellente école. Les bâtiments scolaires jouent un rôle crucial dans le soutien de la réussite des élèves, et le district devra planifier de manière réfléchie et proactive non seulement pour les nouveaux bâtiments scolaires, mais aussi pour ce qui doit être fait avec les bâtiments scolaires existants, afin que les élèves aient accès à des environnements d'apprentissage qui stimulent la créativité, le soutien programmes d'études et répondre à leurs besoins d'apprentissage du 21e siècle.`,
      ar: `في مارس 2022 ، أعلنت منطقة فيلادلفيا التعليمية عن إطلاق عملية تخطيط المرافق (FPP) ، مما يمثل خطوة حاسمة نحو تحقيق رؤيتها لجميع الطلاب للوصول إلى مدرسة رائعة. تلعب المباني المدرسية دورًا مهمًا في دعم تحصيل الطلاب ، وستحتاج المنطقة إلى التخطيط بشكل مدروس واستباقي ليس فقط للمباني المدرسية الجديدة ولكن أيضًا لما يجب القيام به مع المباني المدرسية القائمة ، حتى يتمكن الطلاب من الوصول إلى بيئات التعلم التي تحفز الإبداع والدعم البرامج الأكاديمية ، وتلبية احتياجات التعلم في القرن الحادي والعشرين.`,
      en: `In March 2022 the School District of Philadelphia announced the launch of its Facilities Planning Process (FPP), marking a critical step toward realizing its vision for all students to have access to a great school. School buildings play a crucial role in supporting student achievement, and the District will need to thoughtfully and proactively plan not only for new school buildings but for what must be done with existing school buildings, so students have access to learning environments that spark creativity, support academic programs, and meet their 21st-century learning needs.`,
    },
    body2: {
      es: `El proceso comienza con evaluaciones intensivas de las instalaciones escolares, dirigidas por expertos de la industria, que brindan una mirada estratégica de todo el sistema a los edificios y programas escolares existentes. Las evaluaciones también consideran cómo se prevé que cambien las poblaciones y comunidades de la ciudad en los próximos años. Esta información se puede encontrar en el `,
      "zh-CN": `该过程从由行业专家领导的密集学校设施评估开始，对现有学校建筑和计划进行战略性、系统性的审视。 评估还考虑了整个城市的人口和社区预计在未来几年将如何变化。 此信息可在`,
      vi: `Quá trình này bắt đầu với các đánh giá chuyên sâu về cơ sở vật chất của trường học, do các chuyên gia trong ngành dẫn dắt, nhằm cung cấp một cái nhìn chiến lược, toàn hệ thống về các tòa nhà và chương trình hiện có của trường học. Các đánh giá cũng xem xét dân số và cộng đồng trên toàn thành phố dự kiến sẽ thay đổi như thế nào trong những năm tới. Thông tin này có thể được tìm thấy trong `,
      ru: `Процесс начинается с интенсивной оценки школьных помещений под руководством отраслевых экспертов, которая дает стратегический, общесистемный взгляд на существующие школьные здания и программы. Оценки также учитывают, как население и сообщества по всему городу, по прогнозам, изменятся в ближайшие годы. Эту информацию можно найти в `,
      sq: `Procesi fillon me vlerësime intensive të objekteve shkollore, të udhëhequra nga ekspertë të industrisë, që ofrojnë një vështrim strategjik, të gjerë të sistemit të ndërtesave dhe programeve ekzistuese shkollore. Vlerësimet marrin parasysh gjithashtu se si popullsia dhe komunitetet në të gjithë qytetin parashikohet të ndryshojnë në vitet e ardhshme. Ky informacion mund të gjendet në `,
      km: `ដំណើរការនេះចាប់ផ្តើមជាមួយនឹងការវាយតម្លៃលើបរិក្ខារសាលាដែលពឹងផ្អែកខ្លាំង ដឹកនាំដោយអ្នកជំនាញក្នុងឧស្សាហកម្ម ដែលផ្តល់នូវការមើលជាយុទ្ធសាស្ត្រ និងជាប្រព័ន្ធលើអគារសិក្សា និងកម្មវិធីដែលមានស្រាប់។ ការវាយតម្លៃក៏ពិចារណាពីរបៀបដែលចំនួនប្រជាជន និងសហគមន៍ទូទាំងទីក្រុងត្រូវបានព្យាករណ៍ថានឹងផ្លាស់ប្តូរនៅក្នុងប៉ុន្មានឆ្នាំខាងមុខនេះ។ ព័ត៌មាននេះអាចត្រូវបានរកឃើញនៅក្នុង `,
      fr: `Le processus commence par des évaluations intensives des installations scolaires, dirigées par des experts de l'industrie, qui fournissent un aperçu stratégique à l'échelle du système des bâtiments et des programmes scolaires existants. Les évaluations tiennent également compte de l'évolution prévue des populations et des communautés de la ville dans les années à venir. Ces informations se trouvent dans le `,
      ar: `تبدأ العملية بتقييمات مكثفة لمرافق المدرسة ، بقيادة خبراء الصناعة ، والتي توفر نظرة إستراتيجية على مستوى النظام على المباني والبرامج المدرسية الحالية. كما تأخذ التقييمات في الاعتبار الكيفية التي من المتوقع أن يتغير بها السكان والمجتمعات في جميع أنحاء المدينة في السنوات القادمة. يمكن العثور على هذه المعلومات في `,
      en: `The process starts with intensive school facility assessments, led by industry experts, that provide a strategic, system-wide look at existing school buildings and programs. The assessments also consider how populations and communities across the city are projected to change in the coming years. This information can be found in the `,
    },
    body3: {
      es: ` junto a `,
      "zh-CN": ` 旁边 `,
      vi: ` cùng với `,
      ru: ` рядом `,
      sq: ` krahas `,
      km: ` នៅជាប់គ្នា។ `,
      fr: ` aux côtés de `,
      ar: ` جنبا إلى جنب `,
      en: ` alongside `,
    },
    body4: {
      es: ` sobre las evaluaciones utilizadas. Estos datos se utilizan para identificar los desafíos específicos que enfrentan los edificios escolares o que se espera que enfrenten en los próximos años e informar las recomendaciones para abordar estos desafíos como parte del Plan Maestro de Instalaciones (FMP) a largo plazo del Distrito.`,
      "zh-CN": ` 关于使用的评估。 该数据用于确定学校建筑在未来几年面临或预计将面临的具体挑战，并为解决这些挑战提供建议，作为学区长期设施总体规划 (FMP) 的一部分。`,
      vi: ` về các đánh giá được sử dụng. Dữ liệu này được sử dụng để xác định những thách thức cụ thể mà các tòa nhà trường học đang phải đối mặt hoặc dự kiến sẽ đối mặt trong những năm tới và thông báo các khuyến nghị để giải quyết những thách thức này như một phần của Kế hoạch Tổng thể về Cơ sở vật chất (FMP) dài hạn của Học khu.`,
      ru: ` об используемых оценках. Эти данные используются для определения конкретных проблем, с которыми сталкиваются школьные здания или которые, как ожидается, столкнутся в ближайшие годы, и для предоставления рекомендаций по решению этих проблем в рамках долгосрочного генерального плана школьных зданий (FMP).`,
      sq: ` në lidhje me vlerësimet e përdorura. Këto të dhëna përdoren për të identifikuar sfidat specifike me të cilat përballen ose pritet të përballen ndërtesat e shkollave në vitet e ardhshme dhe për të dhënë rekomandime për adresimin e këtyre sfidave si pjesë e Master Planit afatgjatë të Objekteve të Qarkut (FMP).`,
      km: ` អំពីការវាយតម្លៃដែលបានប្រើ។ ទិន្នន័យនេះត្រូវបានប្រើដើម្បីកំណត់បញ្ហាប្រឈមជាក់លាក់ដែលអគារសិក្សាកំពុងប្រឈមមុខ ឬត្រូវបានរំពឹងថានឹងប្រឈមមុខក្នុងប៉ុន្មានឆ្នាំខាងមុខ ហើយជូនដំណឹងអំពីអនុសាសន៍ដើម្បីដោះស្រាយបញ្ហាប្រឈមទាំងនេះជាផ្នែកនៃផែនការមេនៃគ្រឿងបរិក្ខាររយៈពេលវែង (FMP) របស់ស្រុក។`,
      fr: ` sur les évaluations utilisées. Ces données sont utilisées pour identifier les défis spécifiques auxquels les bâtiments scolaires sont confrontés ou devraient être confrontés dans les années à venir et éclairer les recommandations pour relever ces défis dans le cadre du plan directeur des installations (FMP) à long terme du district.`,
      ar: ` حول التقييمات المستخدمة. تُستخدم هذه البيانات لتحديد التحديات المحددة التي تواجهها المباني المدرسية أو من المتوقع مواجهتها في السنوات القادمة وتقديم التوصيات لمعالجة هذه التحديات كجزء من الخطة الرئيسية للمنشآت طويلة الأجل (FMP).`,
      en: ` about the assessments used. This data is used to identify the specific challenges school buildings are facing or are expected to face in the coming years and inform recommendations to address these challenges as part of the District’s long-term Facilities Master Plan (FMP).`,
    },
    body5: {
      es: `El Distrito se compromete a desarrollar el plan de instalaciones en asociación con las familias y las comunidades escolares y se centrará en las comunidades con la mayor necesidad de cambios en sus edificios escolares físicos. Hay cuatro fases en este proceso: Introducir, Involucrar, Revisar, Planificar.`,
      "zh-CN": `学区致力于与家庭和学校社区合作制定设施计划，并将重点关注最需要改变其实体校舍的社区。 这个过程有四个阶段：引入、参与、修改、计划。`,
      vi: `Học khu cam kết phát triển kế hoạch cơ sở vật chất với sự hợp tác của các gia đình và cộng đồng trường học, đồng thời sẽ tập trung vào các cộng đồng có nhu cầu thay đổi lớn nhất đối với các tòa nhà trường học của họ. Có bốn giai đoạn trong quy trình này: Giới thiệu, Thu hút, Sửa đổi, Lập kế hoạch.`,
      ru: `Округ стремится разработать план объектов в партнерстве с семьями и школьными сообществами и сосредоточит внимание на сообществах, которые больше всего нуждаются в изменениях своих школьных зданий. В этом процессе есть четыре этапа: введение, вовлечение, пересмотр, планирование.`,
      sq: `Distrikti është i përkushtuar të zhvillojë planin e objekteve në partneritet me familjet dhe komunitetet shkollore dhe do të fokusohet në komunitetet me nevojën më të madhe për ndryshime në ndërtesat e tyre të shkollave fizike. Ekzistojnë katër faza në këtë proces: Prezantoni, Angazhoni, Rishikoni, Planifikoni.`,
      km: `ស្រុកបានប្តេជ្ញាចិត្តក្នុងការអភិវឌ្ឍន៍ផែនការសម្ភារៈបរិក្ខារដោយភាពជាដៃគូជាមួយគ្រួសារ និងសហគមន៍សាលារៀន ហើយនឹងផ្តោតលើសហគមន៍ដែលមានតម្រូវការខ្ពស់បំផុតសម្រាប់ការផ្លាស់ប្តូរអគារសិក្សារបស់ពួកគេ។ មានបួនដំណាក់កាលនៅក្នុងដំណើរការនេះ៖ ការណែនាំ ចូលរួម ពិនិត្យឡើងវិញ ផែនការ។`,
      fr: `Le district s'est engagé à élaborer le plan des installations en partenariat avec les familles et les communautés scolaires et se concentrera sur les communautés qui ont le plus besoin de changements dans leurs bâtiments scolaires physiques. Il y a quatre phases dans ce processus : Introduire, Engager, Réviser, Planifier.`,
      ar: `تلتزم المقاطعة بتطوير خطة المرافق بالشراكة مع العائلات والمجتمعات المدرسية وستركز على المجتمعات الأكثر احتياجًا للتغييرات في مباني المدارس المادية الخاصة بهم. هناك أربع مراحل في هذه العملية: تقديم ، إشراك ، مراجعة ، تخطيط.`,
      en: `The District is committed to developing the facilities plan in partnership with families and school communities and will focus on communities with the greatest need for changes to their physical school buildings. There are four phases in this process: Introduce, Engage, Revise, Plan.`,
    },
    body6: {
      es: ` y en esto `,
      "zh-CN": ` 而在这个 `,
      vi: ` và trong này `,
      ru: ` и в этом `,
      sq: ` dhe në këtë `,
      km: ` ហើយនៅក្នុងនេះ។ `,
      fr: ` et dans ce `,
      ar: ` وفي هذا `,
      en: ` and in this `,
    },
    body7: {
      es: `El Distrito desarrollará un conjunto de recomendaciones, informadas por la participación de la comunidad, que los profesionales de ingeniería del Grupo Ambiental e Infraestructura de Parsons utilizarán para crear el Plan Maestro de Instalaciones. El FMP describirá los próximos pasos para la planificación de instalaciones en el Distrito y priorizará la alineación de los gastos de inversión con la evolución de la comunidad, las instalaciones y las necesidades educativas.`,
      "zh-CN": `该地区将根据社区参与制定一套建议，帕森斯环境和基础设施集团的工程专业人员将使用这些建议来制定设施总体规划。 FMP 将概述学区设施规划的后续步骤，并优先考虑使投资支出与不断变化的社区、设施和教育需求保持一致。`,
      vi: `Học khu sẽ phát triển một loạt các đề xuất, được thông báo bởi sự tham gia của cộng đồng, mà các chuyên gia kỹ thuật tại Nhóm Cơ sở hạ tầng và Môi trường Parsons sẽ sử dụng để tạo Kế hoạch Tổng thể về Cơ sở vật chất. FMP sẽ phác thảo các bước tiếp theo để lập kế hoạch cơ sở vật chất trong Học khu và ưu tiên sắp xếp chi tiêu đầu tư phù hợp với nhu cầu giáo dục, cơ sở vật chất và cộng đồng đang phát triển.`,
      ru: `Округ разработает набор рекомендаций, основанных на участии сообщества, которые инженеры-инженеры Parsons Environmental and Infrastructure Group будут использовать для создания генерального плана объектов. FMP наметит следующие шаги по планированию объектов в округе и расставит приоритеты в согласовании инвестиционных расходов с растущими потребностями сообщества, объектов и образования.`,
      sq: `Distrikti do të zhvillojë një sërë rekomandimesh, të informuara nga angazhimi i komunitetit, që profesionistët e inxhinierisë në Parsons Environmental and Infrastructure Group do t'i përdorin për të krijuar Master Planin e Faciliteteve. FMP do të përshkruajë hapat e ardhshëm për planifikimin e objekteve në distrikt dhe do t'i japë prioritet përafrimin e shpenzimeve për investime me nevojat në zhvillim të komunitetit, objekteve dhe arsimit.`,
      km: `ស្រុកនឹងបង្កើតនូវអនុសាសន៍មួយដែលត្រូវបានជូនដំណឹងដោយការចូលរួមរបស់សហគមន៍ ដែលអ្នកជំនាញផ្នែកវិស្វកម្មនៅ Parsons Environmental and Infrastructure Group នឹងប្រើប្រាស់ដើម្បីបង្កើតផែនការមេរបស់ Facilities ។ FMP នឹងគូសបញ្ជាក់ជំហានបន្ទាប់សម្រាប់ការរៀបចំផែនការគ្រឿងបរិក្ខារនៅក្នុងស្រុក និងផ្តល់អាទិភាពក្នុងការតម្រឹមការចំណាយវិនិយោគជាមួយការវិវត្តនៃសហគមន៍ បរិក្ខារ និងតម្រូវការអប់រំ។`,
      fr: `Le district élaborera un ensemble de recommandations, éclairées par l'engagement communautaire, que les professionnels de l'ingénierie du Parsons Environmental and Infrastructure Group utiliseront pour créer le plan directeur des installations. Le FMP décrira les prochaines étapes de la planification des installations dans le district et donnera la priorité à l'alignement des dépenses d'investissement sur l'évolution des besoins de la communauté, des installations et de l'éducation.`,
      ar: `ستضع المنطقة مجموعة من التوصيات ، المستنيرة من خلال مشاركة المجتمع ، والتي سيستخدمها المتخصصون الهندسيون في Parsons Environmental and Infrastructure Group لإنشاء الخطة الرئيسية للمرافق. ستحدد FMP الخطوات التالية لتخطيط المرافق في المنطقة وتعطي الأولوية لمواءمة الإنفاق الاستثماري مع تطور المجتمع والمرافق والاحتياجات التعليمية.`,
      en: `The District will develop a set of recommendations, informed by community engagement, that engineering professionals at Parsons Environmental and Infrastructure Group will use to create the Facilities Master Plan. The FMP will outline next steps for facilities planning in the District and prioritize aligning investment spending with evolving community, facility, and educational needs.`,
    },
    link1: {
      es: `herramienta web`,
      "zh-CN": `网络工具`,
      vi: `webtool`,
      ru: `веб-инструмент`,
      sq: `vegël në internet`,
      km: `ឧបករណ៍គេហទំព័រ`,
      fr: `outil web`,
      ar: `webtool`,
      en: `webtool`,
    },
    link2: {
      es: `videos informativos cortos y animados`,
      "zh-CN": `简短的动画信息视频`,
      vi: `video thông tin ngắn, hoạt hình`,
      ru: `короткие анимированные информационные видеоролики`,
      sq: `video të shkurtra informative të animuara`,
      km: `វីដេអូអប់រំខ្លីៗដែលមានចលនា`,
      fr: `courtes vidéos d'information animées`,
      ar: `مقاطع فيديو إعلامية قصيرة متحركة`,
      en: `short, animated informational videos`,
    },
    link3: {
      es: `Guía de procesos`,
      "zh-CN": `工艺指南`,
      vi: `Hướng dẫn Quy trình`,
      ru: `Руководство по процессу`,
      sq: `Udhëzuesi i procesit`,
      km: `ការណែនាំអំពីដំណើរការ`,
      fr: `Guide de processus`,
      ar: `دليل العملية`,
      en: `Process Guide`,
    },
    link4: {
      es: `línea de tiempo`,
      "zh-CN": `时间线。`,
      vi: `mốc thời gian.`,
      ru: `график.`,
      sq: `afati kohor.`,
      km: `បន្ទាត់ពេលវេលា។`,
      fr: `chronologie.`,
      ar: `الجدول الزمني.`,
      en: `timeline.`,
    },
    link5: {
      es: `haga clic aquí`,
      "zh-CN": `点击这里`,
      vi: `bấm vào đây`,
      ru: `кликните сюда`,
      sq: `Kliko këtu`,
      km: `ចុច​ទីនេះ`,
      fr: `Cliquez ici`,
      ar: `انقر هنا`,
      en: `click here`,
    },
    subtitle1: {
      es: `Toma de decisiones`,
      "zh-CN": `决策`,
      vi: `Quyết định`,
      ru: `Принятие решения`,
      sq: `Vendimmarrja`,
      km: `ការ​ធ្វើ​សេចក្តី​សម្រេច`,
      fr: `La prise de décision`,
      ar: `اتخاذ قرار`,
      en: `Decision-making`,
    },
  },
  why_now: {
    title: {
      es: ``,
      "zh-CN": `为什么现在？`,
      vi: `Tại sao bây giờ?`,
      ru: `Почему сейчас?`,
      sq: `Pse tani?`,
      km: `ហេតុអ្វីឥឡូវនេះ?`,
      fr: `Pourquoi maintenant?`,
      ar: `لماذا الان؟`,
      en: "Why Now?",
    },
    subtitle1: {
      es: `¿Porqué ahora?`,
      "zh-CN": `资金`,
      vi: `Kinh phí`,
      ru: `Финансирование`,
      sq: `Financimi`,
      km: `មូលនិធិ`,
      fr: `Le financement`,
      ar: `التمويل`,
      en: "Funding",
    },
    subtitle2: {
      es: `La oportunidad de hoy`,
      "zh-CN": `今天的机会`,
      vi: `Cơ hội hôm nay`,
      ru: `Возможность сегодня`,
      sq: `Mundësia Sot`,
      km: `ឱកាសថ្ងៃនេះ`,
      fr: `L'opportunité aujourd'hui`,
      ar: `الفرصة اليوم`,
      en: "The Opportunity Today",
    },
    boldtext1: {
      es: `para gastar en septiembre de 2024`,
      "zh-CN": `到 2024 年 9 月用完`,
      vi: `sẽ được chi tiêu trước tháng 9 năm 2024`,
      ru: `будет израсходовано к сентябрю 2024 г.`,
      sq: `do të shpenzohet deri në shtator 2024`,
      km: `នឹងត្រូវចំណាយត្រឹមខែកញ្ញា ឆ្នាំ 2024`,
      fr: `à dépenser d'ici septembre 2024`,
      ar: `تنفق بحلول سبتمبر 2024`,
      en: "to be spent by September 2024",
    },
    link1: {
      es: `Haga clic aquí para obtener más información sobre la historia y el contexto. `,
      "zh-CN": `单击此处了解更多历史和背景信息。`,
      vi: `Bấm vào đây để biết thêm lịch sử và bối cảnh.`,
      ru: `Нажмите здесь, чтобы узнать больше об истории и контексте.`,
      sq: `Klikoni këtu për më shumë histori dhe kontekst.`,
      km: `ចុចទីនេះសម្រាប់ប្រវត្តិ និងបរិបទបន្ថែម។`,
      fr: `Cliquez ici pour plus d'histoire et de contexte.`,
      ar: `انقر هنا لمزيد من التاريخ والسياق.`,
      en: "Click here for more history and context. ",
    },
    body1: {
      es: `Durante el otoño de 2019, SDP lanzó la Revisión integral de planificación escolar (CSPR), un proceso colaborativo que incluyó trabajar con un pequeño subconjunto de comunidades escolares para revisar la evaluación de la inscripción escolar, las instalaciones escolares y las ofertas de programas educativos. Si bien el trabajo se detuvo en 2020 debido a la pandemia de COVID-19, los años posteriores brindaron la oportunidad de reevaluar el trabajo anterior y expandirlo en todo el distrito en lo que ahora es el Proceso de planificación de instalaciones.`,
      "zh-CN": `在 2019 年秋季，SDP 启动了综合学校规划审查 (CSPR)，这是一个协作过程，其中包括与一小部分学校社区合作，审查对入学率、学校设施和教育计划产品的评估。 虽然这项工作因 COVID-19 大流行而于 2020 年暂停，但随后几年提供了一个机会来重新评估以前的工作并将其扩展到整个地区，成为现在的设施规划流程。`,
      vi: `Vào mùa Thu năm 2019, SDP đã khởi động Đánh giá Kế hoạch Toàn diện Trường học (CSPR), một quá trình hợp tác bao gồm làm việc với một nhóm nhỏ các cộng đồng trường học để xem xét đánh giá về việc ghi danh vào trường học, cơ sở vật chất trường học và các chương trình giáo dục. Mặc dù công việc bị tạm dừng vào năm 2020 vì đại dịch COVID-19, những năm tiếp theo đã tạo cơ hội để đánh giá lại công việc trước đó và mở rộng nó ra toàn huyện thành Quy trình lập kế hoạch cơ sở vật chất hiện nay.`,
      ru: `Осенью 2019 года SDP запустила Комплексный обзор школьного планирования (CSPR) — совместный процесс, который включал в себя работу с небольшим подмножеством школьных сообществ для анализа оценки зачисления в школы, школьных помещений и предложений образовательных программ. Хотя в 2020 году работа была приостановлена из-за пандемии COVID-19, последующие годы предоставили возможность переоценить предыдущую работу и расширить ее в масштабах округа до того, что сейчас называется процессом планирования объектов.`,
      sq: `Gjatë vjeshtës 2019 SDP nisi Rishikimin Gjithëpërfshirës të Planifikimit të Shkollës (CSPR), një proces bashkëpunimi që përfshinte punën me një nëngrup të vogël të komuniteteve shkollore për të rishikuar vlerësimin e regjistrimit në shkollë, objektet shkollore dhe ofertat e programeve arsimore. Ndërsa puna u ndërpre në vitin 2020 për shkak të pandemisë COVID-19, vitet pasuese kanë ofruar një mundësi për të rivlerësuar punën e mëparshme dhe për ta zgjeruar atë në të gjithë qarkun në atë që tani është Procesi i Planifikimit të Objekteve.`,
      km: `ក្នុងអំឡុងពេលរដូវស្លឹកឈើជ្រុះឆ្នាំ 2019 SDP បានចាប់ផ្តើមការពិនិត្យឡើងវិញនូវផែនការសាលារៀនដ៏ទូលំទូលាយ (CSPR) ដែលជាដំណើរការសហការដែលរួមបញ្ចូលការធ្វើការជាមួយក្រុមតូចៗនៃសហគមន៍សាលារៀនដើម្បីពិនិត្យមើលការវាយតម្លៃនៃការចុះឈ្មោះចូលរៀន សម្ភារៈសិក្សា និងការផ្តល់ជូនកម្មវិធីអប់រំ។ ខណៈពេលដែលការងារត្រូវបានផ្អាកនៅឆ្នាំ 2020 ដោយសារតែជំងឺរាតត្បាត COVID-19 ឆ្នាំបន្តបន្ទាប់បានផ្តល់ឱកាសមួយដើម្បីវាយតម្លៃការងារពីមុន និងពង្រីកវាទូទាំងស្រុកទៅក្នុងអ្វីដែលឥឡូវនេះជាដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ។`,
      fr: `Au cours de l'automne 2019, SDP a lancé l'Examen complet de la planification scolaire (CSPR), un processus collaboratif qui comprenait une collaboration avec un petit sous-ensemble de communautés scolaires pour examiner l'évaluation des inscriptions scolaires, des installations scolaires et des offres de programmes éducatifs. Alors que les travaux ont été interrompus en 2020 en raison de la pandémie de COVID-19, les années suivantes ont fourni l'occasion de réévaluer les travaux précédents et de les étendre à l'échelle du district dans ce qui est maintenant le processus de planification des installations.`,
      ar: `خلال خريف 2019 ، أطلقت SDP المراجعة الشاملة للتخطيط المدرسي (CSPR) ، وهي عملية تعاونية تضمنت العمل مع مجموعة فرعية صغيرة من المجتمعات المدرسية لمراجعة تقييم الالتحاق بالمدارس والمرافق المدرسية وعروض البرامج التعليمية. بينما توقف العمل مؤقتًا في عام 2020 بسبب جائحة COVID-19 ، أتاحت السنوات اللاحقة فرصة لإعادة تقييم العمل السابق وتوسيعه على مستوى المنطقة ليشمل ما يُعرف الآن بعملية تخطيط المرافق.`,
      en: `During Fall 2019 SDP launched the Comprehensive School Planning Review (CSPR), a collaborative process that included working with a small subset of school communities to review the assessment of school enrollment, school facilities, and educational program offerings. While the work was paused in 2020 because of the COVID-19 pandemic, subsequent years have provided an opportunity to reassess the previous work and expand it district-wide into what is now the Facilities Planning Process. `,
    },
    body2: {
      es: `Los recientes fondos adicionales (ver a continuación) para los edificios escolares han creado una oportunidad de transformación para que el SDP brinde a los estudiantes los entornos de aprendizaje seguros y de alta calidad que se merecen, que incluyen:`,
      "zh-CN": `最近为学校建筑提供的额外资金（见下文）为 SDP 创造了一个转型机会，为学生提供他们应得的安全、高质量的学习环境，其中包括：`,
      vi: `Nguồn tài trợ bổ sung gần đây (xem bên dưới) cho các tòa nhà trường học đã tạo ra một cơ hội chuyển đổi cho SDP để cung cấp cho học sinh môi trường học tập an toàn, chất lượng cao mà chúng xứng đáng được hưởng, bao gồm:`,
      ru: `Недавнее дополнительное финансирование (см. ниже) школьных зданий создало для SDP возможность преобразования, чтобы предоставить учащимся безопасную и высококачественную среду обучения, которой они заслуживают, включая:`,
      sq: `Financimi shtesë i kohëve të fundit (shih më poshtë) për ndërtesat e shkollave ka krijuar një mundësi transformuese për PSZH-në për t'u ofruar studentëve mjediset e sigurta dhe të cilësisë së lartë të të mësuarit që ata meritojnë, duke përfshirë:`,
      km: `ការផ្តល់មូលនិធិបន្ថែមនាពេលថ្មីៗនេះ (សូមមើលខាងក្រោម) សម្រាប់អគារសិក្សាបានបង្កើតឱកាសផ្លាស់ប្តូរសម្រាប់ SDP ដើម្បីផ្តល់ឱ្យសិស្សនូវបរិយាកាសសិក្សាប្រកបដោយសុវត្ថិភាព និងគុណភាពខ្ពស់ដែលពួកគេសមនឹងទទួលបាន ដែលរួមមានៈ`,
      fr: `Un financement supplémentaire récent (voir ci-dessous) pour les bâtiments scolaires a créé une opportunité de transformation pour le SDP de fournir aux élèves les environnements d'apprentissage sûrs et de haute qualité qu'ils méritent, notamment :`,
      ar: `أدى التمويل الإضافي الأخير (انظر أدناه) للمباني المدرسية إلى خلق فرصة تحويلية لبرنامج SDP لتزويد الطلاب ببيئات تعليمية آمنة وعالية الجودة التي يستحقونها والتي تشمل:`,
      en: `Recent additional funding (see below) for school buildings has created a transformational opportunity for the SDP to provide students with the safe, high quality learning environments they deserve which includes:`,
    },
    body3: {
      es: `habitaciones del tamaño correcto, en la ubicación correcta y que cuenten con el equipo y el almacenamiento adecuados`,
      "zh-CN": `房间大小合适，位置合适，并提供足够的设备和存储空间`,
      vi: `phòng có kích thước phù hợp, ở đúng vị trí và cung cấp đầy đủ thiết bị và kho chứa`,
      ru: `комнаты правильного размера, в правильном месте, с надлежащим оборудованием и местами для хранения`,
      sq: `dhoma që kanë madhësinë e duhur, në vendndodhjen e duhur dhe ofrojnë pajisje dhe magazinim të përshtatshëm`,
      km: `បន្ទប់ដែលមានទំហំត្រឹមត្រូវ ទីតាំងត្រឹមត្រូវ និងផ្តល់ឧបករណ៍ និងកន្លែងផ្ទុកគ្រប់គ្រាន់`,
      fr: `des pièces de la bonne taille, au bon endroit et offrant un équipement et un rangement adéquats`,
      ar: `الغرف ذات الحجم المناسب وفي الموقع المناسب وتوفر المعدات والتخزين المناسبين`,
      en: `rooms that are the right size, in the right location, and provide adequate equipment and storage`,
    },

    body4: {
      es: `edificios y sistemas que están en buenas condiciones y solo requieren mantenimiento preventivo`,
      "zh-CN": `状况良好且仅需要预防性维护的建筑物和系统`,
      vi: `các tòa nhà và hệ thống đang ở trong tình trạng tốt và chỉ cần bảo trì phòng ngừa`,
      ru: `здания и системы, находящиеся в хорошем состоянии и требующие только профилактического обслуживания`,
      sq: `ndërtesat dhe sistemet që janë në gjendje të mirë dhe kërkojnë vetëm mirëmbajtje parandaluese`,
      km: `អគារ និងប្រព័ន្ធដែលមានស្ថានភាពល្អ ហើយត្រូវការការថែទាំបង្ការតែប៉ុណ្ណោះ`,
      fr: `bâtiments et systèmes qui sont en bon état et ne nécessitent qu'un entretien préventif`,
      ar: `المباني والأنظمة في حالة جيدة ولا تتطلب سوى صيانة وقائية`,
      en: `buildings and systems that are in good condition and only require preventative maintenance`,
    },
    body5: {
      es: `edificios que no están sobreutilizados ni infrautilizados ahora o en el futuro`,
      "zh-CN": `现在或将来未过度使用或未充分利用的建筑物`,
      vi: `các tòa nhà chưa kết thúc cũng như chưa sử dụng ở hiện tại hoặc trong tương lai`,
      ru: `здания, которые не перегружены или недоиспользуются в настоящее время или в будущем`,
      sq: `ndërtesa që nuk janë përfunduar dhe as të pashfrytëzuara tani ose në të ardhmen`,
      km: `អគារ​ដែល​មិន​ទាន់​ប្រើ​អស់ ឬ​មិន​បាន​ប្រើប្រាស់​ក្នុង​ពេល​បច្ចុប្បន្ន ឬ​អនាគត`,
      fr: `des bâtiments qui ne sont ni surutilisés ni sous-utilisés actuellement ou à l'avenir`,
      ar: `المباني التي لم يتم استغلالها أو زيادة استخدامها الآن أو في المستقبل`,
      en: `buildings that are not over nor underutilized now or in the future`,
    },
    body6: {
      es: `El Distrito Escolar es elegible para recibir $1.114 mil millones a través del Fondo de Ayuda de Emergencia para Escuelas Primarias y Secundarias del Plan Americano de Rescate (ARP ESSER) (que se gastará en septiembre de 2024). Comprometerá $325 millones de fondos de estímulo durante los próximos cuatro años para expandir drásticamente las mejoras de las instalaciones para brindar escuelas seguras, saludables y modernizadas. Estos fondos complementarán los fondos operativos y de capital previamente dedicados, lo que permitirá una inversión de capital planificada total de más de $ 2 mil millones durante un período de seis años.`,
      "zh-CN": `学区有资格通过美国救援计划中小学紧急救济 (ARP ESSER) 基金获得 11.14 亿美元（将于 2024 年 9 月前使用）。 它将在未来四年内投入 3.25 亿美元的刺激资金，以大幅扩大设施改进，以提供安全、健康和现代化的学校。 这些资金将补充以前的专用资本和运营资金，从而在六年内总计划资本投资超过 20 亿美元。`,
      vi: `Học khu đủ điều kiện nhận 1,114 tỷ đô la thông qua Quỹ Cứu trợ Khẩn cấp cho Trường Tiểu học và Trung học Hoa Kỳ (ARP ESSER) (sẽ được chi trước tháng 9 năm 2024). Nó sẽ cam kết tài trợ 325 triệu đô la trong bốn năm tới để mở rộng đáng kể cải tiến cơ sở vật chất nhằm cung cấp các trường học an toàn, lành mạnh và hiện đại hóa. Các quỹ này sẽ bổ sung vốn dành riêng trước đây và quỹ hoạt động, cho phép tổng vốn đầu tư theo kế hoạch vượt quá 2 tỷ đô la trong thời gian sáu năm.`,
      ru: `Школьный округ имеет право на получение 1,114 миллиарда долларов США через Фонд экстренной помощи начальным и средним школам Американского плана спасения (ARP ESSER) (который будет израсходован к сентябрю 2024 года). В течение следующих четырех лет он выделит 325 миллионов долларов на стимулирование, чтобы значительно расширить возможности улучшения условий для обеспечения безопасных, здоровых и модернизированных школ. Эти средства дополнят ранее выделенные капитальные и операционные фонды, что позволит осуществить общие запланированные капиталовложения в размере более 2 миллиардов долларов в течение шестилетнего периода.`,
      sq: `Distrikti shkollor ka të drejtë për 1,114 miliardë dollarë nëpërmjet Fondit të Ndihmës Emergjente në Shkollën Fillore dhe të Mesme të Planit Amerikan të Shpëtimit (ARP ESSER) (që do të shpenzohet deri në shtator 2024). Ajo do të angazhojë 325 milionë dollarë fonde stimuluese gjatë katër viteve të ardhshme për të zgjeruar në mënyrë dramatike përmirësimet e objekteve për të ofruar shkolla të sigurta, të shëndetshme dhe të modernizuara. Këto fonde do të plotësojnë fondet e kapitalit dhe operimit të dedikuar më parë, duke lejuar një investim kapital të planifikuar total mbi 2 miliardë dollarë për një periudhë gjashtëvjeçare.`,
      km: `សាលាស្រុកមានសិទ្ធិទទួលបាន 1.114 ពាន់លានដុល្លារ តាមរយៈមូលនិធិសង្គ្រោះបន្ទាន់របស់សាលាបឋមសិក្សា និងមធ្យមសិក្សារបស់អាមេរិក (ARP ESSER) (ត្រូវចំណាយត្រឹមខែកញ្ញា ឆ្នាំ 2024)។ វានឹងផ្តល់ប្រាក់ចំនួន 325 លានដុល្លារនៃមូលនិធិជំរុញទឹកចិត្តក្នុងរយៈពេល 4 ឆ្នាំខាងមុខ ដើម្បីពង្រីកការកែលម្អគ្រឿងបរិក្ខារយ៉ាងច្រើន ដើម្បីផ្តល់សាលារៀនប្រកបដោយសុវត្ថិភាព សុខភាពល្អ និងទំនើបកម្ម។ មូលនិធិទាំងនេះនឹងបំពេញបន្ថែមដើមទុន និងមូលនិធិប្រតិបត្តិការដែលបានលះបង់ពីមុន ដែលអនុញ្ញាតឱ្យមានការវិនិយោគដើមទុនដែលបានគ្រោងទុកសរុបលើសពី 2 ពាន់លានដុល្លារក្នុងរយៈពេលប្រាំមួយឆ្នាំ។`,
      fr: `Le district scolaire est éligible à 1,114 milliard de dollars par le biais du fonds ARP ESSER (American Rescue Plan Elementary and Secondary School Emergency Relief) (à dépenser d'ici septembre 2024). Il engagera 325 millions de dollars de fonds de relance au cours des quatre prochaines années pour étendre considérablement les améliorations des installations afin de fournir des écoles sûres, saines et modernisées. Ces fonds viendront s'ajouter aux fonds d'immobilisations et d'exploitation précédemment réservés, permettant un investissement total prévu en immobilisations de plus de 2 milliards de dollars sur une période de six ans.`,
      ar: `المنطقة التعليمية مؤهلة للحصول على 1.114 مليار دولار من خلال صندوق الإغاثة في حالات الطوارئ للمدارس الابتدائية والثانوية لخطة الإنقاذ الأمريكية (ARP ESSER) (يتم إنفاقها بحلول سبتمبر 2024). وستخصص 325 مليون دولار من أموال التحفيز على مدى السنوات الأربع المقبلة لتوسيع تحسينات المرافق بشكل كبير لتوفير مدارس آمنة وصحية وحديثة. وستكمل هذه الأموال الأموال المخصصة مسبقًا لرأس المال والتشغيل ، مما يسمح باستثمار رأسمالي إجمالي مخطط له يزيد عن 2 مليار دولار على مدى ست سنوات.`,
      en: `The School District is eligible for $1.114 billion through the American Rescue Plan Elementary and Secondary School Emergency Relief (ARP ESSER) Fund (to be spent by September 2024). It will commit $325 million of stimulus funds over the next four years to dramatically expand facilities improvements to provide safe, healthy and modernized schools. These funds will supplement previously dedicated capital and operating funds, allowing for a total planned capital investment in excess of $2 billion over a six-year period. `,
    },
  },
  whats_next: {
    title: {
      es: `Objetivos y línea de tiempo`,
      "zh-CN": `目标和时间表`,
      vi: `Mục tiêu và Dòng thời gian`,
      ru: `Цели и сроки`,
      sq: `Qëllimet dhe afati kohor`,
      km: `គោលដៅ និងពេលវេលា`,
      fr: `Objectifs et calendrier`,
      ar: `الأهداف والجدول الزمني`,
      en: `Goals & Timeline`,
    },
    subtitle1: {
      es: `Metas`,
      "zh-CN": `目标`,
      vi: `Bàn thắng`,
      ru: `Цели`,
      sq: `Golat`,
      km: `គោលដៅ`,
      fr: `Buts`,
      ar: `الأهداف`,
      en: `Goals`,
    },
    body1: {
      es: `Queremos que todos los estudiantes tengan una escuela de calidad que:`,
      "zh-CN": `我们希望所有学生都能拥有一所优质的学校：`,
      vi: `Chúng tôi muốn tất cả học sinh có một ngôi trường chất lượng:`,
      ru: `Мы хотим, чтобы у всех учащихся была качественная школа, которая:`,
      sq: `Ne dëshirojmë që të gjithë nxënësit të kenë një shkollë cilësore që:`,
      km: `យើង​ចង់​ឲ្យ​សិស្ស​ទាំងអស់​មាន​សាលា​ដែល​មាន​គុណភាព៖`,
      fr: `Nous voulons que tous les élèves aient une école de qualité qui :`,
      ar: `نريد أن يكون لدى جميع الطلاب مدرسة ذات جودة:`,
      en: `We want all students to have a quality school that:`,
    },
    body2: {
      es: `es un entorno de aprendizaje acogedor y estimulante`,
      "zh-CN": `是一个诱人和刺激的学习环境`,
      vi: `là một môi trường học tập hấp dẫn và kích thích`,
      ru: `это привлекательная и стимулирующая среда обучения`,
      sq: `është një mjedis mësimor ftues dhe stimulues`,
      km: `គឺជាបរិយាកាសសិក្សាដ៏ទាក់ទាញ និងទាក់ទាញ`,
      fr: `est un environnement d'apprentissage invitant et stimulant`,
      ar: `هي بيئة تعليمية جذابة ومحفزة`,
      en: `is an inviting and stimulating learning environment`,
    },
    body3: {
      es: `tiene habitaciones que son del tamaño correcto, en la ubicación correcta y proporcionan el equipo y el almacenamiento adecuados`,
      "zh-CN": `房间大小合适，位置合适，并提供足够的设备和存储空间`,
      vi: `có các phòng có kích thước phù hợp, ở đúng vị trí và cung cấp đầy đủ thiết bị và kho chứa`,
      ru: `есть комнаты нужного размера, в правильном месте, с надлежащим оборудованием и складскими помещениями`,
      sq: `ka dhoma që janë të madhësisë së duhur, në vendndodhjen e duhur dhe ofrojnë pajisje dhe magazinim adekuat`,
      km: `មានបន្ទប់ដែលមានទំហំត្រឹមត្រូវ ទីតាំងត្រឹមត្រូវ និងផ្តល់ឧបករណ៍ និងស្តុកទុកគ្រប់គ្រាន់`,
      fr: `dispose de pièces de la bonne taille, au bon endroit et offrant un équipement et un rangement adéquats`,
      ar: `تحتوي الغرف على الحجم المناسب وفي الموقع المناسب وتوفر المعدات والتخزين المناسبين`,
      en: `has rooms that are the right size, in the right location and provide adequate equipment and storage`,
    },
    body4: {
      es: `tiene un edificio y sistemas que están en buenas condiciones y solo requieren mantenimiento preventivo`,
      "zh-CN": `建筑物和系统状况良好，只需要预防性维护`,
      vi: `có một tòa nhà và các hệ thống ở trong tình trạng tốt và chỉ cần bảo trì phòng ngừa`,
      ru: `имеет здание и системы, которые находятся в хорошем состоянии и требуют только профилактического обслуживания`,
      sq: `ka një ndërtesë dhe sisteme që janë në gjendje të mirë dhe kërkojnë vetëm mirëmbajtje parandaluese`,
      km: `មានអាគារ និងប្រព័ន្ធដែលស្ថិតក្នុងស្ថានភាពល្អ ហើយត្រូវការការថែទាំបង្ការតែប៉ុណ្ណោះ`,
      fr: `a un bâtiment et des systèmes qui sont en bon état et ne nécessitent qu'un entretien préventif`,
      ar: `به مبنى وأنظمة في حالة جيدة ولا تتطلب سوى صيانة وقائية`,
      en: `has a building and systems that are in good condition and only require preventative maintenance`,
    },
    body5: {
      es: `no está sobreutilizado o infrautilizado ahora o en el futuro`,
      "zh-CN": `现在或将来没有过度或未充分利用`,
      vi: `không quá mức hoặc không được sử dụng ở hiện tại hoặc trong tương lai`,
      ru: `не исчерпан или недоиспользован в настоящее время или в будущем`,
      sq: `nuk ka mbaruar apo nënshfrytëzuar tani apo në të ardhmen`,
      km: `មិន​ត្រូវ​បាន​ប្រើ​ប្រាស់​លើស​ពី​នេះ​ឬ​នៅ​ពេល​អនាគត​`,
      fr: `n'est pas surutilisé ou sous-utilisé maintenant ou à l'avenir`,
      ar: `لا يزيد أو يستغل بالقدر الكافي الآن أو في المستقبل`,
      en: `is not over or underutilized now or in the future`,
    },
    body6: {
      es: `Actualmente no tenemos esto en todas las escuelas. Y, como se explica en`,
      "zh-CN": `我们目前在所有学校都没有这个。 并且，正如在`,
      vi: `Chúng tôi hiện không có điều này ở tất cả các trường học. Và, như đã giải thích trong `,
      ru: `У нас пока не во всех школах. И, как поясняется в `,
      sq: `Aktualisht nuk e kemi këtë në të gjitha shkollat. Dhe, siç shpjegohet në `,
      km: `បច្ចុប្បន្ននេះ យើងមិនមានវានៅក្នុងសាលារៀនទាំងអស់ទេ។ ហើយដូចដែលបានពន្យល់នៅក្នុង `,
      fr: `Nous n'avons pas cela actuellement dans toutes les écoles. Et, comme expliqué dans `,
      ar: `لا يتوفر هذا حاليًا في جميع المدارس. وكما هو موضح في `,
      en: `We don’t currently have this in all schools.  And, as explained in `,
    },
    link1: {
      es: `¿Porqué ahora?`,
      "zh-CN": `为什么现在？`,
      vi: `Tại sao bây giờ?`,
      ru: `Почему сейчас?`,
      sq: `Pse tani?`,
      km: `ហេតុអ្វីឥឡូវនេះ?`,
      fr: `Pourquoi maintenant?`,
      ar: `لماذا الان؟`,
      en: `Why Now?`,
    },
    body7: {
      es: `, la nueva financiación nos da la oportunidad de abordar estos problemas.`,
      "zh-CN": `，新的资金使我们有机会解决这些问题。`,
      vi: `, nguồn tài trợ mới cho phép chúng tôi có cơ hội giải quyết những vấn đề này.`,
      ru: `, новое финансирование дает нам возможность решить эти проблемы.`,
      sq: `, financimi i ri na jep mundësinë për të adresuar këto çështje.`,
      km: `ការផ្តល់មូលនិធិថ្មីអនុញ្ញាតឱ្យយើងមានឱកាសដោះស្រាយបញ្ហាទាំងនេះ។`,
      fr: `, de nouveaux financements nous permettent de résoudre ces problèmes.`,
      ar: `، يتيح لنا التمويل الجديد الفرصة لمعالجة هذه القضايا.`,
      en: `, new funding allows us the opportunity to address these issues.`,
    },
    subtitle21: {
      es: `Cronología`,
      "zh-CN": `时间线`,
      vi: `Mốc thời gian`,
      ru: `График`,
      sq: `Afati kohor`,
      km: `បន្ទាត់ពេលវេលា`,
      fr: `Chronologie`,
      ar: `الجدول الزمني`,
      en: `Timeline`,
    },
    subtitle2: {
      es: `Participación de la comunidad`,
      "zh-CN": `社区参与`,
      vi: `Kết nối cộng đồng`,
      ru: `Взаимодействие с сообществом`,
      sq: `Angazhimi i komunitetit`,
      km: `ការចូលរួមសហគមន៍`,
      fr: `Engagement communautaire`,
      ar: `المشاركة المجتمعية`,
      en: `Community Engagement`,
    },
    subtitle3: {
      es: `Sesiones Informativas`,
      "zh-CN": `信息会议`,
      vi: `Phiên thông tin`,
      ru: `Информационные сессии`,
      sq: `Sesionet e Informacionit`,
      km: `វគ្គព័ត៌មាន`,
      fr: `Séances d'information`,
      ar: `الجلسات الإعلامية`,
      en: `Information Sessions`,
    },
    subsubtitle1: {
      es: `Equipo de proyecto`,
      "zh-CN": `项目团队`,
      vi: `Nhóm dự án`,
      ru: `Проектная группа`,
      sq: `Ekipi i projektit`,
      km: `ក្រុមគម្រោង`,
      fr: `Projet de groupe`,
      ar: `فريق المشروع`,
      en: `Project Team`,
    },
    body8: {
      es: `El Equipo del Proyecto incluye al Equipo del Distrito y colaboradores expertos que se especializan en evaluaciones de instalaciones, análisis, facilitación, participación y comunicación. Puede leer más sobre el equipo del proyecto a continuación.`,
      "zh-CN": `项目团队包括地区团队和专门从事设施评估、分析、促进、参与和沟通的专家合作者。 您可以在下面阅读有关项目团队的更多信息。`,
      vi: `Nhóm Dự án bao gồm Nhóm Quận và các cộng tác viên chuyên gia chuyên về đánh giá cơ sở vật chất, phân tích, hỗ trợ, tham gia và truyền thông. Bạn có thể đọc thêm về Nhóm Dự án bên dưới.`,
      ru: `В проектную группу входят окружная команда и специалисты, специализирующиеся на оценке объектов, аналитике, содействии, взаимодействии и общении. Вы можете прочитать больше о команде проекта ниже.`,
      sq: `Ekipi i Projektit përfshin Ekipin e Distriktit dhe bashkëpunëtorë ekspertë të specializuar në vlerësimin e objekteve, analitikën, lehtësimin, angazhimin dhe komunikimin. Mund të lexoni më shumë rreth Ekipit të Projektit më poshtë.`,
      km: `ក្រុមគម្រោងរួមមានក្រុមស្រុក និងអ្នកសហការជំនាញដែលមានឯកទេសក្នុងការវាយតម្លៃគ្រឿងបរិក្ខារ ការវិភាគ ការសម្របសម្រួល ការចូលរួម និងការទំនាក់ទំនង។ អ្នកអាចអានបន្ថែមអំពីក្រុមគម្រោងខាងក្រោម។`,
      fr: `L'équipe de projet comprend l'équipe de district et des collaborateurs experts spécialisés dans les évaluations des installations, l'analyse, la facilitation, l'engagement et la communication. Vous pouvez en savoir plus sur l'équipe du projet ci-dessous.`,
      ar: `يشمل فريق المشروع فريق المنطقة والمتعاونين الخبراء المتخصصين في تقييمات المرافق والتحليلات والتيسير والمشاركة والتواصل. يمكنك قراءة المزيد عن فريق المشروع أدناه.`,
      en: `The Project Team includes the District Team and expert collaborators specializing in facilities assessments, analytics, facilitation, engagement and communication. You can read more about the Project Team below.`,
    },
    subsubtitle2: {
      es: ``,
      "zh-CN": `费城学区`,
      vi: `Học khu Philadelphia`,
      ru: `Школьный округ Филадельфии`,
      sq: `Distrikti shkollor i Filadelfias`,
      km: `សាលាស្រុកនៃទីក្រុង Philadelphia`,
      fr: `District scolaire de Philadelphie`,
      ar: `مقاطعة فيلادلفيا التعليمية`,
      en: `School District of Philadelphia`,
    },
    body9: {
      es: `Distrito escolar de Filadelfia`,
      "zh-CN": `地区团队包括来自总监办公室的核心团队和来自每个地区部门的顾问。 地区团队负责清晰透明地传达设施的现状、设施规划的历史和背景、社区参与过程以及它如何为设施总体规划提供信息，以及实施持续和透明的设施规划。`,
      vi: `Quận Đội bao gồm một nhóm nòng cốt từ Văn phòng Giám đốc Học khu và các cố vấn từ mỗi phòng ban của Quận. Huyện đội chịu trách nhiệm thông báo rõ ràng và minh bạch về tình trạng hiện tại của cơ sở vật chất, lịch sử và bối cảnh của việc lập kế hoạch cơ sở vật chất, quá trình tham gia của cộng đồng và cách thức tổ chức này thông báo cho Kế hoạch tổng thể cơ sở vật chất, và việc thực hiện kế hoạch cơ sở vật chất đang diễn ra và minh bạch.`,
      ru: `Окружная команда включает в себя основную команду из офиса суперинтенданта и советников из каждого окружного отдела. Окружная команда отвечает за четкое и прозрачное информирование о текущем состоянии объектов, историю и контекст планирования объектов, процесс взаимодействия с населением и то, как он информирует о Генеральном плане объектов, а также за реализацию текущего и прозрачного планирования объектов.`,
      sq: `Ekipi i Distriktit përfshin një ekip bazë nga Zyra e Mbikëqyrësit dhe këshilltarë nga çdo departament distrikti. Ekipi i Qarkut është përgjegjës për komunikimin e qartë dhe transparent të statusit aktual të objekteve, historisë dhe kontekstit të planifikimit të objekteve, procesit të angazhimit të komunitetit dhe mënyrës se si po informon Masterplanin e objekteve dhe zbatimin e planifikimit të vazhdueshëm dhe transparent të objekteve.`,
      km: `ក្រុមការងារស្រុក រួមមានក្រុមស្នូលមកពីការិយាល័យអគ្គនាយក និងទីប្រឹក្សាមកពីមន្ទីរនីមួយៗ។ ក្រុមការងារស្រុកមានទំនួលខុសត្រូវលើការទំនាក់ទំនងច្បាស់លាស់ និងតម្លាភាពនៃស្ថានភាពបច្ចុប្បន្ននៃគ្រឿងបរិក្ខារ ប្រវត្តិ និងបរិបទនៃការរៀបចំផែនការគ្រឿងបរិក្ខារ ដំណើរការចូលរួមសហគមន៍ និងរបៀបដែលវាកំពុងជូនដំណឹងដល់ផែនការមេនៃគ្រឿងបរិក្ខារ និងការអនុវត្តផែនការមេកន្លែងដែលកំពុងដំណើរការ និងតម្លាភាព។`,
      fr: `L'équipe de district comprend une équipe de base du bureau du surintendant et des conseillers de chaque département de district. L'équipe de district est responsable de la communication claire et transparente de l'état actuel des installations, de l'historique et du contexte de la planification des installations, du processus d'engagement communautaire et de la manière dont il informe le plan directeur des installations, ainsi que de la mise en œuvre d'une planification continue et transparente des installations.`,
      ar: `يضم فريق المنطقة فريقًا أساسيًا من مكتب المشرف ومستشارين من كل قسم من أقسام المنطقة. فريق المنطقة مسؤول عن التواصل الواضح والشفاف للوضع الحالي للمرافق ، وتاريخ وسياق تخطيط المرافق ، وعملية المشاركة المجتمعية وكيف يتم إبلاغ الخطة الرئيسية للمنشآت ، وتنفيذ التخطيط المستمر والشفاف للمرافق.`,
      en: `The District Team includes a core team from the Superintendent’s Office and advisors from each District department. The District Team is responsible for clear and transparent communication of the current status of facilities, the history and context of facilities planning, the community engagement process and how it is informing the Facilities Master Plan, and the implementation of ongoing and transparent facilities planning.`,
    },
    subsubtitle3: {
      es: `Grupo de Infraestructura y Medio Ambiente de Parsons`,
      "zh-CN": `帕森斯环境和基础设施集团`,
      vi: `Nhóm Cơ sở hạ tầng và Môi trường Parsons`,
      ru: `Группа охраны окружающей среды и инфраструктуры Parsons`,
      sq: `Parsons Environmental and Infrastructure Group`,
      km: `Parsons Environmental and Infrastructure Group`,
      fr: `Groupe Parsons de l'environnement et des infrastructures`,
      ar: `مجموعة بارسونز البيئية والبنية التحتية`,
      en: `Parsons Environmental and Infrastructure Group`,
    },
    body10: {
      es: `Los profesionales de Parsons, una firma de arquitectura e ingeniería fundada en 1944, midieron y analizaron datos importantes relevantes para el Proceso de planificación de instalaciones, como la capacidad, las condiciones de las instalaciones y la idoneidad educativa de cada edificio en el Distrito, y desarrollarán el Plan maestro de instalaciones.`,
      "zh-CN": `Parsons 是一家成立于 1944 年的建筑和工程公司，其专业人员已经测量和分析了与设施规划过程相关的重要数据，例如容量、设施条件和该地区每栋建筑的教育适用性，并将制定设施总体规划。`,
      vi: `Các chuyên gia tại Parsons, một công ty kiến trúc và kỹ thuật được thành lập vào năm 1944, đã đo lường và phân tích dữ liệu quan trọng liên quan đến Quy trình Lập kế hoạch Cơ sở vật chất như năng lực, điều kiện cơ sở vật chất và sự phù hợp giáo dục của từng tòa nhà trong Quận và sẽ phát triển Kế hoạch Tổng thể Cơ sở vật chất.`,
      ru: `Специалисты Parsons, архитектурно-инженерной фирмы, основанной в 1944 году, измерили и проанализировали важные данные, относящиеся к процессу планирования объектов, такие как вместимость, условия объектов и пригодность для образовательных учреждений каждого здания в округе, и разработают генеральный план объектов.`,
      sq: `Profesionistët në Parsons, një firmë arkitekturore dhe inxhinierike e themeluar në vitin 1944, kanë matur dhe analizuar të dhëna të rëndësishme të rëndësishme për Procesin e Planifikimit të Objekteve si kapaciteti, kushtet e objektit dhe përshtatshmëria arsimore e çdo ndërtese në distrikt dhe do të zhvillojnë Masterplanin e Objekteve.`,
      km: `អ្នកជំនាញនៅ Parsons ដែលជាក្រុមហ៊ុនស្ថាបត្យកម្ម និងវិស្វកម្មដែលបានបង្កើតឡើងក្នុងឆ្នាំ 1944 បានវាស់វែង និងវិភាគទិន្នន័យសំខាន់ៗដែលពាក់ព័ន្ធសម្រាប់ដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារដូចជា សមត្ថភាព លក្ខខណ្ឌបរិក្ខារ និងភាពសមស្របនៃការអប់រំនៃអគារនីមួយៗក្នុងសង្កាត់ ហើយនឹងបង្កើតផែនការមេនៃគ្រឿងបរិក្ខារ។`,
      fr: `Les professionnels de Parsons, une société d'architecture et d'ingénierie fondée en 1944, ont mesuré et analysé des données importantes pertinentes pour le processus de planification des installations telles que la capacité, l'état des installations et l'adéquation éducative de chaque bâtiment du district et élaboreront le plan directeur des installations.`,
      ar: `قام المحترفون في Parsons ، وهي شركة معمارية وهندسية تأسست عام 1944 ، بقياس وتحليل البيانات المهمة ذات الصلة بعملية تخطيط المرافق مثل السعة وظروف المنشأة والملاءمة التعليمية لكل مبنى في المنطقة وسيقومون بتطوير الخطة الرئيسية للمرافق.`,
      en: `Professionals at Parsons, an architectural and engineering firm founded in 1944, have measured and analyzed important data relevant for the Facilities Planning Process such as capacity, facility conditions, and the educational suitability of each building in the District and will develop the Facilities Master Plan.`,
    },
    subsubtitle4: {
      es: `Análisis de FLO`,
      "zh-CN": `弗洛分析`,
      vi: `Phân tích FLO`,
      ru: `Аналитика ФЛО`,
      sq: `FLO Analytics`,
      km: `ការវិភាគ FLO`,
      fr: `FLO Analytique`,
      ar: `تحليلات FLO`,
      en: `FLO Analytics`,
    },
    body11: {
      es: `Expertos de la industria en el diseño, implementación y uso de GIS y análisis de datos, FLO proporcionará pronósticos de inscripción de estudiantes de diez años para los grados de jardín de infantes a 12.° grado para determinar cómo se prevé que cambien las poblaciones y comunidades en toda la ciudad de Filadelfia en los próximos años. .`,
      "zh-CN": `设计、实施和使用 GIS 和数据分析的行业专家，FLO 将提供从幼儿园到 12 年级的十年学生入学率预测，以确定未来几年费城市的人口和社区预计将如何变化 .`,
      vi: `Các chuyên gia trong ngành trong việc thiết kế, triển khai và sử dụng GIS và phân tích dữ liệu, FLO sẽ cung cấp các dự báo tuyển sinh học sinh mười năm cho các lớp từ mẫu giáo đến lớp 12 để xác định dân số và cộng đồng trên toàn Thành phố Philadelphia dự kiến sẽ thay đổi như thế nào trong những năm tới .`,
      ru: `Эксперты отрасли в области разработки, внедрения и использования ГИС и анализа данных, FLO предоставит десятилетние прогнозы набора учащихся для классов от детского сада до 12-го класса, чтобы определить, как, по прогнозам, население и сообщества в городе Филадельфия изменятся в ближайшие годы. .`,
      sq: `Ekspertët e industrisë në hartimin, zbatimin dhe përdorimin e GIS dhe analitikës së të dhënave, FLO do të ofrojë parashikime dhjetëvjeçare të regjistrimit të studentëve për klasat e kopshtit deri në klasën e 12-të për të përcaktuar se si popullatat dhe komunitetet në të gjithë qytetin e Filadelfia janë parashikuar të ndryshojnë në vitet e ardhshme .`,
      km: `អ្នកជំនាញក្នុងឧស្សាហកម្មក្នុងការរចនា ការអនុវត្ត និងការប្រើប្រាស់ GIS និងការវិភាគទិន្នន័យ FLO នឹងផ្តល់ការព្យាករណ៍ការចុះឈ្មោះសិស្សរយៈពេល 10 ឆ្នាំសម្រាប់ថ្នាក់មត្តេយ្យដល់ថ្នាក់ទី 12 ដើម្បីកំណត់ពីរបៀបដែលចំនួនប្រជាជន និងសហគមន៍នៅទូទាំងទីក្រុង Philadelphia ត្រូវបានព្យាករណ៍ថានឹងផ្លាស់ប្តូរនៅក្នុងប៉ុន្មានឆ្នាំខាងមុខនេះ។ .`,
      fr: `Experts de l'industrie dans la conception, la mise en œuvre et l'utilisation de SIG et d'analyse de données, FLO fournira des prévisions d'inscription d'élèves sur dix ans pour les classes de la maternelle à la 12e année afin de déterminer comment les populations et les communautés de la ville de Philadelphie devraient changer dans les années à venir .`,
      ar: `خبراء الصناعة في تصميم وتنفيذ واستخدام نظم المعلومات الجغرافية وتحليلات البيانات ، سيوفر FLO توقعات التحاق الطلاب لمدة عشر سنوات للصفوف من رياض الأطفال حتى الصف الثاني عشر لتحديد كيف من المتوقع أن يتغير السكان والمجتمعات في جميع أنحاء مدينة فيلادلفيا في السنوات القادمة .`,
      en: `Industry experts in the design, implementation, and use of GIS and data analytics, FLO will provide ten-year student enrollment forecasts for grades kindergarten through 12th grade to determine how populations and communities across the City of Philadelphia are projected to change in the coming years.`,
    },
    subsubtitle5: {
      es: `WXY Studio, facilitador`,
      "zh-CN": `WXY工作室，主持人`,
      vi: `WXY Studio, Người hướng dẫn`,
      ru: `Студия WXY, ведущий`,
      sq: `Studio WXY, Facilitator`,
      km: `WXY Studio, អ្នកសម្របសម្រួល`,
      fr: `Studio WXY, Facilitateur`,
      ar: `WXY Studio ، الميسر`,
      en: `WXY Studio, Facilitator`,
    },
    body12: {
      es: `WXY Studio, una empresa con experiencia en involucrar a las partes interesadas para resolver desafíos complejos del ámbito público, es una de las dos empresas que lideran los extensos esfuerzos de participación. El papel de WXY Studio incluye apoyar al Distrito en la gestión de proyectos y el desarrollo de procesos para la participación, el análisis de datos y el intercambio de información. `,
      "zh-CN": `WXY Studio 是一家在让利益相关者参与解决复杂的公共领域挑战方面经验丰富的公司，是领导广泛参与工作的两家公司之一。 WXY Studio 的职责包括支持学区进行项目管理和开发参与、数据分析和信息共享的流程。`,
      vi: `WXY Studio, một công ty có kinh nghiệm trong việc thu hút các bên liên quan để giải quyết các thách thức phức tạp trong lĩnh vực công cộng, là một trong hai công ty dẫn đầu các nỗ lực tham gia sâu rộng. Vai trò của WXY Studio bao gồm hỗ trợ Học khu trong việc quản lý dự án và phát triển các quy trình tham gia, phân tích dữ liệu và chia sẻ thông tin.`,
      ru: `WXY Studio, фирма, имеющая опыт привлечения заинтересованных сторон для решения сложных задач в сфере общественной жизни, является одной из двух фирм, ведущих обширные усилия по взаимодействию. Роль WXY Studio включает в себя поддержку округа в управлении проектами и разработке процессов взаимодействия, анализа данных и обмена информацией.`,
      sq: `WXY Studio, një firmë me përvojë në angazhimin e palëve të interesuara për të zgjidhur sfidat komplekse të sferës publike, është një nga dy firmat që udhëheqin përpjekjet e gjera të angazhimit. Roli i WXY Studio përfshin mbështetjen e Distriktit në menaxhimin e projektit dhe zhvillimin e proceseve për angazhimin, analizën e të dhënave dhe ndarjen e informacionit.`,
      km: `WXY Studio ដែលជាក្រុមហ៊ុនដែលមានបទពិសោធន៍ក្នុងការចូលរួមជាមួយភាគីពាក់ព័ន្ធដើម្បីដោះស្រាយបញ្ហាប្រឈមនៃអាណាចក្រសាធារណៈដ៏ស្មុគស្មាញ គឺជាក្រុមហ៊ុនមួយក្នុងចំណោមក្រុមហ៊ុនពីរដែលដឹកនាំកិច្ចខិតខំប្រឹងប្រែងចូលរួមយ៉ាងទូលំទូលាយ។ តួនាទីរបស់ WXY Studio រួមមានការគាំទ្រដល់ស្រុកក្នុងការគ្រប់គ្រងគម្រោង និងការអភិវឌ្ឍន៍ដំណើរការសម្រាប់ការចូលរួម ការវិភាគទិន្នន័យ និងការចែករំលែកព័ត៌មាន។`,
      fr: `WXY Studio, une entreprise expérimentée dans l'engagement des parties prenantes pour résoudre les défis complexes du domaine public, est l'une des deux entreprises à la tête des efforts d'engagement étendus. Le rôle de WXY Studio comprend le soutien du district dans la gestion de projet et le développement de processus d'engagement, d'analyse de données et de partage d'informations.`,
      ar: `WXY Studio ، شركة ذات خبرة في إشراك أصحاب المصلحة لحل تحديات المجال العام المعقدة ، هي واحدة من شركتين تقودان جهود المشاركة المكثفة. يتضمن دور WXY Studio دعم المنطقة في إدارة المشاريع وتطوير عمليات المشاركة وتحليل البيانات ومشاركة المعلومات.`,
      en: `WXY Studio, a firm experienced in engaging stakeholders to solve complex public realm challenges, is one of two firms leading the extensive engagement efforts. WXY Studio’s role includes supporting the District in project management and developing processes for engagement, data analysis, and information sharing. `,
    },
    subsubtitle6: {
      es: `Public Engagement Associates (PEA), facilitación y soporte técnico`,
      "zh-CN": `公众参与协会 (PEA)、促进和技术支持`,
      vi: `Hiệp hội Tương tác Công chúng (PEA), Tạo điều kiện thuận lợi và Hỗ trợ Kỹ thuật`,
      ru: `Public Engagement Associates (PEA), содействие и техническая поддержка`,
      sq: `Bashkëpunëtorët e Angazhimit Publik (APP), Lehtësimi dhe Mbështetja Teknike`,
      km: `សហការី ការចូលរួមសាធារណៈ (PEA) ការសម្របសម្រួល និងជំនួយបច្ចេកទេស`,
      fr: `Public Engagement Associates (PEA), animation et soutien technique`,
      ar: `شركاء المشاركة العامة (PEA) والتيسير والدعم الفني`,
      en: `Public Engagement Associates (PEA), Facilitation and Technical Support`,
    },
    body13: {
      es: `Public Engagement Associates, una firma cuya misión es ayudar a mejorar la calidad y la eficacia de la participación ciudadana, también lidera los esfuerzos de participación. PEA desarrollará y facilitará la participación comunitaria y la producción técnica.`,
      "zh-CN": `Public Engagement Associates 是一家致力于帮助提高公民参与质量和有效性的公司，它也在领导参与工作。 PEA 将发展和促进社区参与和技术生产。`,
      vi: `Hiệp hội Tương tác Công chúng, một công ty có sứ mệnh giúp cải thiện chất lượng và hiệu quả của sự tham gia của người dân, cũng đang dẫn đầu các nỗ lực tham gia. PEA sẽ phát triển và tạo điều kiện thuận lợi cho sự tham gia của cộng đồng và sản xuất kỹ thuật.`,
      ru: `Public Engagement Associates, фирма, чья миссия состоит в том, чтобы помочь улучшить качество и эффективность участия граждан, также возглавляет усилия по вовлечению. PEA будет развивать и способствовать вовлечению сообщества и техническому производству.`,
      sq: `Public Engagement Associates, një firmë, misioni i së cilës është të ndihmojë në përmirësimin e cilësisë dhe efektivitetit të angazhimit qytetar, po udhëheq gjithashtu përpjekjet e angazhimit. APP do të zhvillojë dhe lehtësojë angazhimin e komunitetit dhe prodhimin teknik.`,
      km: `Public Engagement Associates ដែលជាក្រុមហ៊ុនដែលមានបេសកកម្មជួយលើកកំពស់គុណភាព និងប្រសិទ្ធភាពនៃការចូលរួមរបស់ប្រជាពលរដ្ឋ ក៏កំពុងដឹកនាំកិច្ចខិតខំប្រឹងប្រែងចូលរួមផងដែរ។ PEA នឹងអភិវឌ្ឍ និងសម្រួលដល់ការចូលរួមសហគមន៍ និងផលិតកម្មបច្ចេកទេស។`,
      fr: `Public Engagement Associates, une entreprise dont la mission est d'aider à améliorer la qualité et l'efficacité de l'engagement des citoyens, dirige également les efforts d'engagement. PEA développera et facilitera l'engagement communautaire et la production technique.`,
      ar: `كما تقود شركة Public Engagement Associates ، وهي شركة مهمتها المساعدة على تحسين جودة وفعالية مشاركة المواطنين ، جهود المشاركة. سوف يقوم برنامج PEA بتطوير وتسهيل المشاركة المجتمعية والإنتاج الفني.`,
      en: `Public Engagement Associates, a firm whose mission is to help improve the quality and effectiveness of citizen engagement, is also leading the engagement efforts. PEA will develop and facilitate community engagement and technical production.`,
    },
    subsubtitle7: {
      es: `Skai Blue Media, Comunicaciones Estratégicas`,
      "zh-CN": `Skai Blue Media，战略传播`,
      vi: `Skai Blue Media, Truyền thông Chiến lược`,
      ru: `Skai Blue Media, стратегические коммуникации`,
      sq: `Skai Blue Media, Strategic Communications`,
      km: `Skai Blue Media, ទំនាក់ទំនងជាយុទ្ធសាស្ត្រ`,
      fr: `Skai Blue Media, Communications stratégiques`,
      ar: `سكاي بلو ميديا ، الاتصالات الاستراتيجية`,
      en: `Skai Blue Media, Strategic Communications`,
    },
    body14: {
      es: `Skai Blue Media brindará apoyo de comunicaciones estratégicas para mantener informadas a las comunidades escolares durante todo el proceso. Esto incluye la identificación de canales, la sincronización y el encuadre de las comunicaciones.`,
      "zh-CN": `Skai Blue Media 将提供战略沟通支持，让学校社区在整个过程中了解情况。 这包括识别通信的渠道、时间和框架。`,
      vi: `Skai Blue Media sẽ cung cấp hỗ trợ truyền thông chiến lược để thông báo cho cộng đồng trường học trong suốt quá trình. Điều này bao gồm xác định các kênh, thời gian và khuôn khổ của thông tin liên lạc.`,
      ru: `Skai Blue Media обеспечит стратегическую коммуникационную поддержку, чтобы информировать школьное сообщество на протяжении всего процесса. Это включает в себя идентификацию каналов, синхронизацию и структуру сообщений.`,
      sq: `Skai Blue Media do të ofrojë mbështetje strategjike komunikimi për të mbajtur komunitetet e shkollave të informuara gjatë gjithë procesit. Kjo përfshin identifikimin e kanaleve, kohën dhe kornizën e komunikimit.`,
      km: `Skai Blue Media នឹងផ្តល់ការគាំទ្រផ្នែកទំនាក់ទំនងជាយុទ្ធសាស្រ្ត ដើម្បីរក្សាសហគមន៍សាលារៀនឱ្យទទួលបានព័ត៌មានពេញមួយដំណើរការ។ នេះរួមបញ្ចូលទាំងការកំណត់អត្តសញ្ញាណបណ្តាញ ពេលវេលា និងការរៀបចំទំនាក់ទំនង។`,
      fr: `Skai Blue Media fournira un soutien stratégique en matière de communication pour tenir les communautés scolaires informées tout au long du processus. Cela comprend l'identification des canaux, le calendrier et le cadrage des communications.`,
      ar: `ستوفر Skai Blue Media دعمًا استراتيجيًا للاتصالات لإبقاء مجتمعات المدارس على اطلاع طوال العملية. وهذا يشمل تحديد القنوات والتوقيت وتأطير الاتصالات.`,
      en: `Skai Blue Media will provide strategic communications support to keep school communities informed throughout the process. This includes identifying channels, timing, and framing of communications.`,
    },
    subsubtitle8: {
      es: `Comunidades escolares y comunidad de Filadelfia`,
      "zh-CN": `学校社区和费城社区`,
      vi: `Cộng đồng Trường học và Cộng đồng Philadelphia`,
      ru: `Школьные сообщества и сообщество Филадельфии`,
      sq: `Komunitetet Shkollore dhe Komuniteti i Filadelfisë`,
      km: `សហគមន៍សាលារៀន និងសហគមន៍ទីក្រុង Philadelphia`,
      fr: `Communautés scolaires et communauté de Philadelphie`,
      ar: `المجتمعات المدرسية ومجتمع فيلادلفيا`,
      en: `School Communities and Philadelphia Community`,
    },
    body15: {
      es: `Las comunidades más afectadas por la planificación de instalaciones son expertas en las necesidades, deseos y prioridades de su comunidad. Para construir un plan para entornos de aprendizaje donde los estudiantes puedan prosperar, el papel de las comunidades escolares y la comunidad de Filadelfia son esenciales para esta planificación.`,
      "zh-CN": `受设施规划影响最大的社区是了解其社区需求、愿望和优先事项的专家。 为了制定学生可以茁壮成长的学习环境计划，学校社区和费城社区的作用对于该计划至关重要。`,
      vi: `Các cộng đồng bị ảnh hưởng nhiều nhất bởi quy hoạch cơ sở vật chất là các chuyên gia về nhu cầu, mong muốn và ưu tiên của cộng đồng của họ. Để xây dựng một kế hoạch cho môi trường học tập nơi học sinh có thể phát triển, vai trò của các cộng đồng trường học và cộng đồng Philadelphia là rất cần thiết cho việc lập kế hoạch này.`,
      ru: `Сообщества, наиболее затронутые планированием объектов, являются экспертами в потребностях, желаниях и приоритетах своего сообщества. Чтобы разработать план учебной среды, в которой учащиеся могут процветать, важную роль в этом планировании играют школьные сообщества и сообщество Филадельфии.`,
      sq: `Komunitetet më të prekura nga planifikimi i objekteve janë ekspertë për nevojat, dëshirat dhe prioritetet e komunitetit të tyre. Për të ndërtuar një plan për mjediset mësimore ku nxënësit mund të lulëzojnë, roli i komuniteteve shkollore dhe komunitetit të Filadelfias janë thelbësor për këtë planifikim.`,
      km: `សហគមន៍ដែលរងផលប៉ះពាល់ខ្លាំងបំផុតដោយការធ្វើផែនការគ្រឿងបរិក្ខារគឺជាអ្នកជំនាញលើតម្រូវការ បំណងប្រាថ្នា និងអាទិភាពនៃសហគមន៍របស់ពួកគេ។ ដើម្បីកសាងផែនការសម្រាប់បរិយាកាសសិក្សាដែលសិស្សអាចរីកចម្រើន តួនាទីរបស់សហគមន៍សាលារៀន និងសហគមន៍ Philadelphia មានសារៈសំខាន់ចំពោះផែនការនេះ។`,
      fr: `Les communautés les plus touchées par la planification des installations sont des experts des besoins, des désirs et des priorités de leur communauté. Pour construire un plan pour des environnements d'apprentissage où les élèves peuvent s'épanouir, le rôle des communautés scolaires et de la communauté de Philadelphie est essentiel à cette planification.`,
      ar: `المجتمعات الأكثر تأثراً بتخطيط المرافق هم خبراء في احتياجات ورغبات وأولويات مجتمعهم. لبناء خطة لبيئات التعلم حيث يمكن للطلاب الازدهار ، فإن دور المجتمعات المدرسية ومجتمع فيلادلفيا ضروري لهذا التخطيط.`,
      en: `Communities most impacted by facilities planning are experts on the needs, desires, and priorities of their community. To build a plan for learning environments where students can thrive, the role of school communities and the Philadelphia community are essential to this planning.`,
    },
    subsubtitle9: {
      es: `Comunidades escolares`,
      "zh-CN": `学校社区`,
      vi: `Cộng đồng trường học`,
      ru: `Школьные сообщества`,
      sq: `Komunitetet Shkollore`,
      km: `សហគមន៍សាលា`,
      fr: `Communautés scolaires`,
      ar: `مجتمعات المدرسة`,
      en: `School Communities`,
    },
    body16: {
      es: `Las comunidades escolares incluyen liderazgo escolar, maestros, personal, estudiantes y familias. En este proceso, las comunidades escolares son los expertos en las necesidades y prioridades de sus comunidades. A través del proceso de participación, brindarán información vital sobre posibles opciones para abordar los problemas de las instalaciones en el Distrito y sobre el futuro de la planificación de las instalaciones en general.`,
      "zh-CN": `学校社区包括学校领导、教师、员工、学生和家庭。 在这个过程中，学校社区是他们社区需求和优先事项的专家。 通过参与过程，他们将为解决该地区设施问题的潜在选择以及更广泛的设施规划的未来提供重要意见。`,
      vi: `Cộng đồng trường học bao gồm ban lãnh đạo nhà trường, giáo viên, nhân viên, học sinh và gia đình. Trong quá trình này, cộng đồng trường học là chuyên gia về các nhu cầu và ưu tiên của cộng đồng. Thông qua quá trình tham gia, họ sẽ cung cấp đầu vào quan trọng về các lựa chọn tiềm năng để giải quyết các vấn đề về cơ sở vật chất trong Học khu và về tương lai của việc lập kế hoạch cơ sở vật chất một cách rộng rãi hơn.`,
      ru: `Школьные сообщества включают школьное руководство, учителей, сотрудников, учащихся и семьи. В этом процессе школьные сообщества являются экспертами по потребностям и приоритетам своих сообществ. В рамках процесса взаимодействия они будут вносить жизненно важный вклад в возможные варианты решения проблем с объектами в округе и в более широком плане будущего планирования объектов.`,
      sq: `Komunitetet shkollore përfshijnë udhëheqjen e shkollës, mësuesit, stafin, studentët dhe familjet. Në këtë proces, komunitetet e shkollave janë ekspertët e nevojave dhe prioriteteve të komuniteteve të tyre. Nëpërmjet procesit të angazhimit, ata do të ofrojnë të dhëna jetike mbi opsionet e mundshme për të adresuar çështjet e objekteve në Distrikt dhe për të ardhmen e planifikimit të objekteve më gjerësisht.`,
      km: `សហគមន៍សាលារៀនរួមមាន ថ្នាក់ដឹកនាំសាលា គ្រូបង្រៀន បុគ្គលិក សិស្ស និងក្រុមគ្រួសារ។ នៅក្នុងដំណើរការនេះ សហគមន៍សាលារៀនគឺជាអ្នកជំនាញលើតម្រូវការ និងអាទិភាពសហគមន៍របស់ពួកគេ។ តាមរយៈដំណើរការចូលរួម ពួកគេនឹងផ្តល់នូវធាតុចូលសំខាន់ៗលើជម្រើសសក្តានុពល ដើម្បីដោះស្រាយបញ្ហាគ្រឿងបរិក្ខារនានានៅក្នុងស្រុក និងអនាគតនៃការរៀបចំផែនការសម្ភារៈបរិក្ខារកាន់តែទូលំទូលាយ។`,
      fr: `Les communautés scolaires comprennent la direction de l'école, les enseignants, le personnel, les élèves et les familles. Dans ce processus, les communautés scolaires sont les experts des besoins et des priorités de leur communauté. Grâce au processus d'engagement, ils fourniront des informations essentielles sur les options potentielles pour résoudre les problèmes d'installations dans le district et sur l'avenir de la planification des installations plus largement.`,
      ar: `تشمل المجتمعات المدرسية قيادة المدرسة والمعلمين والموظفين والطلاب والأسر. في هذه العملية ، تكون المجتمعات المدرسية هي الخبراء في احتياجات وأولويات مجتمعاتهم. من خلال عملية المشاركة ، سوف يقدمون مدخلات حيوية حول الخيارات المحتملة لمعالجة قضايا المرافق في المنطقة ومستقبل تخطيط المرافق على نطاق أوسع.`,
      en: `School communities include school leadership, teachers, staff, students, and families. In this process, school communities are the experts on their communities needs and priorities. Through the engagement process, they will provide vital input on potential options to address facilities issues in the District and on the future of facilities planning more broadly.`,
    },
    subsubtitle10: {
      es: `El Personal`,
      "zh-CN": `职员`,
      vi: `Nhân viên`,
      ru: `Персонал`,
      sq: `Shtab`,
      km: `បុគ្គលិក`,
      fr: `Le Personnel`,
      ar: `العاملين`,
      en: `Staff`,
    },
    body17: {
      es: `El personal escolar es vital para el mantenimiento y cuidado diario de las instalaciones escolares. El equipo del distrito comunicará al personal cualquier actualización relevante durante el proceso de planificación de instalaciones y alentará la participación del personal tanto en la participación de todo el distrito como en las reuniones escolares.`,
      "zh-CN": `学校工作人员对于学校设施的日常维护和护理至关重要。 学区团队将在设施规划过程中与员工沟通任何相关更新，并鼓励员工参与学区范围的参与和基于学校的会议。`,
      vi: `Nhân viên nhà trường đóng vai trò quan trọng trong việc duy trì và chăm sóc hàng ngày trong các cơ sở của trường. Nhóm nghiên cứu của Học khu sẽ trao đổi với nhân viên bất kỳ thông tin cập nhật nào có liên quan trong Quy trình Lập kế hoạch Cơ sở vật chất và khuyến khích nhân viên tham gia vào cả các cuộc họp toàn Học khu và các cuộc họp ở trường.`,
      ru: `Школьный персонал играет жизненно важную роль в повседневном обслуживании и уходе за школьными помещениями. Команда округа будет сообщать персоналу обо всех соответствующих обновлениях в процессе планирования объектов и поощрять участие сотрудников как во встречах в масштабах округа, так и во встречах в школах.`,
      sq: `Stafi i shkollës është jetik për mirëmbajtjen dhe kujdesin e përditshëm të objekteve shkollore. Ekipi i distriktit do të komunikojë me stafin çdo përditësim përkatës gjatë Procesit të Planifikimit të Objekteve dhe do të inkurajojë pjesëmarrjen e stafit në takimet e përfshirjes në mbarë Distriktin dhe në mbledhjet e shkollës.`,
      km: `បុគ្គលិកសាលាមានសារៈសំខាន់ចំពោះការថែទាំ និងការថែទាំប្រចាំថ្ងៃដែលផ្តល់ឲ្យនៅក្នុងបរិក្ខារសាលា។ ក្រុមការងារស្រុកនឹងទាក់ទងជាមួយបុគ្គលិកនូវព័ត៌មានថ្មីៗដែលពាក់ព័ន្ធក្នុងអំឡុងពេលដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ និងលើកទឹកចិត្តឱ្យមានការចូលរួមរបស់បុគ្គលិកក្នុងការចូលរួមទូទាំងស្រុក និងការប្រជុំនៅសាលា។`,
      fr: `Le personnel de l'école est essentiel à l'entretien quotidien et aux soins prodigués dans les installations scolaires. L'équipe du district communiquera avec le personnel toute mise à jour pertinente au cours du processus de planification des installations et encouragera la participation du personnel à la fois à l'engagement à l'échelle du district et aux réunions en milieu scolaire.`,
      ar: `يعتبر موظفو المدرسة أمرًا حيويًا في الصيانة اليومية والرعاية المقدمة في المرافق المدرسية. سيقوم فريق المنطقة بالتواصل مع الموظفين بشأن أي تحديثات ذات صلة أثناء عملية تخطيط المرافق وتشجيع مشاركة الموظفين في كل من المشاركة على مستوى المنطقة والاجتماعات على مستوى المدرسة.`,
      en: `School staff are vital to the everyday maintenance of and care provided in school facilities. The District team will communicate with staff any relevant updates during the Facilities Planning Process and encourage staff participation in both District-wide engagement and school-based meetings.`,
    },
    // subsubtitle11: {
    //   es: `Agencias de la ciudad`,
    //   "zh-CN": `城市机构`,
    //   vi: `Các cơ quan TP.`,
    //   ru: `Городские агентства`,
    //   sq: `Agjencitë e qytetit`,
    //   km: `ទីភ្នាក់ងារទីក្រុង`,
    //   fr: `Agences municipales`,
    //   ar: `وكالات المدينة`,
    //   en: `City Agencies`,
    // },
    // body18: {
    //   es: `El Distrito llevará a cabo Sesiones de Información de Agencias de la Ciudad para proporcionar un espacio para compartir información de FPP y alinear y coordinar recursos y servicios entre instituciones, según sea necesario, para lograr este trabajo. Usando su experiencia en el panorama más amplio de Filadelfia, las agencias de la ciudad también compartirán información sobre los cambios que ocurren en y alrededor de las comunidades afectadas.`,
    //   "zh-CN": `学区将举行城市机构信息会议，以提供一个共享 FPP 信息的空间，并根据需要调整和协调各机构的资源和服务，以完成这项工作。 利用他们在更广泛的费城景观方面的专业知识，市政府机构还将分享有关受影响社区内部和周围发生的变化的见解。`,
    //   vi: `Học khu sẽ tổ chức các Phiên Thông tin Cơ quan Thành phố để cung cấp không gian chia sẻ thông tin FPP và sắp xếp và điều phối các nguồn lực và dịch vụ giữa các cơ quan, nếu cần, để hoàn thành công việc này. Sử dụng kiến thức chuyên môn của họ về bối cảnh rộng lớn hơn của Philadelphia, các cơ quan của Thành phố cũng sẽ chia sẻ những hiểu biết sâu sắc về những thay đổi đang diễn ra trong và xung quanh các cộng đồng bị ảnh hưởng.`,
    //   ru: `Округ будет проводить информационные сессии городских агентств, чтобы обеспечить пространство для обмена информацией о FPP, а также согласования и координации ресурсов и услуг между учреждениями, если это необходимо для выполнения этой работы. Используя свой опыт в более широком ландшафте Филадельфии, городские агентства также поделятся информацией об изменениях, происходящих в пострадавших сообществах и вокруг них.`,
    //   sq: `Distrikti do të mbajë Sesione Informative të Agjencisë së Qytetit për të ofruar një hapësirë për të ndarë informacionin e FPP-së dhe për të lidhur dhe koordinuar burimet dhe shërbimet në të gjithë institucionet, sipas nevojës, për të përmbushur këtë punë. Duke përdorur ekspertizën e tyre në peizazhin më të gjerë të Filadelfias, agjencitë e qytetit do të ndajnë gjithashtu njohuri mbi ndryshimet që ndodhin në dhe rreth komuniteteve të prekura.`,
    //   km: `ស្រុកនឹងរៀបចំវគ្គព័ត៌មានរបស់ទីភ្នាក់ងារទីក្រុង ដើម្បីផ្តល់កន្លែងមួយដើម្បីចែករំលែកព័ត៌មាន FPP និងតម្រឹម និងសម្របសម្រួលធនធាន និងសេវាកម្មនៅទូទាំងស្ថាប័ន តាមតម្រូវការ ដើម្បីសម្រេចការងារនេះ។ ដោយប្រើជំនាញរបស់ពួកគេលើទេសភាពទីក្រុង Philadelphia កាន់តែទូលំទូលាយ ភ្នាក់ងារទីក្រុងក៏នឹងចែករំលែកការយល់ដឹងអំពីការផ្លាស់ប្តូរដែលកំពុងកើតឡើងនៅក្នុង និងជុំវិញសហគមន៍ដែលរងផលប៉ះពាល់ផងដែរ។`,
    //   fr: `Le district organisera des séances d'information de l'agence municipale pour fournir un espace pour partager les informations du FPP et aligner et coordonner les ressources et les services entre les institutions, selon les besoins, pour accomplir ce travail. En utilisant leur expertise sur le paysage plus large de Philadelphie, les agences de la ville partageront également des informations sur les changements qui se produisent dans et autour des communautés touchées.`,
    //   ar: `ستعقد المنطقة جلسات معلومات وكالة المدينة لتوفير مساحة لمشاركة معلومات FPP ومواءمة وتنسيق الموارد والخدمات عبر المؤسسات ، حسب الحاجة ، لإنجاز هذا العمل. باستخدام خبراتهم في المشهد الأوسع لفيلادلفيا ، ستشارك وكالات المدينة أيضًا الأفكار حول التغييرات التي تحدث في المجتمعات المتأثرة وحولها.`,
    //   en: `The District will hold City Agency Information Sessions to provide a space to share FPP information and align and coordinate resources and services across institutions, as needed, to accomplish this work. Using their expertise on the broader Philadelphia landscape, City agencies will also share insights on changes happening in and around impacted communities.`,
    // },
    // subsubtitle12: {
    //   es: `Organizaciones comunitarias`,
    //   "zh-CN": `社区组织`,
    //   vi: `Tổ chức cộng đồng`,
    //   ru: `Общественные организации`,
    //   sq: `Organizatat Komunitare`,
    //   km: `អង្គការសហគមន៍`,
    //   fr: `Organismes communautaires`,
    //   ar: `منظمات المجتمع`,
    //   en: `Community Organizations`,
    // },
    // body19: {
    //   es: `De manera similar, el Distrito también llevará a cabo sesiones informativas separadas para organizaciones comunitarias que tengan una gran experiencia en torno a las necesidades, los deseos y los cambios de la comunidad. Estas organizaciones brindarán un apoyo crucial para impulsar la participación comunitaria en este proceso. Estas sesiones también tendrán como objetivo identificar oportunidades de colaboración y alineación.`,
    //   "zh-CN": `同样，学区还将为社区组织举办单独的信息会议，这些组织在社区需求、愿望和变化方面拥有深厚的专业知识。 这些组织将为推动社区参与这一过程提供重要支持。 这些会议还将旨在确定合作和协调的机会。`,
    //   vi: `Tương tự, Học khu cũng sẽ tổ chức các buổi cung cấp thông tin riêng cho các tổ chức cộng đồng có chuyên môn sâu về nhu cầu, mong muốn và thay đổi của cộng đồng. Các tổ chức này sẽ cung cấp hỗ trợ quan trọng để thúc đẩy sự tham gia của cộng đồng vào quá trình này. Các phiên họp này cũng sẽ nhằm xác định các cơ hội hợp tác và liên kết.`,
    //   ru: `Точно так же округ также будет проводить отдельные информационные сессии для общественных организаций, обладающих глубокими знаниями о потребностях, желаниях и изменениях сообщества. Эти организации окажут важнейшую поддержку для вовлечения сообщества в этот процесс. Эти сессии также будут направлены на выявление возможностей для сотрудничества и согласования.`,
    //   sq: `Në mënyrë të ngjashme, Distrikti do të mbajë gjithashtu sesione të veçanta informacioni për organizatat e komunitetit që kanë ekspertizë të thellë rreth nevojave, dëshirave dhe ndryshimeve të komunitetit. Këto organizata do të ofrojnë mbështetje thelbësore për nxitjen e pjesëmarrjes së komunitetit në këtë proces. Këto sesione do të synojnë gjithashtu të identifikojnë mundësitë për bashkëpunim dhe përafrim.`,
    //   km: `ដូចគ្នានេះដែរ ស្រុកនឹងរៀបចំវគ្គព័ត៌មានដាច់ដោយឡែកសម្រាប់អង្គការសហគមន៍ដែលមានជំនាញជ្រៅជ្រះជុំវិញតម្រូវការ បំណងប្រាថ្នា និងការផ្លាស់ប្តូរសហគមន៍។ អង្គការទាំងនេះនឹងផ្តល់ការគាំទ្រយ៉ាងសំខាន់សម្រាប់ការជំរុញការចូលរួមរបស់សហគមន៍នៅក្នុងដំណើរការនេះ។ វគ្គទាំងនេះក៏នឹងមានបំណងកំណត់ឱកាសសម្រាប់កិច្ចសហការ និងការតម្រឹមផងដែរ។`,
    //   fr: `De même, le district organisera également des séances d'information distinctes pour les organisations communautaires qui détiennent une expertise approfondie des besoins, des désirs et des changements de la communauté. Ces organisations fourniront un soutien crucial pour stimuler la participation communautaire à ce processus. Ces sessions viseront également à identifier les opportunités de collaboration et d'alignement.`,
    //   ar: `وبالمثل ، ستعقد المنطقة أيضًا جلسات إعلامية منفصلة لمنظمات المجتمع التي لديها خبرة عميقة حول احتياجات المجتمع ورغباته وتغيراته. ستوفر هذه المنظمات دعمًا حاسمًا لدفع مشاركة المجتمع في هذه العملية. ستهدف هذه الجلسات أيضًا إلى تحديد فرص التعاون والمواءمة.`,
    //   en: `Similarly, the District will also hold separate Information Sessions for community organizations that hold deep expertise around community needs, desires, and changes. These organizations will provide crucial support for driving community participation in this process. These sessions will also aim to identify opportunities for collaboration and alignment.`,
    // },
    // subsubtitle13: {
    //   es: `Sindicatos`,
    //   "zh-CN": `工会`,
    //   vi: `Đoàn thể`,
    //   ru: `Союзы`,
    //   sq: `Sindikatat`,
    //   km: `សហជីព`,
    //   fr: `Les syndicats`,
    //   ar: `النقابات`,
    //   en: `Unions`,
    // },
    // body20: {
    //   es: `Los sindicatos son una plataforma colectiva importante con la cual el Distrito puede comunicar planes. El Distrito Escolar de Filadelfia mantendrá actualizados a los sindicatos sobre los cambios en curso en el Proceso de Planificación de Instalaciones y alentará la participación en todos los compromisos relevantes de FPP.`,
    //   "zh-CN": `工会是学区可以沟通计划的重要集体平台。 费城学区将让工会了解设施规划流程的持续变化，并将鼓励参与所有相关的 FPP 参与。`,
    //   vi: `Các đoàn thể là một nền tảng tập thể quan trọng mà Học khu có thể truyền đạt các kế hoạch. Học khu Philadelphia sẽ cập nhật cho các công đoàn về những thay đổi liên tục trong Quy trình Lập kế hoạch Cơ sở vật chất và sẽ khuyến khích sự tham gia vào tất cả các hoạt động tham gia FPP có liên quan.`,
    //   ru: `Профсоюзы являются важной коллективной платформой, с помощью которой округ может обмениваться планами. Школьный округ Филадельфии будет информировать профсоюзы о текущих изменениях в процессе планирования объектов и поощрять участие во всех соответствующих мероприятиях FPP.`,
    //   sq: `Sindikatat janë një platformë e rëndësishme kolektive me të cilën Qarku mund të komunikojë planet. Distrikti shkollor i Filadelfias do t'i mbajë sindikatat të përditësuara për ndryshimet e vazhdueshme në Procesin e Planifikimit të Objekteve dhe do të inkurajojë pjesëmarrjen në të gjithë angazhimin përkatës të FPP-së.`,
    //   km: `សហជីពគឺជាវេទិកាសមូហភាពដ៏សំខាន់មួយដែលស្រុកអាចទំនាក់ទំនងផែនការ។ School District of Philadelphia នឹងបន្តធ្វើបច្ចុប្បន្នភាពសហជីពអំពីការផ្លាស់ប្តូរជាបន្តបន្ទាប់នៅក្នុងដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ ហើយនឹងលើកទឹកចិត្តឱ្យមានការចូលរួមនៅក្នុងការចូលរួមរបស់ FPP ដែលពាក់ព័ន្ធទាំងអស់។`,
    //   fr: `Les syndicats sont une plate-forme collective importante avec laquelle le district peut communiquer des plans. Le district scolaire de Philadelphie tiendra les syndicats informés des changements en cours dans le processus de planification des installations et encouragera la participation à tous les engagements FPP pertinents.`,
    //   ar: `النقابات هي منصة جماعية مهمة يمكن للمنطقة أن توصل الخطط من خلالها. ستبقي منطقة فيلادلفيا التعليمية النقابات على اطلاع دائم بالتغييرات الجارية في عملية تخطيط المرافق وستشجع المشاركة في جميع مشاركة FPP ذات الصلة.`,
    //   en: `Unions are an important collective platform with which the District can communicate plans. The School District of Philadelphia will keep unions updated on ongoing changes in the Facilities Planning Process and will encourage participation in all relevant FPP engagement.`,
    // },
    // subsubtitle14: {
    //   es: `Organizaciones basadas en la fe`,
    //   "zh-CN": `基于信仰的组织`,
    //   vi: `Tổ chức dựa trên niềm tin`,
    //   ru: `Религиозные организации`,
    //   sq: `Organizatat e bazuara në besim`,
    //   km: `អង្គការផ្អែកលើជំនឿ`,
    //   fr: `Organisations confessionnelles`,
    //   ar: `المنظمات الدينية`,
    //   en: `Faith-based Organizations`,
    // },
    // body21: {
    //   es: `Las organizaciones religiosas son anclas comunitarias importantes en Filadelfia. El Distrito compartirá información sobre el Proceso de Planificación de Instalaciones con representantes de organizaciones religiosas a través de Sesiones de Información. En estas sesiones, el Distrito y las organizaciones religiosas también trabajarán para identificar cómo las relaciones de las organizaciones y el conocimiento de la comunidad pueden ayudar a impulsar la participación de la comunidad en el FPP.`,
    //   "zh-CN": `信仰组织是费城重要的社区支柱。 学区将通过信息会议与信仰组织的代表分享有关设施规划流程的信息。 在这些会议上，学区和信仰组织还将努力确定组织的关系和社区知识如何帮助推动社区参与 FPP。`,
    //   vi: `Các tổ chức đức tin là những điểm neo cộng đồng quan trọng ở Philadelphia. Học khu sẽ chia sẻ thông tin về Quy trình Lập kế hoạch Cơ sở vật chất với đại diện từ các tổ chức tín ngưỡng thông qua các Phiên Thông tin. Tại các buổi này, Học khu và các tổ chức tín ngưỡng cũng sẽ làm việc để xác định mối quan hệ của các tổ chức và kiến thức cộng đồng có thể giúp thúc đẩy sự tham gia của cộng đồng vào FPP như thế nào.`,
    //   ru: `Религиозные организации являются важными общественными якорями в Филадельфии. Округ будет делиться информацией о процессе планирования объектов с представителями религиозных организаций посредством информационных сессий. На этих сессиях округ и религиозные организации также будут работать над определением того, как отношения организаций и знания сообщества могут способствовать участию сообщества в FPP.`,
    //   sq: `Organizatat e besimit janë spiranca të rëndësishme të komunitetit në Filadelfia. Distrikti do të ndajë informacion mbi Procesin e Planifikimit të Objekteve me përfaqësuesit e organizatave fetare përmes sesioneve informative. Në këto sesione, organizatat e distriktit dhe besimit do të punojnë gjithashtu për të identifikuar se si marrëdhëniet e organizatave dhe njohuritë e komunitetit mund të ndihmojnë në nxitjen e pjesëmarrjes së komunitetit në FPP.`,
    //   km: `អង្គការជំនឿគឺជាយុថ្កាសហគមន៍ដ៏សំខាន់នៅក្នុងទីក្រុង Philadelphia ។ ស្រុកនឹងចែករំលែកព័ត៌មានអំពីដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារជាមួយអ្នកតំណាងមកពីអង្គការជំនឿតាមរយៈវគ្គព័ត៌មាន។ នៅក្នុងវគ្គទាំងនេះ អង្គការស្រុក និងជំនឿក៏នឹងធ្វើការដើម្បីកំណត់ពីរបៀបដែលទំនាក់ទំនងរបស់អង្គការ និងចំណេះដឹងសហគមន៍អាចជួយជំរុញឱ្យមានការចូលរួមរបស់សហគមន៍នៅក្នុង FPP ។`,
    //   fr: `Les organisations confessionnelles sont des points d'ancrage communautaires importants à Philadelphie. Le district partagera des informations sur le processus de planification des installations avec des représentants d'organisations religieuses par le biais de séances d'information. Lors de ces sessions, le district et les organisations confessionnelles travailleront également pour identifier comment les relations des organisations et les connaissances communautaires peuvent aider à stimuler la participation communautaire au FPP.`,
    //   ar: `المنظمات الدينية هي مراسي مجتمعية مهمة في فيلادلفيا. ستشارك المنطقة المعلومات حول عملية تخطيط المرافق مع ممثلين من المنظمات الدينية من خلال جلسات المعلومات. في هذه الجلسات ، ستعمل المنظمات الإقليمية والدينية أيضًا على تحديد كيف يمكن أن تساعد علاقات المنظمات ومعرفة المجتمع في دفع مشاركة المجتمع في FPP.`,
    //   en: `Faith organizations are important community anchors in Philadelphia. The District will share information on the Facilities Planning Process with representatives from faith organizations through Information Sessions. At these sessions, the District and faith organizations will also work to identify how organizations’ relationships and community knowledge can help drive community participation in the FPP.`,
    // },
    timeline_title1: {
      es: `1. Presentar`,
      "zh-CN": `一、介绍`,
      vi: `1. Giới thiệu`,
      ru: `1. Представьте`,
      sq: `1. Prezantoni`,
      km: `1. ណែនាំ`,
      fr: `1. Présentez`,
      ar: `1. أعرض`,
      en: `1. Introduce`,
    },
    timeline_title2: {
      es: `2. Participar`,
      "zh-CN": `2. 参与`,
      vi: `2. Tham gia`,
      ru: `2. Вовлекайте`,
      sq: `2. Angazhohu`,
      km: `2. ចូលរួម`,
      fr: `2. Engagez-vous`,
      ar: `2. الانخراط`,
      en: `2. Engage`,
    },
    timeline_title3: {
      es: `3. Revisar`,
      "zh-CN": `3. 修改`,
      vi: `3. Sửa lại`,
      ru: `3. Пересмотреть`,
      sq: `3. Rishikoni`,
      km: `3. ពិនិត្យឡើងវិញ`,
      fr: `3. Réviser`,
      ar: `3. مراجعة`,
      en: `3. Revise`,
    },
    timeline_title4: {
      es: `4. Planificar`,
      "zh-CN": `4. 计划`,
      vi: `4. Kế hoạch`,
      ru: `4. План`,
      sq: `4. Planifikoni`,
      km: `4. ផែនការ`,
      fr: `4. Planifier`,
      ar: `4. خطة`,
      en: `4. Plan`,
    },
    timeline1_point1: {
      es: `Lanzamiento del sitio web del proyecto, la herramienta web y la guía del proceso de planificación`,
      "zh-CN": `项目网站、网络工具和规划流程指南发布`,
      vi: `Khởi chạy trang web dự án, webtool và hướng dẫn quy trình lập kế hoạch`,
      ru: `Запуск веб-сайта проекта, веб-инструмента и руководства по процессу планирования`,
      sq: `Hapja e uebsajtit të projektit, veglave të internetit dhe udhëzuesit të procesit të planifikimit`,
      km: `គេហទំព័រគម្រោង webtool និងការណែនាំអំពីដំណើរការរៀបចំផែនការ`,
      fr: `Lancement du site Web du projet, de l'outil Web et du guide du processus de planification`,
      ar: `إطلاق موقع الويب الخاص بالمشروع ، وأداة الويب ، ودليل عملية التخطيط`,
      en: `Project website, webtool, and planning process guide launch`,
    },
    timeline1_point2: {
      es: `Conversaciones comunitarias y sesiones informativas en todo el distrito`,
      "zh-CN": `地区范围的社区对话和信息会议`,
      vi: `Các buổi trò chuyện và thông tin cộng đồng trên toàn khu vực`,
      ru: `Общественные беседы и информационные сессии в масштабах округа`,
      sq: `Biseda dhe sesione informacioni në komunitet në mbarë rrethin`,
      km: `វគ្គសន្ទនា និងព័ត៌មានសហគមន៍ទូទាំងស្រុក`,
      fr: `Conversations communautaires et séances d'information à l'échelle du district`,
      ar: `محادثات المجتمع على مستوى المنطقة وجلسات المعلومات`,
      en: `District-wide Community Conversations and Information Sessions`,
    },
    timeline1_point3: {
      es: `Compartir los hallazgos de la comunidad`,
      "zh-CN": `分享社区发现`,
      vi: `Chia sẻ những phát hiện của cộng đồng`,
      ru: `Делитесь выводами сообщества`,
      sq: `Ndani gjetjet e komunitetit`,
      km: `ចែករំលែកការរកឃើញរបស់សហគមន៍`,
      fr: `Partager les découvertes de la communauté`,
      ar: `مشاركة نتائج المجتمع`,
      en: `Share out community findings`,
    },
    timeline2_point1: {
      es: `Reuniones escolares y sesiones informativas`,
      "zh-CN": `校本会议和信息交流会`,
      vi: `Các cuộc họp tại trường và các buổi Thông tin`,
      ru: `Школьные встречи и информационные сессии`,
      sq: `Takime në shkollë dhe sesione informative`,
      km: `ការប្រជុំនៅសាលា និងវគ្គព័ត៌មាន`,
      fr: `Réunions scolaires et séances d'information`,
      ar: `اجتماعات مدرسية وجلسات إعلامية`,
      en: `School-based meetings and Information Sessions`,
    },
    timeline2_point2: {
      es: `Introducir opciones potenciales para abordar los desafíos de las instalaciones`,
      "zh-CN": `介绍解决设施挑战的潜在选择`,
      vi: `Giới thiệu các phương án tiềm năng để giải quyết các thách thức về cơ sở vật chất`,
      ru: `Представить потенциальные варианты решения проблем с объектами`,
      sq: `Prezantoni opsionet e mundshme për adresimin e sfidave të objekteve`,
      km: `ណែនាំជម្រើសសក្តានុពលសម្រាប់ដោះស្រាយបញ្ហាប្រឈមផ្នែកបរិក្ខារ`,
      fr: `Présenter des options potentielles pour relever les défis des installations`,
      ar: `تقديم خيارات محتملة لمواجهة تحديات المرافق`,
      en: `Introduce potential options for addressing facilities challenges`,
    },
    timeline2_point3: {
      es: `Recopile comentarios sobre posibles opciones y sugerencias adicionales`,
      "zh-CN": `收集有关潜在选项和其他建议的反馈`,
      vi: `Thu thập phản hồi về các tùy chọn tiềm năng và đề xuất bổ sung`,
      ru: `Соберите отзывы о возможных вариантах и дополнительные предложения`,
      sq: `Mblidhni komente për opsionet e mundshme dhe sugjerime shtesë`,
      km: `ប្រមូលមតិកែលម្អលើជម្រើសសក្តានុពល និងការណែនាំបន្ថែម`,
      fr: `Recueillir des commentaires sur les options potentielles et des suggestions supplémentaires`,
      ar: `جمع الملاحظات حول الخيارات المحتملة والاقتراحات الإضافية`,
      en: `Collect feedback on potential options and additional suggestions`,
    },
    timeline3_point1: {
      es: `Revisar y resumir los comentarios de la comunidad`,
      "zh-CN": `审查和总结社区反馈`,
      vi: `Xem xét và tổng hợp phản hồi của cộng đồng`,
      ru: `Просмотрите и обобщите отзывы сообщества`,
      sq: `Rishikoni dhe përmblidhni reagimet e komunitetit`,
      km: `ពិនិត្យ និងសង្ខេបមតិរបស់សហគមន៍`,
      fr: `Examiner et résumer les commentaires de la communauté`,
      ar: `مراجعة وتلخيص آراء المجتمع`,
      en: `Review and summarize community feedback`,
    },
    timeline3_point2: {
      es: `Compartir los hallazgos de la comunidad`,
      "zh-CN": `分享社区发现`,
      vi: `Chia sẻ những phát hiện của cộng đồng`,
      ru: `Делитесь выводами сообщества`,
      sq: `Ndani gjetjet e komunitetit`,
      km: `ចែករំលែកការរកឃើញរបស់សហគមន៍`,
      fr: `Partager les découvertes de la communauté`,
      ar: `مشاركة نتائج المجتمع`,
      en: `Share out community findings`,
    },
    timeline4_point1: {
      es: `Conversaciones comunitarias y sesiones informativas en todo el distrito`,
      "zh-CN": `地区范围的社区对话和信息会议`,
      vi: `Các buổi trò chuyện và thông tin cộng đồng trên toàn khu vực`,
      ru: `Общественные беседы и информационные сессии в масштабах округа`,
      sq: `Biseda dhe sesione informacioni në komunitet në mbarë rrethin`,
      km: `វគ្គសន្ទនា និងព័ត៌មានសហគមន៍ទូទាំងស្រុក`,
      fr: `Conversations communautaires et séances d'information à l'échelle du district`,
      ar: `محادثات المجتمع على مستوى المنطقة وجلسات المعلومات`,
      en: `District-wide Community Conversations and Information Sessions`,
    },
    timeline4_point2: {
      es: `Desarrollar el Plan Maestro de Instalaciones y presentarlo a la Junta de Educación`,
      "zh-CN": `制定设施总体规划并提交给教育委员会`,
      vi: `Xây dựng Kế hoạch Tổng thể Cơ sở vật chất và trình bày với Hội đồng Giáo dục`,
      ru: `Разработать генеральный план объектов и представить в Совет по образованию`,
      sq: `Zhvillimi i Masterplanit të Objekteve dhe paraqitja në Bordin e Arsimit`,
      km: `រៀបចំផែនការមេ និងបង្ហាញជូនក្រុមប្រឹក្សាភិបាល`,
      fr: `Élaborer le plan directeur des installations et le présenter au conseil scolaire`,
      ar: `تطوير المخطط الرئيسي للمنشآت وعرضه على مجلس التعليم`,
      en: `Develop Facilities Master Plan and present to Board of Education`,
    },
  },
  need_to_know: {
    title1: {
      es: `Lo que necesitará saber`,
      "zh-CN": `你需要知道什么`,
      vi: `Những gì bạn cần biết`,
      ru: `Что вам нужно знать`,
      sq: `Çfarë ju duhet të dini`,
      km: `អ្វីដែលអ្នកនឹងត្រូវដឹង`,
      fr: `Ce que vous devez savoir`,
      ar: `ما عليك معرفته`,
      en: `What You'll Need to Know`,
    },
    subtitle1: {
      es: `Medición de la calidad de una instalación escolar`,
      "zh-CN": `衡量学校设施的质量`,
      vi: `Đo lường Chất lượng của Cơ sở Trường học`,
      ru: `Измерение качества школьного учреждения`,
      sq: `Matja e cilësisë së një objekti shkollor`,
      km: `ការវាស់វែងគុណភាពនៃសម្ភារៈសិក្សា`,
      fr: `Mesurer la qualité d'une installation scolaire`,
      ar: `قياس جودة منشأة مدرسية`,
      en: `Measuring the Quality of a School Facility`,
    },
    body1: {
      es: `El Distrito trabajó con un equipo de profesionales de la industria en Parsons Environmental and Infrastructure Group y FLO Analytics para comprender la condición física de las instalaciones escolares, qué tan bien un edificio escolar apoya los programas de instrucción en cada escuela y cómo los cambios en la inscripción de estudiantes a lo largo del tiempo afectan a las escuelas. y el Distrito en su conjunto. En esta sección, aprenderá acerca de cuatro medidas importantes que utiliza el Distrito Escolar de Filadelfia para comprender la calidad general de las instalaciones escolares.`,
      "zh-CN": `学区与 Parsons 环境与基础设施集团和 FLO Analytics 的行业专业人士团队合作，了解学校设施的物理状况、学校建筑对每所学校教学计划的支持程度以及学生入学率随时间的变化如何影响学校 和整个学区。 在本节中，您将了解费城学区用于了解学校设施整体质量的四项重要衡量标准。`,
      vi: `Học khu đã làm việc với một nhóm các chuyên gia trong ngành tại Parsons Môi trường và Cơ sở hạ tầng Group và FLO Analytics để hiểu điều kiện vật chất của cơ sở vật chất trường học, việc xây dựng trường học hỗ trợ các chương trình giảng dạy tại mỗi trường tốt như thế nào và những thay đổi trong việc ghi danh học sinh theo thời gian ảnh hưởng đến trường học như thế nào và toàn thể Quận. Trong phần này, bạn sẽ tìm hiểu về bốn thước đo quan trọng mà Học Khu Philadelphia sử dụng để hiểu chất lượng tổng thể của cơ sở vật chất trường học.`,
      ru: `Округ работал с командой отраслевых специалистов из Parsons Environmental and Infrastructure Group и FLO Analytics, чтобы понять физическое состояние школьных помещений, насколько хорошо школьное здание поддерживает учебные программы в каждой школе и как изменения в наборе учащихся с течением времени влияют на школы. и округа в целом. В этом разделе вы узнаете о четырех важных показателях, которые школьный округ Филадельфии использует для оценки общего качества школьных помещений.`,
      sq: `Distrikti punoi me një ekip profesionistësh të industrisë në Parsons Environmental and Infrastructure Group dhe FLO Analytics për të kuptuar gjendjen fizike të objekteve shkollore, sa mirë një ndërtesë shkolle mbështet programet mësimore në secilën shkollë dhe se si ndryshimet në regjistrimin e studentëve me kalimin e kohës ndikojnë në shkolla. dhe rrethit në tërësi. Në këtë pjesë, do të mësoni rreth katër masave të rëndësishme që Distrikti Shkollor i Filadelfisë përdor për të kuptuar cilësinë e përgjithshme të objekteve shkollore.`,
      km: `ស្រុកបានធ្វើការជាមួយក្រុមអ្នកជំនាញផ្នែកឧស្សាហកម្មនៅ Parsons Environmental and Infrastructure Group និង FLO Analytics ដើម្បីស្វែងយល់ពីស្ថានភាពរូបវន្តនៃសម្ភារៈសិក្សា ថាតើអគារសិក្សាគាំទ្រកម្មវិធីអប់រំនៅសាលានីមួយៗបានកម្រិតណា និងរបៀបដែលការផ្លាស់ប្តូរការចុះឈ្មោះសិស្សតាមពេលវេលាប៉ះពាល់ដល់សាលារៀន។ និងស្រុកទាំងមូល។ នៅក្នុងផ្នែកនេះ អ្នកនឹងរៀនអំពីវិធានការសំខាន់ៗចំនួន 4 ដែលសាលាស្រុក Philadelphia ប្រើដើម្បីស្វែងយល់ពីគុណភាពទាំងមូលនៃសម្ភារៈសិក្សា។`,
      fr: `Le District a travaillé avec une équipe de professionnels de l'industrie du Parsons Environmental and Infrastructure Group et de FLO Analytics pour comprendre l'état physique des installations scolaires, dans quelle mesure un bâtiment scolaire soutient les programmes d'enseignement de chaque école et comment les changements dans l'inscription des élèves au fil du temps affectent les écoles. et le District dans son ensemble. Dans cette section, vous découvrirez quatre mesures importantes que le district scolaire de Philadelphie utilise pour comprendre la qualité globale des installations scolaires.`,
      ar: `عملت المنطقة مع فريق من المتخصصين في الصناعة في Parsons Environmental and Infrastructure Group و FLO Analytics لفهم الحالة المادية للمرافق المدرسية ، ومدى دعم مبنى المدرسة للبرامج التعليمية في كل مدرسة ، وكيف تؤثر التغييرات في تسجيل الطلاب بمرور الوقت على المدارس والمنطقة ككل. في هذا القسم ، ستتعرف على أربعة مقاييس مهمة تستخدمها منطقة فيلادلفيا التعليمية لفهم الجودة الشاملة للمرافق المدرسية.`,
      en: `The District worked with a team of industry professionals at Parsons Environmental and Infrastructure Group and FLO Analytics to understand the physical condition of school facilities, how well a school building supports the instructional programs at each school, and how changes in student enrollment over time affect schools and the District as a whole. In this section, you will learn about four important measures that the School District of Philadelphia uses to understand the overall quality of school facilities.`,
    },
    body2: {
      es: `Comprender estas cuatro medidas y su impacto en el personal y los estudiantes es fundamental para la planificación de las instalaciones. Igualmente importante son las experiencias y reflexiones de las comunidades escolares sobre esta información;`,
      "zh-CN": `了解这四项措施及其对教职员工和学生的影响对于设施规划至关重要。 同样重要的是学校社区的经验和对这些信息的反思；`,
      vi: `Việc hiểu rõ bốn biện pháp này và tác động của chúng đối với nhân viên và học sinh là rất quan trọng cho việc lập kế hoạch cơ sở vật chất. Điều quan trọng không kém là kinh nghiệm và phản ánh của cộng đồng trường học về thông tin này;`,
      ru: `Понимание этих четырех показателей и их влияния на сотрудников и студентов имеет решающее значение для планирования объектов. Не менее важен опыт и размышления школьных сообществ об этой информации;`,
      sq: `Kuptimi i këtyre katër masave dhe ndikimi i tyre te stafi dhe studentët është kritik për planifikimin e objekteve. Po aq e rëndësishme janë përvojat dhe reflektimet e komuniteteve shkollore mbi këtë informacion;`,
      km: `ការយល់ដឹងអំពីវិធានការទាំងបួននេះ និងឥទ្ធិពលរបស់វាទៅលើបុគ្គលិក និងសិស្ស គឺមានសារៈសំខាន់សម្រាប់ការរៀបចំផែនការសម្ភារៈបរិក្ខារ។ សារៈសំខាន់ដូចគ្នាគឺបទពិសោធន៍ និងការឆ្លុះបញ្ចាំងរបស់សហគមន៍សាលារៀនលើព័ត៌មាននេះ;`,
      fr: `Comprendre ces quatre mesures et leur impact sur le personnel et les étudiants est essentiel pour la planification des installations. Les expériences et les réflexions des communautés scolaires sur ces informations sont tout aussi importantes ;`,
      ar: `إن فهم هذه المقاييس الأربعة وتأثيرها على الموظفين والطلاب أمر بالغ الأهمية لتخطيط المرافق. لا تقل أهمية عن تجارب المجتمعات المدرسية وانعكاساتها على هذه المعلومات ؛`,
      en: `Understanding these four measures and their impact on staff and students is critical for facilities planning. Equally important is school communities’ experiences and reflections on this information;`,
    },
    // link1: {
    //   es: ``,
    //   "zh-CN": `校内会议`,
    //   vi: `các cuộc họp ở trường`,
    //   ru: `школьные встречи`,
    //   sq: `takime në shkollë`,
    //   km: `ការប្រជុំនៅសាលា`,
    //   fr: `réunions scolaires`,
    //   ar: `اجتماعات مدرسية`,
    //   en: `school-based meetings`,
    // },
    body3: {
      es: ` el FPP buscará elevar los comentarios de la comunidad para informar la planificación a largo plazo en el Plan Maestro de Instalaciones.`,
      "zh-CN": ` FPP 将寻求提升社区反馈，为设施总体规划中的长期规划提供信息。`,
      vi: ` FPP sẽ tìm cách nâng cao phản hồi của cộng đồng để cung cấp thông tin về kế hoạch dài hạn trong Kế hoạch Tổng thể về Cơ sở vật chất.`,
      ru: ` FPP будет стремиться повысить уровень обратной связи с сообществом для информирования долгосрочного планирования в Генеральном плане объектов.`,
      sq: ` FPP do të kërkojë të rrisë reagimet e komunitetit për të informuar planifikimin afatgjatë në Master Planin e Objekteve.`,
      km: ` FPP នឹងស្វែងរកការលើកកំពស់មតិកែលម្អសហគមន៍ ដើម្បីជូនដំណឹងអំពីការធ្វើផែនការរយៈពេលវែងនៅក្នុងផែនការមេរបស់ Facilities ។`,
      fr: ` le FPP cherchera à élever les commentaires de la communauté pour éclairer la planification à long terme dans le plan directeur des installations.`,
      ar: ` سيسعى FPP إلى رفع تعليقات المجتمع لإبلاغ التخطيط طويل الأجل في الخطة الرئيسية للمرافق.`,
      en: ` the FPP will seek to elevate community feedback to inform long-term planning in the Facilities Master Plan.`,
    },
    subtitle2: {
      es: `Cuatro puntajes importantes que debe conocer`,
      "zh-CN": `要知道的四个重要分数`,
      vi: `Bốn điểm quan trọng cần biết`,
      ru: `Четыре важных показателя, которые нужно знать`,
      sq: `Katër pikë të rëndësishme për t'u ditur`,
      km: `ពិន្ទុសំខាន់ៗចំនួនបួនដែលត្រូវដឹង`,
      fr: `Quatre scores importants à connaître`,
      ar: `أربع درجات مهمة يجب معرفتها`,
      en: `Four Important Scores to Know`,
    },
    body4: {
      es: `Hay cuatro medidas clave que utiliza el Distrito Escolar de Filadelfia para comprender la calidad de las instalaciones escolares en el Distrito. Más abajo en esta página, encontrará cuatro videos informativos que describen cada medida.`,
      "zh-CN": `费城学区使用四项关键措施来了解学区学校设施的质量。 在此页面的下方，您将找到四个描述每个措施的信息视频。`,
      vi: `Có bốn thước đo chính mà Học khu Philadelphia sử dụng để hiểu chất lượng của các cơ sở trường học trong Học khu. Tiếp tục xuống trang này, bạn sẽ tìm thấy bốn video thông tin mô tả từng biện pháp.`,
      ru: `Школьный округ Филадельфии использует четыре ключевых показателя для оценки качества школьных помещений в округе. Ниже на этой странице вы найдете четыре информационных видеоролика, описывающих каждую меру.`,
      sq: `Ka katër masa kryesore që përdor Distrikti Shkollor i Filadelfisë për të kuptuar cilësinë e objekteve shkollore në distrikt. Më tej në këtë faqe, do të gjeni katër video informative që përshkruajnë secilën masë.`,
      km: `មានវិធានការសំខាន់ៗចំនួនបួនដែលសាលាស្រុក Philadelphia ប្រើប្រាស់ដើម្បីស្វែងយល់ពីគុណភាពនៃសម្ភារៈសិក្សានៅក្នុងស្រុក។ នៅខាងក្រោមទំព័រនេះ អ្នកនឹងឃើញវីដេអូព័ត៌មានចំនួន 4 ដែលពិពណ៌នាអំពីវិធានការនីមួយៗ។`,
      fr: `Le district scolaire de Philadelphie utilise quatre mesures clés pour comprendre la qualité des installations scolaires du district. Plus bas sur cette page, vous trouverez quatre vidéos d'information décrivant chaque mesure.`,
      ar: `هناك أربعة مقاييس رئيسية تستخدمها منطقة فيلادلفيا التعليمية لفهم جودة مرافق المدرسة في المنطقة. في أسفل هذه الصفحة ، ستجد أربعة مقاطع فيديو إعلامية تصف كل مقياس.`,
      en: `There are four key measures the School District of Philadelphia uses to understand the quality of school facilities in the District. Further down this page, you will find four informational videos describing each measure.`,
    },
    title2: {
      es: `Evaluación del estado de las instalaciones`,
      "zh-CN": `设施状况评估`,
      vi: `Đánh giá tình trạng cơ sở vật chất`,
      ru: `Оценка состояния объектов`,
      sq: `Vlerësimi i gjendjes së objekteve`,
      km: `ការវាយតម្លៃស្ថានភាពគ្រឿងបរិក្ខារ`,
      fr: `Évaluation de l'état des installations`,
      ar: `تقييم حالة المرافق`,
      en: `Facilities Conditions Assessment`,
    },
    body5: {
      es: `La Evaluación de las condiciones de las instalaciones (FCA) es una puntuación de 0 a 100 que mide la condición física de los edificios y sistemas escolares. Cuanto mayor sea el número, más tiempo estará el edificio al servicio de la enseñanza y el aprendizaje para el personal y los estudiantes.`,
      "zh-CN": `设施条件评估 (FCA) 是一个从 0 到 100 的分数，用于衡量学校建筑和系统的物理状况。 数字越高，大楼为教职工和学生提供教学服务的时间就越长。`,
      vi: `Đánh giá Điều kiện Cơ sở vật chất (FCA) là điểm từ 0 đến 100 để đo tình trạng vật chất của các tòa nhà và hệ thống của trường học. Số lượng càng cao thì công trình càng lâu dài để phục vụ cho việc giảng dạy và học tập cho cán bộ nhân viên và học sinh.`,
      ru: `Оценка условий объектов (FCA) — это оценка от 0 до 100, которая измеряет физическое состояние школьных зданий и систем. Чем выше число, тем дольше здание будет использоваться для обучения и обучения персонала и студентов.`,
      sq: `Vlerësimi i kushteve të objekteve (FCA) është një rezultat nga 0 në 100 që mat gjendjen fizike të ndërtesave dhe sistemeve të shkollave. Sa më i madh të jetë numri, aq më gjatë do të jetë ndërtesa në shërbim të mësimdhënies dhe të nxënit për stafin dhe studentët.`,
      km: `ការវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ (FCA) គឺជាពិន្ទុពី 0 ដល់ 100 ដែលវាស់វែងស្ថានភាពរាងកាយនៃអគារ និងប្រព័ន្ធរបស់សាលារៀន។ ចំនួនកាន់តែខ្ពស់ អគារនេះកាន់តែប្រើប្រាស់បានយូរក្នុងការបម្រើការបង្រៀន និងការរៀនសម្រាប់បុគ្គលិក និងសិស្ស។`,
      fr: `L'évaluation de l'état des installations (FCA) est un score de 0 à 100 qui mesure l'état physique des bâtiments et des systèmes des écoles. Plus le nombre est élevé, plus longtemps le bâtiment sera au service de l'enseignement et de l'apprentissage pour le personnel et les étudiants.`,
      ar: `تقييم ظروف المرافق (FCA) عبارة عن درجة من 0 إلى 100 تقيس الحالة المادية لمباني وأنظمة المدارس. كلما زاد العدد ، كلما طالت مدة خدمة المبنى للتدريس والتعلم للموظفين والطلاب.`,
      en: `Facilities Conditions Assessment (FCA) is a score from 0 to 100 that measures the physical condition of schools’ buildings and systems. The higher the number, the longer the building will be in service to teaching and learning for staff and students.`,
    },
    body6: {
      es: `Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para identificar cualquier problema estructural y/o secciones de una instalación que necesiten reparación o reemplazo.`,
      "zh-CN": `为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走遍了该地区的每一所学校，以确定任何结构问题和/或需要维修或更换的设施部分。`,
      vi: `Để xác định điều này, một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để xác định bất kỳ vấn đề nào về cấu trúc và / hoặc các bộ phận của cơ sở cần sửa chữa hoặc thay thế.`,
      ru: `Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу округа, чтобы выявить любые структурные проблемы и/или участки объекта, которые нуждаются в ремонте или замене.`,
      sq: `Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të identifikuar çdo problem strukturor dhe/ose seksion të një objekti që kanë nevojë për riparim ose zëvendësim.`,
      km: `ដើម្បីកំណត់បញ្ហានេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីកំណត់បញ្ហារចនាសម្ព័ន្ធ និង/ឬផ្នែកនៃកន្លែងដែលត្រូវការការជួសជុល ឬជំនួស។`,
      fr: `Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour identifier tout problème structurel et / ou section d'une installation nécessitant une réparation ou un remplacement.`,
      ar: `لتحديد ذلك ، قام فريق من المهنيين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتحديد أي مشاكل هيكلية و / أو أقسام بالمنشأة تحتاج إلى إصلاح أو استبدال.`,
      en: `To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to identify any structural problems and/or sections of a facility that are in need of repair or replacement.`,
    },
    title3: {
      es: `Evaluación de la idoneidad educativa`,
      "zh-CN": `教育适宜性评估`,
      vi: `Đánh giá tính phù hợp giáo dục`,
      ru: `Оценка пригодности для образования`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ`,
      fr: `Évaluation de la pertinence scolaire`,
      ar: `تقييم الملاءمة التعليمية`,
      en: `Educational Suitability Assessment`,
    },
    body7: {
      es: `La Evaluación de idoneidad educativa (ESA) es una puntuación de 0 a 100 que mide qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela. Cuanto más alto sea el puntaje, mejor el edificio satisface las necesidades del personal y los estudiantes.`,
      "zh-CN": `教育适宜性评估 (ESA) 是一个从 0 到 100 的分数，用于衡量我们的学校设施如何支持每所学校的教学计划。 分数越高，建筑越能满足教职员工和学生的需求。`,
      vi: `Đánh giá Tính phù hợp Giáo dục (ESA) là điểm từ 0 đến 100 để đo lường mức độ cơ sở vật chất trường học của chúng tôi hỗ trợ các chương trình giảng dạy tại mỗi trường tốt như thế nào. Điểm càng cao, tòa nhà càng đáp ứng tốt nhu cầu của nhân viên và sinh viên.`,
      ru: `Оценка пригодности для обучения (ESA) — это оценка от 0 до 100, которая измеряет, насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе. Чем выше балл, тем лучше здание отвечает потребностям сотрудников и студентов.`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore (ESA) është një rezultat nga 0 në 100 që mat se sa mirë ambientet e shkollës sonë mbështesin programet mësimore në secilën shkollë. Sa më i lartë të jetë rezultati, aq më mirë ndërtesa plotëson nevojat e stafit dhe studentëve.`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ (ESA) គឺជាពិន្ទុពី 0 ដល់ 100 ដែលវាស់វែងថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា។ ពិន្ទុកាន់តែខ្ពស់ អគារកាន់តែល្អបំពេញតម្រូវការរបស់បុគ្គលិក និងសិស្ស។`,
      fr: `L'évaluation de l'adéquation éducative (ESA) est un score de 0 à 100 qui mesure dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école. Plus le score est élevé, mieux le bâtiment répond aux besoins du personnel et des étudiants.`,
      ar: `تقييم الملاءمة التعليمية (ESA) عبارة عن درجة من 0 إلى 100 تقيس مدى جودة دعم مرافق مدرستنا للبرامج التعليمية في كل مدرسة. كلما زادت النتيجة ، كان المبنى يلبي احتياجات الموظفين والطلاب بشكل أفضل.`,
      en: `Educational Suitability Assessment (ESA) is a score from 0 to 100 that measures how well our school facilities support the instructional programs at each school. The higher the score, the better the building meets the needs of staff and students.`,
    },
    body8: {
      es: `Para determinar este puntaje, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para evaluar el entorno de aprendizaje, el tamaño, la ubicación, el almacenamiento y el equipo en cada espacio dentro de la escuela.`,
      "zh-CN": `为了确定这个分数，Parsons Environmental and Infrastructure Group 的一组专业人士走访了该地区的每所学校，以评估学校内每个空间的学习环境、规模、位置、存储和设备。`,
      vi: `Để xác định điểm số này, một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để đánh giá môi trường học tập, quy mô, vị trí, kho chứa và thiết bị trong từng không gian trong trường.`,
      ru: `Чтобы определить этот балл, команда профессионалов из Parsons Environmental and Infrastructure Group обошла каждую школу в округе, чтобы оценить учебную среду, размер, расположение, хранение и оборудование в каждом пространстве школы.`,
      sq: `Për të përcaktuar këtë rezultat, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaluan nëpër secilën shkollë në rreth për të vlerësuar mjedisin mësimor, madhësinë, vendndodhjen, ruajtjen dhe pajisjet në çdo hapësirë brenda shkollës.`,
      km: `ដើម្បីកំណត់ពិន្ទុនេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីវាយតម្លៃបរិយាកាសសិក្សា ទំហំ ទីតាំង កន្លែងស្តុកទុក និងឧបករណ៍នៅក្នុងកន្លែងនីមួយៗនៅក្នុងសាលា។`,
      fr: `Pour déterminer ce score, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour évaluer l'environnement d'apprentissage, la taille, l'emplacement, le stockage et l'équipement dans chaque espace de l'école.`,
      ar: `لتحديد هذه النتيجة ، قام فريق من المتخصصين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتقييم بيئة التعلم والحجم والموقع والتخزين والمعدات في كل مساحة داخل المدرسة.`,
      en: `To determine this score, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to assess the learning environment, size, location, storage, and equipment in each space within the school.`,
    },
    title4: {
      es: `Puntuación combinada ponderada`,
      "zh-CN": `加权综合得分`,
      vi: `Điểm kết hợp có trọng số`,
      ru: `Взвешенный комбинированный балл`,
      sq: `Rezultati i ponderuar i kombinuar`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដោយថ្លឹងថ្លែង`,
      fr: `Note combinée pondérée`,
      ar: `النتيجة المجمعة المرجحة`,
      en: `Weighted Combined Score`,
    },
    body9: {
      es: `El puntaje combinado ponderado (WCS) es un puntaje ponderado de 0 a 100 que calculó el Distrito Escolar de Filadelfia (basado en el `,
      "zh-CN": `加权综合分数 (WCS) 是费城学区计算的从 0 到 100 的加权分数（基于 `,
      vi: `Điểm Kết hợp Có Trọng số (WCS) là điểm có trọng số từ 0 đến 100 mà Học khu Philadelphia tính toán (dựa trên `,
      ru: `Взвешенный комбинированный балл (WCS) — это взвешенный балл от 0 до 100, рассчитанный школьным округом Филадельфии (на основе `,
      sq: `Rezultati i Ponderuar i Kombinuar (WCS) është një rezultat i ponderuar nga 0 në 100 që Distrikti Shkollor i Filadelfia ka llogaritur (bazuar në `,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់ (WCS) គឺជាពិន្ទុទម្ងន់ពី 0 ដល់ 100 ដែលសាលាស្រុក Philadelphia គណនា (ផ្អែកលើ `,
      fr: `Le score combiné pondéré (WCS) est un score pondéré de 0 à 100 que le district scolaire de Philadelphie a calculé (basé sur le `,
      ar: `الدرجة المجمعة الموزونة (WCS) هي درجة مرجحة من 0 إلى 100 والتي حسبتها منطقة فيلادلفيا التعليمية (بناءً على `,
      en: `The Weighted Combined Score (WCS) is a weighted score from 0 to 100 that the School District of Philadelphia calculated (based on the `,
    },
    body10: {
      es: ` y `,
      "zh-CN": ` 和 `,
      vi: ` và `,
      ru: ` и `,
      sq: ` dhe `,
      km: ` និង `,
      fr: ` et `,
      ar: ` و `,
      en: ` and `,
    },
    body11: {
      es: `) para ayudar a identificar qué escuelas tienen la mayor necesidad de renovación o deberían ser consideradas para reemplazo. La puntuación es el promedio ponderado del 60 % de evaluación de idoneidad educativa y del 40 % de evaluación de las condiciones de las instalaciones.`,
      "zh-CN": `) 帮助确定哪些学校最需要翻新或应考虑更换。 该分数是 60% 的教育适宜性评估和 40% 的设施条件评估的加权平均值。`,
      vi: `) để giúp xác định những trường nào có nhu cầu cải tạo cao nhất hoặc cần được xem xét để thay thế. Điểm là trung bình có trọng số của 60% Đánh giá Tính phù hợp Giáo dục và 40% Đánh giá Điều kiện Cơ sở vật chất.`,
      ru: `) чтобы помочь определить, какие школы больше всего нуждаются в ремонте или должны быть рассмотрены для замены. Оценка представляет собой средневзвешенное значение 60% оценки пригодности для образования и 40% оценки состояния помещений.`,
      sq: `) për të ndihmuar në identifikimin se cilat shkolla kanë nevojën më të madhe për rinovim ose duhet të konsiderohen për zëvendësim. Rezultati është mesatarja e ponderuar e 60% të vlerësimit të përshtatshmërisë arsimore dhe 40% të vlerësimit të kushteve të objekteve.`,
      km: `) ដើម្បីជួយកំណត់ថាសាលារៀនណាដែលមានតម្រូវការខ្ពស់បំផុតសម្រាប់ការជួសជុល ឬគួរតែត្រូវបានពិចារណាសម្រាប់ការជំនួស។ ពិន្ទុ​គឺ​ជា​មធ្យម​ទម្ងន់​នៃ​ការ​វាយ​តម្លៃ​សមភាព​ផ្នែក​អប់រំ 60% និង​ការ​វាយ​តម្លៃ​លក្ខខណ្ឌ​គ្រឿង​បរិក្ខារ 40%។`,
      fr: `) pour aider à identifier les écoles qui ont le plus besoin de rénovation ou dont le remplacement devrait être envisagé. Le score est la moyenne pondérée de 60 % d'évaluation de la pertinence éducative et de 40 % d'évaluation de l'état des installations.`,
      ar: `) للمساعدة في تحديد المدارس الأكثر احتياجًا للتجديد أو التي يجب التفكير فيها للاستبدال. النتيجة هي المتوسط المرجح لـ 60٪ من تقييم الملاءمة التعليمية و 40٪ لتقييم ظروف المرافق.`,
      en: `) to help identify which schools have the highest need for renovation or should be considered for replacement. The score is the weighted average of 60% Educational Suitability Assessment and 40% Facilities Conditions Assessment.`,
    },
    body12: {
      es: `Cualquier escuela con un puntaje combinado ponderado de menos de 60 tendrá la primera prioridad para abordar los problemas de la instalación. `,
      "zh-CN": `任何加权综合得分低于 60 分的学校都将优先考虑解决设施的问题。`,
      vi: `Bất kỳ trường nào có Điểm Kết hợp Trọng số dưới 60 sẽ được ưu tiên giải quyết các vấn đề của cơ sở.`,
      ru: `Любая школа с комбинированным взвешенным баллом менее 60 будет иметь приоритет в решении проблем учреждения.`,
      sq: `Çdo shkollë me një rezultat të kombinuar të ponderuar më pak se 60 do t'i jepet përparësia e parë në adresimin e çështjeve të institucionit.`,
      km: `សាលាណាមួយដែលមានពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់តិចជាង 60 នឹងត្រូវបានផ្តល់អាទិភាពដំបូងក្នុងការដោះស្រាយបញ្ហារបស់គ្រឹះស្ថាន។`,
      fr: `Toute école avec un score combiné pondéré inférieur à 60 aura la priorité pour résoudre les problèmes de l'établissement.`,
      ar: `أي مدرسة ذات مجموع نقاط مرجحة أقل من 60 ستعطى الأولوية الأولى في معالجة مشكلات المنشأة.`,
      en: `Any school with a Weighted Combined Score of less than 60 will be given first priority in addressing the facility’s issues.`,
    },
    link2: {
      es: ` ESA `,
      "zh-CN": ` ESA `,
      vi: ` ESA `,
      ru: ` ESA `,
      sq: ` ESA `,
      km: ` ESA `,
      fr: ` ESA `,
      ar: ` ESA `,
      en: ` ESA `,
    },
    link3: {
      es: ` FCA`,
      "zh-CN": ` FCA`,
      vi: ` FCA`,
      ru: ` FCA`,
      sq: ` FCA`,
      km: ` FCA`,
      fr: ` FCA`,
      ar: ` FCA`,
      en: ` FCA`,
    },
    title5: {
      es: `Pronóstico de Inscripción y Utilización`,
      "zh-CN": `招生预测和利用率`,
      vi: `Dự báo tuyển sinh và sử dụng`,
      ru: `Прогноз регистрации и использование`,
      sq: `Parashikimi dhe shfrytëzimi i regjistrimit`,
      km: `ការព្យាករណ៍ និងការប្រើប្រាស់ការចុះឈ្មោះ`,
      fr: `Prévision d'inscription et utilisation`,
      ar: `توقعات التسجيل والاستخدام`,
      en: `Enrollment Forecast & Utilization`,
    },
    body13: {
      es: `La inscripción de estudiantes es el número de estudiantes que asisten a cada escuela. Cada edificio escolar puede albergar de forma segura a una cierta cantidad de estudiantes, según el tamaño del edificio, la cantidad de aulas, etc. Esta es la "capacidad" del edificio. Si una escuela tiene una inscripción baja en comparación con el tamaño de su edificio, entonces el edificio está 'infrautilizado'.`,
      "zh-CN": `学生入学人数是每所学校就读的学生人数。 每栋教学楼可以安全地容纳一定数量的学生，具体取决于建筑物的大小、教室的数量等。这是建筑物的“容量”。如果一所学校的入学人数与其建筑物的大小相比较低，那么 该建筑“未得到充分利用”。`,
      vi: `Số lượng học sinh đang theo học tại mỗi trường. Mỗi tòa nhà trường học có thể giữ một số lượng học sinh nhất định một cách an toàn, tùy thuộc vào kích thước của tòa nhà, số lượng phòng học, v.v. Đây là 'sức chứa' của tòa nhà. Nếu một trường có số học sinh đăng ký thấp so với quy mô của tòa nhà đó thì tòa nhà 'đang được sử dụng kém.'`,
      ru: `Количество учащихся – это количество учащихся, посещающих каждую школу. Каждое школьное здание может безопасно вместить определенное количество учащихся, в зависимости от размера здания, количества классных комнат и т. д. Это «вместимость здания». здание «недостаточно используется».`,
      sq: `Regjistrimi i studentëve është numri i nxënësve që ndjekin çdo shkollë. Çdo ndërtesë shkolle mund të mbajë në mënyrë të sigurt një numër të caktuar nxënësish, në varësi të madhësisë së ndërtesës, numrit të klasave, etj. Ky është 'kapaciteti' i ndërtesës. Nëse një shkollë ka një regjistrim të ulët në krahasim me madhësinë e ndërtesës së saj, atëherë ndërtesa është 'e pashfrytëzuar'.`,
      km: `ការចុះឈ្មោះសិស្ស គឺជាចំនួនសិស្សដែលចូលរៀននៅសាលានីមួយៗ។ អគារសិក្សានីមួយៗអាចផ្ទុកសិស្សបានដោយសុវត្ថិភាព អាស្រ័យលើទំហំអគារ ចំនួនថ្នាក់រៀន។ល។ នេះគឺជា 'សមត្ថភាព' របស់អគារ។ ប្រសិនបើសាលាមានការចុះឈ្មោះចូលរៀនទាប បើធៀបនឹងទំហំអគារ នោះ អគារនេះត្រូវបាន 'មិនប្រើប្រាស់។'`,
      fr: `L'inscription des élèves est le nombre d'élèves fréquentant chaque école. Chaque bâtiment scolaire peut accueillir en toute sécurité un certain nombre d'élèves, en fonction de la taille du bâtiment, du nombre de salles de classe, etc. Il s'agit de la « capacité » du bâtiment. Si une école a un faible taux d'inscription par rapport à la taille de son bâtiment, alors le bâtiment est « sous-utilisé ».`,
      ar: `تسجيل الطلاب هو عدد الطلاب الملتحقين بكل مدرسة. يمكن أن يستوعب كل مبنى مدرسي عددًا معينًا من الطلاب بأمان ، اعتمادًا على حجم المبنى ، وعدد الفصول الدراسية ، وما إلى ذلك. هذه هي "سعة" المبنى. إذا كان معدل الالتحاق بالمدرسة منخفضًا مقارنة بحجم المبنى ، فعندئذ المبنى "غير مستغل بما فيه الكفاية".`,
      en: `Student Enrollment is the number of students attending each school. Each school building can safely hold a certain number of students, depending on the size of the building, number of classrooms, etc. This is the building’s ‘capacity.’ If a school has a low enrollment compared to the size of its building, then the building is ‘under-utilized.’`,
    },
    body14: {
      es: `Estos números son una parte fundamental del proceso de planificación de instalaciones. La cantidad de fondos que recibe una escuela está determinada en parte por la inscripción de estudiantes; la baja matriculación y la subutilización dificultan tener fondos suficientes para pagar los maestros y otros recursos. También puede ser un desafío cuando hay escuelas sobreutilizadas porque las aulas abarrotadas pueden afectar negativamente el rendimiento y la seguridad de los estudiantes, obstaculizar la capacidad de la administración para programar las aulas de manera efectiva y sobrecargar los equipos y sistemas del edificio.`,
      "zh-CN": `这些数字是设施规划流程的关键部分。 学校获得的资助金额部分取决于学生入学率； 入学率低和利用率低使得很难有足够的资金来支付教师和其他资源的费用。 当学校过度使用时，这也可能是一个挑战，因为拥挤的教室会对学生的表现和安全产生负面影响，阻碍政府有效安排教室的能力，并使建筑物的设备和系统负担过重`,
      vi: `Những con số này là một phần quan trọng của Quy trình Lập kế hoạch Cơ sở vật chất. Số tiền tài trợ mà trường nhận được một phần được xác định bởi Số lượng sinh viên; tỷ lệ tuyển sinh thấp và sử dụng ít gây khó khăn cho việc có đủ kinh phí để trả cho giáo viên và các nguồn lực khác. Nó cũng có thể là một thách thức khi có những trường học được sử dụng quá mức vì lớp học đông đúc có thể ảnh hưởng tiêu cực đến kết quả hoạt động và sự an toàn của học sinh, cản trở khả năng sắp xếp lịch học hiệu quả của ban quản lý và làm quá tải thiết bị và hệ thống của tòa nhà.`,
      ru: `Эти цифры являются важной частью процесса планирования объектов. Сумма финансирования, которую получает школа, частично определяется набором учащихся; низкий уровень зачисления и недостаточное использование затрудняют получение достаточного финансирования для оплаты труда учителей и других ресурсов. Это также может быть проблемой, когда есть чрезмерно загруженные школы, потому что переполненные классы могут негативно повлиять на успеваемость и безопасность учащихся, помешать администрации эффективно планировать классы и перегрузить оборудование и системы здания.`,
      sq: `Këta numra janë një pjesë kritike e Procesit të Planifikimit të Objekteve. Shuma e financimit që merr një shkollë përcaktohet pjesërisht nga Regjistrimi i Studentëve; Regjistrimi i ulët dhe mospërdorimi e bëjnë të vështirë të kesh fonde të mjaftueshme për të paguar mësuesit dhe burime të tjera. Mund të jetë gjithashtu një sfidë kur ka shkolla të mbishfrytëzuara, sepse klasat e mbushura me njerëz mund të ndikojnë negativisht në performancën dhe sigurinë e nxënësve, të pengojnë aftësinë e administratës për të planifikuar në mënyrë efektive klasat dhe të mbingarkojnë pajisjet dhe sistemet e ndërtesës.`,
      km: `លេខទាំងនេះគឺជាផ្នែកសំខាន់នៃដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ។ ចំនួនមូលនិធិដែលសាលាទទួលបានត្រូវបានកំណត់ដោយផ្នែកដោយការចុះឈ្មោះសិស្ស។ ការចុះឈ្មោះទាប និងការប្រើប្រាស់មិនគ្រប់គ្រាន់ ធ្វើឱ្យមានការលំបាកក្នុងការមានមូលនិធិគ្រប់គ្រាន់ដើម្បីចំណាយសម្រាប់គ្រូបង្រៀន និងធនធានផ្សេងទៀត។ វាក៏អាចជាបញ្ហាប្រឈមផងដែរ នៅពេលដែលមានសាលារៀនប្រើប្រាស់ហួសកម្រិត ពីព្រោះថ្នាក់រៀនមានមនុស្សច្រើនអាចប៉ះពាល់អវិជ្ជមានដល់ការអនុវត្ត និងសុវត្ថិភាពសិស្ស រារាំងសមត្ថភាពរបស់រដ្ឋបាលក្នុងការកំណត់កាលវិភាគថ្នាក់រៀនប្រកបដោយប្រសិទ្ធភាព និងលើសទម្ងន់ឧបករណ៍ និងប្រព័ន្ធរបស់អគារ។`,
      fr: `Ces chiffres sont un élément essentiel du processus de planification des installations. Le montant du financement qu'une école reçoit est en partie déterminé par l'inscription des élèves; le faible taux d'inscription et la sous-utilisation font qu'il est difficile d'avoir suffisamment de fonds pour payer les enseignants et d'autres ressources. Cela peut également être un défi lorsqu'il y a des écoles surutilisées, car les salles de classe surpeuplées peuvent affecter négativement les performances et la sécurité des élèves, entraver la capacité de l'administration à planifier efficacement les salles de classe et surcharger l'équipement et les systèmes du bâtiment.`,
      ar: `هذه الأرقام هي جزء مهم من عملية تخطيط المرافق. يتم تحديد مقدار التمويل الذي تتلقاه المدرسة جزئيًا عن طريق تسجيل الطلاب ؛ يؤدي انخفاض معدل الالتحاق وقلة الاستخدام إلى صعوبة الحصول على تمويل كافٍ لدفع تكاليف المعلمين والموارد الأخرى. يمكن أن يمثل ذلك أيضًا تحديًا عندما يكون هناك استخدام مفرط للمدارس لأن الفصول الدراسية المزدحمة يمكن أن تؤثر سلبًا على أداء الطلاب وسلامتهم ، وتعيق قدرة الإدارة على جدولة الفصول الدراسية بشكل فعال ، وتثقل كاهل معدات وأنظمة المبنى`,
      en: `These numbers are a critical part of the Facilities Planning Process. The amount of funding a school receives is partly determined by the Student Enrollment; low enrollment and under-utilization make it difficult to have enough funding to pay for teachers and other resources. It can also be a challenge when there are over-utilized schools because crowded classrooms can negatively affect student performance and safety, hinder the administration’s ability to schedule classrooms effectively, and overburden the building’s equipment and systems.`,
    },
  },
  video_transcripts: {
    title1: {
      es: `Evaluación de la idoneidad educativa`,
      "zh-CN": `教育适宜性评估`,
      vi: `Đánh giá tính phù hợp giáo dục`,
      ru: `Оценка пригодности для образования`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ`,
      fr: `Évaluation de la pertinence scolaire`,
      ar: `تقييم الملاءمة التعليمية`,
      en: `Educational Suitability Assessment`,
    },
    subtitle1: {
      es: `Transcripción del video con descripción de las imágenes`,
      "zh-CN": `带有视觉效果描述的视频记录`,
      vi: `Bản ghi video có mô tả về hình ảnh`,
      ru: `Стенограмма видео с описанием визуальных эффектов`,
      sq: `Video Transkript me përshkrim të pamjeve`,
      km: `វីដេអូប្រតិចារឹកជាមួយនឹងការពិពណ៌នាអំពីរូបភាព`,
      fr: `Transcription vidéo avec description des visuels`,
      ar: `نص فيديو مع وصف للعناصر المرئية`,
      en: `Video Transcript with Description of Visuals`,
    },
    body1: {
      es: `¡Hola! Mi nombre es Yvette y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia determinó qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela.`,
      "zh-CN": `你好！我的名字是 Yvette，我在这里与您分享费城学区如何确定我们的学校设施如何支持每所学校的教学计划。`,
      vi: `Xin chào! Tên tôi là Yvette, và tôi có mặt ở đây để chia sẻ với bạn cách Học khu Philadelphia xác định cơ sở vật chất trường học của chúng tôi hỗ trợ tốt như thế nào đối với các chương trình giảng dạy tại mỗi trường.`,
      ru: `Привет! Меня зовут Иветт, и я здесь, чтобы поделиться с вами тем, как школьный округ Филадельфии определил, насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе.`,
      sq: `Përshëndetje! Emri im është Yvette dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfias përcaktoi se sa mirë ambientet tona shkollore mbështesin programet mësimore në secilën shkollë.`,
      km: `សួស្តី! ឈ្មោះរបស់ខ្ញុំគឺ Yvette ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែលសាលាស្រុក Philadelphia បានកំណត់ថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា។`,
      fr: `Salut! Je m'appelle Yvette et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a déterminé dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école.`,
      ar: `أهلاً! اسمي إيفيت ، وأنا هنا لأشارككم كيف حددت مقاطعة فيلادلفيا التعليمية مدى جودة دعم مرافق مدرستنا للبرامج التعليمية في كل مدرسة.`,
      en: `Hi! My name is Yvette, and I’m here to share with you how the School District of Philadelphia determined how well our school facilities support the instructional programs at each school.`,
    },
    body2: {
      es: `A esto lo llamamos una Evaluación de Idoneidad Educativa, o ESA. Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del Distrito para evaluar el entorno de aprendizaje, el tamaño, la ubicación, el almacenamiento y el equipo en cada espacio dentro de la escuela.`,
      "zh-CN": `我们称之为教育适宜性评估，或 ESA。为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走访了学区的每一所学校，以评估学校内每个空间的学习环境、规模、位置、存储和设备。`,
      vi: `Chúng tôi gọi đây là Đánh giá Tính phù hợp Giáo dục, hoặc ESA. Để xác định điều này, một nhóm chuyên gia từ Nhóm Cơ sở Hạ tầng và Môi trường Parsons đã đi qua từng trường học trong Học khu để đánh giá môi trường học tập, quy mô, vị trí, kho chứa và thiết bị trong từng không gian trong trường.`,
      ru: `Мы называем это оценкой пригодности для образования, или ESA. Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу в округе, чтобы оценить учебную среду, размер, расположение, хранение и оборудование в каждом помещении школы.`,
      sq: `Ne e quajmë këtë një Vlerësim të Përshtatshmërisë Arsimore, ose ESA. Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaluan nëpër secilën shkollë në distrikt për të vlerësuar mjedisin mësimor, madhësinë, vendndodhjen, ruajtjen dhe pajisjet në çdo hapësirë ​​brenda shkollës.`,
      km: `យើងហៅវាថា ការវាយតម្លៃភាពសមស្របនៃការអប់រំ ឬ ESA ។ ដើម្បីកំណត់នេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីវាយតម្លៃបរិយាកាសសិក្សា ទំហំ ទីតាំង កន្លែងស្តុកទុក និងឧបករណ៍ក្នុងចន្លោះនីមួយៗនៅក្នុងសាលា។`,
      fr: `Nous appelons cela une évaluation de la pertinence éducative, ou ESA. Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour évaluer l'environnement d'apprentissage, la taille, l'emplacement, le stockage et l'équipement dans chaque espace de l'école.`,
      ar: `نسمي هذا تقييم الملاءمة التعليمية ، أو ESA. لتحديد ذلك ، قام فريق من المتخصصين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتقييم بيئة التعلم والحجم والموقع والتخزين والمعدات في كل مساحة داخل المدرسة.`,
      en: `We call this an Educational Suitability Assessment, or ESA. To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the District to assess the learning environment, size, location, storage and equipment in each space within the school.`,
    },
    body3: {
      es: `Esto incluyó aulas de educación general y especial, centros de medios, gimnasios, laboratorios de ciencias, salas de arte, espacios de artes escénicas, espacios al aire libre y más.`,
      "zh-CN": `这包括普通和特殊教育教室、媒体中心、健身房、科学实验室、艺术室、表演艺术空间、户外空间等。`,
      vi: `Điều này bao gồm các phòng học giáo dục phổ thông và giáo dục đặc biệt, trung tâm truyền thông, phòng tập thể dục, phòng thí nghiệm khoa học, phòng nghệ thuật, không gian biểu diễn nghệ thuật, không gian ngoài trời và hơn thế nữa.`,
      ru: `Сюда входили классы общего и специального образования, медиацентры, спортивные залы, научные лаборатории, художественные залы, помещения для исполнительских искусств, открытые площадки и многое другое.`,
      sq: `Kjo përfshinte klasa të arsimit të përgjithshëm dhe të veçantë, qendra mediatike, palestra, laboratorë shkencorë, dhoma arti, hapësira të arteve interpretuese, hapësira të jashtme dhe më shumë.`,
      km: `នេះរួមបញ្ចូលទាំងថ្នាក់អប់រំទូទៅ និងពិសេស មជ្ឈមណ្ឌលប្រព័ន្ធផ្សព្វផ្សាយ កន្លែងហាត់ប្រាណ បន្ទប់ពិសោធន៍វិទ្យាសាស្ត្រ បន្ទប់សិល្បៈ កន្លែងសំដែងសិល្បៈ កន្លែងក្រៅ និងកន្លែងជាច្រើនទៀត។`,
      fr: `Cela comprenait des salles de classe d'enseignement général et spécial, des centres multimédias, des gymnases, des laboratoires scientifiques, des salles d'art, des espaces d'arts du spectacle, des espaces extérieurs et plus encore.`,
      ar: `وشمل ذلك الفصول الدراسية للتعليم العام والخاص ، ومراكز الوسائط ، وصالات الألعاب الرياضية ، ومختبرات العلوم ، وغرف الفنون ، ومساحات الفنون المسرحية ، والمساحات الخارجية والمزيد.`,
      en: `This included general and special education classrooms, media centers, gyms, science labs, art rooms, performing arts spaces, outdoor spaces and more.`,
    },
    body4: {
      es: `Ahora, echemos un vistazo a un salón de clases. El evaluador hizo preguntas como: ¿Está bien iluminado? ¿Está bien ventilado? ¿Necesita pintura? ¿Cumple con los estándares de tamaño establecidos por el Distrito? ¿Tiene el equipo adecuado y suficiente espacio de almacenamiento? ¿Está en un lugar apropiado según las necesidades del programa?`,
      "zh-CN": `现在，让我们看一个教室。评估员提出了以下问题：光线充足吗？通风良好？需要油漆吗？是否符合学区规定的尺寸标准？它有合适的设备和足够的存储空间吗？它是否根据程序需要在适当的位置？`,
      vi: `Bây giờ, chúng ta hãy nhìn vào một lớp học. Người đánh giá hỏi những câu hỏi như: Nó có đủ ánh sáng không? Thông gió thích hợp? Nó có cần sơn không? Nó có đáp ứng các tiêu chuẩn về kích thước do Quận đặt ra không? Nó có thiết bị phù hợp và đủ không gian lưu trữ không? Nó có ở một vị trí thích hợp dựa trên nhu cầu của chương trình không?`,
      ru: `Теперь давайте посмотрим на классную комнату. Оценщик задавал такие вопросы, как: Хорошо ли он освещен? Хорошо проветривается? Нужна ли краска? Соответствует ли размер стандартам, установленным округом? Есть ли у него подходящее оборудование и достаточно места для хранения? Находится ли он в подходящем месте, исходя из потребностей программы?`,
      sq: `Tani, le të shohim një klasë. Vlerësuesi bëri pyetje si: A është i ndriçuar mirë? Ajroset siç duhet? A ka nevojë për bojë? A i plotëson standardet e madhësisë të përcaktuara nga Distrikti? A ka pajisjet e duhura dhe hapësirë ​​të mjaftueshme magazinimi? A është në një vend të përshtatshëm bazuar në nevojat e programit?`,
      km: `ឥឡូវនេះសូមក្រឡេកមើលថ្នាក់រៀន។ អ្នកវាយតម្លៃបានសួរសំណួរដូចជា៖ តើវាមានពន្លឺល្អទេ? មានខ្យល់ចេញចូលត្រឹមត្រូវ? តើវាត្រូវការថ្នាំលាបទេ? តើវាបំពេញតាមស្តង់ដារទំហំដែលកំណត់ដោយស្រុកទេ? តើវាមានឧបករណ៍ត្រឹមត្រូវ និងទំហំផ្ទុកគ្រប់គ្រាន់ទេ? តើ​វា​ស្ថិត​ក្នុង​ទីតាំង​សមស្រប​តាម​តម្រូវការ​កម្មវិធី​ដែរ​ឬ​ទេ?`,
      fr: `Maintenant, regardons une salle de classe. L'évaluateur a posé des questions telles que : Est-ce bien éclairé ? Bien aéré ? A-t-il besoin de peinture ? Répond-il aux normes de taille fixées par le District ? A-t-il le bon équipement et suffisamment d'espace de stockage ? Est-ce dans un endroit approprié en fonction des besoins du programme ?`,
      ar: `الآن ، دعونا نلقي نظرة على فصل دراسي. سأل المقيم أسئلة مثل: هل هو جيد الإضاءة؟ تهوية جيدة؟ هل تحتاج إلى طلاء؟ هل يفي بمعايير الحجم التي وضعتها المنطقة؟ هل لديها المعدات المناسبة ومساحة تخزين كافية؟ هل هو في موقع مناسب بناءً على احتياجات البرنامج؟`,
      en: `Now, let’s look at a classroom. The evaluator asked questions like: Is it well-lit? Properly ventilated? Does it need paint? Does it meet the size standards set by the District? Does it have the right equipment and enough storage space? Is it in an appropriate location based on the program needs?`,
    },
    body5: {
      es: `La evaluación de idoneidad también incluye el exterior del edificio, patrones de tráfico, estacionamiento y acceso a la escuela, patios de recreo, áreas deportivas y cuestiones de seguridad, como iluminación, señalización y entradas seguras.`,
      "zh-CN": `适用性评估还包括建筑物的外部、交通模式、停车和进入学校、操场、运动区和安全问题，如照明、标牌和安全入口。`,
      vi: `Đánh giá mức độ phù hợp cũng bao gồm bên ngoài tòa nhà, kiểu giao thông, bãi đậu xe và lối vào trường, sân chơi, khu vực thể thao và các vấn đề an toàn, như ánh sáng, biển báo và lối vào an toàn.`,
      ru: `Оценка пригодности также включает в себя внешний вид здания, схемы движения, парковку и доступ к школе, игровые площадки, спортивные площадки и вопросы безопасности, такие как освещение, вывески и безопасные входы.`,
      sq: `Vlerësimi i përshtatshmërisë përfshin gjithashtu pjesën e jashtme të ndërtesës, modelet e trafikut, parkimin dhe aksesin në shkollë, këndet e lojërave, zonat atletike dhe çështjet e sigurisë, si ndriçimi, sinjalistika dhe hyrjet e sigurta.`,
      km: `ការវាយតម្លៃភាពសមស្របនេះក៏រួមបញ្ចូលផងដែរនូវផ្នែកខាងក្រៅនៃអគារ គំរូចរាចរណ៍ ចំណត និងការចូលទៅកាន់សាលារៀន សួនកុមារ កន្លែងហាត់ប្រាណ និងបញ្ហាសុវត្ថិភាព ដូចជាភ្លើង សញ្ញា និងច្រកចូលដែលមានសុវត្ថិភាព។`,
      fr: `L'évaluation de l'adéquation comprend également l'extérieur du bâtiment, les schémas de circulation, le stationnement et l'accès à l'école, les terrains de jeux, les zones sportives et les problèmes de sécurité, comme l'éclairage, la signalisation et les entrées sécurisées.`,
      ar: `يشمل تقييم الملاءمة أيضًا السطح الخارجي للمبنى وأنماط المرور ومواقف السيارات والوصول إلى المدرسة والملاعب والمناطق الرياضية وقضايا السلامة ، مثل الإضاءة واللافتات والمداخل الآمنة.`,
      en: `The suitability assessment also includes the outside of the building, traffic patterns, parking and access to the school, playgrounds, athletic areas, and safety issues, like lighting, signage and secure entrances.`,
    },
    body6: {
      es: `Después de ver el interior y el exterior del edificio y de hablar con el director, cada escuela recibe una puntuación de cero a 100. Cuanto más alta sea la puntuación, mejor se adapta el edificio a las necesidades de nuestro personal y estudiantes.`,
      "zh-CN": `在查看了大楼的内部和外部并与校长交谈后，每所学校都给出了从零到 100 的分数。分数越高，大楼越能满足我们教职员工和学生的需求。`,
      vi: `Sau khi xem bên trong và bên ngoài của tòa nhà và nói chuyện với hiệu trưởng, mỗi trường được cho điểm từ 0 đến 100. Điểm càng cao, tòa nhà càng đáp ứng tốt nhu cầu của nhân viên và học sinh của chúng tôi.`,
      ru: `После осмотра здания внутри и снаружи и разговора с директором каждой школе присваивается оценка от нуля до 100. Чем выше оценка, тем лучше здание отвечает потребностям наших сотрудников и учащихся.`,
      sq: `Pas shikimit të pjesës së brendshme dhe të jashtme të ndërtesës dhe bisedës me drejtorin, secilës shkollë i jepet një pikë nga zero deri në 100. Sa më i lartë të jetë rezultati, aq më mirë ndërtesa plotëson nevojat e stafit dhe nxënësve tanë.`,
      km: `បន្ទាប់ពីមើលខាងក្នុង និងខាងក្រៅអគារ ហើយនិយាយជាមួយនាយកសាលា សាលានីមួយៗទទួលបានពិន្ទុពីសូន្យដល់ 100។ ពិន្ទុកាន់តែខ្ពស់ អគារកាន់តែល្អ ឆ្លើយតបនឹងតម្រូវការរបស់បុគ្គលិក និងសិស្សរបស់យើង។`,
      fr: `Après avoir visité l'intérieur et l'extérieur du bâtiment et discuté avec le directeur, chaque école reçoit une note de zéro à 100. Plus la note est élevée, mieux le bâtiment répond aux besoins de notre personnel et de nos élèves.`,
      ar: `بعد مشاهدة داخل المبنى وخارجه والتحدث مع المدير ، تحصل كل مدرسة على درجة من صفر إلى 100. وكلما ارتفعت الدرجة ، كان المبنى يلبي احتياجات موظفينا وطلابنا بشكل أفضل.`,
      en: `After viewing the inside and outside of the building and speaking with the principal, each school is given a score from zero to 100. The higher the score, the better the building meets the needs of our staff and students.`,
    },
    title2: {
      es: `Evaluación del estado de las instalaciones`,
      "zh-CN": `设施状况评估`,
      vi: `Đánh giá tình trạng cơ sở vật chất`,
      ru: `Оценка состояния объектов`,
      sq: `Vlerësimi i gjendjes së objekteve`,
      km: `ការវាយតម្លៃស្ថានភាពគ្រឿងបរិក្ខារ`,
      fr: `Évaluation de l'état des installations`,
      ar: `تقييم حالة المرافق`,
      en: `Facilities Condition Assessment`,
    },
    body7: {
      es: `¡Hola! Soy Kathy y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia evaluó la condición física de sus edificios y sistemas.`,
      "zh-CN": `你好！我是凯西，我在这里与您分享费城学区如何评估其建筑物和系统的物理状况。`,
      vi: `Xin chào! Tôi là Kathy và tôi ở đây để chia sẻ với bạn cách Học khu Philadelphia đánh giá tình trạng vật chất của các tòa nhà và hệ thống của nó.`,
      ru: `Привет! Меня зовут Кэти, и я здесь, чтобы поделиться с вами тем, как школьный округ Филадельфии оценил физическое состояние своих зданий и систем.`,
      sq: `Përshëndetje! Unë jam Kathy dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfia vlerësoi gjendjen fizike të ndërtesave dhe sistemeve të tij.`,
      km: `សួស្តី! ខ្ញុំគឺ Kathy ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែលសាលាស្រុក Philadelphia បានវាយតម្លៃស្ថានភាពរាងកាយនៃអគារ និងប្រព័ន្ធរបស់វា។`,
      fr: `Salut! Je m'appelle Kathy et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a évalué l'état physique de ses bâtiments et systèmes.`,
      ar: `أهلاً! أنا كاثي ، وأنا هنا لأطلعكم على كيفية تقييم منطقة فيلادلفيا التعليمية للحالة المادية لمبانيها وأنظمتها.`,
      en: `Hi! I’m Kathy, and I’m here to share with you how the School District of Philadelphia evaluated the physical condition of its buildings and systems.`,
    },
    body8: {
      es: `A esto lo llamamos Evaluación de la Condición de las Instalaciones, o FCA. Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del Distrito para identificar cualquier problema estructural y/o secciones de las instalaciones que necesiten reparación o reemplazo. Los equipos visitaron las instalaciones para recopilar datos sobre el estado de los principales sistemas, incluida la estructura del edificio, el techo, los rociadores, la iluminación, el cableado, el control de la temperatura y la ventilación, por nombrar algunos.`,
      "zh-CN": `我们称之为设施状况评估，或 FCA。为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走遍了学区的每一所学校，以确定任何结构问题和/或需要维修或更换的设施部分。小组参观了这些设施，以收集有关主要系统状况的数据，包括建筑结构、屋顶、洒水装置、照明、布线、温度控制和通风等。`,
      vi: `Chúng tôi gọi đây là Đánh giá Tình trạng Cơ sở, hoặc FCA. Để xác định điều này, một nhóm chuyên gia từ Nhóm Cơ sở Hạ tầng và Môi trường Parsons đã đi qua từng trường học trong Học khu để xác định bất kỳ vấn đề nào về cấu trúc và / hoặc các bộ phận của cơ sở cần sửa chữa hoặc thay thế. Các nhóm đã đến thăm các cơ sở để thu thập dữ liệu về tình trạng của các hệ thống chính bao gồm cấu trúc tòa nhà, mái nhà, vòi phun nước, hệ thống chiếu sáng, hệ thống dây điện, kiểm soát nhiệt độ và thông gió, để nêu tên một số.`,
      ru: `Мы называем это оценкой состояния объекта или FCA. Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу в округе, чтобы выявить любые структурные проблемы и/или участки объекта, которые нуждаются в ремонте или замене. Команды посетили объекты для сбора данных о состоянии основных систем, включая конструкцию здания, кровлю, спринклеры, освещение, электропроводку, контроль температуры и вентиляцию, и это лишь некоторые из них.`,
      sq: `Ne e quajmë këtë një vlerësim të gjendjes së objektit, ose FCA. Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të identifikuar çdo problem strukturor dhe/ose seksion të objektit që kanë nevojë për riparim ose zëvendësim. Ekipet vizituan objektet për të mbledhur të dhëna mbi gjendjen e sistemeve kryesore, duke përfshirë strukturën e ndërtesës, çatinë, spërkatës, ndriçimin, instalimet elektrike, kontrollin e temperaturës dhe ventilimin, për të përmendur disa.`,
      km: `យើងហៅវាថា ការវាយតម្លៃលក្ខខណ្ឌបរិក្ខារបរិក្ខារ ឬ FCA ។ ដើម្បីកំណត់បញ្ហានេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីកំណត់បញ្ហារចនាសម្ព័ន្ធ និង/ឬផ្នែកនៃកន្លែងដែលត្រូវការការជួសជុល ឬជំនួស។ ក្រុមការងារបានចុះទៅពិនិត្យសម្ភារៈបរិក្ខារនានា ដើម្បីប្រមូលទិន្នន័យអំពីស្ថានភាពនៃប្រព័ន្ធសំខាន់ៗ រួមមាន រចនាសម្ព័ន្ធអគារ ដំបូល ប្រដាប់បាញ់ទឹក ភ្លើង ខ្សែភ្លើង ការគ្រប់គ្រងសីតុណ្ហភាព និងប្រព័ន្ធខ្យល់ចេញចូល ដើម្បីកំណត់ឈ្មោះមួយចំនួន។`,
      fr: `Nous appelons cela une évaluation de l'état des installations, ou FCA. Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour identifier tout problème structurel et / ou section de l'installation nécessitant une réparation ou un remplacement. Les équipes ont visité les installations pour recueillir des données sur l'état des principaux systèmes, y compris la structure du bâtiment, la toiture, les gicleurs, l'éclairage, le câblage, le contrôle de la température et la ventilation, pour n'en nommer que quelques-uns.`,
      ar: `نسمي هذا تقييم حالة المنشأة ، أو FCA. لتحديد ذلك ، قام فريق من المهنيين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتحديد أي مشاكل هيكلية و / أو أقسام بالمنشأة تحتاج إلى إصلاح أو استبدال. زارت الفرق المرافق لجمع البيانات عن حالة الأنظمة الرئيسية بما في ذلك هيكل المبنى ، والسقوف ، والرشاشات ، والإضاءة ، والأسلاك ، والتحكم في درجة الحرارة ، والتهوية ، على سبيل المثال لا الحصر.`,
      en: `We call this a Facility Condition Assessment, or FCA. To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the District to identify any structural problems and / or sections of the facility that are in need of repair or replacement. The teams visited the facilities to collect data on the condition of major systems including building structure,roofing, sprinklers, lighting, wiring, temperature control and ventilation, to name a few.`,
    },
    body9: {
      es: `Después de evaluar la condición del edificio, a cada escuela se le otorga una puntuación de cero a 100. Cuanto mayor sea el número, más tiempo estará el edificio al servicio de la enseñanza y el aprendizaje de nuestro personal y estudiantes. `,
      "zh-CN": `在评估了建筑物的状况后，每所学校都会获得从 0 到 100 的分数。分数越高，建筑物为我们的教职员工和学生提供教学和学习服务的时间就越长。`,
      vi: `Sau khi đánh giá tình trạng của tòa nhà, mỗi trường được cho điểm từ 0 đến 100. Con số này càng cao thì tòa nhà sẽ càng tồn tại lâu để phục vụ cho việc dạy và học cho cán bộ và học sinh của chúng tôi.`,
      ru: `После оценки состояния здания каждой школе присваивается балл от нуля до 100. Чем выше число, тем дольше здание будет служить для обучения и обучения наших сотрудников и учащихся.`,
      sq: `Ne e quajmë këtë një vlerësim të gjendjes së objektit, ose FCA. Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të identifikuar çdo problem strukturor dhe/ose seksion të objektit që kanë nevojë për riparim ose zëvendësim. Ekipet vizituan objektet për të mbledhur të dhëna mbi gjendjen e sistemeve kryesore, duke përfshirë strukturën e ndërtesës, çatinë, spërkatës, ndriçimin, instalimet elektrike, kontrollin e temperaturës dhe ventilimin, për të përmendur disa.`,
      km: `បន្ទាប់ពីវាយតម្លៃស្ថានភាពអគាររួច សាលានីមួយៗទទួលបានពិន្ទុពីសូន្យដល់១០០។ លេខកាន់តែខ្ពស់ អគារកាន់តែយូរនឹងបម្រើការបង្រៀន និងរៀនសម្រាប់បុគ្គលិក និងសិស្សរបស់យើង។`,
      fr: `Après avoir évalué l'état du bâtiment, chaque école reçoit une note de zéro à 100. Plus le nombre est élevé, plus le bâtiment sera au service de l'enseignement et de l'apprentissage de notre personnel et de nos élèves.`,
      ar: `بعد تقييم حالة المبنى ، يتم منح كل مدرسة درجة من صفر إلى 100. وكلما زاد العدد ، كلما طالت مدة خدمة المبنى للتدريس والتعلم لموظفينا وطلابنا.`,
      en: `After evaluating the condition of the building, each school is given a score from zero to 100. The higher the number, the longer the building will be in service to teaching and learning for our staff and students.`,
    },
    title3: {
      es: `Puntuación combinada ponderada`,
      "zh-CN": `加权综合得分`,
      vi: `Điểm kết hợp có trọng số`,
      ru: `Взвешенный комбинированный балл`,
      sq: `Rezultati i ponderuar i kombinuar`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដោយថ្លឹងថ្លែង`,
      fr: `Note combinée pondérée`,
      ar: `النتيجة المجمعة المرجحة`,
      en: `Weighted Combined Score`,
    },
    body10: {
      es: `¡Hola! Mi nombre es Marcela, y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia calculó el puntaje combinado ponderado de las instalaciones para medir qué tan bien nuestros edificios están apoyando la enseñanza y el aprendizaje de nuestro personal y estudiantes.`,
      "zh-CN": `你好！我的名字是 Marcela，我在这里与您分享费城学区如何计算加权综合设施分数，以衡量我们的建筑物对教职员工和学生的教学和学习的支持程度。`,
      vi: `Xin chào! Tên tôi là Marcela, và tôi ở đây để chia sẻ với bạn cách Học khu Philadelphia tính Điểm Cơ sở vật chất Kết hợp Có Trọng số để đo lường mức độ hiệu quả của các tòa nhà của chúng tôi trong việc hỗ trợ giảng dạy và học tập cho nhân viên và học sinh của chúng tôi.`,
      ru: `Привет! Меня зовут Марсела, и я здесь, чтобы поделиться с вами тем, как Школьный округ Филадельфии рассчитал взвешенную оценку комбинированных объектов, чтобы измерить, насколько хорошо наши здания поддерживают преподавание и обучение наших сотрудников и учащихся.`,
      sq: `Përshëndetje! Emri im është Marcela dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfias llogariti rezultatin e ponderuar të pajisjeve të kombinuara për të matur se sa mirë ndërtesat tona po mbështesin mësimdhënien dhe të nxënit për stafin dhe studentët tanë.`,
      km: `សួស្តី! ខ្ញុំឈ្មោះ Marcela ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែល School District of Philadelphia គណនាពិន្ទុឧបករណ៍រួមបញ្ចូលគ្នាដែលមានទម្ងន់ ដើម្បីវាស់ថាតើអគាររបស់យើងកំពុងគាំទ្រការបង្រៀន និងការរៀនសម្រាប់បុគ្គលិក និងសិស្សរបស់យើងបានល្អប៉ុណ្ណា។`,
      fr: `Salut! Je m'appelle Marcela et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a calculé le score combiné pondéré des installations pour mesurer dans quelle mesure nos bâtiments soutiennent l'enseignement et l'apprentissage de notre personnel et de nos étudiants.`,
      ar: `أهلاً! اسمي مارسيلا ، وأنا هنا لأشارككم كيف قامت منطقة فيلادلفيا التعليمية بحساب نقاط التسهيلات المجمعة الموزونة لقياس مدى جودة دعم مبانينا للتعليم والتعلم لموظفينا وطلابنا.`,
      en: `Hi! My name is Marcela, and I’m here to share with you how the School District of Philadelphia calculated the Weighted Combined Facilities Score to measure how well our buildings are supporting teaching and learning for our staff and students.`,
    },
    body11: {
      es: `Si no ha tenido noticias de Yvette sobre la Evaluación de idoneidad educativa, o ESA, y de Kathy sobre la Evaluación de la condición de las instalaciones, o FCA, querrá ver esos videos primero, para que comprenda las partes que intervienen en los cálculos que yo hablare de aqui.`,
      "zh-CN": `如果您还没有从 Yvette 那里听说过教育适宜性评估（ESA）和 Kathy 关于设施状况评估（FCA）的消息，那么您需要先观看这些视频，这样您才能了解我计算的部分内容会说到这里。`,
      vi: `Nếu bạn chưa nghe Yvette nói về Đánh giá mức độ phù hợp giáo dục hoặc ESA và Kathy về Đánh giá tình trạng cơ sở vật chất, hoặc FCA, bạn sẽ muốn xem những video đó trước, để bạn hiểu các phần đi vào các phép tính mà tôi Tôi sẽ nói về đây.`,
      ru: `Если вы еще не слышали от Иветт об оценке пригодности для образования, или ESA, и от Кэти об оценке состояния объектов, или FCA, вам следует сначала просмотреть эти видеоролики, чтобы вы поняли, какие части входят в расчеты, которые я поговорим здесь.`,
      sq: `Nëse nuk keni dëgjuar nga Yvette për Vlerësimin e Përshtatshmërisë Arsimore, ose ESA, dhe Kathy për Vlerësimin e Gjendjes së Objekteve, ose FCA, do të dëshironi t'i shikoni ato video fillimisht, në mënyrë që të kuptoni pjesët që hyjnë në llogaritjet që unë do të flasim këtu.`,
      km: `ប្រសិនបើអ្នកមិនទាន់បានឮពី Yvette អំពីការវាយតម្លៃភាពសមស្របនៃការអប់រំ ឬ ESA និង Kathy អំពីការវាយតម្លៃស្ថានភាពគ្រឿងបរិក្ខារ ឬ FCA ទេ អ្នកនឹងចង់មើលវីដេអូទាំងនោះជាមុនសិន ដូច្នេះអ្នកយល់ពីផ្នែកដែលចូលទៅក្នុងការគណនាដែលខ្ញុំ នឹងនិយាយអំពីទីនេះ។`,
      fr: `Si vous n'avez pas entendu Yvette parler de l'évaluation de l'adéquation éducative, ou ESA, et Kathy, de l'évaluation de l'état des installations, ou FCA, vous voudrez d'abord regarder ces vidéos, afin de comprendre les parties qui entrent dans les calculs que je 'll parler ici.`,
      ar: `إذا لم تكن قد سمعت من Yvette عن تقييم الملاءمة التعليمية ، أو ESA ، و Kathy حول تقييم حالة المرافق ، أو FCA ، فستحتاج إلى مشاهدة مقاطع الفيديو هذه أولاً ، حتى تفهم الأجزاء التي تدخل في الحسابات التي أجريها. سأتحدث عنه هنا.`,
      en: `If you haven’t heard from Yvette about the Educational Suitability Assessment, or ESA, and Kathy about the Facilities Condition Assessment, or FCA, you’ll want to watch those videos first, so you understand the parts that go into the calculations that I’ll talk about here.`,
    },
    body12: {
      es: `Al evaluar las escuelas, el Distrito toma en consideración tanto la ESA, qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela, y la FCA, la condición física del edificio y sus sistemas. El puntaje combinado ponderado es un promedio ponderado de esos dos puntajes.`,
      "zh-CN": `在评估学校时，学区会考虑 ESA、我们的学校设施如何支持每所学校的教学计划，以及 FCA、建筑物及其系统的物理状况。加权综合分数是这两个分数的加权平均值。`,
      vi: `Khi đánh giá các trường học, Học khu xem xét cả ESA, cơ sở vật chất trường học của chúng tôi hỗ trợ các chương trình giảng dạy tại mỗi trường tốt như thế nào, và FCA, tình trạng vật chất của tòa nhà và các hệ thống của nó. Điểm kết hợp có trọng số là điểm trung bình có trọng số của hai điểm đó.`,
      ru: `При оценке школ округ принимает во внимание как ESA (насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе), так и FCA (физическое состояние здания и его систем). Взвешенный комбинированный балл представляет собой средневзвешенное значение этих двух баллов.`,
      sq: `Në vlerësimin e shkollave, Qarku merr në konsideratë si ESA-në, sa mirë ambientet e shkollës sonë mbështesin programet mësimore në çdo shkollë, ashtu edhe FCA, gjendjen fizike të ndërtesës dhe sistemet e saj. Rezultati i ponderuar i kombinuar është një mesatare e ponderuar e këtyre dy pikëve.`,
      km: `ក្នុងការវាយតម្លៃសាលារៀន ស្រុកត្រូវយកមកពិចារណាទាំង ESA ថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា និង FCA ស្ថានភាពរាងកាយនៃអគារ និងប្រព័ន្ធរបស់វា។ ពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់គឺជាមធ្យមទម្ងន់នៃពិន្ទុទាំងពីរនោះ។`,
      fr: `Lors de l'évaluation des écoles, le district prend en considération à la fois l'ESA, dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école, et la FCA, l'état physique du bâtiment et de ses systèmes. Le score combiné pondéré est une moyenne pondérée de ces deux scores.`,
      ar: `عند تقييم المدارس ، تأخذ المقاطعة في الاعتبار كلاً من ESA ، ومدى دعم مرافق مدرستنا للبرامج التعليمية في كل مدرسة ، و FCA ، والحالة المادية للمبنى وأنظمته. النتيجة المجمعة الموزونة هي متوسط ​​مرجح لهاتين الدرجتين.`,
      en: `In evaluating schools the District takes into consideration both the ESA, how well our school facilities support the instructional programs at each school, and the FCA, the physical condition of the building and its systems. The Weighted Combined Score is a weighted average of those two scores.`,
    },
    body13: {
      es: `Le asignamos un énfasis levemente más alto al puntaje de la ESA que al de la FCA porque creemos que la capacidad del edificio para respaldar los programas educativos debe tener un poco más de peso que la condición física del edificio. Cuanto más alto sea el puntaje ponderado combinado, mejor será la capacidad de la escuela para respaldar enseñando y aprendiendo.`,
      "zh-CN": `我们对 ESA 分数的重视程度略高于 FCA，因为我们认为建筑物支持教学计划的能力应该比建筑物的物理状况更受重视。综合加权分数越高，学校的支持能力就越好教学和学习。`,
      vi: `Chúng tôi chỉ định điểm ESA cao hơn một chút so với FCA vì chúng tôi tin rằng khả năng hỗ trợ các chương trình giảng dạy của tòa nhà nên được coi trọng hơn một chút so với tình trạng vật chất của tòa nhà. dạy và học.`,
      ru: `Мы уделили немного больше внимания баллу ESA, чем FCA, потому что считаем, что способности здания поддерживать учебные программы следует придавать немного больше значения, чем физическому состоянию здания. Чем выше комбинированный взвешенный балл, тем лучше способность школы поддерживать учить и учиться.`,
      sq: `Ne caktuam një theks pak më të lartë në rezultatin ESA mbi FCA sepse besojmë se aftësisë së ndërtesës për të mbështetur programet mësimore duhet t'i jepet pak më shumë peshë sesa gjendja fizike e ndërtesës. Sa më i lartë të jetë Rezultati i Ponderuar i Kombinuar, aq më i mirë është aftësia e shkollës për të mbështetur mësimdhënies dhe të nxënit.`,
      km: `យើងបានកំណត់ការសង្កត់ធ្ងន់ខ្ពស់ជាងនេះបន្តិចលើពិន្ទុ ESA លើ FCA ដោយសារតែយើងជឿថាសមត្ថភាពរបស់អគារក្នុងការគាំទ្រកម្មវិធីបង្រៀនគួរតែត្រូវបានផ្តល់ទម្ងន់ច្រើនជាងស្ថានភាពរាងកាយរបស់អគារបន្តិច។ ពិន្ទុទម្ងន់រួមកាន់តែខ្ពស់ សមត្ថភាពរបស់សាលាក្នុងការគាំទ្រកាន់តែប្រសើរ។ ការបង្រៀន និងការរៀន។`,
      fr: `Nous avons accordé une importance légèrement plus élevée au score ESA par rapport au FCA, car nous pensons que la capacité du bâtiment à soutenir les programmes d'enseignement devrait avoir un peu plus de poids que l'état physique du bâtiment. Plus le score pondéré combiné est élevé, meilleure est la capacité de l'école à soutenir enseigner et apprendre.`,
      ar: `لقد خصصنا تركيزًا أعلى قليلاً على درجة ESA مقارنة بـ FCA لأننا نعتقد أن قدرة المبنى على دعم البرامج التعليمية يجب أن تُعطى وزناً أكبر قليلاً من الحالة المادية للمبنى ، فكلما ارتفعت النتيجة المرجحة المجمعة ، كانت قدرة المدرسة على الدعم أفضل التعليم والتعلم.`,
      en: `We assigned a slightly higher emphasis on the ESA score over the FCA because we believe that the building’s ability to support instructional programs should be given slightly more weight than the building’s physical condition.The higher the Combined Weighted Score, the better the school’s ability to support teaching and learning.`,
    },
    body14: {
      es: `Veamos un ejemplo. Aquí tenemos una escuela con un puntaje de Evaluación de idoneidad educativa de 90, lo que significa que la instalación está bien diseñada para proporcionar y apoyar los programas educativos que se ofrecen. El puntaje de la evaluación de la condición de la instalación es 75, por lo que el edificio y/o algunos de sus sistemas se encuentran en condiciones aceptables y requieren reparaciones menores a moderadas. El puntaje combinado ponderado de las instalaciones es 84, lo que clasifica a la escuela como en buenas condiciones.`,
      "zh-CN": `让我们看一个例子。在这里，我们有一所学校的教育适宜性评估得分为 90，这意味着该设施经过精心设计，可以提供和支持所提供的教育计划。设施状况评估得分为 75，因此建筑物和/或其部分系统状况良好，需要进行小到中度维修。加权综合设施得分为 84，将学校归类为状况良好。`,
      vi: `Hãy xem một ví dụ. Ở đây chúng tôi có một trường với điểm Đánh giá Tính phù hợp Giáo dục là 90, có nghĩa là cơ sở được thiết kế tốt để cung cấp và hỗ trợ các chương trình giáo dục được cung cấp. Điểm Đánh giá Tình trạng Cơ sở vật chất là 75, vì vậy tòa nhà và / hoặc một số hệ thống của nó đang ở trong tình trạng tốt và cần sửa chữa từ nhỏ đến trung bình. Điểm Cơ sở Kết hợp Trọng số là 84, xếp loại trường là trong tình trạng tốt.`,
      ru: `Давайте посмотрим на пример. Здесь у нас есть школа с оценкой пригодности для образования 90 баллов, что означает, что объект хорошо спроектирован для обеспечения и поддержки предлагаемых образовательных программ. Оценка состояния объекта составляет 75 баллов, поэтому здание и/или некоторые его системы находятся в удовлетворительном состоянии и требуют ремонта от незначительного до среднего. Взвешенная комбинированная оценка объекта составляет 84, что означает, что школа находится в хорошем состоянии.`,
      sq: `Le të shohim një shembull. Këtu kemi një shkollë me një rezultat të Vlerësimit të Përshtatshmërisë Arsimore prej 90, që do të thotë se objekti është projektuar mirë për të siguruar dhe mbështetur programet arsimore të ofruara. Rezultati i vlerësimit të gjendjes së objektit është 75, kështu që ndërtesa dhe/ose disa nga sistemet e saj janë në gjendje të drejtë dhe kërkojnë riparime të vogla deri në mesatare. Rezultati i ponderuar i objektit të kombinuar është 84, i cili e klasifikon shkollën si në gjendje të mirë.`,
      km: `សូមក្រឡេកមើលឧទាហរណ៍មួយ។ នៅទីនេះយើងមានសាលារៀនមួយដែលមានពិន្ទុវាយតម្លៃភាពសមស្របនៃការអប់រំ 90 ដែលមានន័យថា កន្លែងនេះត្រូវបានរចនាឡើងយ៉ាងល្អដើម្បីផ្តល់ និងគាំទ្រដល់កម្មវិធីអប់រំដែលបានផ្តល់ជូន។ ពិន្ទុវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារគឺ 75 ដូច្នេះអគារ និង/ឬប្រព័ន្ធមួយចំនួនរបស់វាស្ថិតក្នុងស្ថានភាពសមរម្យ ហើយត្រូវការការជួសជុលតិចតួចដល់កម្រិតមធ្យម។ ពិន្ទុគ្រឿងបរិក្ខាររួមបញ្ចូលគ្នាដែលមានទម្ងន់គឺ 84 ដែលចាត់ថ្នាក់សាលាថាស្ថិតក្នុងស្ថានភាពល្អ។`,
      fr: `Prenons un exemple. Ici, nous avons une école avec un score d'évaluation de l'adéquation éducative de 90, ce qui signifie que l'établissement est bien conçu pour fournir et soutenir les programmes éducatifs proposés. Le score d'évaluation de l'état des installations est de 75, de sorte que le bâtiment et/ou certains de ses systèmes sont dans un état passable et nécessitent des réparations mineures à modérées. Le score combiné pondéré des installations est de 84, ce qui classe l'école comme étant en bon état.`,
      ar: `لنلقي نظرة على مثال. هنا لدينا مدرسة حصلت على درجة تقييم ملاءمة تعليمية 90 ، مما يعني أن المنشأة مصممة جيدًا لتوفير ودعم البرامج التعليمية المقدمة. درجة تقييم حالة المنشأة هي 75 ، وبالتالي فإن المبنى و / أو بعض أنظمته في حالة جيدة ويتطلب إصلاحًا بسيطًا إلى متوسط. درجة المنشأة المجمعة الموزونة هي 84 ، مما يصنف المدرسة على أنها في حالة جيدة.`,
      en: `Let’s look at an example. Here we have a school with an Educational Suitability Assessment score of 90, which means the facility is well designed to provide for and support the educational programs offered. The Facility Condition Assessment score is 75, so the building and / or some of its systems are in fair condition and require minor to moderate repair. The Weighted Combined Facility Score is 84, which classifies the school as being in good condition.`,
    },
    body15: {
      es: `Clasificar nuestras escuelas de esta manera nos permite identificar qué escuelas necesitan renovación o deben considerarse para reemplazo.`,
      "zh-CN": `以这种方式对我们的学校进行分类使我们能够确定哪些学校需要翻新或应该考虑更换。`,
      vi: `Việc phân loại các trường của chúng tôi theo cách này cho phép chúng tôi xác định những trường nào cần cải tạo hoặc cần được xem xét để thay thế.`,
      ru: `Классификация наших школ таким образом позволяет нам определить, какие школы нуждаются в ремонте или должны быть рассмотрены для замены.`,
      sq: `Klasifikimi i shkollave tona në këtë mënyrë na lejon të identifikojmë se cilat shkolla kanë nevojë për rinovim ose duhet të merren parasysh për zëvendësim.`,
      km: `ការចាត់ថ្នាក់សាលារបស់យើងតាមរបៀបនេះអនុញ្ញាតឱ្យយើងកំណត់ថាសាលារៀនណាដែលត្រូវការការជួសជុល ឬគួរតែត្រូវបានពិចារណាសម្រាប់ការជំនួស។`,
      fr: `Classer nos écoles de cette manière nous permet d'identifier les écoles qui ont besoin d'être rénovées ou dont le remplacement devrait être envisagé.`,
      ar: `يتيح لنا تصنيف مدارسنا بهذه الطريقة تحديد المدارس التي تحتاج إلى تجديد أو التي يجب التفكير فيها للاستبدال.`,
      en: `Classifying our schools in this manner allows us to identify which schools need renovation or should be considered for replacement. `,
    },
    title4: {
      es: `Pronóstico de Inscripción y Utilización`,
      "zh-CN": `招生预测和利用率`,
      vi: `Dự báo tuyển sinh và sử dụng`,
      ru: `Прогноз регистрации и использование`,
      sq: `Parashikimi dhe shfrytëzimi i regjistrimit`,
      km: `ការព្យាករណ៍ និងការប្រើប្រាស់ការចុះឈ្មោះ`,
      fr: `Prévision d'inscription et utilisation`,
      ar: `توقعات التسجيل والاستخدام`,
      en: `Enrollment Forecast & Utilization`,
    },
    body16: {
      es: `¡Hola! Mi nombre es Yvette y estoy aquí para compartir con ustedes el papel fundamental que desempeña la Inscripción de Estudiantes en el Proceso de Planificación de Instalaciones para el Distrito Escolar de Filadelfia.`,
      "zh-CN": `你好！我的名字是 Yvette，我在这里与您分享学生注册在费城学区的设施规划过程中发挥的关键作用。`,
      vi: `Xin chào! Tên tôi là Yvette, và tôi ở đây để chia sẻ với bạn vai trò quan trọng của việc Ghi danh cho Học sinh trong Quy trình Lập kế hoạch Cơ sở vật chất cho Học khu Philadelphia.`,
      ru: `Привет! Меня зовут Иветт, и я здесь, чтобы поделиться с вами важной ролью, которую набор учащихся играет в процессе планирования объектов для школьного округа Филадельфии.`,
      sq: `Përshëndetje! Emri im është Yvette dhe jam këtu për të ndarë me ju rolin kritik që luan Regjistrimi i Studentëve në Procesin e Planifikimit të Faciliteteve për Distriktin Shkollor të Filadelfias.`,
      km: `សួស្តី! ឈ្មោះរបស់ខ្ញុំគឺ Yvette ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកនូវតួនាទីសំខាន់ដែលការចុះឈ្មោះសិស្សដើរតួក្នុងដំណើរការរៀបចំផែនការសម្រាប់សាលាស្រុក Philadelphia ។`,
      fr: `Salut! Je m'appelle Yvette et je suis ici pour partager avec vous le rôle essentiel que joue l'inscription des élèves dans le processus de planification des installations pour le district scolaire de Philadelphie.`,
      ar: `أهلاً! اسمي إيفيت ، وأنا هنا لأشارككم الدور الحاسم الذي يلعبه تسجيل الطلاب في عملية تخطيط المرافق لمنطقة فيلادلفيا التعليمية.`,
      en: `Hi! My name is Yvette, and I’m here to share with you the critical role that Student Enrollment plays in the Facilities Planning Process for the School District of Philadelphia.`,
    },
    body17: {
      es: `La cantidad de estudiantes que una escuela puede albergar de manera segura y razonable depende del tamaño del edificio. Esta es la capacidad del edificio, que fue medida en cada escuela por un equipo de profesionales de Parsons Environmental and Infrastructure Group.`,
      "zh-CN": `一所学校可以安全合理地容纳的学生人数取决于建筑物的大小。这是建筑物的容量，由帕森斯环境和基础设施集团的专业团队在每所学校进行测量。`,
      vi: `Số lượng học sinh mà một trường có thể tiếp nhận một cách an toàn và hợp lý tùy thuộc vào quy mô của tòa nhà. Đây là năng lực của tòa nhà, được đo lường tại mỗi trường học bởi một nhóm chuyên gia từ Nhóm Cơ sở Hạ tầng và Môi trường Parsons.`,
      ru: `Количество учеников, которое школа может безопасно и разумно удерживать, зависит от размера здания. Это мощность здания, которую измеряла в каждой школе команда профессионалов Parsons Environmental and Infrastructure Group.`,
      sq: `Numri i nxënësve që një shkollë mund të mbajë në mënyrë të sigurt dhe të arsyeshme varet nga madhësia e ndërtesës. Ky është kapaciteti i ndërtesës, i cili është matur në çdo shkollë nga një ekip profesionistësh nga Parsons Environmental and Infrastructure Group.`,
      km: `ចំនួនសិស្សសាលាអាចរក្សាបានដោយសុវត្ថិភាព និងសមហេតុផលអាស្រ័យលើទំហំអគារ។ នេះគឺជាសមត្ថភាពរបស់អគារ ដែលត្រូវបានវាស់នៅសាលានីមួយៗដោយក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group។`,
      fr: `Le nombre d'élèves qu'une école peut raisonnablement et en toute sécurité accueillir dépend de la taille du bâtiment. Il s'agit de la capacité du bâtiment, qui a été mesurée dans chaque école par une équipe de professionnels du Parsons Environmental and Infrastructure Group.`,
      ar: `يعتمد عدد الطلاب الذين يمكن للمدرسة استيعابهم بأمان وبشكل معقول على حجم المبنى. هذه هي قدرة المبنى ، والتي تم قياسها في كل مدرسة من قبل فريق من المهنيين من Parsons Environmental and Infrastructure Group.`,
      en: `The number of students a school can safely and reasonably hold depends on the size of the building. This is the building’s capacity, which was measured at each school by a team of professionals from Parsons Environmental and Infrastructure Group.`,
    },
    body18: {
      es: `La inscripción de estudiantes es el número de estudiantes que asisten a cada escuela del distrito. La cantidad de fondos que recibe una escuela está determinada principalmente por la inscripción de estudiantes. Es importante que analicemos la inscripción de estudiantes de una escuela en comparación con el tamaño de su edificio. Esta es la tasa de utilización de una escuela.`,
      "zh-CN": `学生入学人数是每所学区学校就读的学生人数。学校获得的资助金额主要取决于学生入学率。重要的是，我们将学校的学生注册情况与其建筑物的大小进行比较。这是学校的利用率。`,
      vi: `Số học sinh ghi danh là số học sinh đang theo học tại mỗi trường của Học khu. Số tiền tài trợ mà một trường nhận được chủ yếu được xác định bởi Bộ phận Ghi danh Học sinh. Điều quan trọng là chúng ta phải xem xét Số lượng học sinh của một trường học so với quy mô của tòa nhà đó. Đây là Tỷ lệ Sử dụng của một trường học.`,
      ru: `Набор учащихся — это количество учащихся, посещающих каждую школу округа. Сумма финансирования, которую получает школа, в первую очередь определяется набором учащихся. Важно, чтобы мы сравнивали количество учащихся в школе с размером ее здания. Это коэффициент использования школы.`,
      sq: `Regjistrimi i nxënësve është numri i nxënësve që ndjekin çdo shkollë të rrethit. Shuma e financimit që merr një shkollë përcaktohet kryesisht nga Regjistrimi i Studentëve. Është e rëndësishme që të shikojmë Regjistrimin e Studentëve të një shkolle në krahasim me madhësinë e ndërtesës së saj. Kjo është Shkalla e Përdorimit të një shkolle.`,
      km: `ការចុះឈ្មោះសិស្ស គឺជាចំនួនសិស្សដែលចូលរៀននៅសាលាស្រុកនីមួយៗ។ ចំនួនមូលនិធិដែលសាលាទទួលបានគឺត្រូវបានកំណត់ជាចម្បងដោយការចុះឈ្មោះសិស្ស។ វាជារឿងសំខាន់ដែលយើងត្រូវពិនិត្យមើលការចុះឈ្មោះសិស្សរបស់សាលា បើប្រៀបធៀបទៅនឹងទំហំអគាររបស់វា។ នេះគឺជាអត្រាប្រើប្រាស់របស់សាលា។`,
      fr: `L'inscription des élèves est le nombre d'élèves fréquentant chaque école du district. Le montant du financement qu'une école reçoit est principalement déterminé par l'inscription des élèves. Il est important que nous examinions l'effectif scolaire d'une école par rapport à la taille de son bâtiment. Il s'agit du taux d'utilisation d'une école.`,
      ar: `تسجيل الطلاب هو عدد الطلاب الملتحقين بكل مدرسة من مدارس المنطقة. يتم تحديد مبلغ التمويل الذي تتلقاه المدرسة بشكل أساسي من خلال تسجيل الطلاب. من المهم أن ننظر إلى التحاق الطلاب بالمدرسة مقارنة بحجم المبنى. هذا هو معدل استخدام المدرسة.`,
      en: `Student Enrollment is the number of students attending each District school. The amount of funding a school receives is primarily determined by the Student Enrollment. It is important that we look at a school’s Student Enrollment compared to the size of its building. This is a school’s Utilization Rate.`,
    },
    body19: {
      es: `Por ejemplo, si una escuela tiene una inscripción baja, en comparación con la cantidad de cupos disponibles, digamos 450 estudiantes de los 800 cupos disponibles, entonces el edificio se considera infrautilizado e ineficiente.`,
      "zh-CN": `例如，如果一所学校的入学率低于可用座位数，假设 800 个座位中有 450 名学生，那么该建筑被认为未充分利用且效率低下。`,
      vi: `Ví dụ: nếu một trường học có số lượng học sinh đăng ký thấp, so với số lượng chỗ ngồi có sẵn, giả sử có 450 sinh viên trong số 800 chỗ ngồi còn trống, thì tòa nhà đó được coi là sử dụng kém và kém hiệu quả.`,
      ru: `Например, если в школе низкий набор учащихся по сравнению с количеством доступных мест, скажем, 450 учеников из 800 доступных мест, то здание считается малоиспользуемым и неэффективным.`,
      sq: `Për shembull, nëse një shkollë ka regjistrim të ulët, krahasuar me numrin e vendeve të disponueshme, le të themi 450 nxënës nga 800 vende të disponueshme, atëherë ndërtesa konsiderohet e pashfrytëzuar dhe joefikase.`,
      km: `ជាឧទាហរណ៍ ប្រសិនបើសាលាមួយមានការចុះឈ្មោះចូលរៀនទាប បើប្រៀបធៀបនឹងចំនួនកៅអីដែលមាន ឧបមាថាសិស្ស 450 នាក់ក្នុងចំណោម 800 កៅអីដែលអាចប្រើបាន នោះអគារនេះត្រូវបានគេចាត់ទុកថាមិនសូវប្រើប្រាស់ និងគ្មានប្រសិទ្ធភាព។`,
      fr: `Par exemple, si une école a un faible taux d'inscription par rapport au nombre de places disponibles, disons 450 élèves sur 800 places disponibles, alors le bâtiment est considéré comme sous-utilisé et inefficace.`,
      ar: `على سبيل المثال ، إذا كان معدل الالتحاق بالمدرسة منخفضًا ، مقارنةً بعدد المقاعد المتاحة ، فلنفترض أن 450 طالبًا من بين 800 مقعد متاح ، فإن المبنى يعتبر غير مستغل وغير فعال.`,
      en: `For example, if a school has low enrollment, compared to the number of seats available, let’s say 450 students out of 800 seats available, then the building is considered under-utilized and inefficient.`,
    },
    body20: {
      es: `Tener escuelas infrautilizadas dificulta tener suficientes fondos para pagar maestros, recursos y otros apoyos. También puede ser un desafío cuando se considera que una escuela está sobreutilizada porque los edificios abarrotados pueden afectar negativamente el rendimiento académico y la seguridad de los estudiantes, obstaculizar la capacidad de la administración para programar las aulas de manera efectiva y sobrecargar los equipos y sistemas del edificio.`,
      "zh-CN": `学校利用率低下，很难有足够的资金来支付教师、资源和其他支持。当学校被认为过度使用时，这也可能是一个挑战，因为拥挤的建筑物会对学生的学习成绩和安全产生负面影响，阻碍政府有效安排教室的能力，并使建筑物的设备和系统负担过重。`,
      vi: `Có những trường học chưa được sử dụng đầy đủ gây khó khăn cho việc có đủ kinh phí để trả cho giáo viên, nguồn lực và các hỗ trợ khác. Nó cũng có thể là một thách thức khi một trường học bị coi là sử dụng quá mức vì các tòa nhà đông đúc có thể ảnh hưởng tiêu cực đến kết quả học tập và sự an toàn của học sinh, cản trở khả năng sắp xếp lịch học một cách hiệu quả của ban giám đốc và gây quá tải cho các thiết bị và hệ thống của tòa nhà.`,
      ru: `Недостаточное использование школ затрудняет получение достаточного финансирования для оплаты учителей, ресурсов и другой поддержки. Это также может быть проблемой, когда школа считается перегруженной, потому что переполненные здания могут негативно повлиять на академическую успеваемость и безопасность учащихся, помешать администрации эффективно планировать классные комнаты и перегрузить оборудование и системы здания.`,
      sq: `Të paturit e shkollave të pashfrytëzuara e bën të vështirë të kesh fonde të mjaftueshme për të paguar mësuesit, burimet dhe mbështetje të tjera. Mund të jetë gjithashtu një sfidë kur një shkollë konsiderohet e mbishfrytëzuar, sepse ndërtesat e mbushura me njerëz mund të ndikojnë negativisht në performancën akademike dhe sigurinë e studentëve, të pengojnë aftësinë e administratës për të planifikuar në mënyrë efektive klasat dhe të mbingarkojnë pajisjet dhe sistemet e ndërtesës.`,
      km: `ការមានសាលារៀនដែលប្រើប្រាស់មិនគ្រប់គ្រាន់ធ្វើឱ្យមានការលំបាកក្នុងការមានថវិកាគ្រប់គ្រាន់ដើម្បីចំណាយសម្រាប់គ្រូបង្រៀន ធនធាន និងជំនួយផ្សេងៗទៀត។ វាក៏អាចជាបញ្ហាប្រឈមផងដែរ នៅពេលដែលសាលារៀនត្រូវបានចាត់ទុកថាប្រើប្រាស់ហួសកម្រិត ដោយសារអគារដែលមានមនុស្សច្រើនអាចជះឥទ្ធិពលអវិជ្ជមានដល់ដំណើរការសិក្សា និងសុវត្ថិភាពរបស់សិស្ស រារាំងសមត្ថភាពរបស់រដ្ឋបាលក្នុងការកំណត់កាលវិភាគថ្នាក់រៀនប្រកបដោយប្រសិទ្ធភាព និងលើសបន្ទុកលើឧបករណ៍ និងប្រព័ន្ធរបស់អគារ។`,
      fr: `Le fait d'avoir des écoles sous-utilisées rend difficile d'avoir suffisamment de financement pour payer les enseignants, les ressources et d'autres soutiens. Cela peut également être un défi lorsqu'une école est considérée comme surutilisée, car les bâtiments surpeuplés peuvent affecter négativement les performances scolaires et la sécurité des élèves, entraver la capacité de l'administration à planifier efficacement les salles de classe et surcharger les équipements et les systèmes du bâtiment.`,
      ar: `وجود مدارس غير مستغلة بشكل كاف يجعل من الصعب الحصول على تمويل كاف لدفع تكاليف المعلمين والموارد وغيرها من أشكال الدعم. يمكن أن يمثل تحديًا أيضًا عندما يتم اعتبار المدرسة مستغلة بشكل مفرط لأن المباني المزدحمة يمكن أن تؤثر سلبًا على الأداء الأكاديمي للطلاب وسلامتهم ، وتعيق قدرة الإدارة على جدولة الفصول الدراسية بشكل فعال ، وتزيد العبء على معدات وأنظمة المبنى.`,
      en: `Having under-utilized schools makes it difficult to have enough funding to pay for teachers, resources and other supports. It can also be a challenge when a school is considered over-utilized because crowded buildings can negatively affect students’ academic performance and safety, hinder the administration’s ability to schedule classrooms effectively, and over-burden the building’s equipment and systems.`,
    },
    body21: {
      es: `Para propósitos de planificación, también tomamos en cuenta cuál es la Utilización Escolar probable en el futuro. Los expertos de FLO Analytics proporcionaron pronósticos de inscripción para todo el Distrito.`,
      "zh-CN": `出于规划目的，我们还考虑了未来的学校利用率。 FLO Analytics 的专家提供了整个学区的入学率预测。`,
      vi: `Vì mục đích lập kế hoạch, chúng tôi cũng tính đến việc Sử dụng Trường học có khả năng như thế nào trong tương lai. Các chuyên gia tại FLO Analytics đã cung cấp dự báo tuyển sinh cho toàn Học khu.`,
      ru: `В целях планирования мы также принимаем во внимание то, каким, вероятно, будет использование школы в будущем. Эксперты FLO Analytics предоставили прогнозы набора для всего округа.`,
      sq: `Për qëllime planifikimi, ne gjithashtu marrim parasysh se çfarë mund të jetë Shfrytëzimi i Shkollës në të ardhmen. Ekspertët në FLO Analytics dhanë parashikimet e regjistrimit për të gjithë Distriktin.`,
      km: `សម្រាប់គោលបំណងធ្វើផែនការ យើងក៏គិតផងដែរអំពីអ្វីដែលការប្រើប្រាស់សាលាទំនងជានឹងមាននាពេលអនាគត។ អ្នកជំនាញនៅ FLO Analytics បានផ្តល់ការព្យាករណ៍សម្រាប់ការចុះឈ្មោះសម្រាប់សង្កាត់ទាំងមូល។`,
      fr: `À des fins de planification, nous tenons également compte de ce que l'utilisation de l'école est susceptible d'être à l'avenir. Les experts de FLO Analytics ont fourni des prévisions d'inscriptions pour l'ensemble du district.`,
      ar: `لأغراض التخطيط ، نأخذ في الاعتبار أيضًا ما من المحتمل أن يكون استخدام المدرسة في المستقبل. قدم الخبراء في FLO Analytics توقعات التسجيل للمنطقة بأكملها.`,
      en: `For planning purposes we also take into account what School Utilization is likely to be in the future. Experts at FLO Analytics provided enrollment forecasts for the entire District.`,
    },
    body22: {
      es: `Por ejemplo, si durante los próximos cinco años se proyecta que una escuela tenga 900 estudiantes, pero la escuela solo puede albergar de manera segura a 800 estudiantes, entonces la tasa de utilización del edificio proyectada es del 113 %, lo que se clasificaría como Inadecuado.`,
      "zh-CN": `例如，如果未来五年一所学校预计有 900 名学生，但学校只能安全地容纳 800 名学生，那么预计的建筑利用率为 113%，这将被归类为不足。`,
      vi: `Ví dụ, nếu trong năm năm tới, một trường dự kiến ​​có 900 sinh viên, nhưng trường đó chỉ có thể chứa 800 sinh viên một cách an toàn, thì tỷ lệ sử dụng tòa nhà dự kiến ​​là 113%, sẽ được xếp vào loại Không đủ.`,
      ru: `Например, если в течение следующих пяти лет прогнозируется, что в школе будет обучаться 900 учеников, но школа может безопасно вместить только 800 учеников, то прогнозируемый уровень использования здания составляет 113%, что будет классифицироваться как неудовлетворительное.`,
      sq: `Për shembull, nëse gjatë pesë viteve të ardhshme një shkollë parashikohet të ketë 900 nxënës, por shkolla mund të mbajë të sigurt vetëm 800 nxënës, atëherë shkalla e parashikuar e shfrytëzimit të ndërtesave është 113%, e cila do të klasifikohej si e pamjaftueshme.`,
      km: `ជាឧទាហរណ៍ ប្រសិនបើក្នុងរយៈពេល 5 ឆ្នាំខាងមុខ សាលារៀនមួយត្រូវបានព្យាករណ៍ថាមានសិស្ស 900 នាក់ ប៉ុន្តែសាលាអាចផ្ទុកសិស្សបានត្រឹមតែ 800 នាក់ប៉ុណ្ណោះ នោះអត្រាប្រើប្រាស់អគារដែលបានព្យាករណ៍គឺ 113% ដែលនឹងត្រូវបានចាត់ថ្នាក់ថាមិនគ្រប់គ្រាន់។`,
      fr: `Par exemple, si au cours des cinq prochaines années, une école devrait avoir 900 élèves, mais que l'école ne peut accueillir en toute sécurité que 800 élèves, alors le taux d'utilisation prévu du bâtiment est de 113 %, ce qui serait classé comme Inadéquat.`,
      ar: `على سبيل المثال ، إذا كان من المتوقع على مدار السنوات الخمس المقبلة أن تضم المدرسة 900 طالب ، ولكن يمكن للمدرسة استيعاب 800 طالب فقط بأمان ، فإن معدل استخدام المبنى المتوقع هو 113٪ ، والتي سيتم تصنيفها على أنها غير كافية.`,
      en: `For example, if over the next five years a school is projected to have 900 students, but the school can only safely hold 800 students, then the projected building utilization rate is 113%, which would be classified as Inadequate.`,
    },
    body23: {
      es: `Cualquier escuela con una Utilización de Inscripción Proyectada para el año escolar 2025 a 2026 de menos del 60 % o más del 110 % es una prioridad clave que debe abordar el Distrito.`,
      "zh-CN": `任何 2025 至 2026 学年的预计入学率低于 60% 或高于 110% 的学校都是学区需要解决的关键优先事项。`,
      vi: `Bất kỳ trường nào có Tỷ lệ Sử dụng Ghi danh Dự kiến ​​cho năm học 2025 đến 2026 dưới 60% hoặc lớn hơn 110% là ưu tiên chính mà Học khu phải giải quyết.`,
      ru: `Любая школа с прогнозируемым использованием учащихся на 2025-2026 учебный год менее 60% или более 110% является ключевым приоритетом для округа.`,
      sq: `Çdo shkollë me një shfrytëzim të parashikuar të regjistrimit për vitin shkollor 2025 deri në 2026 prej më pak se 60% ose më shumë se 110% është një prioritet kyç për të trajtuar Distriktin.`,
      km: `សាលាណាមួយដែលមានគម្រោងប្រើប្រាស់ការចុះឈ្មោះចូលរៀនសម្រាប់ឆ្នាំសិក្សា 2025 ដល់ឆ្នាំ 2026 តិចជាង 60% ឬធំជាង 110% គឺជាអាទិភាពសំខាន់សម្រាប់ស្រុកដើម្បីដោះស្រាយ។`,
      fr: `Toute école dont l'utilisation prévue des inscriptions pour l'année scolaire 2025 à 2026 est inférieure à 60 % ou supérieure à 110 % est une priorité clé que le district doit traiter.`,
      ar: `تعتبر أي مدرسة ذات استخدام تسجيل متوقع للعام الدراسي 2025 حتى 2026 أقل من 60٪ أو أكثر من 110٪ أولوية رئيسية للمنطقة التعليمية`,
      en: `Any school with a Projected Enrollment Utilization for school year 2025 through 2026 of less than 60% or greater than 110% is a key priority for the District to address.`,
    },
  },
  webtool: {
    legend1: {
      es: `Escuelas`,
      "zh-CN": `学校`,
      vi: `Trường học`,
      ru: `Школы`,
      sq: `Shkollat`,
      km: `សាលារៀន`,
      fr: `Écoles`,
      ar: `المدارس`,
      en: `Schools`,
    },
    legend2: {
      es: `Escuela primaria`,
      "zh-CN": `小学`,
      vi: `Trường tiểu học`,
      ru: `Начальная школа`,
      sq: `Shkollë fillore`,
      km: `បឋមសិក្សា`,
      fr: `École primaire`,
      ar: `مدرسة ابتدائية`,
      en: `Elementary`,
    },
    legend3: {
      es: `Primaria-Medio`,
      "zh-CN": `初中`,
      vi: `Sơ cấp-Trung học`,
      ru: `Начальный-средний`,
      sq: `Fillore-Mesme`,
      km: `បឋមសិក្សា - កណ្តាល`,
      fr: `Primaire-Moyen`,
      ar: `الابتدائية والمتوسطة`,
      en: `Elementary-Middle`,
    },
    legend4: {
      es: `Medio`,
      "zh-CN": `中间`,
      vi: `Ở giữa`,
      ru: `Середина`,
      sq: `E mesme`,
      km: `កណ្តាល`,
      fr: `Milieu`,
      ar: `وسط`,
      en: `Middle`,
    },
    legend5: {
      es: `Medio-Alto`,
      "zh-CN": `中高`,
      vi: `Trung cao`,
      ru: `средний-высокий`,
      sq: `Mesme-Lartë`,
      km: `មធ្យម-ខ្ពស់។`,
      fr: `Moyen-élevé`,
      ar: `منتصف عالية`,
      en: `Middle-High`,
    },
    legend6: {
      es: `Alto`,
      "zh-CN": `高的`,
      vi: `Cao`,
      ru: `Высоко`,
      sq: `Lartë`,
      km: `ខ្ពស់`,
      fr: `Haute`,
      ar: `متوسط`,
      en: `High`,
    },
    legend7: {
      es: `Elemental-Medio-Alto`,
      "zh-CN": `初中高中`,
      vi: `Tiểu học-Trung học phổ thông`,
      ru: `Начальный-средний-высокий`,
      sq: `Fillore-Mes-I lartë`,
      km: `បឋមសិក្សា - កណ្តាល - ខ្ពស់។`,
      fr: `Primaire-Moyen-High`,
      ar: `الابتدائية والمتوسطة`,
      en: `Elementary-Middle-High`,
    },
    legend8: {
      es: `Límite del distrito`,
      "zh-CN": `区界`,
      vi: `Ranh giới quận`,
      ru: `Граница района`,
      sq: `Kufiri i rrethit`,
      km: `ព្រំប្រទល់ស្រុក`,
      fr: `Limite de district`,
      ar: `حدود المنطقة`,
      en: `District Boundary`,
    },
    accordion_title1: {
      es: `Evaluación de las condiciones de las instalaciones`,
      "zh-CN": `设施条件评估`,
      vi: `Đánh giá điều kiện cơ sở vật chất`,
      ru: `Оценка состояния объектов`,
      sq: `Vlerësimi i kushteve të objekteve`,
      km: `ការវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ`,
      fr: `Évaluation de l'état des installations`,
      ar: `تقييم ظروف المرافق`,
      en: `Facility Conditions Assessment`,
    },
    accordion_body1: {
      es: `La Evaluación de las condiciones de las instalaciones (FCA) es una puntuación de 0 a 100 que mide la condición física de los edificios y sistemas escolares. Cuanto mayor sea el número, más tiempo estará el edificio al servicio de la enseñanza y el aprendizaje para el personal y los estudiantes.`,
      "zh-CN": `设施条件评估 (FCA) 是一个从 0 到 100 的分数，用于衡量学校建筑和系统的物理状况。 数字越高，大楼为教职工和学生提供教学服务的时间就越长。`,
      vi: `Đánh giá Điều kiện Cơ sở vật chất (FCA) là điểm từ 0 đến 100 để đo tình trạng vật chất của các tòa nhà và hệ thống của trường học. Số lượng càng cao thì công trình càng lâu dài để phục vụ cho việc giảng dạy và học tập cho cán bộ nhân viên và học sinh.`,
      ru: `Оценка условий объектов (FCA) — это оценка от 0 до 100, которая измеряет физическое состояние школьных зданий и систем. Чем выше число, тем дольше здание будет использоваться для обучения и обучения персонала и студентов.`,
      sq: `Vlerësimi i kushteve të objekteve (FCA) është një rezultat nga 0 në 100 që mat gjendjen fizike të ndërtesave dhe sistemeve të shkollave. Sa më i madh të jetë numri, aq më gjatë do të jetë ndërtesa në shërbim të mësimdhënies dhe të nxënit për stafin dhe studentët.`,
      km: `ការវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ (FCA) គឺជាពិន្ទុពី 0 ដល់ 100 ដែលវាស់វែងស្ថានភាពរាងកាយនៃអគារ និងប្រព័ន្ធរបស់សាលារៀន។ ចំនួនកាន់តែខ្ពស់ អគារនេះកាន់តែប្រើប្រាស់បានយូរក្នុងការបម្រើការបង្រៀន និងការរៀនសម្រាប់បុគ្គលិក និងសិស្ស។`,
      fr: `L'évaluation de l'état des installations (FCA) est un score de 0 à 100 qui mesure l'état physique des bâtiments et des systèmes des écoles. Plus le nombre est élevé, plus longtemps le bâtiment sera au service de l'enseignement et de l'apprentissage pour le personnel et les étudiants.`,
      ar: `تقييم ظروف المرافق (FCA) عبارة عن درجة من 0 إلى 100 تقيس الحالة المادية لمباني وأنظمة المدارس. كلما زاد العدد ، كلما طالت مدة خدمة المبنى للتدريس والتعلم للموظفين والطلاب.`,
      en: `Facilities Conditions Assessment (FCA) is a score from 0 to 100 that measures the physical condition of schools’ buildings and systems. The higher the number, the longer the building will be in service to teaching and learning for staff and students.`,
    },
    accordion_body2: {
      es: `Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para identificar cualquier problema estructural y/o secciones de una instalación que necesiten reparación o reemplazo.`,
      "zh-CN": `为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走遍了该地区的每一所学校，以确定任何结构问题和/或需要维修或更换的设施部分。`,
      vi: `Để xác định điều này, một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để xác định bất kỳ vấn đề nào về cấu trúc và / hoặc các bộ phận của cơ sở cần sửa chữa hoặc thay thế.`,
      ru: `Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу округа, чтобы выявить любые структурные проблемы и/или участки объекта, которые нуждаются в ремонте или замене.`,
      sq: `Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të identifikuar çdo problem strukturor dhe/ose seksion të një objekti që kanë nevojë për riparim ose zëvendësim.`,
      km: `ដើម្បីកំណត់បញ្ហានេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីកំណត់បញ្ហារចនាសម្ព័ន្ធ និង/ឬផ្នែកនៃកន្លែងដែលត្រូវការការជួសជុល ឬជំនួស។`,
      fr: `Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour identifier tout problème structurel et / ou section d'une installation nécessitant une réparation ou un remplacement.`,
      ar: `لتحديد ذلك ، قام فريق من المتخصصين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتحديد أي مشاكل هيكلية و / أو أقسام بالمنشأة التي تحتاج إلى إصلاح أو استبدال.`,
      en: `To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to identify any structural problems and/or sections of a facility that are in need of repair or replacement.`,
    },
    accordion_body22: {
      es: `Actualmente, varios edificios están experimentando renovaciones importantes y, por lo tanto, actualmente no cuentan con las condiciones de las instalaciones o puntajes combinados ponderados.`,
      "zh-CN": `目前有几座建筑物正在进行重大翻修，因此目前没有设施条件或加权综合分数。`,
      vi: `Một số tòa nhà hiện đang được cải tạo lớn và do đó hiện không có điều kiện cơ sở vật chất hoặc điểm tổng hợp có trọng số.`,
      ru: `В нескольких зданиях в настоящее время проводится капитальный ремонт, и поэтому в настоящее время они не имеют условий для обслуживания или взвешенных комбинированных баллов.`,
      sq: `Disa ndërtesa janë aktualisht duke iu nënshtruar rinovimeve të mëdha dhe për këtë arsye aktualisht nuk kanë kushte objektesh ose rezultate të kombinuara të ponderuara.`,
      km: `បច្ចុប្បន្ននេះ អគារជាច្រើនកំពុងដំណើរការជួសជុលធំ ហើយដូច្នេះបច្ចុប្បន្នមិនមានលក្ខខណ្ឌបរិក្ខារបរិក្ខារ ឬពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់។`,
      fr: `Plusieurs bâtiments font actuellement l'objet de rénovations majeures et ne disposent donc pas actuellement de conditions d'installations ou de scores combinés pondérés.`,
      ar: `تخضع العديد من المباني حاليًا لعمليات تجديد رئيسية ، وبالتالي لا تحتوي حاليًا على شروط مرافق أو درجات مجمعة مرجحة.`,
      en: `Several buildings are currently undergoing major renovations and therefore do not currently have facilities conditions or weighted combined scores.`,
    },
    accordion_title2: {
      es: `Evaluación de la idoneidad educativa`,
      "zh-CN": `教育适宜性评估`,
      vi: `Đánh giá tính phù hợp giáo dục`,
      ru: `Оценка пригодности для образования`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ`,
      fr: `Évaluation de la pertinence scolaire`,
      ar: `تقييم الملاءمة التعليمية`,
      en: `Educational Suitability Assessment`,
    },
    accordion_body3: {
      es: `La Evaluación de idoneidad educativa (ESA) es una puntuación de 0 a 100 que mide qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela. Cuanto más alto sea el puntaje, mejor el edificio satisface las necesidades del personal y los estudiantes.`,
      "zh-CN": `教育适宜性评估 (ESA) 是一个从 0 到 100 的分数，用于衡量我们的学校设施如何支持每所学校的教学计划。 分数越高，建筑越能满足教职员工和学生的需求。`,
      vi: `Đánh giá Tính phù hợp Giáo dục (ESA) là điểm từ 0 đến 100 để đo lường mức độ cơ sở vật chất trường học của chúng tôi hỗ trợ các chương trình giảng dạy tại mỗi trường tốt như thế nào. Điểm càng cao, tòa nhà càng đáp ứng tốt nhu cầu của nhân viên và sinh viên.`,
      ru: `Оценка пригодности для обучения (ESA) — это оценка от 0 до 100, которая измеряет, насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе. Чем выше балл, тем лучше здание отвечает потребностям сотрудников и студентов.`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore (ESA) është një rezultat nga 0 në 100 që mat se sa mirë ambientet e shkollës sonë mbështesin programet mësimore në secilën shkollë. Sa më i lartë të jetë rezultati, aq më mirë ndërtesa plotëson nevojat e stafit dhe studentëve.`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ (ESA) គឺជាពិន្ទុពី 0 ដល់ 100 ដែលវាស់វែងថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា។ ពិន្ទុកាន់តែខ្ពស់ អគារកាន់តែល្អបំពេញតម្រូវការរបស់បុគ្គលិក និងសិស្ស។`,
      fr: `L'évaluation de l'adéquation éducative (ESA) est un score de 0 à 100 qui mesure dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école. Plus le score est élevé, mieux le bâtiment répond aux besoins du personnel et des étudiants.`,
      ar: `تقييم الملاءمة التعليمية (ESA) عبارة عن درجة من 0 إلى 100 تقيس مدى جودة دعم مرافق مدرستنا للبرامج التعليمية في كل مدرسة. كلما زادت النتيجة ، كان المبنى يلبي احتياجات الموظفين والطلاب بشكل أفضل.`,
      en: `Educational Suitability Assessment (ESA) is a score from 0 to 100 that measures how well our school facilities support the instructional programs at each school. The higher the score, the better the building meets the needs of staff and students.`,
    },
    accordion_body4: {
      es: `Para determinar este puntaje, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para evaluar el entorno de aprendizaje, el tamaño, la ubicación, el almacenamiento y el equipo en cada espacio dentro de la escuela.`,
      "zh-CN": `为了确定这个分数，Parsons Environmental and Infrastructure Group 的一组专业人士走访了该地区的每所学校，以评估学校内每个空间的学习环境、规模、位置、存储和设备。`,
      vi: `Để xác định điểm số này, một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để đánh giá môi trường học tập, quy mô, vị trí, kho chứa và thiết bị trong từng không gian trong trường.`,
      ru: `Чтобы определить этот балл, команда профессионалов из Parsons Environmental and Infrastructure Group обошла каждую школу в округе, чтобы оценить учебную среду, размер, расположение, хранение и оборудование в каждом пространстве школы.`,
      sq: `Për të përcaktuar këtë rezultat, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaluan nëpër secilën shkollë në rreth për të vlerësuar mjedisin mësimor, madhësinë, vendndodhjen, ruajtjen dhe pajisjet në çdo hapësirë brenda shkollës.`,
      km: `ដើម្បីកំណត់ពិន្ទុនេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីវាយតម្លៃបរិយាកាសសិក្សា ទំហំ ទីតាំង កន្លែងស្តុកទុក និងឧបករណ៍នៅក្នុងកន្លែងនីមួយៗនៅក្នុងសាលា។`,
      fr: `Pour déterminer ce score, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour évaluer l'environnement d'apprentissage, la taille, l'emplacement, le stockage et l'équipement dans chaque espace de l'école.`,
      ar: `لتحديد هذه النتيجة ، قام فريق من المتخصصين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتقييم بيئة التعلم والحجم والموقع والتخزين والمعدات في كل مساحة داخل المدرسة.`,
      en: `To determine this score, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to assess the learning environment, size, location, storage, and equipment in each space within the school.`,
    },
    accordion_body5: {
      es: `Para cada una de las secciones a continuación, se muestran puntajes del 1 al 5, donde 1 significa excelente y 5 significa no tan excelente. Los comentarios explican todas las puntuaciones inferiores a "1".`,
      "zh-CN": `对于以下每个部分，分数从 1 到 5 显示，其中 1 表示非常好，5 表示不太好。 注释解释了所有小于“1”的分数。`,
      vi: `Đối với mỗi phần bên dưới, điểm số được hiển thị từ 1-5 trong đó 1 nghĩa là Tuyệt vời và 5 nghĩa là Không quá tuyệt. Nhận xét giải thích tất cả các điểm nhỏ hơn "1".`,
      ru: `Для каждого из разделов ниже показаны баллы от 1 до 5, где 1 означает отлично, а 5 означает не очень хорошо. Комментарии объясняют все оценки меньше «1».`,
      sq: `Për secilin nga seksionet e mëposhtme, rezultatet tregohen nga 1-5 ku 1 do të thotë e shkëlqyer dhe 5 do të thotë Jo aq e shkëlqyer. Komentet shpjegojnë të gjitha pikët më pak se "1".`,
      km: `សម្រាប់ផ្នែកនីមួយៗខាងក្រោម ពិន្ទុត្រូវបានបង្ហាញពី 1-5 ដែល 1 មានន័យថាអស្ចារ្យ និង 5 មានន័យថាមិនអស្ចារ្យ។ មតិយោបល់ពន្យល់ពីពិន្ទុទាំងអស់តិចជាង "1" ។`,
      fr: `Pour chacune des sections ci-dessous, les scores sont affichés de 1 à 5, où 1 signifie excellent et 5 signifie pas si excellent. Les commentaires expliquent tous les scores inférieurs à "1".`,
      ar: `لكل قسم من الأقسام أدناه ، يتم عرض الدرجات من 1 إلى 5 حيث 1 تعني عظيم و 5 تعني ليست عظيمة. توضح التعليقات جميع الدرجات الأقل من "1".`,
      en: `For each of the sections below, scores are shown from 1-5 where 1 means Great and 5 means Not So Great. Comments explain all scores less than "1".`,
    },
    accordion_title3: {
      es: `Puntuación combinada ponderada`,
      "zh-CN": `加权综合得分`,
      vi: `Điểm kết hợp có trọng số`,
      ru: `Взвешенный комбинированный балл`,
      sq: `Rezultati i ponderuar i kombinuar`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដោយថ្លឹងថ្លែង`,
      fr: `Note combinée pondérée`,
      ar: `النتيجة المجمعة المرجحة`,
      en: `Weighted Combined Score`,
    },
    accordion_body6: {
      es: `El puntaje combinado ponderado (WCS) es un puntaje ponderado de 0 a 100 que calculó el Distrito Escolar de Filadelfia (basado en la Evaluación de idoneidad educativa y la Evaluación de la condición de las instalaciones para ayudar a identificar qué escuelas tienen la mayor necesidad de renovación o deben considerarse para reemplazo La puntuación es un promedio ponderado del 60 % de evaluación de la idoneidad educativa y del 40 % del índice de condiciones de las instalaciones.`,
      "zh-CN": `加权综合分数 (WCS) 是费城学区计算的从 0 到 100 的加权分数（基于教育适宜性评估和设施状况评估，以帮助确定哪些学校最需要翻新或应考虑更换 . 该分数是 60% 的教育适宜性评估和 40% 的设施条件指数的加权平均值。`,
      vi: `Điểm Kết hợp Có Trọng số (WCS) là điểm có trọng số từ 0 đến 100 mà Học khu Philadelphia đã tính toán (dựa trên Đánh giá Tính phù hợp Giáo dục và Đánh giá Điều kiện Cơ sở vật chất để giúp xác định trường nào có nhu cầu cải tạo cao nhất hoặc cần được xem xét để thay thế . Điểm là trung bình có trọng số của 60% Đánh giá Mức độ Phù hợp Giáo dục và 40% Chỉ số Điều kiện Cơ sở vật chất.`,
      ru: `Взвешенный комбинированный балл (WCS) представляет собой взвешенный балл от 0 до 100, рассчитанный школьным округом Филадельфии (на основе оценки пригодности для образования и оценки состояния помещений, чтобы помочь определить, какие школы больше всего нуждаются в ремонте или должны быть рассмотрены для замены). , Оценка представляет собой средневзвешенное значение 60 % оценки пригодности для образования и 40 % индекса состояния помещений.`,
      sq: `Rezultati i ponderuar i kombinuar (WCS) është një pikë e ponderuar nga 0 në 100 që Distrikti Shkollor i Filadelfisë e ka llogaritur (bazuar në Vlerësimin e Përshtatshmërisë së Arsimit dhe Vlerësimin e Gjendjes së Objekteve për të ndihmuar në identifikimin se cilat shkolla kanë nevojën më të madhe për rinovim ose duhet të merren parasysh për zëvendësim Rezultati është mesatarja e ponderuar prej 60% Vlerësimi i Përshtatshmërisë Arsimore dhe 40% Indeksi i kushteve të objekteve.`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់ (WCS) គឺជាពិន្ទុដែលមានទម្ងន់ពី 0 ដល់ 100 ដែលសាលាស្រុក Philadelphia បានគណនា (ផ្អែកលើការវាយតម្លៃភាពសមស្របនៃការអប់រំ និងលក្ខខណ្ឌបរិក្ខារដើម្បីជួយកំណត់ថាសាលារៀនណាដែលមានតម្រូវការខ្ពស់បំផុតសម្រាប់ការជួសជុល ឬគួរតែត្រូវបានពិចារណាជំនួស ពិន្ទុគឺជាមធ្យមទម្ងន់នៃការវាយតម្លៃសមភាពនៃការអប់រំ 60% និងសន្ទស្សន៍លក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ 40%។`,
      fr: `Le score combiné pondéré (WCS) est un score pondéré de 0 à 100 que le district scolaire de Philadelphie a calculé (sur la base de l'évaluation de l'adéquation de l'éducation et de l'évaluation de l'état des installations pour aider à identifier les écoles qui ont le plus besoin de rénovation ou devraient être envisagées pour le remplacement Le score est une moyenne pondérée de 60 % d'évaluation de l'adéquation éducative et de 40 % d'indice de l'état des installations.`,
      ar: `الدرجة المجمعة المرجحة (WCS) هي درجة مرجحة من 0 إلى 100 تم حسابها في منطقة فيلادلفيا التعليمية (بناءً على تقييم ملاءمة التعليم وتقييم حالة المرافق للمساعدة في تحديد المدارس التي لديها أعلى حاجة للتجديد أو التي يجب التفكير فيها للاستبدال • النتيجة هي متوسط مرجح 60٪ تقييم ملاءمة التعليم و 40٪ مؤشر ظروف المرافق.`,
      en: `The Weighted Combined Score (WCS) is a weighted score from 0 to 100 that the School District of Philadelphia calculated (based on the Education Suitability Assessment and Facilities Condition Assessment to help identify which schools have the highest need for renovation or should be considered for replacement. The score is weighted average of 60% Educational Suitability Assessment and 40% Facilities Conditions Index.`,
    },
    // accordion_body7: {
    //   es: `Cualquier escuela con un puntaje combinado ponderado de menos de 60 tendrá la primera prioridad para abordar los problemas de la instalación.`,
    //   "zh-CN": `任何加权综合得分低于 60 分的学校都将优先考虑解决设施的问题。`,
    //   vi: `Bất kỳ trường nào có Điểm Kết hợp Trọng số dưới 60 sẽ được ưu tiên giải quyết các vấn đề của cơ sở.`,
    //   ru: `Любая школа с комбинированным взвешенным баллом менее 60 будет иметь приоритет в решении проблем учреждения.`,
    //   sq: `Çdo shkollë me një rezultat të kombinuar të ponderuar më pak se 60 do t'i jepet përparësia e parë në adresimin e çështjeve të institucionit.`,
    //   km: `សាលាណាមួយដែលមានពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់តិចជាង 60 នឹងត្រូវបានផ្តល់អាទិភាពដំបូងក្នុងការដោះស្រាយបញ្ហារបស់គ្រឹះស្ថាន។`,
    //   fr: `Toute école avec un score combiné pondéré inférieur à 60 aura la priorité pour résoudre les problèmes de l'établissement.`,
    //   ar: `أي مدرسة ذات مجموع نقاط مرجحة أقل من 60 ستعطى الأولوية الأولى في معالجة مشكلات المنشأة.`,
    //   en: `Any school with a Weighted Combined Score of less than 60 will be given first priority in addressing the facility’s issues.`,
    // },
    accordion_title4: {
      es: `Inscripción de estudiantes (2021-2022)`,
      "zh-CN": `学生入学（2021-2022）`,
      vi: `Tuyển sinh sinh viên (2021-2022)`,
      ru: `Набор студентов (2021-2022)`,
      sq: `Regjistrimi i studentëve (2021-2022)`,
      km: `ការចុះឈ្មោះសិស្ស (2021-2022)`,
      fr: `Inscription des étudiants (2021-2022)`,
      ar: `قيد الطلاب (2021-2022)`,
      en: `Student Enrollment (2021-2022)`,
    },
    accordion_disclaimer1: {
      es: `Inscripción a partir del 1 de octubre de 2021; Capacidad recalculada año escolar 2020-2021.`,
      "zh-CN": `报名于 2021 年 10 月 1 日生效； 重新计算 2020-2021 学年的容量。`,
      vi: `Tuyển sinh có hiệu lực từ ngày 1 tháng 10 năm 2021; Công suất tính lại năm học 2020-2021.`,
      ru: `Регистрация действует с 1 октября 2021 г.; Вместимость пересчитана на 2020-2021 учебный год.`,
      sq: `Regjistrimi hyn në fuqi më 1 tetor 2021; Kapaciteti i rillogaritur viti shkollor 2020-2021.`,
      km: `ការចុះឈ្មោះចូលរៀនចាប់ពីថ្ងៃទី 1 ខែតុលា ឆ្នាំ 2021; សមត្ថភាពគណនាឡើងវិញក្នុងឆ្នាំសិក្សា ២០២០-២០២១។`,
      fr: `Inscription effective le 1er octobre 2021 ; Capacité recalculée année scolaire 2020-2021.`,
      ar: `التسجيل اعتبارًا من 1 أكتوبر 2021 ؛ إعادة حساب القدرات للعام الدراسي 2020-2021.`,
      en: `Enrollment effective October 1, 2021; Capacity recalculated 2020-2021 school year.`,
    },
    accordion_body8: {
      es: `La inscripción de estudiantes es el número de estudiantes que asisten a cada escuela. Cada escuela puede acomodar una cierta cantidad de estudiantes, según el tamaño del edificio, la cantidad de aulas, etc. Esta es la "capacidad" del edificio. Si una escuela tiene una inscripción baja en comparación con el tamaño de su edificio, entonces el edificio está "subutilizado".`,
      "zh-CN": `学生入学人数是每所学校就读的学生人数。 每所学校可以容纳一定数量的学生，具体取决于建筑物的大小、教室的数量等。这就是建筑物的“容量”。 如果一所学校的入学率与其建筑规模相比较低，那么该建筑就“未得到充分利用”。`,
      vi: `Số lượng học sinh đang theo học tại mỗi trường. Mỗi trường có thể phù hợp với một số lượng học sinh nhất định, tùy thuộc vào kích thước của tòa nhà, số lượng phòng học, v.v. Đây là “sức chứa” của tòa nhà. Nếu một trường có số học sinh đăng ký thấp so với quy mô của tòa nhà của nó, thì tòa nhà đó “đang được sử dụng kém.”`,
      ru: `Количество учащихся – это количество учащихся, посещающих каждую школу. Каждая школа может вместить определенное количество учеников, в зависимости от размера здания, количества классных комнат и т. д. Это «вместимость» здания. Если в школе мало учащихся по сравнению с размером ее здания, то это здание «недостаточно используется».`,
      sq: `Regjistrimi i studentëve është numri i studentëve që ndjekin çdo shkollë. Çdo shkollë mund të përshtatet me një numër të caktuar nxënësish, në varësi të madhësisë së ndërtesës, numrit të klasave, etj. Ky është "kapaciteti" i ndërtesës. Nëse një shkollë ka një regjistrim të ulët në krahasim me madhësinë e ndërtesës së saj, atëherë ndërtesa është "nën-shfrytëzuar".`,
      km: `ការចុះឈ្មោះសិស្ស គឺជាចំនួនសិស្សដែលចូលរៀននៅសាលានីមួយៗ។ សាលានីមួយៗអាចសមនឹងចំនួនសិស្សជាក់លាក់ អាស្រ័យលើទំហំនៃអគារ ចំនួនថ្នាក់រៀន។ល។ នេះគឺជា "សមត្ថភាព" របស់អាគារ។ ប្រសិនបើសាលាមានការចុះឈ្មោះចូលរៀនទាប បើធៀបនឹងទំហំអគាររបស់វា នោះអគារនេះ "មិនប្រើប្រាស់"។`,
      fr: `L'inscription des élèves est le nombre d'élèves fréquentant chaque école. Chaque école peut accueillir un certain nombre d'élèves, en fonction de la taille du bâtiment, du nombre de salles de classe, etc. C'est la « capacité » du bâtiment. Si une école a un faible taux d'inscription par rapport à la taille de son bâtiment, alors le bâtiment est « sous-utilisé ».`,
      ar: `تسجيل الطلاب هو عدد الطلاب الملتحقين بكل مدرسة. يمكن أن تستوعب كل مدرسة عددًا معينًا من الطلاب ، اعتمادًا على حجم المبنى وعدد الفصول الدراسية وما إلى ذلك. هذه هي "سعة" المبنى. إذا كان معدل الالتحاق بالمدرسة منخفضًا مقارنة بحجم المبنى ، فإن المبنى "غير مستغل بشكل كافٍ".`,
      en: `Student Enrollment is the number of students attending each school. Each school can fit a certain number of students, depending on the size of the building, number of classrooms, etc. This is the building’s “capacity.” If a school has a low enrollment compared to the size of its building, then the building is “under-utilized.”`,
    },
    accordion_body9: {
      es: `Estos números son una parte fundamental del proceso de planificación de instalaciones. Debido a que la cantidad de fondos que recibe una escuela está determinada en parte por la inscripción de estudiantes, la baja inscripción y la subutilización dificultan tener suficientes fondos para pagar a los maestros y otros recursos. También puede ser un desafío cuando hay escuelas sobreutilizadas porque las aulas abarrotadas pueden afectar negativamente el rendimiento y la seguridad de los estudiantes, obstaculizar la capacidad de la administración para programar las aulas de manera efectiva y sobrecargar los equipos y sistemas del edificio.`,
      "zh-CN": `这些数字是设施规划流程的关键部分。 由于学校获得的资金数额部分取决于学生入学率，入学率低和利用率不足使得很难有足够的资金来支付教师和其他资源的费用。 当学校过度使用时，这也可能是一个挑战，因为拥挤的教室会对学生的表现和安全产生负面影响，阻碍政府有效安排教室的能力，并使建筑物的设备和系统负担过重。`,
      vi: `Những con số này là một phần quan trọng của Quy trình Lập kế hoạch Cơ sở vật chất. Bởi vì số tiền tài trợ mà một trường nhận được một phần được xác định bởi Số lượng học sinh, việc ghi danh thấp và sử dụng ít gây khó khăn cho việc có đủ kinh phí để trả cho giáo viên và các nguồn lực khác. Nó cũng có thể là một thách thức khi có những trường học được sử dụng quá mức vì lớp học đông đúc có thể ảnh hưởng tiêu cực đến hiệu suất và sự an toàn của học sinh, cản trở khả năng sắp xếp lịch học một cách hiệu quả của ban quản lý và làm quá tải thiết bị và hệ thống của tòa nhà.`,
      ru: `Эти цифры являются важной частью процесса планирования объектов. Поскольку объем финансирования, получаемого школой, частично определяется набором учащихся, низкий уровень зачисления и недостаточное использование затрудняют получение достаточного финансирования для оплаты труда учителей и других ресурсов. Это также может быть проблемой, когда есть чрезмерно загруженные школы, потому что переполненные классы могут негативно повлиять на успеваемость и безопасность учащихся, помешать администрации эффективно планировать классы и перегрузить оборудование и системы здания.`,
      sq: `Këto shifra janë një pjesë kritike e Procesit të Planifikimit të Objekteve. Për shkak se shuma e financimit që merr një shkollë përcaktohet pjesërisht nga Regjistrimi i Studentëve, regjistrimi i ulët dhe mospërdorimi e bëjnë të vështirë të ketë fonde të mjaftueshme për të paguar mësuesit dhe burime të tjera. Mund të jetë gjithashtu një sfidë kur ka shkolla të mbishfrytëzuara, sepse klasat e mbushura me njerëz mund të ndikojnë negativisht në performancën dhe sigurinë e nxënësve, të pengojnë aftësinë e administratës për të planifikuar në mënyrë efektive klasat dhe të mbingarkojnë pajisjet dhe sistemet e ndërtesës.`,
      km: `លេខទាំងនេះគឺជាផ្នែកសំខាន់នៃដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ។ ដោយសារតែចំនួនមូលនិធិដែលសាលាទទួលបានត្រូវបានកំណត់ដោយផ្នែកដោយការចុះឈ្មោះសិស្ស ការចុះឈ្មោះទាប និងការប្រើប្រាស់តិច ធ្វើឱ្យមានការលំបាកក្នុងការមានថវិកាគ្រប់គ្រាន់សម្រាប់ចំណាយសម្រាប់គ្រូបង្រៀន និងធនធានផ្សេងទៀត។ វាក៏អាចជាបញ្ហាប្រឈមផងដែរ នៅពេលដែលសាលារៀនប្រើប្រាស់ហួសកម្រិត ពីព្រោះថ្នាក់រៀនមានមនុស្សច្រើនអាចជះឥទ្ធិពលអវិជ្ជមានដល់ការអនុវត្ត និងសុវត្ថិភាពសិស្ស រារាំងសមត្ថភាពរបស់រដ្ឋបាលក្នុងការកំណត់កាលវិភាគថ្នាក់រៀនប្រកបដោយប្រសិទ្ធភាព និងធ្វើឱ្យលើសទម្ងន់នូវឧបករណ៍ និងប្រព័ន្ធរបស់អគារ។`,
      fr: `Ces chiffres sont un élément essentiel du processus de planification des installations. Étant donné que le montant du financement qu'une école reçoit est en partie déterminé par l'inscription des élèves, le faible taux d'inscription et la sous-utilisation rendent difficile l'obtention de fonds suffisants pour payer les enseignants et d'autres ressources. Cela peut également être un défi lorsqu'il y a des écoles surutilisées, car les salles de classe surpeuplées peuvent affecter négativement les performances et la sécurité des élèves, entraver la capacité de l'administration à planifier efficacement les salles de classe et surcharger l'équipement et les systèmes du bâtiment.`,
      ar: `هذه الأرقام هي جزء مهم من عملية تخطيط المرافق. نظرًا لأن مقدار التمويل الذي تتلقاه المدرسة يتم تحديده جزئيًا من خلال تسجيل الطلاب ، فإن انخفاض التسجيل وقلة الاستخدام يجعل من الصعب الحصول على تمويل كافٍ لدفع تكاليف المعلمين والموارد الأخرى. يمكن أن يمثل ذلك أيضًا تحديًا عندما يكون هناك استخدام مفرط للمدارس لأن الفصول الدراسية المزدحمة يمكن أن تؤثر سلبًا على أداء الطلاب وسلامتهم ، وتعيق قدرة الإدارة على جدولة الفصول الدراسية بشكل فعال ، وتثقل كاهل معدات وأنظمة المبنى.`,
      en: `These numbers are a critical part of the Facilities Planning Process. Because the amount of funding a school receives is partly determined by Student Enrollment, low enrollment and under-utilization make it difficult to have enough funding to pay for teachers and other resources. It can also be a challenge when there are over-utilized schools because crowded classrooms can negatively affect student performance and safety, hinder the administration’s ability to schedule classrooms effectively, and overburden the building’s equipment and systems.`,
    },
    accordion_title5: {
      es: `Utilización escolar (2021-2022)`,
      "zh-CN": `学校利用率（2021-2022）`,
      vi: `Sử dụng trường học (2021-2022)`,
      ru: `Использование школы (2021-2022)`,
      sq: `Shfrytëzimi i shkollës (2021-2022)`,
      km: `ការប្រើប្រាស់សាលារៀន (2021-2022)`,
      fr: `Utilisation de l'école (2021-2022)`,
      ar: `استخدام المدرسة (2021-2022)`,
      en: `School Utilization (2021-2022)`,
    },
    accordion_body10: {
      es: `La utilización de la escuela es la relación entre el número de estudiantes matriculados en una escuela y la capacidad total de la escuela. La capacidad escolar es el número máximo de estudiantes que un edificio escolar puede acomodar satisfactoriamente al mismo tiempo para la programación educativa y el currículo ofrecido.`,
      "zh-CN": `学校利用率是学校在校学生人数与学校总容量之间的比率。 学校容量是一所学校建筑一次可以满足提供的教育计划和课程的最大学生人数。`,
      vi: `Hiệu suất sử dụng của trường là tỷ lệ giữa số lượng học sinh đăng ký tại một trường và tổng công suất của trường. Sức chứa của trường là số lượng học sinh tối đa mà một tòa nhà trường học có thể đáp ứng một cách thỏa đáng tại một thời điểm cho chương trình giáo dục và chương trình giảng dạy được cung cấp.`,
      ru: `Загрузка школы – это соотношение между количеством учащихся, зачисленных в школу, и общей вместимостью школы. Вместимость школы – это максимальное количество учащихся, которое здание школы может удовлетворительно разместить одновременно в соответствии с предлагаемыми образовательными программами и учебным планом.`,
      sq: `Shfrytëzimi i shkollës është raporti ndërmjet numrit të nxënësve të regjistruar në një shkollë dhe kapacitetit të përgjithshëm të shkollës. Kapaciteti i shkollës është numri maksimal i nxënësve që një ndërtesë shkollore mund të strehojë në mënyrë të kënaqshme në të njëjtën kohë për programin arsimor dhe kurrikulën e ofruar.`,
      km: `ការប្រើប្រាស់សាលាគឺជាសមាមាត្ររវាងចំនួនសិស្សដែលបានចុះឈ្មោះចូលរៀននៅសាលា និងសមត្ថភាពសរុបរបស់សាលា។ សមត្ថភាពសាលាគឺជាចំនួនសិស្សអតិបរមាដែលអគារសិក្សាអាចស្នាក់នៅបានយ៉ាងគាប់ចិត្តក្នុងពេលតែមួយសម្រាប់កម្មវិធីអប់រំ និងកម្មវិធីសិក្សាដែលផ្តល់ជូន។`,
      fr: `L'utilisation de l'école est le rapport entre le nombre d'élèves inscrits dans une école et la capacité totale de l'école. La capacité scolaire est le nombre maximum d'élèves qu'un bâtiment scolaire peut accueillir de manière satisfaisante en même temps pour le programme éducatif et le programme d'études offerts.`,
      ar: `استخدام المدرسة هو النسبة بين عدد الطلاب المسجلين في مدرسة وإجمالي سعة المدرسة. سعة المدرسة هي الحد الأقصى لعدد الطلاب الذي يمكن أن يستوعبه مبنى المدرسة بشكل مُرضٍ في وقت واحد للبرامج التعليمية والمناهج الدراسية المقدمة.`,
      en: `School utilization is the ratio between the number of students enrolled at a school and the school’s total capacity. School capacity is the maximum number of students that a school building can satisfactorily accommodate at one time for the educational programming and curriculum offered.`,
    },
    template: {
      es: ``,
      "zh-CN": ``,
      vi: ``,
      ru: ``,
      sq: ``,
      km: ``,
      fr: ``,
      ar: ``,
      en: ``,
    },
  },
  links_page: {
    header1: {
      es: `Esta página enlaza con materiales compartidos a lo largo del proceso de planificación de instalaciones y continuará actualizándose a lo largo de cada fase.`,
      "zh-CN": `此页面链接到整个设施规划过程中共享的材料，并将在每个阶段继续更新。`,
      vi: `Trang này liên kết đến các tài liệu được chia sẻ trong suốt Quy trình Lập kế hoạch Cơ sở vật chất và sẽ tiếp tục được cập nhật trong mỗi giai đoạn.`,
      ru: `На этой странице размещены ссылки на материалы, используемые в ходе процесса планирования объектов, и они будут обновляться на каждом этапе.`,
      sq: `Kjo faqe lidhet me materialet e shpërndara gjatë procesit të planifikimit të objekteve dhe do të vazhdojë të përditësohet gjatë çdo faze.`,
      km: `ទំព័រនេះភ្ជាប់ទៅសម្ភារៈដែលបានចែករំលែកពេញដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារ ហើយនឹងបន្តធ្វើបច្ចុប្បន្នភាពពេញមួយដំណាក់កាលនីមួយៗ។`,
      fr: `Cette page contient des liens vers des documents partagés tout au long du processus de planification des installations et continuera d'être mise à jour tout au long de chaque phase.`,
      ar: `ترتبط هذه الصفحة بالمواد التي تمت مشاركتها خلال عملية تخطيط المرافق وسيستمر تحديثها طوال كل مرحلة.`,
      en: `This page links to materials shared throughout the Facilities Planning Process and will continue to be updated throughout each phase.`,
    },
    header2: {
      es: `El departamento de Programas de Capital del Distrito Escolar de Filadelfia ha puesto a disposición los siguientes informes. Estos informes abordan las pautas y especificaciones sobre cómo se evaluaron las instalaciones para el FPP.`,
      "zh-CN": `费城学区资本计划部门提供了以下报告。 这些报告阐述了如何为 FPP 评估设施的指南和规范。`,
      vi: `Bộ phận Chương trình Thủ đô của Học khu Philadelphia đã cung cấp các báo cáo sau đây. Các báo cáo này đề cập đến các hướng dẫn và thông số kỹ thuật về cách thức đánh giá các cơ sở cho FPP.`,
      ru: `Департамент капитальных программ Школьного округа Филадельфии опубликовал следующие отчеты. Эти отчеты касаются руководящих принципов и спецификаций того, как объекты оценивались для FPP.`,
      sq: `Departamenti i Programeve Kapitale të Distriktit Shkollor të Filadelfias ka vënë në dispozicion raportet e mëposhtme. Këto raporte adresojnë udhëzimet dhe specifikimet se si janë vlerësuar objektet për FPP.`,
      km: `នាយកដ្ឋានកម្មវិធីរាជធានីនៃទីក្រុង Philadelphia បានធ្វើរបាយការណ៍ដូចខាងក្រោម។ របាយការណ៍ទាំងនេះនិយាយអំពីគោលការណ៍ណែនាំ និងលក្ខណៈជាក់លាក់សម្រាប់របៀបដែលគ្រឿងបរិក្ខារត្រូវបានវាយតម្លៃសម្រាប់ FPP ។`,
      fr: `Le département des programmes d'immobilisations du district scolaire de Philadelphie a mis à disposition les rapports suivants. Ces rapports traitent des lignes directrices et des spécifications sur la façon dont les installations ont été évaluées pour le FPP.`,
      ar: `أتاح قسم برامج العاصمة في منطقة فيلادلفيا التعليمية التقارير التالية. تتناول هذه التقارير المبادئ التوجيهية والمواصفات الخاصة بكيفية تقييم المرافق لـ FPP.`,
      en: `The School District of Philadelphia’s Capital Programs department has made the following reports available. These reports address the guidelines and specifications for how facilities were assessed for the FPP.`,
    },
    link1: {
      es: `Especificaciones técnicas`,
      "zh-CN": `技术规格`,
      vi: `Thông số kỹ thuật`,
      ru: `Технические характеристики`,
      sq: `Specifikimet teknike`,
      km: `លក្ខណៈបច្ចេកទេស`,
      fr: `Spécifications techniques`,
      ar: `المواصفات الفنية`,
      en: `Technical Specifications`,
    },
    body1: {
      es: `Define materiales típicos y fabricantes aceptables para uso en proyectos del Distrito. Se basan en las directrices de diseño y las complementan.s`,
      "zh-CN": `定义可用于地区项目的典型材料和制造商。 它们基于并补充了设计指南。`,
      vi: `Xác định các vật liệu và nhà sản xuất điển hình được chấp nhận để sử dụng trong các dự án của Quận. Chúng dựa trên và bổ sung cho các hướng dẫn thiết kế.`,
      ru: `Определяет типичные материалы и производителей, приемлемых для использования в проектах Района. Они основаны на рекомендациях по проектированию и дополняют их.`,
      sq: `Përcakton materialet dhe prodhuesit tipikë të pranueshëm për përdorim në projektet e Distriktit. Ato bazohen dhe plotësojnë udhëzimet e projektimit.`,
      km: `កំណត់សម្ភារៈធម្មតា និងអ្នកផលិតដែលអាចទទួលយកបានសម្រាប់ប្រើប្រាស់ក្នុងគម្រោងក្នុងស្រុក។ ពួកវាផ្អែកលើ និងបំពេញបន្ថែមនូវគោលការណ៍ណែនាំនៃការរចនា។`,
      fr: `Définit les matériaux typiques et les fabricants acceptables pour une utilisation dans les projets du District. Ils sont basés sur les directives de conception et les complètent.`,
      ar: `يحدد المواد النموذجية والمصنعين المقبولين للاستخدام في مشاريع المنطقة. وهي تستند إلى إرشادات التصميم وتكملها.`,
      en: `Defines typical materials and manufacturers acceptable for use in District projects. They are based on and complement the design guidelines.`,
    },
    link2: {
      es: `FCA- Evaluación de las condiciones de las instalaciones`,
      "zh-CN": `FCA-设施条件评估`,
      vi: `FCA- Đánh giá điều kiện cơ sở vật chất`,
      ru: `FCA- Оценка состояния объектов`,
      sq: `FCA- Vlerësimi i kushteve të objekteve`,
      km: `FCA- ការវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ`,
      fr: `FCA - Évaluation des conditions des installations`,
      ar: `FCA- تقييم ظروف المرافق`,
      en: `FCA- Facilities Conditions Assessment`,
    },
    body2: {
      es: `Proporciona información sobre el estado de los terrenos escolares, los edificios escolares, los campos deportivos y los sistemas de construcción relacionados. Los informes resumidos de FCA para todas las escuelas se pueden encontrar en el encabezado Informes resumidos de FCA (FY2015). Los puntajes de las escuelas que se actualizaron recientemente se pueden encontrar resaltados bajo el encabezado Informes resumidos de FCA (FY2022).`,
      "zh-CN": `提供有关学校场地、校舍、运动场和相关建筑系统状况的信息。 所有学校的 FCA 摘要报告都可以在 FCA 摘要报告 (FY2015) 标题下找到。 可以在 FCA 摘要报告 (FY2022) 标题下突出显示最近更新的学校分数。`,
      vi: `Cung cấp thông tin về tình trạng của sân trường, trường học, sân thể thao và các hệ thống xây dựng liên quan. Các báo cáo tóm tắt của FCA cho tất cả các trường có thể được tìm thấy trong tiêu đề Báo cáo Tóm tắt FCA (FY2015). Điểm cho các trường đã được cập nhật gần đây có thể được đánh dấu trong tiêu đề Báo cáo Tóm tắt FCA (FY2022).`,
      ru: `Предоставляет информацию о состоянии школьных территорий, школьных зданий, спортивных площадок и соответствующих строительных систем. Сводные отчеты FCA по всем школам можно найти в разделе «Сводные отчеты FCA (2015 финансовый год)». Оценки для школ, которые были недавно обновлены, можно найти под заголовком сводных отчетов FCA (FY2022).`,
      sq: `Ofron informacion mbi gjendjen e mjediseve të shkollës, ndërtesave shkollore, fushave atletike dhe sistemeve përkatëse të ndërtimit. Raportet përmbledhëse të FCA-së për të gjitha shkollat mund të gjenden nën titullin e Raporteve përmbledhëse të FCA (FY2015). Rezultatet për shkollat që janë përditësuar së fundmi mund të gjenden të theksuara nën titullin e Raporteve përmbledhëse të FCA (FY2022).`,
      km: `ផ្តល់ព័ត៌មានអំពីស្ថានភាពនៃទីធ្លាសាលា អគារសិក្សា ទីលានកីឡា និងប្រព័ន្ធអគារពាក់ព័ន្ធ។ របាយការណ៍សង្ខេបរបស់ FCA សម្រាប់សាលារៀនទាំងអស់អាចរកបាននៅក្រោមចំណងជើងរបាយការណ៍សង្ខេប FCA (FY2015) ។ ពិន្ទុសម្រាប់សាលារៀនដែលត្រូវបានអាប់ដេតថ្មីៗនេះអាចត្រូវបានរកឃើញរំលេចនៅក្រោមចំណងជើងរបាយការណ៍សង្ខេបរបស់ FCA (FY2022)។`,
      fr: `Fournit des informations sur l'état des cours d'école, des bâtiments scolaires, des terrains de sport et des systèmes de construction connexes. Les rapports sommaires de la FCA pour toutes les écoles se trouvent sous l'en-tête Rapports sommaires de la FCA (exercice 2015). Les scores des écoles qui ont été récemment mis à jour peuvent être trouvés en surbrillance sous l'en-tête des rapports de synthèse de la FCA (exercice 2022).`,
      ar: `يوفر معلومات عن حالة ساحات المدرسة والمباني المدرسية والملاعب الرياضية وأنظمة البناء ذات الصلة. يمكن الاطلاع على تقارير ملخص هيئة السلوك المالي (FCA) لجميع المدارس تحت عنوان تقارير ملخص هيئة السلوك المالي (FY2015). يمكن العثور على نتائج المدارس التي تم تحديثها مؤخرًا مظللة تحت عنوان تقارير ملخص FCA (FY2022).`,
      en: ` Provides information on the condition of school grounds, school buildings, athletic fields and related building systems. The FCA summary reports for all schools can be found under the FCA Summary Reports (FY2015) header. Scores for schools that have been recently updated can be found highlighted under the FCA Summary Reports (FY2022) header.`,
    },
    link3: {
      es: `Guía de diseño`,
      "zh-CN": `设计指南`,
      vi: `Nguyên tắc thiết kế`,
      ru: `Рекомендации по дизайну`,
      sq: `Udhëzimet e projektimit`,
      km: `ការណែនាំអំពីការរចនា`,
      fr: `Directives de conception`,
      ar: `موجهات التصميم`,
      en: `Design Guidelines`,
    },
    body3: {
      es: `Proporciona dirección e instrucciones para el trabajo de diseño de los proyectos del Distrito en lo que respecta a los requisitos de diseño, las especificaciones estándar, los detalles estándar y los estándares de planificación de salas.`,
      "zh-CN": `为地区项目的设计工作提供指导和说明，因为它与设计要求、标准规范、标准细节和房间规划标准有关。`,
      vi: `Cung cấp chỉ đạo và hướng dẫn cho công việc thiết kế cho các dự án của Quận vì nó liên quan đến các yêu cầu thiết kế, thông số kỹ thuật tiêu chuẩn, chi tiết tiêu chuẩn và tiêu chuẩn quy hoạch phòng.`,
      ru: `Предоставляет указания и инструкции по проектным работам для проектов округа в отношении требований к дизайну, стандартных спецификаций, стандартных деталей и стандартов планировки помещений.`,
      sq: `Ofron drejtime dhe udhëzime për punën e projektimit për projektet e Distriktit pasi lidhet me kërkesat e projektimit, specifikimet standarde, detajet standarde dhe standardet e planifikimit të dhomës.`,
      km: `ផ្តល់ការណែនាំ និងការណែនាំសម្រាប់ការងាររចនាសម្រាប់គម្រោងស្រុក ដោយសារវាទាក់ទងនឹងតម្រូវការរចនា លក្ខណៈបច្ចេកទេស ព័ត៌មានលម្អិតស្តង់ដារ និងស្តង់ដាររៀបចំផែនការបន្ទប់។`,
      fr: `Fournit des directives et des instructions pour les travaux de conception pour les projets du district en ce qui concerne les exigences de conception, les spécifications standard, les détails standard et les normes de planification des salles.`,
      ar: `يوفر التوجيه والإرشادات الخاصة بأعمال التصميم لمشاريع المنطقة من حيث صلتها بمتطلبات التصميم والمواصفات القياسية والتفاصيل القياسية ومعايير تخطيط الغرف.`,
      en: `Provides direction and instructions for design work for District projects as it relates to design requirements, standard specifications, standard details, and room planning standards.`,
    },
    link4: {
      es: `Especificaciones educativas`,
      "zh-CN": `教育规范`,
      vi: `Thông số kỹ thuật giáo dục`,
      ru: `Образовательные характеристики`,
      sq: `Specifikimet arsimore`,
      km: `លក្ខណៈបច្ចេកទេសនៃការអប់រំ`,
      fr: `Spécifications pédagogiques`,
      ar: `المواصفات التعليمية`,
      en: `Educational Specifications`,
    },
    body4: {
      es: `Explica los criterios desarrollados por los expertos del Distrito en cada departamento que se utilizan para evaluar el entorno de aprendizaje, el tamaño, el equipo y el almacenamiento dentro de cada espacio en una escuela en relación con esos departamentos.`,
      "zh-CN": `解释每个部门的学区专家制定的标准，用于评估学校每个空间内的学习环境、规模、设备和存储，因为它与这些部门相关。`,
      vi: `Giải thích các tiêu chí được phát triển bởi các chuyên gia của Học khu trong mọi bộ phận được sử dụng để đánh giá môi trường học tập, kích thước, thiết bị và lưu trữ trong mỗi không gian trong trường học vì nó liên quan đến các bộ phận đó.`,
      ru: `Объясняет критерии, разработанные экспертами округа в каждом отделе, которые используются для оценки учебной среды, размера, оборудования и хранения в каждом помещении школы, поскольку это относится к этим отделам.`,
      sq: `Shpjegon kriteret e zhvilluara nga ekspertët e distriktit në çdo departament, të përdorura për të vlerësuar mjedisin mësimor, madhësinë, pajisjet dhe ruajtjen brenda çdo hapësire në një shkollë, në lidhje me ato departamente.`,
      km: `ពន្យល់ពីលក្ខណៈវិនិច្ឆ័យដែលបង្កើតឡើងដោយអ្នកជំនាញស្រុកនៅគ្រប់នាយកដ្ឋានដែលប្រើដើម្បីវាយតម្លៃបរិយាកាសសិក្សា ទំហំ ឧបករណ៍ និងកន្លែងផ្ទុកក្នុងចន្លោះនីមួយៗក្នុងសាលា ដោយសារវាទាក់ទងនឹងនាយកដ្ឋានទាំងនោះ។`,
      fr: `Explique les critères développés par les experts du district dans chaque département utilisés pour évaluer l'environnement d'apprentissage, la taille, l'équipement et le stockage dans chaque espace d'une école en ce qui concerne ces départements.`,
      ar: `يشرح المعايير التي وضعها خبراء المنطقة في كل قسم لتقييم بيئة التعلم والحجم والمعدات والتخزين داخل كل مساحة في المدرسة من حيث صلتها بتلك الأقسام.`,
      en: `Explains the criteria developed by District experts in every department used to assess the learning environment, size, equipment and storage within each space in a school as it relates to those departments.`,
    },
  },
  video_descriptions: {
    title1: {
      es: `Evaluación de las condiciones de las instalaciones`,
      "zh-CN": `设施条件评估`,
      vi: `Đánh giá Điều kiện Cơ sở`,
      ru: `Оценка состояния объекта`,
      sq: `Vlerësimi i kushteve të objektit`,
      km: `ការវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារ`,
      fr: `Évaluation des conditions de l'installation`,
      ar: `تقييم ظروف المنشأة`,
      en: `Facility Conditions Assessment`,
    },
    text1: {
      es: `(En letra grande en el centro de una pantalla blanca, aparece el texto Proceso de planificación de instalaciones. Arriba está el logotipo del Distrito Escolar de Filadelfia. La siguiente pantalla muestra el texto Medición de la calidad de una instalación escolar con cuatro íconos debajo del texto. Uno es una mochila rosa con Evaluación de idoneidad educativa escrita debajo, una es un tubo curvo azul con Evaluación de condiciones de instalaciones debajo, la tercera es una pizarra verde con símbolos matemáticos con Puntaje combinado ponderado debajo y, por último, hay una animación rosa de un edificio escolar con Pronóstico de Inscripción y Utilización debajo de él. Todos los íconos están atenuados, excepto la Evaluación de la Condición de las Instalaciones.`,
      "zh-CN": `（在白色屏幕中央以大字体显示设施规划过程文本。其上方是费城学区徽标。下一个屏幕显示文本测量学校设施的质量，文本下方有四个图标。一个一个粉红色的背包，下面写着教育适合性评估，一个是蓝色的弯管，下面是设施条件评估，第三个是绿色的黑板，上面有数学符号，下面是加权综合分数，最后是一个粉红色的动画学校建筑，其下方有入学预测和利用率。除设施状况评估外，所有图标都显示为灰色。`,
      vi: `(Bằng phông chữ lớn ở giữa màn hình trắng, dòng chữ Quy trình Hoạch định Cơ sở vật chất xuất hiện. Phía trên là biểu trưng của Học khu Philadelphia. Màn hình tiếp theo hiển thị văn bản Đo lường Chất lượng của Cơ sở Trường học với bốn biểu tượng bên dưới văn bản. Một là một chiếc ba lô màu hồng với Đánh giá mức độ phù hợp giáo dục được viết bên dưới, một cái là một cái ống cong màu xanh lam với Đánh giá Điều kiện Thuận lợi bên dưới, cái thứ ba là một bảng đen màu xanh lá cây với các ký hiệu toán học với Điểm Kết hợp Có Trọng số bên dưới, và cuối cùng là hình ảnh động màu hồng của một tòa nhà trường học với Dự báo Ghi danh và Sử dụng bên dưới. Tất cả các biểu tượng sau đó sẽ chuyển sang màu xám, ngoại trừ Đánh giá Tình trạng Cơ sở vật chất.`,
      ru: `(В центре белого экрана крупным шрифтом отображается текст «Процесс планирования объектов». Над ним находится логотип школьного округа Филадельфии. На следующем экране отображается текст «Измерение качества школьного объекта» с четырьмя значками под текстом. Один представляет собой розовый рюкзак с надписью «Оценка пригодности для образования», под которой написана синяя изогнутая труба с оценкой условий учреждения, третья — зеленая классная доска с математическими символами на ней и взвешенной комбинированной оценкой под ней, и, наконец, есть розовая анимация школьное здание с прогнозом зачисления и использованием под ним Все значки затемнены серым цветом, за исключением оценки состояния объекта.`,
      sq: `(Me font të madh në qendër të një ekrani të bardhë, shfaqet teksti Facilities Planning Process. Mbi të është logoja e Distriktit Shkollor të Filadelfias. Ekrani tjetër tregon tekstin Matja e cilësisë së një objekti shkollor me katër ikona poshtë tekstit. Njëra është një çantë shpine rozë me vlerësimin e përshtatshmërisë arsimore të shkruar poshtë saj, njëra është një tub i lakuar blu me Vlerësimin e kushteve të objektit poshtë, i treti është një dërrasë e gjelbër me simbole matematikore mbi të me Rezultatin e Kombinuar të Ponderuar poshtë, dhe së fundi ka një animacion rozë të një godina e shkollës me Parashikimin e Regjistrimit dhe Përdorimin poshtë saj. Më pas të gjitha ikonat janë gri, me përjashtim të Vlerësimit të gjendjes së objektit.`,
      km: `(នៅក្នុងពុម្ពអក្សរធំនៅចំកណ្តាលអេក្រង់ពណ៌ស អត្ថបទដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារនឹងលេចចេញមក។ ខាងលើវាជានិមិត្តសញ្ញាសាលាក្រុង Philadelphia។ អេក្រង់បន្ទាប់បង្ហាញអត្ថបទ វាស់គុណភាពនៃបរិក្ខារសាលា ដែលមានរូបតំណាងបួននៅខាងក្រោមអត្ថបទមួយ។ កាបូបស្ពាយពណ៌ផ្កាឈូកជាមួយនឹងការវាយតម្លៃភាពសមស្របនៃការអប់រំដែលសរសេរនៅខាងក្រោមវា មួយគឺជាបំពង់កោងពណ៌ខៀវដែលមានការវាយតម្លៃលក្ខខណ្ឌភាពងាយស្រួលនៅពីក្រោម ទីបីគឺជាក្ដារខៀនពណ៌បៃតងដែលមាននិមិត្តសញ្ញាគណិតវិទ្យានៅលើវាជាមួយនឹងពិន្ទុរួមបញ្ចូលគ្នានៅខាងក្រោម ហើយចុងក្រោយមានចលនាពណ៌ផ្កាឈូកនៃ អគារសិក្សាដែលមានការព្យាករណ៍ចុះឈ្មោះចូលរៀន និងការប្រើប្រាស់នៅខាងក្រោមវា។ រូបតំណាងទាំងអស់ត្រូវបានដាក់ជាពណ៌ប្រផេះ លើកលែងតែការវាយតម្លៃលក្ខខណ្ឌបរិក្ខារប្រើប្រាស់។`,
      fr: `(En gros caractères au centre d'un écran blanc, le texte Processus de planification des installations apparaît. Au-dessus se trouve le logo du district scolaire de Philadelphie. L'écran suivant affiche le texte Mesurer la qualité d'une installation scolaire avec quatre icônes sous le texte. Une est un sac à dos rose avec une évaluation d'aptitude éducative écrite en dessous, l'un est un tuyau incurvé bleu avec une évaluation des conditions d'installation en dessous, le troisième est un tableau vert avec des symboles mathématiques dessus avec un score combiné pondéré en dessous, et enfin il y a une animation rose d'un bâtiment de l'école avec les prévisions d'inscription et l'utilisation en dessous. Toutes les icônes sont alors grisées, à l'exception de l'évaluation de l'état des installations.`,
      ar: `(بخط كبير في وسط شاشة بيضاء ، يظهر النص "عملية تخطيط المرافق". وفوقه يوجد شعار مقاطعة فيلادلفيا التعليمية. تُظهر الشاشة التالية النص "قياس جودة منشأة مدرسية" مع وجود أربعة أيقونات أسفل النص. واحد هي حقيبة ظهر وردية اللون مكتوبًا تحتها تقييم الملاءمة التعليمية ، أحدهما أنبوب منحني أزرق تحته تقييم شروط التسهيل ، والثالث عبارة عن سبورة خضراء عليها رموز رياضية مع مجموع نقاط مرجحة تحتها ، وأخيرًا هناك رسم متحرك وردي اللون مبنى المدرسة مع توقع التسجيل والاستخدام تحته. يتم بعد ذلك إظهار جميع الرموز باللون الرمادي ، باستثناء تقييم حالة المنشأة.`,
      en: `(In large font in the center of a white screen, the text Facilities Planning Process appears. Above it is the School District of Philadelphia logo.The next screen shows the text Measuring the Quality of a School Facility with four icons below the text.  One is a pink backpack with Educational Suitability Assessment written below it, one is a blue curved pipe with Facility Conditions Assessment underneath, the third is a green chalkboard with math symbols on it with Weighted Combined Score underneath, and lastly there is a pink animation of a school building with Enrollment Forecast and Utilization below it. All icons are then grayed out, except for Facility Condition Assessment.`,
    },
    text2: {
      es: `La pantalla se vuelve blanca y aparece una animación de una mujer con un blazer negro y pantalones azules. Se detiene frente a un edificio escolar animado con dos árboles al fondo.)`,
      "zh-CN": `屏幕变白，一个身穿黑色西装外套和蓝色裤子的女人的动画出现在屏幕上。她停在一座以两棵树为背景的动画教学楼前。）`,
      vi: `Màn hình chuyển sang màu trắng và hoạt ảnh của một người phụ nữ mặc áo cộc tay đen và quần xanh đi trên màn hình. Cô ấy dừng lại trước một tòa nhà hoạt hình với hai cái cây ở phía sau.)`,
      ru: `Экран становится белым, и на экране появляется анимация женщины в черном блейзере и синих брюках. Она останавливается перед анимированным школьным зданием с двумя деревьями на заднем плане.)`,
      sq: `Ekrani bëhet i bardhë dhe një animacion i një gruaje me xhaketë të zezë dhe pantallona blu shfaqet në ekran. Ajo ndalon përpara një ndërtese shkolle të animuar me dy pemë në sfond.)`,
      km: `អេក្រង់​ប្រែ​ជា​ពណ៌​ស ហើយ​គំនូរ​ជីវចល​របស់​ស្ត្រី​ម្នាក់​ក្នុង​អាវ​ធំ​ពណ៌​ខ្មៅ និង​ខោ​ពណ៌​ខៀវ​ដើរ​លើ​អេក្រង់។ នាងឈប់នៅមុខអគារសិក្សាដែលមានចលនាដែលមានដើមឈើពីរនៅខាងក្រោយ។ )`,
      fr: `L'écran devient blanc et une animation d'une femme portant un blazer noir et un pantalon bleu apparaît à l'écran. Elle s'arrête devant un bâtiment scolaire animé avec deux arbres en arrière-plan.)`,
      ar: `تتحول الشاشة إلى اللون الأبيض ، وتظهر على الشاشة صورة متحركة لامرأة ترتدي سترة سوداء وبنطالًا أزرق. تتوقف أمام مبنى مدرسة متحرك مع شجرتين في الخلفية).`,
      en: `The screen turns white, and an animation of a woman in a black blazer and blue pants walks on screen. She stops in front of an animated school building with two trees in the background.)`,
    },
    text3: {
      es: `¡Hola! Soy Kathy y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia evaluó la condición física de sus edificios y sistemas.`,
      "zh-CN": `你好！我是凯西，我在这里与您分享费城学区如何评估其建筑物和系统的物理状况。`,
      vi: `Xin chào! Tôi là Kathy và tôi ở đây để chia sẻ với bạn cách Học khu Philadelphia đánh giá tình trạng vật chất của các tòa nhà và hệ thống của nó.`,
      ru: `Привет! Меня зовут Кэти, и я здесь, чтобы поделиться с вами тем, как школьный округ Филадельфии оценил физическое состояние своих зданий и систем.`,
      sq: `Përshëndetje! Unë jam Kathy dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfia vlerësoi gjendjen fizike të ndërtesave dhe sistemeve të tij.`,
      km: `សួស្តី! ខ្ញុំគឺ Kathy ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែលសាលាស្រុក Philadelphia បានវាយតម្លៃស្ថានភាពរាងកាយនៃអគារ និងប្រព័ន្ធរបស់វា។`,
      fr: `Salut! Je m'appelle Kathy et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a évalué l'état physique de ses bâtiments et systèmes.`,
      ar: `أهلاً! أنا كاثي ، وأنا هنا لأطلعكم على كيفية تقييم منطقة فيلادلفيا التعليمية للحالة المادية لمبانيها وأنظمتها.`,
      en: `Hi! I’m Kathy, and I’m here to share with you how the School District of Philadelphia evaluated the physical condition of its buildings and systems.`,
    },
    text4: {
      es: `A esto lo llamamos Evaluación de la Condición de las Instalaciones, o FCA. Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para identificar cualquier problema estructural y/o secciones de una instalación que necesiten reparación o reemplazo.`,
      "zh-CN": `我们称之为设施状况评估，或 FCA。为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走遍了该地区的每一所学校，以确定任何结构问题和/或需要维修或更换的设施部分。`,
      vi: `Chúng tôi gọi đây là Đánh giá Tình trạng Cơ sở, hoặc FCA. Để xác định điều này, một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để xác định bất kỳ vấn đề nào về cấu trúc và / hoặc các bộ phận của cơ sở cần sửa chữa hoặc thay thế.`,
      ru: `Мы называем это оценкой состояния объекта или FCA. Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу округа, чтобы выявить любые структурные проблемы и/или участки объекта, которые нуждаются в ремонте или замене.`,
      sq: `Ne e quajmë këtë një vlerësim të gjendjes së objektit, ose FCA. Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të identifikuar çdo problem strukturor dhe/ose seksion të një objekti që kanë nevojë për riparim ose zëvendësim.`,
      km: `យើងហៅវាថា ការវាយតម្លៃលក្ខខណ្ឌបរិក្ខារបរិក្ខារ ឬ FCA ។ ដើម្បីកំណត់បញ្ហានេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីកំណត់បញ្ហារចនាសម្ព័ន្ធ និង/ឬផ្នែកនៃកន្លែងដែលត្រូវការការជួសជុល ឬជំនួស។`,
      fr: `Nous appelons cela une évaluation de l'état des installations, ou FCA. Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour identifier tout problème structurel et / ou section d'une installation nécessitant une réparation ou un remplacement.`,
      ar: `نسمي هذا تقييم حالة المنشأة ، أو FCA. لتحديد ذلك ، قام فريق من المهنيين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتحديد أي مشاكل هيكلية و / أو أقسام بالمنشأة تحتاج إلى إصلاح أو استبدال.`,
      en: `We call this a Facility Condition Assessment, or FCA. To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to identify any structural problems and/or sections of a facility that are in need of repair or replacement.`,
    },
    text5: {
      es: `(Las animaciones de los trabajadores de la construcción fuera de la escuela caminan alrededor del edificio y aparecen dos íconos pequeños para representar la reparación o el reemplazo: una llave inglesa y una escuela con una estrella).`,
      "zh-CN": `（学校外建筑工人的动画在大楼周围走动，弹出两个小图标代表维修或更换：一个扳手和一个带有星星的学校。）`,
      vi: `(Hình ảnh động những công nhân xây dựng bên ngoài trường học đi quanh tòa nhà và hai biểu tượng nhỏ bật lên để biểu thị việc sửa chữa hoặc thay thế: cờ lê và ngôi trường có ngôi sao.)`,
      ru: `(Анимации строителей за пределами школы ходят по зданию, и появляются две маленькие иконки, обозначающие ремонт или замену: гаечный ключ и школа со звездой.)`,
      sq: `(Animacionet e punëtorëve të ndërtimit jashtë shkollës ecin përreth ndërtesës dhe dy ikona të vogla shfaqen për të përfaqësuar riparimin ose zëvendësimin: një çelës dhe një shkollë me një yll.)`,
      km: `(គំនូរជីវចលរបស់កម្មករសំណង់នៅខាងក្រៅសាលាដើរជុំវិញអគារ ហើយរូបតំណាងតូចៗពីរលេចឡើងដើម្បីតំណាងឱ្យការជួសជុល ឬការជំនួស៖ wrench និងសាលារៀនដែលមានផ្កាយ។ )`,
      fr: `(Des animations d'ouvriers du bâtiment à l'extérieur de l'école marchent autour du bâtiment et deux petites icônes apparaissent pour représenter la réparation ou le remplacement : une clé et une école avec une étoile.)`,
      ar: `(رسوم متحركة لعمال البناء خارج المدرسة يتجولون حول المبنى ويظهر رمزان صغيران لتمثيل الإصلاح أو الاستبدال: مفتاح ربط ومدرسة بنجمة.)`,
      en: `(Animations of construction workers outside the school walk around the building and two small icons pop up to represent repair or replacement: a wrench and a school with a star.)`,
    },
    text6: {
      es: `Los equipos visitaron las instalaciones para recopilar datos sobre el estado de los principales sistemas, incluidos: la estructura del edificio, el techo, los rociadores, la iluminación, el cableado, el control de la temperatura y la ventilación, por nombrar algunos (los íconos de cada sistema aparecen cuando Kathy los nombra).`,
      "zh-CN": `团队访问了这些设施，以收集有关主要系统状况的数据，包括：建筑结构、屋顶、洒水装置、照明、布线、温度控制和通风等（每个系统的图标显示为 Kathy 命名它们）。`,
      vi: `Các đội đã đến thăm các cơ sở để thu thập dữ liệu về tình trạng của các hệ thống chính bao gồm: cấu trúc tòa nhà, mái nhà, vòi phun nước, hệ thống chiếu sáng, hệ thống dây điện, kiểm soát nhiệt độ và thông gió, để đặt tên cho một số hệ thống (biểu tượng của mỗi hệ thống xuất hiện dưới tên Kathy đặt tên cho chúng).`,
      ru: `Команды посетили объекты для сбора данных о состоянии основных систем, включая: конструкцию здания, кровлю, спринклеры, освещение, электропроводку, контроль температуры и вентиляцию, и это лишь некоторые из них (значки каждой системы появляются так, как их называет Кэти).`,
      sq: `Ekipet vizituan objektet për të mbledhur të dhëna mbi gjendjen e sistemeve kryesore, duke përfshirë: strukturën e ndërtesës, çatinë, spërkatës, ndriçimin, instalimet elektrike, kontrollin e temperaturës dhe ventilimin, për të përmendur disa (ikona e secilit sistem shfaqen siç i emëron Kathy).`,
      km: `ក្រុមការងារបានចុះទៅពិនិត្យកន្លែងនានា ដើម្បីប្រមូលទិន្នន័យអំពីស្ថានភាពនៃប្រព័ន្ធសំខាន់ៗ រួមមានៈ រចនាសម្ព័ន្ធអគារ ដំបូល ប្រក់ទឹក ភ្លើង ខ្សែភ្លើង ការគ្រប់គ្រងសីតុណ្ហភាព និងខ្យល់ចេញចូល ដើម្បីកំណត់ឈ្មោះមួយចំនួន (រូបតំណាងនៃប្រព័ន្ធនីមួយៗលេចឡើងដូចដែល Kathy ដាក់ឈ្មោះពួកគេ)។`,
      fr: `Les équipes ont visité les installations pour recueillir des données sur l'état des principaux systèmes, notamment : la structure du bâtiment, la toiture, les gicleurs, l'éclairage, le câblage, le contrôle de la température et la ventilation, pour n'en nommer que quelques-uns (les icônes de chaque système apparaissent comme Kathy les nomme).`,
      ar: `زارت الفرق المرافق لجمع البيانات عن حالة الأنظمة الرئيسية بما في ذلك: هيكل المبنى ، والسقوف ، والرشاشات ، والإضاءة ، والأسلاك ، والتحكم في درجة الحرارة ، والتهوية ، على سبيل المثال لا الحصر (تظهر أيقونات كل نظام باسم كاثي).`,
      en: `The teams visited the facilities to collect data on the condition of major systems including: building structure, roofing, sprinklers, lighting, wiring, temperature control, and ventilation, to name a few (icons of each system appear as Kathy names them).`,
    },
    text7: {
      es: `Después de evaluar el estado del edificio, a cada escuela se le asigna una puntuación de cero a 100 (una barra verde horizontal se llena de cero a 92 %, como ejemplo).`,
      "zh-CN": `在评估了建筑物的状况后，每所学校的分数从 0 到 100 分（例如，水平绿色条从 0 填充到 92%）。`,
      vi: `Sau khi đánh giá tình trạng của tòa nhà, mỗi trường được cho điểm từ 0 đến 100 (ví dụ như một thanh ngang màu xanh lá cây lấp đầy từ 0 đến 92%).`,
      ru: `После оценки состояния здания каждой школе присваивается балл от нуля до 100 (например, горизонтальная зеленая полоса заполняется от нуля до 92%).`,
      sq: `Pas vlerësimit të gjendjes së ndërtesës, secilës shkollë i jepet një pikë nga zero në 100 (një shirit jeshil horizontal mbushet nga zero në 92%, si shembull).`,
      km: `បន្ទាប់ពីវាយតម្លៃស្ថានភាពអគារ សាលារៀននីមួយៗត្រូវបានផ្តល់ពិន្ទុពីសូន្យដល់ 100 (របារពណ៌បៃតងផ្តេកបំពេញពីសូន្យដល់ 92% ជាឧទាហរណ៍)។`,
      fr: `Après évaluation de l'état du bâtiment, chaque école reçoit une note de zéro à 100 (une barre verte horizontale se remplit de zéro à 92 %, par exemple).`,
      ar: `بعد تقييم حالة المبنى ، تُمنح كل مدرسة درجة من صفر إلى 100 (شريط أخضر أفقي يملأ من صفر إلى 92٪ ، كمثال).`,
      en: `After evaluating the condition of the building, each school is given a score from zero to 100 (a horizontal green bar fills up from zero to 92%, as an example).`,
    },
    text8: {
      es: `Cuanto mayor sea el número, más tiempo estará el edificio al servicio de la enseñanza y el aprendizaje para nuestro personal y estudiantes.`,
      "zh-CN": `数字越高，大楼为我们的教职员工和学生提供教学和学习服务的时间就越长。`,
      vi: `Con số này càng cao thì công trình sẽ càng lâu dài để phục vụ cho việc dạy và học cho cán bộ và học sinh của chúng ta.`,
      ru: `Чем выше число, тем дольше здание будет служить для обучения и обучения наших сотрудников и студентов.`,
      sq: `Sa më i madh të jetë numri, aq më gjatë do të jetë ndërtesa në shërbim të mësimdhënies dhe mësimnxënies për stafin dhe studentët tanë.`,
      km: `ចំនួនកាន់តែខ្ពស់ អគារនេះកាន់តែប្រើប្រាស់បានយូរក្នុងការបម្រើការបង្រៀន និងការរៀនសម្រាប់បុគ្គលិក និងសិស្សរបស់យើង។`,
      fr: `Plus le nombre est élevé, plus le bâtiment sera au service de l'enseignement et de l'apprentissage pour notre personnel et nos étudiants.`,
      ar: `كلما زاد العدد ، كلما طالت مدة خدمة المبنى للتدريس والتعلم لموظفينا وطلابنا.`,
      en: `The higher the number, the longer the building will be in service to teaching and learning for our staff and students.`,
    },
    text9: {
      es: `(Aparece una tabla que muestra las calificaciones de la FCA. Las puntuaciones de 90 a 100 son Excelente, Bueno es 80-89, Regular es 70-79, Pobre es 60-69 e Insatisfactorio es menos de 60.`,
      "zh-CN": `（出现一个显示 FCA 评级的表格。90-100 的分数是优秀的，好是 80-89，一般是 70-79，差是 60-69，不满意是小于 60。`,
      vi: `(Một bảng xuất hiện hiển thị Xếp hạng FCA. Điểm từ 90-100 là Xuất sắc, Tốt là 80-89, Khá là 70-79, Kém là 60-69 và Không đạt là dưới 60.`,
      ru: `(Появится таблица, в которой показаны рейтинги FCA. Оценки от 90 до 100 — это «отлично», «хорошо» — 80–89, «удовлетворительно» — 70–79, «плохо» — 60–69 и «неудовлетворительно» — меньше 60.`,
      sq: `(Shfaqet një tabelë që tregon vlerësimet e FCA. Rezultatet nga 90-100 janë Shkëlqyeshëm, Mirë është 80-89, E drejtë është 70-79, Dobët është 60-69 dhe E pakënaqshme është më pak se 60.`,
      km: `(តារាងមួយលេចឡើងដែលបង្ហាញពីការវាយតម្លៃ FCA ។ ពិន្ទុពី 90-100 គឺល្អឥតខ្ចោះ ល្អគឺ 80-89 យុត្តិធម៌គឺ 70-79 អន់គឺ 60-69 ហើយមិនពេញចិត្តគឺតិចជាង 60 ។`,
      fr: `(Un tableau apparaît indiquant les notes de la FCA. Les notes de 90 à 100 sont excellentes, bonnes de 80 à 89, acceptables de 70 à 79, mauvaises de 60 à 69 et insatisfaisantes de moins de 60.`,
      ar: `(يظهر جدول يوضح تصنيفات هيئة السلوك المالي الفيدرالي (FCA). الدرجات من 90-100 ممتازة ، جيدة 80-89 ، مقبول 70-79 ، ضعيف 60-69 ، وغير مرض أقل من 60.`,
      en: `(A table appears that shows the FCA Ratings. Scores from 90-100 are Excellent, Good is 80-89, Fair is 70-79, Poor is 60-69, and Unsatisfactory is less than 60.`,
    },
    text10: {
      es: `Luego, la pantalla cambia a un fondo blanco y aparece el logotipo del Distrito Escolar de Filadelfia).`,
      "zh-CN": `然后屏幕变为白色背景，并出现费城学区徽标。）`,
      vi: `Sau đó, màn hình chuyển sang nền trắng và logo của Học khu Philadelphia xuất hiện.)`,
      ru: `Затем экран становится белым и появляется логотип школьного округа Филадельфии.)`,
      sq: `Ekrani më pas kthehet në një sfond të bardhë dhe shfaqet logoja e Distriktit Shkollor të Filadelfias.)`,
      km: `បន្ទាប់មកអេក្រង់ប្រែទៅជាផ្ទៃខាងក្រោយពណ៌ស ហើយស្លាកសញ្ញា School District of Philadelphia លេចឡើង។)`,
      fr: `L'écran passe alors à un fond blanc et le logo du district scolaire de Philadelphie apparaît.)`,
      ar: `تتحول الشاشة بعد ذلك إلى خلفية بيضاء ويظهر شعار مقاطعة فيلادلفيا التعليمية.)`,
      en: `The screen then turns to a white background and the School District of Philadelphia logo appears.)`,
    },
    title2: {
      es: `Evaluación de la idoneidad educativa`,
      "zh-CN": `教育适宜性评估`,
      vi: `Đánh giá tính phù hợp giáo dục`,
      ru: `Оценка пригодности для образования`,
      sq: `Vlerësimi i Përshtatshmërisë Arsimore`,
      km: `ការវាយតម្លៃភាពសមស្របនៃការអប់រំ`,
      fr: `Évaluation de la pertinence scolaire`,
      ar: `تقييم الملاءمة التعليمية`,
      en: `Educational Suitability Assessment`,
    },

    text11: {
      es: `(En letra grande en el centro de una pantalla blanca, aparece el texto Proceso de planificación de instalaciones. Arriba está el logotipo del Distrito Escolar de Filadelfia. La siguiente pantalla muestra el texto Medición de la calidad de una instalación escolar con cuatro íconos debajo del texto. Uno hay una mochila rosa con una Evaluación de idoneidad educativa escrita debajo, una es un tubo curvo azul con una Evaluación de la condición de las instalaciones debajo, la tercera es una pizarra verde con símbolos matemáticos con un puntaje combinado ponderado debajo y, por último, hay una animación rosa de un edificio escolar con Pronóstico de inscripción y Utilización debajo de él. Todos los íconos aparecen en gris, excepto la Evaluación de idoneidad educativa.`,
      "zh-CN": `（在白色屏幕中央以大字体显示设施规划过程文本。其上方是费城学区徽标。下一个屏幕显示文本测量学校设施的质量，文本下方有四个图标。一个一个粉红色的背包，下面写着教育适合性评估，一个是蓝色的弯管，下面是设施状况评估，第三个是绿色的黑板，上面有数学符号，下面是加权综合分数，最后是一个粉红色的动画学校建筑，其下方有入学预测和利用率。除教育适宜性评估外，所有图标均显示为灰色。`,
      vi: `(Bằng phông chữ lớn ở giữa màn hình trắng, dòng chữ Quy trình Hoạch định Cơ sở vật chất xuất hiện. Phía trên là biểu trưng của Học khu Philadelphia. Màn hình tiếp theo hiển thị văn bản Đo lường Chất lượng của Cơ sở Trường học với bốn biểu tượng bên dưới văn bản. Một là một chiếc ba lô màu hồng với phần Đánh giá Mức độ Phù hợp Giáo dục được viết bên dưới, một cái là một cái ống cong màu xanh lam với Đánh giá Tình trạng Cơ sở vật chất bên dưới, cái thứ ba là một bảng đen màu xanh lá cây với các ký hiệu toán học với Điểm Kết hợp Có Trọng số bên dưới, và cuối cùng là hình ảnh động màu hồng của một tòa nhà trường học với Dự báo tuyển sinh và sử dụng bên dưới. Tất cả các biểu tượng sau đó sẽ chuyển sang màu xám, ngoại trừ Đánh giá mức độ phù hợp giáo dục.`,
      ru: `(В центре белого экрана крупным шрифтом отображается текст «Процесс планирования объектов». Над ним находится логотип школьного округа Филадельфии. На следующем экране отображается текст «Измерение качества школьного объекта» с четырьмя значками под текстом. Один представляет собой розовый рюкзак с надписью «Оценка пригодности для образования», под ним одна представляет собой синюю изогнутую трубу с оценкой состояния объектов под ней, третья — зеленая классная доска с математическими символами на ней и взвешенной комбинированной оценкой под ней, и, наконец, есть розовая анимация школьное здание с прогнозом зачисления и использованием под ним Все значки затемнены серым цветом, за исключением оценки пригодности для образования.`,
      sq: `(Me font të madh në qendër të një ekrani të bardhë, shfaqet teksti Facilities Planning Process. Mbi të është logoja e Distriktit Shkollor të Filadelfias. Ekrani tjetër tregon tekstin Matja e cilësisë së një objekti shkollor me katër ikona poshtë tekstit. Njëra është një çantë shpine rozë me Vlerësimin e Përshtatshmërisë Arsimore të shkruar poshtë saj, njëra është një tub i lakuar blu me Vlerësimin e Gjendjes së Objekteve poshtë, i treti është një dërrasë e gjelbër me simbole matematikore mbi të me Rezultatin e Kombinuar të Ponderuar poshtë, dhe së fundi ka një animacion rozë të një godina e shkollës me Parashikimin e Regjistrimit dhe Përdorimin poshtë saj. Më pas të gjitha ikonat janë gri, me përjashtim të Vlerësimit të Përshtatshmërisë Arsimore.`,
      km: `(នៅក្នុងពុម្ពអក្សរធំនៅចំកណ្តាលអេក្រង់ពណ៌ស អត្ថបទដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារនឹងលេចចេញមក។ ខាងលើវាជានិមិត្តសញ្ញាសាលាក្រុង Philadelphia។ អេក្រង់បន្ទាប់បង្ហាញអត្ថបទ វាស់គុណភាពនៃបរិក្ខារសាលា ដែលមានរូបតំណាងបួននៅខាងក្រោមអត្ថបទមួយ។ កាបូបស្ពាយពណ៌ផ្កាឈូកជាមួយនឹងការវាយតម្លៃភាពសមស្របនៃការអប់រំដែលសរសេរនៅខាងក្រោមវា មួយគឺជាបំពង់កោងពណ៌ខៀវដែលមានការវាយតម្លៃលក្ខខណ្ឌគ្រឿងបរិក្ខារនៅពីក្រោម ទីបីគឺជាក្ដារខៀនពណ៌បៃតងដែលមាននិមិត្តសញ្ញាគណិតវិទ្យានៅលើវាជាមួយនឹងពិន្ទុរួមបញ្ចូលគ្នានៅខាងក្រោម ហើយចុងក្រោយមានចលនាពណ៌ផ្កាឈូកនៃ អគារសិក្សាដែលមានការព្យាករណ៍ចុះឈ្មោះចូលរៀន និងការប្រើប្រាស់នៅខាងក្រោមវា។ រូបតំណាងទាំងអស់ត្រូវបានដាក់ជាពណ៌ប្រផេះ លើកលែងតែការវាយតម្លៃភាពសមស្របនៃការអប់រំ។`,
      fr: `(En gros caractères au centre d'un écran blanc, le texte Processus de planification des installations apparaît. Au-dessus se trouve le logo du district scolaire de Philadelphie. L'écran suivant affiche le texte Mesurer la qualité d'une installation scolaire avec quatre icônes sous le texte. Une est un sac à dos rose avec une évaluation d'aptitude éducative écrite en dessous, l'un est un tuyau incurvé bleu avec une évaluation de l'état des installations en dessous, le troisième est un tableau vert avec des symboles mathématiques dessus avec un score combiné pondéré en dessous, et enfin il y a une animation rose d'un bâtiment de l'école avec Prévision d'inscription et Utilisation en dessous. Toutes les icônes sont alors grisées, à l'exception de l'Évaluation de l'adéquation éducative.`,
      ar: `(بخط كبير في وسط شاشة بيضاء ، يظهر النص "عملية تخطيط المرافق". وفوقه يوجد شعار مقاطعة فيلادلفيا التعليمية. تُظهر الشاشة التالية النص "قياس جودة منشأة مدرسية" مع وجود أربعة أيقونات أسفل النص. واحد هي حقيبة ظهر وردية اللون مكتوبًا تحتها تقييم الملاءمة التعليمية ، إحداها عبارة عن أنبوب منحني أزرق تحته تقييم حالة المرافق ، والثالثة عبارة عن سبورة خضراء عليها رموز رياضية مع مجموع نقاط مرجحة تحتها ، وأخيرًا هناك رسم متحرك وردي اللون مبنى المدرسة مع توقع التسجيل والاستخدام تحته. يتم بعد ذلك إظهار جميع الرموز باللون الرمادي ، باستثناء تقييم الملاءمة التعليمية.`,
      en: `(In large font in the center of a white screen, the text Facilities Planning Process appears. Above it is the School District of Philadelphia logo.The next screen shows the text Measuring the Quality of a School Facility with four icons below the text. One is a pink backpack with Educational Suitability Assessment written below it, one is a blue curved pipe with Facilities Condition Assessment underneath, the third is a green chalkboard with math symbols on it with Weighted Combined Score underneath, and lastly there is a pink animation of a school building with Enrollment Forecast and Utilization below it. All icons are then grayed out, except for Educational Suitability Assessment.`,
    },
    text12: {
      es: `La pantalla se vuelve blanca y aparece una animación de una mujer con una camisa rosa claro y pantalones celestes. Se detiene frente a un edificio escolar animado con dos árboles al fondo.)`,
      "zh-CN": `屏幕变白，一个身穿浅粉色衬衫和浅蓝色裤子的女人的动画出现在屏幕上。她停在一座以两棵树为背景的动画教学楼前。）`,
      vi: `Màn hình chuyển sang màu trắng và hình ảnh động của một người phụ nữ mặc áo sơ mi hồng nhạt và quần xanh nhạt đi trên màn hình. Cô ấy dừng lại trước một tòa nhà hoạt hình với hai cái cây ở phía sau.)`,
      ru: `Экран становится белым, и по экрану ходит анимация женщины в светло-розовой рубашке и голубых брюках. Она останавливается перед анимированным школьным зданием с двумя деревьями на заднем плане.)`,
      sq: `Ekrani bëhet i bardhë dhe një animacion i një gruaje me një këmishë rozë të lehtë dhe pantallona blu të lehta shfaqet në ekran. Ajo ndalon përpara një ndërtese shkolle të animuar me dy pemë në sfond.)`,
      km: `អេក្រង់ប្រែជាពណ៌ស ហើយគំនូរជីវចលរបស់នារីម្នាក់ក្នុងអាវពណ៌ផ្កាឈូកស្រាល និងខោពណ៌ខៀវស្រាលដើរលើអេក្រង់។ នាងឈប់នៅមុខអគារសិក្សាដែលមានចលនាដែលមានដើមឈើពីរនៅខាងក្រោយ។ )`,
      fr: `L'écran devient blanc et une animation d'une femme vêtue d'une chemise rose clair et d'un pantalon bleu clair apparaît à l'écran. Elle s'arrête devant un bâtiment scolaire animé avec deux arbres en arrière-plan.)`,
      ar: `تتحول الشاشة إلى اللون الأبيض ، وتظهر صورة متحركة لامرأة ترتدي قميصًا زهريًا فاتحًا وبنطالًا أزرق فاتح تمشي على الشاشة. تتوقف أمام مبنى مدرسة متحرك مع شجرتين في الخلفية).`,
      en: `The screen turns white, and an animation of a woman in a light pink shirt and light blue pants walks on screen. She stops in front of an animated school building with two trees in the background.)`,
    },
    text13: {
      es: `¡Hola! Mi nombre es Yvette y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia determinó qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela.`,
      "zh-CN": `你好！我的名字是 Yvette，我在这里与您分享费城学区如何确定我们的学校设施如何支持每所学校的教学计划。`,
      vi: `Xin chào! Tên tôi là Yvette, và tôi có mặt ở đây để chia sẻ với bạn cách Học khu Philadelphia xác định cơ sở vật chất trường học của chúng tôi hỗ trợ tốt như thế nào đối với các chương trình giảng dạy tại mỗi trường.`,
      ru: `Привет! Меня зовут Иветт, и я здесь, чтобы поделиться с вами тем, как школьный округ Филадельфии определил, насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе.`,
      sq: `Përshëndetje! Emri im është Yvette dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfias përcaktoi se sa mirë ambientet tona shkollore mbështesin programet mësimore në secilën shkollë.`,
      km: `សួស្តី! ឈ្មោះរបស់ខ្ញុំគឺ Yvette ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែលសាលាស្រុក Philadelphia បានកំណត់ថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា។`,
      fr: `Salut! Je m'appelle Yvette et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a déterminé dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école.`,
      ar: `أهلاً! اسمي إيفيت ، وأنا هنا لأشارككم كيف حددت مقاطعة فيلادلفيا التعليمية مدى جودة دعم مرافق مدرستنا للبرامج التعليمية في كل مدرسة.`,
      en: `Hi! My name is Yvette, and I’m here to share with you how the School District of Philadelphia determined how well our school facilities support the instructional programs at each school.`,
    },
    text14: {
      es: `A esto lo llamamos una Evaluación de Idoneidad Educativa, o ESA. Para determinar esto, un equipo de profesionales de Parsons Environmental and Infrastructure Group recorrió todas y cada una de las escuelas del distrito para evaluar el entorno de aprendizaje, el tamaño, la ubicación, el almacenamiento y el equipo en cada espacio dentro de la escuela.`,
      "zh-CN": `我们称之为教育适宜性评估，或 ESA。为了确定这一点，帕森斯环境和基础设施集团的一个专业团队走访了该地区的每所学校，以评估学校内每个空间的学习环境、规模、位置、存储和设备。`,
      vi: `Chúng tôi gọi đây là Đánh giá Tính phù hợp Giáo dục, hoặc ESA. Để xác định điều này, một nhóm các chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons đã đi qua từng trường học trong học khu để đánh giá môi trường học tập, quy mô, vị trí, kho chứa và thiết bị trong từng không gian trong trường.`,
      ru: `Мы называем это оценкой пригодности для образования, или ESA. Чтобы определить это, команда профессионалов из Parsons Environmental and Infrastructure Group осмотрела каждую школу в округе, чтобы оценить учебную среду, размер, расположение, хранение и оборудование в каждом пространстве школы.`,
      sq: `Ne e quajmë këtë një Vlerësim të Përshtatshmërisë Arsimore, ose ESA. Për të përcaktuar këtë, një ekip profesionistësh nga Parsons Environmental and Infrastructure Group kaloi nëpër secilën shkollë në rreth për të vlerësuar mjedisin mësimor, madhësinë, vendndodhjen, ruajtjen dhe pajisjet në çdo hapësirë ​​brenda shkollës.`,
      km: `យើងហៅវាថា ការវាយតម្លៃភាពសមស្របនៃការអប់រំ ឬ ESA ។ ដើម្បីកំណត់នេះ ក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group បានដើរឆ្លងកាត់សាលារៀននីមួយៗក្នុងសង្កាត់ ដើម្បីវាយតម្លៃបរិយាកាសសិក្សា ទំហំ ទីតាំង កន្លែងស្តុកទុក និងឧបករណ៍ក្នុងចន្លោះនីមួយៗនៅក្នុងសាលា។`,
      fr: `Nous appelons cela une évaluation de la pertinence éducative, ou ESA. Pour déterminer cela, une équipe de professionnels du Parsons Environmental and Infrastructure Group a parcouru chaque école du district pour évaluer l'environnement d'apprentissage, la taille, l'emplacement, le stockage et l'équipement dans chaque espace de l'école.`,
      ar: `نسمي هذا تقييم الملاءمة التعليمية ، أو ESA. لتحديد ذلك ، قام فريق من المهنيين من Parsons Environmental and Infrastructure Group بالتجول في كل مدرسة في المنطقة لتقييم بيئة التعلم والحجم والموقع والتخزين والمعدات في كل مساحة داخل المدرسة.`,
      en: `We call this an Educational Suitability Assessment, or ESA.  To determine this, a team of professionals from Parsons Environmental and Infrastructure Group walked through each and every school in the district to assess the learning environment, size, location, storage, and equipment in each space within the school.`,
    },
    text15: {
      es: `(Las animaciones de los trabajadores de la construcción fuera de la escuela caminan alrededor del edificio y los íconos parecen representar lo que Parsons está evaluando, incluido un aro de baloncesto para el entorno de aprendizaje, flechas dentro de un cuadrado que apunta en cuatro direcciones para el tamaño, el lado del edificio de la escuela con una estrella para ubicación, estantes bajos para almacenamiento y una silla para el equipo).`,
      "zh-CN": `（学校外建筑工人的动画在大楼周围走动，图标似乎代表了 Parsons 正在评估的内容，包括用于学习环境的篮球框，正方形内的箭头指向四个方向的大小，校舍的侧面有一颗星位置，用于存储的低架子和用于设备的椅子。）`,
      vi: `(Hình ảnh động các công nhân xây dựng bên ngoài trường học đi quanh tòa nhà và các biểu tượng xuất hiện để đại diện cho những gì Parsons đang đánh giá, bao gồm một vòng bóng rổ cho môi trường học tập, các mũi tên trong hình vuông chỉ về bốn hướng cho kích thước, cạnh của tòa nhà có một ngôi sao cho vị trí, giá thấp để lưu trữ và một chiếc ghế cho thiết bị.)`,
      ru: `(Анимации строителей за пределами школы ходят по зданию, и значки, кажется, представляют то, что оценивает Парсонс, включая баскетбольное кольцо для учебной среды, стрелки внутри квадрата, указывающие в четырех направлениях для размера, сторона школьного здания со звездой для место, низкий стеллаж для хранения и стул для оборудования.)`,
      sq: `(Animacionet e punëtorëve të ndërtimit jashtë shkollës ecin rreth ndërtesës dhe ikonat duket se përfaqësojnë atë që po vlerëson Parsons, duke përfshirë një unazë basketbolli për mjedisin mësimor, shigjeta brenda një katrori që tregojnë në katër drejtime për madhësinë, anën e ndërtesës së shkollës me një yll për vendndodhja, raftet e ulëta për ruajtje dhe një karrige për pajisje.)`,
      km: `(គំនូរជីវចលរបស់កម្មករសំណង់នៅខាងក្រៅសាលាដើរជុំវិញអគារ ហើយរូបតំណាងហាក់ដូចជាតំណាងឱ្យអ្វីដែល Parsons កំពុងវាយតម្លៃ រួមទាំងបាល់បោះសម្រាប់បរិយាកាសសិក្សា ព្រួញក្នុងរង្វង់ចង្អុលទៅទិសបួនសម្រាប់ទំហំ ចំហៀងអគារសិក្សាដែលមានផ្កាយសម្រាប់ ទីតាំង ធ្នើរទាបសម្រាប់ផ្ទុក និងកៅអីសម្រាប់ឧបករណ៍។ )`,
      fr: `(Des animations de travailleurs de la construction à l'extérieur de l'école marchent autour du bâtiment et des icônes semblent représenter ce que Parsons évalue, y compris un panier de basket pour l'environnement d'apprentissage, des flèches dans un carré pointant dans quatre directions pour la taille, le côté du bâtiment de l'école avec une étoile pour emplacement, des étagères basses pour le rangement et une chaise pour l'équipement.)`,
      ar: `(الرسوم المتحركة لعمال البناء خارج المدرسة يتجولون حول المبنى ويبدو أن الأيقونات تمثل ما يقيمه بارسونز ، بما في ذلك طوق كرة السلة لبيئة التعلم ، والأسهم داخل مربع تشير إلى أربعة اتجاهات للحجم ، وجانب مبنى المدرسة بنجمة الموقع ، ورفوف منخفضة للتخزين ، وكرسي للمعدات.)`,
      en: `(Animations of construction workers outside the school walk around the building and icons appear to represent what Parsons is assessing, including a basketball hoop for learning environment, arrows within a square pointing in four directions for size, the side of school building with a star for location, low shelving for storage, and a chair for equipment.)`,
    },
    text16: {
      es: `¡Esto incluyó aulas de educación general y especial, centros de medios, gimnasios, laboratorios de ciencias, salas de arte, espacios de artes escénicas, espacios al aire libre y más!`,
      "zh-CN": `这包括普通和特殊教育教室、媒体中心、健身房、科学实验室、艺术室、表演艺术空间、户外空间等等！`,
      vi: `Điều này bao gồm các phòng học giáo dục phổ thông và giáo dục đặc biệt, trung tâm truyền thông, phòng tập thể dục, phòng thí nghiệm khoa học, phòng nghệ thuật, không gian biểu diễn nghệ thuật, không gian ngoài trời, và hơn thế nữa!`,
      ru: `Сюда входили классы общего и специального образования, медиацентры, спортивные залы, научные лаборатории, художественные залы, помещения для исполнительских искусств, открытые площадки и многое другое!`,
      sq: `Këtu përfshiheshin klasat e arsimit të përgjithshëm dhe special, qendrat e medias, palestrat, laboratorët shkencorë, dhomat e artit, hapësirat e arteve interpretuese, hapësirat e jashtme dhe më shumë!`,
      km: `នេះរួមបញ្ចូលទាំងថ្នាក់អប់រំទូទៅ និងពិសេស មជ្ឈមណ្ឌលប្រព័ន្ធផ្សព្វផ្សាយ កន្លែងហាត់ប្រាណ បន្ទប់ពិសោធន៍វិទ្យាសាស្ត្រ បន្ទប់សិល្បៈ កន្លែងសំដែងសិល្បៈ កន្លែងក្រៅ និងច្រើនទៀត!`,
      fr: `Cela comprenait des salles de classe d'enseignement général et spécial, des centres multimédias, des gymnases, des laboratoires scientifiques, des salles d'art, des espaces d'arts du spectacle, des espaces extérieurs, et plus encore !`,
      ar: `وشمل ذلك الفصول الدراسية للتعليم العام والخاص ، ومراكز الوسائط ، وصالات الألعاب الرياضية ، ومختبرات العلوم ، وغرف الفنون ، ومساحات الفنون المسرحية ، والمساحات الخارجية ، والمزيد!`,
      en: `This included general and special education classrooms, media centers, gyms, science labs, art rooms, performing arts spaces, outdoor spaces, and more!`,
    },
    text17: {
      es: `(El texto parece representar cada espacio dentro de la escuela. Para las aulas de educación general y especial y los centros de medios aparece una imagen adicional que muestra un aula y un centro de computadoras/medios con sillas y escritorios).`,
      "zh-CN": `（文字似乎代表了学校内的每个空间。对于普通和特殊教育教室和媒体中心，会出现一个额外的图像，显​​示一个教室和一个带椅子和桌子的计算机/媒体中心。）`,
      vi: `(Văn bản dường như đại diện cho từng không gian trong trường học. Đối với các lớp học phổ thông và giáo dục đặc biệt và các trung tâm truyền thông, một hình ảnh bổ sung sẽ hiển thị cho thấy một lớp học và một trung tâm máy tính / phương tiện với ghế và bàn.)`,
      ru: `(Появляется текст, представляющий каждое пространство в школе. Для классов общего и специального образования и медиацентров появляется дополнительное изображение, показывающее классную комнату и компьютерный/медиацентр со стульями и партами.)`,
      sq: `(Teksti duket se përfaqëson çdo hapësirë ​​brenda shkollës. Për klasat e arsimit të përgjithshëm dhe special dhe qendrat mediatike shfaqet një imazh shtesë që tregon një klasë dhe një qendër kompjuteri/media me karrige dhe tavolina.)`,
      km: `(អត្ថបទហាក់ដូចជាតំណាងឱ្យចន្លោះនីមួយៗនៅក្នុងសាលារៀន។ សម្រាប់ថ្នាក់រៀនទូទៅ និងពិសេស និងមជ្ឈមណ្ឌលប្រព័ន្ធផ្សព្វផ្សាយ រូបភាពបន្ថែមលេចឡើងដែលបង្ហាញថ្នាក់រៀន និងកុំព្យូទ័រ/ប្រព័ន្ធផ្សព្វផ្សាយដែលមានកៅអី និងតុ។)`,
      fr: `(Le texte semble représenter chaque espace de l'école. Pour les salles de classe d'enseignement général et spécialisé et les centres multimédias, une image supplémentaire apparaît qui montre une salle de classe et un centre informatique/média avec des chaises et des bureaux.)`,
      ar: `(يبدو أن النص يمثل كل مساحة داخل المدرسة. بالنسبة للفصول الدراسية للتعليم العام والخاص ومراكز الوسائط ، تظهر صورة إضافية تُظهر فصلًا دراسيًا ومركز كمبيوتر / وسائط به كراسي ومكاتب.)`,
      en: `(Text appears to represent each space within the school. For general and special education classrooms and media centers an additional image appears that shows a classroom and a computer/media center with chairs and desks.)`,
    },
    text18: {
      es: `Ahora, echemos un vistazo a un salón de clases.`,
      "zh-CN": `现在，让我们看一个教室。`,
      vi: `Bây giờ, chúng ta hãy nhìn vào một lớp học.`,
      ru: `Теперь давайте посмотрим на классную комнату.`,
      sq: `Tani, le të shohim një klasë.`,
      km: `ឥឡូវនេះសូមក្រឡេកមើលថ្នាក់រៀន។`,
      fr: `Maintenant, regardons une salle de classe.`,
      ar: `الآن ، دعونا نلقي نظرة على فصل دراسي.`,
      en: `Now, let’s look at a classroom.`,
    },
    text19: {
      es: `(Yvette aparece dentro de un salón de clases con una pizarra, dos ventanas, estantes bajos y 8 escritorios).`,
      "zh-CN": `（伊维特出现在一间带黑板、两扇窗户、矮架子和 8 张桌子的教室里。）`,
      vi: `(Yvette xuất hiện bên trong một lớp học với bảng phấn, hai cửa sổ, giá đỡ thấp và 8 bàn học.)`,
      ru: `(Ивет появляется в классе с классной доской, двумя окнами, низкими полками и 8 столами.)`,
      sq: `(Yvette shfaqet brenda një klase me një dërrasë, dy dritare, rafte të ulëta dhe 8 tavolina.)`,
      km: `(Yvette លេច​ឡើង​នៅ​ក្នុង​ថ្នាក់​រៀន​ដែល​មាន​ក្តារខៀន បង្អួច​ពីរ ធ្នើរ​ទាប និង​តុ 8 ។ )`,
      fr: `(Yvette apparaît dans une salle de classe avec un tableau noir, deux fenêtres, des étagères basses et 8 bureaux.)`,
      ar: `(تظهر إيفيت داخل فصل دراسي به سبورة ، ونافذتان ، ورفوف منخفضة ، و 8 مكاتب.)`,
      en: `(Yvette appears inside a classroom with a chalkboard, two windows, low-shelving, and 8 desks.)`,
    },
    text20: {
      es: `El evaluador hizo preguntas como:`,
      "zh-CN": `评估员提出了以下问题：`,
      vi: `Người đánh giá đã hỏi những câu hỏi như:`,
      ru: `Оценщик задавал такие вопросы:`,
      sq: `Vlerësuesi bëri pyetje si:`,
      km: `អ្នកវាយតម្លៃបានសួរសំណួរដូចជា៖`,
      fr: `L'évaluateur a posé des questions telles que :`,
      ar: `سأل المقيم أسئلة مثل:`,
      en: `The evaluator asked questions like:`,
    },
    text21: {
      es: `¿Está bien iluminado? Se muestran rayos de luz, brillando desde el dispositivo de iluminación.`,
      "zh-CN": `光线充足吗？显示光线，从照明设备发出。`,
      vi: `Nó có đủ ánh sáng không? Các tia sáng được chiếu, chiếu ra từ thiết bị chiếu sáng.`,
      ru: `Он хорошо освещен? Показаны световые лучи, исходящие от осветительного прибора.`,
      sq: `A është ndriçuar mirë? Tregohen rrezet e dritës, që shkëlqejnë nga pajisja e ndriçimit.`,
      km: `តើវាភ្លឺល្អទេ? កាំរស្មីពន្លឺត្រូវបានបង្ហាញ ចាំងពីឧបករណ៍បំភ្លឺ។`,
      fr: `Est-ce bien éclairé ? Les rayons lumineux sont affichés, brillant de l'appareil d'éclairage.`,
      ar: `هل هي مضاءة جيدا؟ يتم عرض أشعة الضوء ، تتلألأ من وحدة الإضاءة.`,
      en: `Is it well lit? Light rays are shown, shining from the lighting fixture.`,
    },
    text211: {
      es: `¿Está bien ventilado? El viento entra por la ventana.`,
      "zh-CN": `通风良好？ 风从窗户进来。`,
      vi: `Thông gió thích hợp? Gió thổi qua cửa sổ.`,
      ru: `Хорошо проветривается? Ветер приходит через окно.`,
      sq: `Ajroset siç duhet? Era vjen nga dritarja.`,
      km: `មានខ្យល់ចេញចូលត្រឹមត្រូវ? ខ្យល់មកតាមបង្អួច។`,
      fr: `Bien aéré ? Le vent passe par la fenêtre.`,
      ar: `تهوية جيدة؟ تأتي الرياح من النافذة.`,
      en: `Properly ventilated? Wind comes through the window.`,
    },
    text22: {
      es: `¿Necesita pintura? Las paredes cambian a un color azul claro.`,
      "zh-CN": `需要油漆吗？ 墙壁变成浅蓝色。`,
      vi: `Nó có cần sơn không? Tường chuyển sang màu xanh nhạt.`,
      ru: `Нужна ли краска? Стены меняют цвет на светло-голубой.`,
      sq: `A ka nevojë për bojë? Muret ndryshojnë në një ngjyrë blu të hapur.`,
      km: `តើវាត្រូវការថ្នាំលាបទេ? ជញ្ជាំងផ្លាស់ប្តូរទៅជាពណ៌ខៀវខ្ចី។`,
      fr: `A-t-il besoin de peinture ? Les murs prennent une couleur bleu clair.`,
      ar: `هل تحتاج إلى طلاء؟ تتغير الجدران إلى اللون الأزرق الفاتح.`,
      en: `Does it need paint? The walls change to a light blue color. `,
    },
    text23: {
      es: `¿Cumple con los estándares de tamaño establecidos por el distrito? Aparecen flechas para medir el tamaño de la habitación.`,
      "zh-CN": `是否符合学区规定的尺寸标准？ 箭头似乎用来测量房间的大小。`,
      vi: `Nó có đáp ứng các tiêu chuẩn về kích thước do quận quy định không? Các mũi tên xuất hiện để đo kích thước của căn phòng.`,
      ru: `Соответствует ли размер стандартам, установленным районом? Появляются стрелки для измерения размера комнаты.`,
      sq: `A i plotëson standardet e madhësisë të përcaktuara nga distrikti? Shigjetat shfaqen për të matur madhësinë e dhomës.`,
      km: `តើវាបំពេញតាមស្តង់ដារទំហំដែលកំណត់ដោយស្រុកទេ? ព្រួញលេចឡើងដើម្បីវាស់ទំហំបន្ទប់។`,
      fr: `Répond-il aux normes de taille fixées par le district ? Des flèches apparaissent pour mesurer la taille de la pièce.`,
      ar: `هل يفي بمعايير الحجم التي حددتها المنطقة؟ يبدو أن الأسهم تقيس حجم الغرفة.`,
      en: `Does it meet the size standards set by the district? Arrows appear to measure the size of the room.`,
    },
    text24: {
      es: `Hace tiene el equipo adecuado y suficiente espacio de almacenamiento? La pizarra y la estantería tiemblan ligeramente.`,
      "zh-CN": `它有合适的设备和足够的存储空间吗？ 黑板和架子微微晃动。`,
      vi: `Nó có thiết bị phù hợp và đủ không gian lưu trữ không? Bảng đen và giá đỡ rung nhẹ.`,
      ru: `Есть ли у него подходящее оборудование и достаточно места для хранения? Доска и полки слегка трясутся.`,
      sq: `A ka pajisjet e duhura dhe hapësirë ​​të mjaftueshme magazinimi? Dërrasa e zezë dhe raftet dridhen pak.`,
      km: `តើវាមានឧបករណ៍ត្រឹមត្រូវ និងទំហំផ្ទុកគ្រប់គ្រាន់ទេ? ក្តារខៀន និងធ្នើរញ័របន្តិច។`,
      fr: `A-t-il le bon équipement et suffisamment d'espace de stockage ? Le tableau noir et les étagères tremblent légèrement.`,
      ar: `هل لديها المعدات المناسبة ومساحة تخزين كافية؟ اهتز السبورة والرفوف قليلاً.`,
      en: `Does it have the right equipment and enough storage space? The chalkboard and shelving shake slightly.`,
    },
    text25: {
      es: `¿Está en un lugar apropiado según las necesidades del programa?`,
      "zh-CN": `它是否根据程序需要在适当的位置？`,
      vi: `Nó có ở một vị trí thích hợp dựa trên nhu cầu của chương trình không?`,
      ru: `Находится ли он в подходящем месте в соответствии с потребностями программы?`,
      sq: `A është në një vend të përshtatshëm bazuar në nevojat e programit?`,
      km: `តើ​វា​ស្ថិត​ក្នុង​ទីតាំង​សមស្រប​តាម​តម្រូវការ​កម្មវិធី​ដែរ​ឬ​ទេ?`,
      fr: `Est-ce dans un endroit approprié en fonction des besoins du programme ?`,
      ar: `هل هو في موقع مناسب بناءً على احتياجات البرنامج؟`,
      en: `Is it in an appropriate location based on the program needs?`,
    },
    text26: {
      es: `(La escena cambia e Yvette está fuera del edificio de la escuela de nuevo.)`,
      "zh-CN": `（场景变了，伊薇特又出现在教学楼外。）`,
      vi: `(Cảnh thay đổi và Yvette lại ở bên ngoài trường học.)`,
      ru: `(Сцена меняется, и Иветта снова оказывается возле школьного здания.)`,
      sq: `(Skena ndryshon dhe Yvette është përsëri jashtë ndërtesës së shkollës.)`,
      km: `(កន្លែងកើតហេតុផ្លាស់ប្តូរ ហើយ Yvette នៅខាងក្រៅអគារសិក្សាម្តងទៀត។ )`,
      fr: `(La scène change et Yvette est à nouveau à l'extérieur du bâtiment de l'école.)`,
      ar: `(تغير المشهد وعادت إيفيت خارج مبنى المدرسة مرة أخرى).`,
      en: `(The scene changes and Yvette is outside the school building again.)`,
    },
    text27: {
      es: `La evaluación de idoneidad también incluye el exterior del edificio (el edificio tiembla un poco), los patrones de tráfico (aparecen detrás de la escuela caminos con coches circulando), el estacionamiento y el acceso a la escuela (aparecen aparcamientos detrás de la escuela), los patios de recreo (un el patio de recreo aparece a la izquierda de la escuela), áreas deportivas (una cancha de fútbol aparece a la izquierda del patio de recreo) y cuestiones de seguridad, como iluminación, señalización y entradas seguras (luces de la calle y señales de alto aparecen en la carretera).`,
      "zh-CN": `适宜性评估还包括建筑物外部（建筑物轻微晃动）、交通模式（有汽车驶过的道路出现在学校后面）、停车和进入学校（停车场出现在学校后面）、操场（一个操场出现在学校的左侧）、运动区（足球场出现在操场的左侧）以及安全问题，例如照明、标牌和安全入口（路灯和停车标志出现在道路上）。`,
      vi: `Đánh giá mức độ phù hợp cũng bao gồm bên ngoài của tòa nhà (tòa nhà rung chuyển một chút), mô hình giao thông (những con đường có ô tô chạy qua xuất hiện phía sau trường học), bãi đậu xe và lối vào trường (bãi đậu xe xuất hiện phía sau trường học), sân chơi (a sân chơi xuất hiện ở bên trái trường học), khu vực thể thao (sân bóng đá xuất hiện bên trái sân chơi) và các vấn đề an toàn, như ánh sáng, biển báo và lối vào an toàn (đèn đường và biển báo dừng xuất hiện trên đường).`,
      ru: `Оценка пригодности также включает внешний вид здания (здание немного трясется), схему движения (за школой появляются дороги с проезжающими автомобилями), парковку и подъезд к школе (за школой появляются автостоянки), игровые площадки ( игровая площадка появляется слева от школы), спортивные площадки (слева от игровой площадки появляется футбольное поле) и вопросы безопасности, такие как освещение, вывески и безопасные входы (на дороге появляются уличные фонари и знаки остановки).`,
      sq: `Vlerësimi i përshtatshmërisë përfshin gjithashtu pjesën e jashtme të ndërtesës (ndërtesa dridhet pak), modelet e trafikut (rrugët me makina që lëvizin shfaqen pas shkollës), parkimin dhe aksesin në shkollë (parkingjet shfaqen pas shkollës), këndet e lojërave (a këndi i lojërave shfaqet në të majtë të shkollës), zona atletike (një fushë futbolli shfaqet në të majtë të sheshit të lojërave) dhe çështjet e sigurisë, si ndriçimi, sinjalistika dhe hyrjet e sigurta (dritat e rrugëve dhe shenjat e ndalimit shfaqen në rrugë).`,
      km: `ការវាយតម្លៃភាពសមស្របក៏រួមបញ្ចូលផងដែរនូវផ្នែកខាងក្រៅនៃអគារ (អគារញ័របន្តិច) គំរូចរាចរណ៍ (ផ្លូវដែលមានរថយន្តបើកនៅខាងក្រោយសាលា) ចំណត និងការចូលទៅកាន់សាលារៀន (ចំណតរថយន្តលេចឡើងនៅខាងក្រោយសាលា) សួនកុមារ (ក។ សួនកុមារលេចឡើងនៅខាងឆ្វេងសាលារៀន) តំបន់កីឡា (ទីលានបាល់ទាត់លេចឡើងនៅខាងឆ្វេងនៃសួនកុមារ) និងបញ្ហាសុវត្ថិភាពដូចជាភ្លើង ផ្លាកសញ្ញា និងច្រកចូលសុវត្ថិភាព (ភ្លើងតាមដងផ្លូវ និងសញ្ញាឈប់លេចឡើងនៅលើផ្លូវ)។`,
      fr: `L'évaluation de l'adéquation comprend également l'extérieur du bâtiment (le bâtiment tremble un peu), les schémas de circulation (des routes avec des voitures passant par là apparaissent derrière l'école), le stationnement et l'accès à l'école (des parkings apparaissent derrière l'école), des terrains de jeux (un aire de jeux apparaît à gauche de l'école), des zones sportives (un terrain de football apparaît à gauche de l'aire de jeux) et des problèmes de sécurité, comme l'éclairage, la signalisation et les entrées sécurisées (des lampadaires et des panneaux d'arrêt apparaissent sur la route).`,
      ar: `يشمل تقييم الملاءمة أيضًا السطح الخارجي للمبنى (المبنى يهتز قليلاً) ، وأنماط المرور (تظهر الطرق مع السيارات التي تسير خلف المدرسة) ، ومواقف السيارات والوصول إلى المدرسة (تظهر مواقف السيارات خلف المدرسة) ، والملاعب (أ يظهر ملعب على يسار المدرسة) ، ومناطق رياضية (يظهر ملعب كرة قدم على يسار الملعب) ، ومشكلات تتعلق بالسلامة ، مثل الإضاءة واللافتات والمداخل الآمنة (تظهر أضواء الشوارع وعلامات التوقف على الطريق).`,
      en: `The suitability assessment also includes the outside of the building (the building shakes a little), traffic patterns (roads with cars driving by appear behind the school), parking and access to the school (parking lots appear behind the school), playgrounds (a playground appears to the left of the school), athletic areas (a soccer field appears to the left of the playground), and safety issues, like lighting, signage, and secure entrances (street lights and stop signs appear on the road).`,
    },
    text28: {
      es: `(La escena regresa para mostrar solo la escuela e Yvette.)`,
      "zh-CN": `（场景返回，只显示学校和伊薇特。）`,
      vi: `(Cảnh quay lại chỉ cho thấy trường học và Yvette.)`,
      ru: `(Сцена возвращается, чтобы показать только школу и Иветт.)`,
      sq: `(Skena kthehet për të treguar vetëm shkollën dhe Yvette.)`,
      km: `(ឈុតនេះត្រលប់ទៅបង្ហាញតែសាលានិង Yvette ។ )`,
      fr: `(La scène revient pour ne montrer que l'école et Yvette.)`,
      ar: `(يعود المشهد لإظهار المدرسة وإيفيت فقط).`,
      en: `(The scene returns to show just the school and Yvette.)`,
    },
    text29: {
      es: `Después de ver el interior y el exterior del edificio Y hablar con el director, cada escuela recibe una puntuación de cero a 100 (una barra naranja horizontal se llena de cero a 63 %, como ejemplo).`,
      "zh-CN": `在查看建筑物的内部和外部并与校长交谈后，每所学校的分数从 0 到 100 分（例如，水平橙色条从 0 到 63% 填充）。`,
      vi: `Sau khi xem bên trong và bên ngoài của tòa nhà VÀ nói chuyện với hiệu trưởng, mỗi trường được cho điểm từ 0 đến 100 (ví dụ như một thanh màu cam nằm ngang lấp đầy từ 0 đến 63%).`,
      ru: `После осмотра здания внутри и снаружи И разговора с директором каждой школе присваивается оценка от нуля до 100 (например, горизонтальная оранжевая полоса заполняется от нуля до 63%).`,
      sq: `Pasi të keni parë pjesën e brendshme dhe të jashtme të ndërtesës DHE duke folur me drejtorin, secilës shkollë i jepet një pikë nga zero në 100 (një shirit portokalli horizontal mbushet nga zero në 63%, si shembull).`,
      km: `បន្ទាប់ពីមើលខាងក្នុង និងខាងក្រៅអគារ ហើយនិយាយជាមួយនាយកសាលា សាលានីមួយៗត្រូវបានផ្តល់ពិន្ទុពីសូន្យដល់ 100 (របារពណ៌ទឹកក្រូចផ្តេកបំពេញពីសូន្យដល់ 63% ជាឧទាហរណ៍)។`,
      fr: `Après avoir vu l'intérieur et l'extérieur du bâtiment ET avoir parlé avec le directeur, chaque école reçoit un score de zéro à 100 (une barre orange horizontale se remplit de zéro à 63 %, par exemple).`,
      ar: `بعد مشاهدة داخل المبنى وخارجه والتحدث مع المدير ، تحصل كل مدرسة على درجة من صفر إلى 100 (شريط برتقالي أفقي يملأ من صفر إلى 63٪ ، كمثال).`,
      en: `After viewing the inside and outside of the building AND speaking with the principal, each school is given a score from zero to 100 (a horizontal orange bar fills up from zero to 63%, as an example).`,
    },
    text30: {
      es: `Cuanto más alto sea el puntaje, mejor el edificio satisface las necesidades de nuestro personal y estudiantes.`,
      "zh-CN": `分数越高，建筑越能满足我们教职员工和学生的需求。`,
      vi: `Điểm càng cao, tòa nhà càng đáp ứng tốt nhu cầu của nhân viên và sinh viên của chúng tôi.`,
      ru: `Чем выше балл, тем лучше здание отвечает потребностям наших сотрудников и студентов.`,
      sq: `Sa më i lartë të jetë rezultati, aq më mirë ndërtesa plotëson nevojat e stafit dhe studentëve tanë.`,
      km: `ពិន្ទុកាន់តែខ្ពស់ អគារកាន់តែល្អបំពេញតម្រូវការរបស់បុគ្គលិក និងសិស្សរបស់យើង។`,
      fr: `Plus le score est élevé, mieux le bâtiment répond aux besoins de notre personnel et de nos étudiants.`,
      ar: `كلما زادت النتيجة ، كان المبنى يلبي احتياجات موظفينا وطلابنا بشكل أفضل.`,
      en: `The higher the score, the better the building meets the needs of our staff and students.`,
    },
    text31: {
      es: `(Aparece una tabla que muestra las calificaciones de la ESA. Las puntuaciones de 90 a 100 son Excelente, Bueno es 80-89, Regular es 70-79, Pobre es 60-69 e Insatisfactorio es menos de 60. La pantalla luego se vuelve blanca fondo y aparece el logo del Distrito Escolar de Filadelfia.)`,
      "zh-CN": `（出现一个显示 ESA 评级的表格。从 90-100 的分数是优秀，好是 80-89，一般是 70-79，差是 60-69，不满意低于 60。然后屏幕变成白色背景和费城学区标志出现。）`,
      vi: `(Một bảng xuất hiện hiển thị Xếp hạng ESA. Điểm từ 90-100 là Xuất sắc, Tốt là 80-89, Khá là 70-79, Kém là 60-69 và Không đạt là dưới 60. Sau đó, màn hình chuyển sang màu trắng nền và logo của Học khu Philadelphia xuất hiện.)`,
      ru: `(Появится таблица, в которой показаны рейтинги ESA. Оценки от 90 до 100 — «Отлично», «Хорошо» — 80–89, «Удовлетворительно» — 70–79, «Плохо» — 60–69 и «Неудовлетворительно» — меньше 60. Затем экран становится белым. появляется фон и логотип школьного округа Филадельфии.)`,
      sq: `(Shfaqet një tabelë që tregon vlerësimet e ESA-së. Rezultatet nga 90-100 janë të shkëlqyera, mirë është 80-89, e drejtë është 70-79, e dobët është 60-69 dhe e pakënaqshme është më pak se 60. Më pas ekrani kthehet në të bardhë sfondi dhe shfaqet logoja e Distriktit Shkollor të Filadelfias.)`,
      km: `(តារាងមួយលេចឡើងដែលបង្ហាញពីការវាយតម្លៃ ESA ។ ពិន្ទុពី 90-100 គឺល្អឥតខ្ចោះ ល្អគឺ 80-89 យុត្តិធម៌គឺ 70-79 អន់គឺ 60-69 ហើយមិនពេញចិត្តគឺតិចជាង 60។ អេក្រង់បន្ទាប់មកប្រែទៅជាពណ៌ស។ ផ្ទៃ​ខាង​ក្រោយ និង​ស្លាក​សញ្ញា School District of Philadelphia លេច​ឡើង។)`,
      fr: `(Un tableau indiquant les notes ESA apparaît. Les notes de 90 à 100 sont excellentes, bonnes de 80 à 89, acceptables de 70 à 79, mauvaises de 60 à 69 et insatisfaisantes de moins de 60. L'écran devient alors blanc. arrière-plan et le logo du district scolaire de Philadelphie apparaît.)`,
      ar: `(يظهر جدول يوضح تقييمات ESA. الدرجات من 90-100 ممتازة ، جيدة 80-89 ، مقبول 70-79 ، ضعيف 60-69 ، وغير مرض أقل من 60. ثم تتحول الشاشة إلى اللون الأبيض الخلفية ويظهر شعار منطقة فيلادلفيا التعليمية.)`,
      en: `(A table appears that shows the ESA Ratings. Scores from 90-100 are Excellent, Good is 80-89, Fair is 70-79, Poor is 60-69, and Unsatisfactory is less than 60. The screen then turns to a white background and the School District of Philadelphia logo appears.)`,
    },
    title3: {
      es: `Puntuación combinada ponderada`,
      "zh-CN": `加权综合得分`,
      vi: `Điểm kết hợp có trọng số`,
      ru: `Взвешенный комбинированный балл`,
      sq: `Rezultati i ponderuar i kombinuar`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់`,
      fr: `Note combinée pondérée`,
      ar: `النتيجة المجمعة المرجحة`,
      en: `Weighted Combined Score`,
    },
    text32: {
      es: `(En letra grande en el centro de una pantalla blanca, aparece el texto Proceso de planificación de instalaciones. Arriba está el logotipo del Distrito Escolar de Filadelfia. La siguiente pantalla muestra el texto Medición de la calidad de una instalación escolar con cuatro íconos debajo del texto. Uno hay una mochila rosa con una Evaluación de idoneidad educativa escrita debajo, una es un tubo curvo azul con una Evaluación de la condición de las instalaciones debajo, la tercera es una pizarra verde con símbolos matemáticos con un puntaje combinado ponderado debajo y, por último, hay una animación rosa de un edificio escolar con el Pronóstico de Inscripción y la Utilización debajo de él. Todos los íconos se atenúan, excepto el Puntaje Combinado Ponderado.`,
      "zh-CN": `（在白色屏幕中央以大字体显示设施规划过程文本。其上方是费城学区徽标。下一个屏幕显示文本测量学校设施的质量，文本下方有四个图标。一个一个粉红色的背包，下面写着教育适合性评估，一个是蓝色的弯管，下面是设施状况评估，第三个是绿色的黑板，上面有数学符号，下面是加权综合分数，最后是一个粉红色的动画学校建筑，其下方有入学预测和利用率。除加权综合得分外，所有图标均显示为灰色。`,
      vi: `(Bằng phông chữ lớn ở giữa màn hình trắng, dòng chữ Quy trình Hoạch định Cơ sở vật chất xuất hiện. Phía trên là biểu trưng của Học khu Philadelphia. Màn hình tiếp theo hiển thị văn bản Đo lường Chất lượng của Cơ sở Trường học với bốn biểu tượng bên dưới văn bản. Một là một chiếc ba lô màu hồng với phần Đánh giá Mức độ Phù hợp Giáo dục được viết bên dưới, một cái là một cái ống cong màu xanh lam với Đánh giá Tình trạng Cơ sở vật chất bên dưới, cái thứ ba là một bảng đen màu xanh lá cây với các ký hiệu toán học với Điểm Kết hợp Có Trọng số bên dưới, và cuối cùng là hình ảnh động màu hồng của một tòa nhà trường học với Dự báo tuyển sinh và Sử dụng bên dưới. Tất cả các biểu tượng sau đó sẽ chuyển sang màu xám, ngoại trừ Điểm Kết hợp Có Trọng số.`,
      ru: `(В центре белого экрана крупным шрифтом отображается текст «Процесс планирования объектов». Над ним находится логотип школьного округа Филадельфии. На следующем экране отображается текст «Измерение качества школьного объекта» с четырьмя значками под текстом. Один представляет собой розовый рюкзак с надписью «Оценка пригодности для образования», под ним одна представляет собой синюю изогнутую трубу с оценкой состояния объектов под ней, третья — зеленая классная доска с математическими символами на ней и взвешенной комбинированной оценкой под ней, и, наконец, есть розовая анимация школьное здание с прогнозом зачисления и использованием под ним Все значки затемнены серым цветом, кроме взвешенного комбинированного балла.`,
      sq: `(Me font të madh në qendër të një ekrani të bardhë, shfaqet teksti Facilities Planning Process. Mbi të është logoja e Distriktit Shkollor të Filadelfias. Ekrani tjetër tregon tekstin Matja e cilësisë së një objekti shkollor me katër ikona poshtë tekstit. Njëra është një çantë shpine rozë me Vlerësimin e Përshtatshmërisë Arsimore të shkruar poshtë saj, njëra është një tub i lakuar blu me Vlerësimin e Gjendjes së Objekteve poshtë, i treti është një dërrasë e gjelbër me simbole matematikore mbi të me Rezultatin e Kombinuar të Ponderuar poshtë, dhe së fundi ka një animacion rozë të një godina e shkollës me Parashikimin e Regjistrimit dhe Përdorimin poshtë saj. Më pas të gjitha ikonat zbardhen, përveç rezultatit të ponderuar të kombinuar.`,
      km: `(នៅក្នុងពុម្ពអក្សរធំនៅចំកណ្តាលអេក្រង់ពណ៌ស អត្ថបទដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារនឹងលេចចេញមក។ ខាងលើវាជានិមិត្តសញ្ញាសាលាក្រុង Philadelphia។ អេក្រង់បន្ទាប់បង្ហាញអត្ថបទ វាស់គុណភាពនៃបរិក្ខារសាលា ដែលមានរូបតំណាងបួននៅខាងក្រោមអត្ថបទមួយ។ កាបូបស្ពាយពណ៌ផ្កាឈូកជាមួយនឹងការវាយតម្លៃភាពសមស្របនៃការអប់រំដែលសរសេរនៅខាងក្រោមវា មួយគឺជាបំពង់កោងពណ៌ខៀវដែលមានការវាយតម្លៃលក្ខខណ្ឌគ្រឿងបរិក្ខារនៅពីក្រោម ទីបីគឺជាក្ដារខៀនពណ៌បៃតងដែលមាននិមិត្តសញ្ញាគណិតវិទ្យានៅលើវាជាមួយនឹងពិន្ទុរួមបញ្ចូលគ្នានៅខាងក្រោម ហើយចុងក្រោយមានចលនាពណ៌ផ្កាឈូកនៃ អគារសិក្សាដែលមានការព្យាករណ៍ចុះឈ្មោះចូលរៀន និងការប្រើប្រាស់នៅខាងក្រោមវា។ រូបតំណាងទាំងអស់ត្រូវបានដាក់ជាពណ៌ប្រផេះ លើកលែងតែពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់។`,
      fr: `(En gros caractères au centre d'un écran blanc, le texte Processus de planification des installations apparaît. Au-dessus se trouve le logo du district scolaire de Philadelphie. L'écran suivant affiche le texte Mesurer la qualité d'une installation scolaire avec quatre icônes sous le texte. Une est un sac à dos rose avec une évaluation d'aptitude éducative écrite en dessous, l'un est un tuyau incurvé bleu avec une évaluation de l'état des installations en dessous, le troisième est un tableau vert avec des symboles mathématiques dessus avec un score combiné pondéré en dessous, et enfin il y a une animation rose d'un bâtiment de l'école avec les prévisions d'inscription et l'utilisation en dessous. Toutes les icônes sont alors grisées, à l'exception du score combiné pondéré.`,
      ar: `(بخط كبير في وسط شاشة بيضاء ، يظهر النص "عملية تخطيط المرافق". وفوقه يوجد شعار مقاطعة فيلادلفيا التعليمية. تُظهر الشاشة التالية النص "قياس جودة منشأة مدرسية" مع وجود أربعة أيقونات أسفل النص. واحد هي حقيبة ظهر وردية اللون مكتوبًا تحتها تقييم الملاءمة التعليمية ، إحداها عبارة عن أنبوب منحني أزرق تحته تقييم حالة المرافق ، والثالثة عبارة عن سبورة خضراء عليها رموز رياضية مع مجموع نقاط مرجحة تحتها ، وأخيرًا هناك رسم متحرك وردي اللون مبنى المدرسة مع توقع التسجيل والاستخدام تحته. يتم بعد ذلك إظهار جميع الرموز باللون الرمادي ، باستثناء النقاط المجمعة الموزونة.`,
      en: `(In large font in the center of a white screen, the text Facilities Planning Process appears. Above it is the School District of Philadelphia logo.The next screen shows the text Measuring the Quality of a School Facility with four icons below the text.  One is a pink backpack with Educational Suitability Assessment written below it, one is a blue curved pipe with Facilities Condition Assessment underneath, the third is a green chalkboard with math symbols on it with Weighted Combined Score underneath, and lastly there is a pink animation of a school building with Enrollment Forecast and Utilization below it. All icons are then grayed out, except for Weighted Combined Score.`,
    },
    text33: {
      es: `La pantalla se vuelve blanca y aparece una animación de una mujer con un suéter índigo, pantalones negros y zapatos rosas. Se detiene frente a un edificio escolar animado con dos árboles al fondo.)`,
      "zh-CN": `屏幕变白，一个身穿靛蓝毛衣、黑色裤子和粉色鞋子的女人的动画出现在屏幕上。她停在一座以两棵树为背景的动画教学楼前。）`,
      vi: `Màn hình chuyển sang màu trắng và hình ảnh động của một người phụ nữ mặc áo len màu chàm, quần đen và giày hồng đi trên màn hình. Cô ấy dừng lại trước một tòa nhà hoạt hình với hai cái cây ở phía sau.)`,
      ru: `Экран становится белым, и по экрану ходит анимация женщины в свитере цвета индиго, черных брюках и розовых туфлях. Она останавливается перед анимированным школьным зданием с двумя деревьями на заднем плане.)`,
      sq: `Ekrani bëhet i bardhë dhe një animacion i një gruaje me pulovër indigo, pantallona të zeza dhe këpucë rozë shfaqet në ekran. Ajo ndalon përpara një ndërtese shkolle të animuar me dy pemë në sfond.)`,
      km: `អេក្រង់ប្រែជាពណ៌ស ហើយគំនូរជីវចលរបស់នារីម្នាក់ក្នុងអាវយឺតពណ៌ទឹកប៊ិច ខោពណ៌ខ្មៅ និងស្បែកជើងពណ៌ផ្កាឈូកដើរលើអេក្រង់។ នាងឈប់នៅមុខអគារសិក្សាដែលមានចលនាដែលមានដើមឈើពីរនៅខាងក្រោយ។ )`,
      fr: `L'écran devient blanc et une animation d'une femme portant un pull indigo, un pantalon noir et des chaussures roses apparaît à l'écran. Elle s'arrête devant un bâtiment scolaire animé avec deux arbres en arrière-plan.)`,
      ar: `تتحول الشاشة إلى اللون الأبيض ، وتظهر صورة متحركة لامرأة ترتدي سترة باللون النيلي وبنطلون أسود وحذاء وردي تمشي على الشاشة. تتوقف أمام مبنى مدرسة متحرك مع شجرتين في الخلفية).`,
      en: `The screen turns white, and an animation of a woman in an indigo sweater, black pants, and pink shoes walks on screen. She stops in front of an animated school building with two trees in the background.)`,
    },
    text34: {
      es: `¡Hola! Mi nombre es Marcela, y estoy aquí para compartir con ustedes cómo el Distrito Escolar de Filadelfia calculó el puntaje combinado ponderado de las instalaciones para medir qué tan bien nuestros edificios están apoyando la enseñanza y el aprendizaje de nuestro personal y estudiantes.`,
      "zh-CN": `你好！我的名字是 Marcela，我在这里与您分享费城学区如何计算加权综合设施分数，以衡量我们的建筑物对教职员工和学生的教学和学习的支持程度。`,
      vi: `Xin chào! Tên tôi là Marcela, và tôi ở đây để chia sẻ với bạn cách Học khu Philadelphia tính Điểm Cơ sở vật chất Kết hợp Có Trọng số để đo lường mức độ hiệu quả của các tòa nhà của chúng tôi trong việc hỗ trợ giảng dạy và học tập cho nhân viên và học sinh của chúng tôi.`,
      ru: `Привет! Меня зовут Марсела, и я здесь, чтобы поделиться с вами тем, как Школьный округ Филадельфии рассчитал взвешенную оценку комбинированных объектов, чтобы измерить, насколько хорошо наши здания поддерживают преподавание и обучение наших сотрудников и учащихся.`,
      sq: `Përshëndetje! Emri im është Marcela dhe jam këtu për të ndarë me ju se si Distrikti Shkollor i Filadelfias llogariti rezultatin e ponderuar të pajisjeve të kombinuara për të matur se sa mirë ndërtesat tona po mbështesin mësimdhënien dhe të nxënit për stafin dhe studentët tanë.`,
      km: `សួស្តី! ខ្ញុំឈ្មោះ Marcela ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកពីរបៀបដែល School District of Philadelphia គណនាពិន្ទុឧបករណ៍រួមបញ្ចូលគ្នាដែលមានទម្ងន់ ដើម្បីវាស់ថាតើអគាររបស់យើងកំពុងគាំទ្រការបង្រៀន និងការរៀនសម្រាប់បុគ្គលិក និងសិស្សរបស់យើងបានល្អប៉ុណ្ណា។`,
      fr: `Salut! Je m'appelle Marcela et je suis ici pour partager avec vous comment le district scolaire de Philadelphie a calculé le score combiné pondéré des installations pour mesurer dans quelle mesure nos bâtiments soutiennent l'enseignement et l'apprentissage de notre personnel et de nos étudiants.`,
      ar: `أهلاً! اسمي مارسيلا ، وأنا هنا لأشارككم كيف قامت منطقة فيلادلفيا التعليمية بحساب نقاط التسهيلات المجمعة الموزونة لقياس مدى جودة دعم مبانينا للتعليم والتعلم لموظفينا وطلابنا.`,
      en: `Hi! My name is Marcela, and I’m here to share with you how the School District of Philadelphia calculated the Weighted Combined Facilities Score to measure how well our buildings are supporting teaching and learning for our staff and students.`,
    },
    text35: {
      es: `Si no ha tenido noticias de Yvette sobre la Evaluación de idoneidad educativa, o ESA (Yvette camina en la pantalla con un globo de diálogo que dice ESA), y Kathy sobre la Evaluación de las condiciones de las instalaciones, o FCA (Kathy camina en la pantalla con un globo de diálogo que dice dice FCA), querrá ver esos videos primero para comprender las partes que intervienen en los cálculos de los que hablaré aquí.`,
      "zh-CN": `如果您还没有从 Yvette 那里听到关于 Educational Suitability Assessment 或 ESA（Yvette 在屏幕上出现一个显示 ESA 的讲话泡泡）和 Kathy 关于设施状况评估或 FCA（Kathy 在屏幕上出现一个讲话泡泡的消息）的消息FCA 说），您需要先观看这些视频，以便了解我将在此处讨论的计算部分。`,
      vi: `Nếu bạn chưa nghe Yvette nói về Đánh giá mức độ phù hợp giáo dục hoặc ESA (Yvette bước trên màn hình với bong bóng nói có nội dung ESA) và Kathy về Đánh giá tình trạng cơ sở vật chất, hoặc FCA (Kathy bước trên màn hình với bong bóng nói FCA cho biết), bạn sẽ muốn xem những video đó trước tiên để bạn hiểu các phần đi vào các phép tính mà tôi sẽ nói ở đây.`,
      ru: `Если вы еще не слышали от Иветт об оценке пригодности для образования, или ESA (Иветт выходит на экран с выноской, которая говорит ESA), и от Кэти об оценке состояния помещений, или FCA (Кэти выходит на экран, с выноской, которая говорит FCA), вам нужно сначала посмотреть эти видео, чтобы вы поняли части, которые входят в расчеты, о которых я буду говорить здесь.`,
      sq: `Nëse nuk keni dëgjuar nga Yvette për Vlerësimin e Përshtatshmërisë Arsimore, ose ESA (Yvette ecën në ekran me një flluskë të folur që thotë ESA) dhe Kathy për Vlerësimin e Gjendjes së Objekteve, ose FCA (Kathy ecën në ekran me një flluskë të folur që thotë FCA), ju do të dëshironi t'i shikoni ato video fillimisht në mënyrë që të kuptoni pjesët që hyjnë në llogaritjet për të cilat do të flas këtu.`,
      km: `ប្រសិនបើអ្នកមិនទាន់បានឮពី Yvette អំពីការវាយតម្លៃភាពសមស្របនៃការអប់រំ ឬ ESA (Yvette ដើរលើអេក្រង់ជាមួយនឹងពពុះនិយាយដែលនិយាយថា ESA) ហើយ Kathy អំពីការវាយតម្លៃស្ថានភាពគ្រឿងបរិក្ខារ ឬ FCA (Kathy ដើរលើអេក្រង់ជាមួយនឹងពពុះនិយាយដែល និយាយថា FCA) អ្នកនឹងចង់មើលវីដេអូទាំងនោះជាមុនសិន ដូច្នេះអ្នកយល់ពីផ្នែកដែលចូលទៅក្នុងការគណនាដែលខ្ញុំនឹងនិយាយអំពីនៅទីនេះ។`,
      fr: `Si vous n'avez pas entendu parler d'Yvette à propos de l'évaluation de l'adéquation éducative, ou ESA (Yvette marche à l'écran avec une bulle qui dit ESA), et Kathy à propos de l'évaluation de l'état des installations, ou FCA (Kathy marche à l'écran avec une bulle qui dit dit FCA), vous voudrez d'abord regarder ces vidéos afin de comprendre les parties qui entrent dans les calculs dont je vais parler ici.`,
      ar: `إذا لم تكن قد سمعت من Yvette عن تقييم الملاءمة التعليمية ، أو ESA (تمشي Yvette على الشاشة بفقاعة تحدث تقول ESA) ، وكاثي عن تقييم حالة المرافق ، أو FCA (تمشي كاثي على الشاشة بفقاعة التحدث التي يقول FCA) ، فأنت تريد مشاهدة مقاطع الفيديو هذه أولاً حتى تفهم الأجزاء التي تدخل في الحسابات التي سأتحدث عنها هنا.`,
      en: `If you haven’t heard from Yvette about the Educational Suitability Assessment, or ESA (Yvette walks on screen with a speak bubble that says ESA), and Kathy about the Facilities Condition Assessment, or FCA (Kathy walks on screen with a speak bubble that says FCA), you’ll want to watch those videos first so you understand the parts that go into the calculations that I’ll talk about here.`,
    },
    text36: {
      es: `(La escena cambia a un fondo blanco y Marcela se para en un círculo gris con un cuadro curvo gris junto a ella. Aparecerán animaciones dentro del cuadro).`,
      "zh-CN": `（场景切换到白色背景，Marcela 站在一个灰色圆圈上，旁边有一个灰色曲线框。框内会出现动画。）`,
      vi: `(Cảnh chuyển sang nền trắng và Marcela đứng trên một vòng tròn màu xám với một hộp cong màu xám bên cạnh. Hình ảnh động bên trong hộp sẽ xuất hiện.)`,
      ru: `(Сцена переходит на белый фон, и Марсела стоит на сером круге с серым изогнутым прямоугольником рядом с ней. Внутри прямоугольника появится анимация.)`,
      sq: `(Skena kalon në një sfond të bardhë dhe Marcela qëndron në një rreth gri me një kuti gri të lakuar pranë saj. Brenda kutisë do të shfaqen animacionet.)`,
      km: `(ឈុតនេះប្តូរទៅផ្ទៃខាងក្រោយពណ៌ស ហើយ Marcela ឈរនៅលើរង្វង់ពណ៌ប្រផេះដែលមានប្រអប់រាងកោងពណ៌ប្រផេះនៅជាប់នឹងនាង។ ចលនានៅក្នុងប្រអប់នឹងលេចឡើង។ )`,
      fr: `(La scène passe à un arrière-plan blanc et Marcela se tient sur un cercle gris avec une boîte incurvée grise à côté d'elle. Dans la boîte, des animations apparaîtront.)`,
      ar: `(ينتقل المشهد إلى خلفية بيضاء ، وتقف مارسيلا على دائرة رمادية مع مربع رمادي منحني بجانبها. ستظهر داخل الصندوق الرسوم المتحركة.)`,
      en: `(The scene shifts to a white background, and Marcela stands on a gray circle with a gray curved box next to her. Within the box animations will appear.)`,
    },
    text37: {
      es: `Al evaluar las escuelas, el distrito toma en consideración tanto la ESA, qué tan bien nuestras instalaciones escolares apoyan los programas de instrucción en cada escuela (una mochila y las palabras Evaluación de idoneidad educativa aparecen en el recuadro gris), y la FCA, la condición física de los edificio y sus sistemas (al lado de la mochila ESA aparece un tubo curvo con las palabras Evaluación del estado de las instalaciones debajo).`,
      "zh-CN": `在评估学校时，学区会考虑 ESA，即我们的学校设施如何支持每所学校的教学计划（背包和教育适合性评估字样出现在灰色框中）和 FCA，即学校的身体状况建筑物及其系统（在 ESA 背包旁边出现一个弯曲的管道，下方有“设施状况评估”字样）。`,
      vi: `Khi đánh giá các trường học, học khu xem xét cả ESA, cơ sở vật chất trường học của chúng tôi hỗ trợ tốt như thế nào đối với các chương trình giảng dạy tại mỗi trường (một ba lô và dòng chữ Đánh giá Tính phù hợp Giáo dục xuất hiện trong hộp màu xám) và FCA, điều kiện vật chất của tòa nhà và các hệ thống của nó (bên cạnh ba lô ESA xuất hiện một đường ống cong với dòng chữ Đánh giá Tình trạng Cơ sở vật chất bên dưới).`,
      ru: `При оценке школ округ принимает во внимание как ESA, насколько хорошо наши школьные помещения поддерживают учебные программы в каждой школе (рюкзак и слова «Оценка пригодности для обучения» отображаются в сером поле), так и FCA, физическое состояние учащихся. здание и его системы (рядом с рюкзаком ЕКА появляется изогнутая труба со словами «Оценка состояния помещений» внизу).`,
      sq: `Në vlerësimin e shkollave, distrikti merr parasysh si ESA-në, sa mirë ambientet e shkollës sonë mbështesin programet mësimore në secilën shkollë (një çantë shpine dhe fjalët Vlerësimi i përshtatshmërisë arsimore shfaqen në kutinë gri), dhe FCA, gjendjen fizike të shkollës. ndërtesa dhe sistemet e saj (krahas çantës së shpinës ESA shfaqet një tub i lakuar me fjalët Facilities Condition Assessment poshtë).`,
      km: `ក្នុងការវាយតម្លៃសាលារៀន ស្រុកត្រូវយកមកពិចារណាទាំង ESA ថាតើបរិក្ខារសាលារបស់យើងគាំទ្រកម្មវិធីបង្រៀននៅសាលានីមួយៗបានល្អប៉ុណ្ណា (កាបូបស្ពាយ និងពាក្យការវាយតម្លៃសមភាពការអប់រំបង្ហាញក្នុងប្រអប់ប្រផេះ) និង FCA ស្ថានភាពរាងកាយរបស់ អគារ និងប្រព័ន្ធរបស់វា (នៅក្បែរកាបូបស្ពាយ ESA លេចចេញជាបំពង់កោងដែលមានពាក្យ Facilities Condition Assessment នៅខាងក្រោម)។`,
      fr: `Lors de l'évaluation des écoles, le district prend en considération à la fois l'ESA, dans quelle mesure nos installations scolaires soutiennent les programmes d'enseignement de chaque école (un sac à dos et les mots Évaluation de l'adéquation éducative apparaissent dans la case grise), et le FCA, la condition physique du bâtiment et ses systèmes (à côté du sac à dos de l'ESA apparaît un tuyau incurvé avec les mots Évaluation de l'état des installations en dessous).`,
      ar: `عند تقييم المدارس ، تأخذ المنطقة في الاعتبار كلاً من ESA ، ومدى جودة دعم منشآتنا المدرسية للبرامج التعليمية في كل مدرسة (تظهر حقيبة ظهر وكلمات تقييم ملاءمة التعليم في المربع الرمادي) ، و FCA ، الحالة المادية لـ المبنى وأنظمته (يظهر بجانب حقيبة الظهر ESA أنبوبًا منحنيًا تحته عبارة "تقييم حالة المرافق").`,
      en: `In evaluating schools, the district takes into consideration both the ESA, how well our school facilities support the instructional programs at each school (a backpack and the words Educational Suitability Assessment appear in the gray box), and the FCA, the physical condition of the building and its systems (beside the ESA backpack appears a curved pipe with the words Facilities Condition Assessment underneath).`,
    },
    text38: {
      es: `El puntaje combinado ponderado es un promedio ponderado de estos dos puntajes. (La ESA y la FCA se suman para obtener la misma puntuación combinada ponderada, que se representa mediante una pizarra con símbolos matemáticos).`,
      "zh-CN": `加权综合分数是这两个分数的加权平均值。 （ESA 和 FCA 相加得到相等的加权综合得分，由带有数学符号的黑板表示）。`,
      vi: `Điểm kết hợp có trọng số là điểm trung bình có trọng số của hai điểm này. (ESA và FCA được cộng lại với nhau thành Điểm Kết hợp Có Trọng số bằng nhau, được thể hiện bằng bảng đen với các ký hiệu toán học trên đó).`,
      ru: `Взвешенный комбинированный балл представляет собой средневзвешенное значение этих двух баллов. (ESA и FCA складываются вместе, чтобы получить равный взвешенный комбинированный балл, который представлен классной доской с математическими символами).`,
      sq: `Rezultati i ponderuar i kombinuar është një mesatare e ponderuar e këtyre dy pikëve. (ESA dhe FCA janë shtuar së bashku në rezultatin e barabartë të ponderuar të kombinuar, i cili përfaqësohet nga një dërrasë me simbole matematikore mbi të).`,
      km: `ពិន្ទុរួមបញ្ចូលគ្នាដែលមានទម្ងន់ គឺជាមធ្យមទម្ងន់នៃពិន្ទុទាំងពីរនេះ។ ( ESA និង FCA ត្រូវ​បាន​បូក​បញ្ចូល​គ្នា​ដើម្បី​ស្មើ​ពិន្ទុ​រួម​គ្នា​ដោយ​ទម្ងន់ ដែល​ត្រូវ​បាន​តំណាង​ដោយ​ក្ដារខៀន​ដែល​មាន​និមិត្តសញ្ញា​គណិតវិទ្យា​នៅ​លើ​វា)។`,
      fr: `Le score combiné pondéré est une moyenne pondérée de ces deux scores. (L'ESA et le FCA sont additionnés pour donner le score combiné pondéré, qui est représenté par un tableau noir avec des symboles mathématiques dessus).`,
      ar: `النتيجة المجمعة الموزونة هي متوسط ​​مرجح لهاتين الدرجتين. (يتم إضافة ESA و FCA معًا للحصول على نقاط مجمعة متساوية ، والتي يتم تمثيلها بواسطة السبورة مع رموز رياضية عليها).`,
      en: `The Weighted Combined Score is a weighted average of these two scores. (The ESA and FCA are added together to equal Weighted Combined Score, which is represented by a chalkboard with math symbols on it).`,
    },
    text39: {
      es: `Le asignamos un énfasis levemente mayor al puntaje de ESA (aparece un recuadro negro con un 60 % sobre la mochila de ESA) sobre el FCA (aparece un recuadro negro con un 40 % sobre el conducto de FCA) porque creemos que la capacidad del edificio para respaldar los programas educativos se le debe dar un poco más de peso que la condición física del edificio.`,
      "zh-CN": `我们对 ESA 分数（60% 的黑框出现在 ESA 背包上）的重视程度略高于 FCA（40% 的黑框出现在 FCA 管道上），因为我们认为建筑物支持教学计划的能力应该比建筑物的物理条件略重。`,
      vi: `Chúng tôi chỉ định điểm ESA cao hơn một chút (hộp đen có 60% xuất hiện trên ba lô ESA) so với FCA (hộp đen có 40% xuất hiện trên đường ống FCA) vì chúng tôi tin rằng khả năng hỗ trợ các chương trình giảng dạy của tòa nhà nên được cung cấp trọng lượng nhiều hơn một chút so với tình trạng vật chất của tòa nhà.`,
      ru: `Мы уделили несколько большее внимание оценке ESA (черный ящик с 60 % появляется над рюкзаком ESA), а не FCA (черный ящик с 40 % появляется над трубой FCA), потому что мы считаем, что способность здания поддерживать учебные программы следует придавать немного большее значение, чем физическому состоянию здания.`,
      sq: `Ne caktuam një theks pak më të lartë në rezultatin ESA (një kuti e zezë me 60% shfaqet mbi çantën e shpinës ESA) mbi FCA (një kuti e zezë me 40% shfaqet mbi tubin FCA) sepse ne besojmë se aftësia e ndërtesës për të mbështetur programet mësimore duhet t'i jepet pak më shumë peshë sesa gjendja fizike e ndërtesës.`,
      km: `យើងបានកំណត់ការសង្កត់ធ្ងន់ខ្ពស់បន្តិចលើពិន្ទុ ESA (ប្រអប់ខ្មៅដែលមាន 60% លេចឡើងនៅលើកាបូបស្ពាយ ESA) លើ FCA (ប្រអប់ខ្មៅដែលមាន 40% លេចឡើងនៅលើបំពង់ FCA) ពីព្រោះយើងជឿថាសមត្ថភាពរបស់អគារដើម្បីគាំទ្រកម្មវិធីអប់រំ។ គួរតែត្រូវបានផ្តល់ទម្ងន់ច្រើនជាងស្ថានភាពរាងកាយរបស់អាគារបន្តិច។`,
      fr: `Nous avons accordé une importance légèrement plus élevée au score ESA (une boîte noire avec 60 % apparaît sur le sac à dos ESA) par rapport à la FCA (une boîte noire avec 40 % apparaît sur le tuyau FCA) car nous pensons que la capacité du bâtiment à soutenir les programmes d'enseignement devrait avoir un peu plus de poids que l'état physique du bâtiment.`,
      ar: `لقد خصصنا تركيزًا أعلى قليلاً على درجة ESA (يظهر الصندوق الأسود بنسبة 60٪ فوق حقيبة الظهر ESA) على FCA (يظهر الصندوق الأسود بنسبة 40٪ فوق أنبوب FCA) لأننا نعتقد أن قدرة المبنى على دعم البرامج التعليمية يجب أن يُعطى وزنًا أكبر بقليل من الحالة المادية للمبنى.`,
      en: `We assigned a slightly higher emphasis on the ESA score (a black box with 60% appears over the ESA backpack) over the FCA (a black box with 40% appears over the FCA pipe) because we believe that the building’s ability to support instructional programs should be given slightly more weight than the building's physical condition.`,
    },
    text40: {
      es: `Cuanto más alto sea el puntaje ponderado combinado, mejor será la capacidad de la escuela para apoyar la enseñanza y el aprendizaje.`,
      "zh-CN": `综合加权分数越高，学校支持教与学的能力就越好。`,
      vi: `Điểm tổng hợp có trọng số càng cao thì khả năng hỗ trợ giảng dạy và học tập của trường càng tốt.`,
      ru: `Чем выше комбинированный взвешенный балл, тем выше способность школы поддерживать преподавание и обучение.`,
      sq: `Sa më i lartë të jetë Rezultati i Ponderuar i Kombinuar, aq më e mirë është aftësia e shkollës për të mbështetur mësimdhënien dhe të nxënit.`,
      km: `ពិន្ទុទម្ងន់រួមកាន់តែខ្ពស់ សមត្ថភាពរបស់សាលាកាន់តែប្រសើរឡើងក្នុងការគាំទ្រការបង្រៀន និងការរៀន។`,
      fr: `Plus le score pondéré combiné est élevé, meilleure est la capacité de l'école à soutenir l'enseignement et l'apprentissage.`,
      ar: `كلما زادت النتيجة المرجحة المجمعة ، زادت قدرة المدرسة على دعم التدريس والتعلم.`,
      en: `The higher the Combined Weighted Score, the better the school’s ability to support teaching and learning.`,
    },
    text41: {
      es: `(Una barra verde horizontal se llena de cero a 80 %, como ejemplo).`,
      "zh-CN": `（例如，水平绿色条从 0 填充到 80%。）`,
      vi: `(Ví dụ như một thanh màu xanh lá cây nằm ngang lấp đầy từ 0 đến 80%.)`,
      ru: `(Например, горизонтальная зеленая полоса заполняется от нуля до 80 %).`,
      sq: `(Një shirit jeshil horizontal mbushet nga zero në 80%, si shembull.)`,
      km: `(របារពណ៌បៃតងផ្តេកបំពេញពីសូន្យទៅ 80% ជាឧទាហរណ៍។ )`,
      fr: `(Une barre verte horizontale se remplit de zéro à 80 %, par exemple.)`,
      ar: `(يملأ الشريط الأخضر الأفقي من صفر إلى 80٪ ، كمثال.)`,
      en: `(A horizontal green bar fills up from zero to 80%, as an example.) `,
    },
    text42: {
      es: `Veamos un ejemplo. Aquí, tenemos una escuela con un puntaje de Evaluación de idoneidad educativa de 90 (el recuadro negro sobre la mochila de ESA muestra 90), lo que significa que la instalación está bien diseñada para proporcionar y apoyar los programas educativos ofrecidos (algunas sillas y estanterías aparecen brevemente).`,
      "zh-CN": `让我们看一个例子。在这里，我们有一所学校的教育适宜性评估得分为 90（ESA 背包上的黑框显示为 90），这意味着该设施经过精心设计，可以提供和支持所提供的教育计划（一些椅子和书架短暂出现） .`,
      vi: `Hãy xem một ví dụ. Ở đây, chúng tôi có một trường có điểm Đánh giá Tính phù hợp Giáo dục là 90 (hộp đen trên ba lô ESA hiển thị 90), có nghĩa là cơ sở được thiết kế tốt để cung cấp và hỗ trợ các chương trình giáo dục được cung cấp (một số ghế và giá đỡ xuất hiện trong thời gian ngắn) .`,
      ru: `Давайте посмотрим на пример. Здесь у нас есть школа с оценкой пригодности для образования, равной 90 (черный ящик над рюкзаком ESA показывает 90), что означает, что объект хорошо спроектирован для обеспечения и поддержки предлагаемых образовательных программ (на короткое время появляются некоторые стулья и стеллажи)`,
      sq: `Le të shohim një shembull. Këtu, ne kemi një shkollë me një vlerësim të përshtatshmërisë arsimore prej 90 (kutia e zezë mbi çantën e shpinës ESA tregon 90), që do të thotë se objekti është projektuar mirë për të siguruar dhe mbështetur programet arsimore të ofruara (disa karrige dhe rafte shfaqen shkurtimisht) .`,
      km: `សូមក្រឡេកមើលឧទាហរណ៍មួយ។ នៅទីនេះ យើងមានសាលារៀនមួយដែលមានពិន្ទុវាយតម្លៃភាពសមស្របនៃការអប់រំ 90 (ប្រអប់ខ្មៅនៅលើកាបូបស្ពាយ ESA បង្ហាញ 90) ដែលមានន័យថា កន្លែងនេះត្រូវបានរចនាឡើងយ៉ាងល្អដើម្បីផ្តល់ និងគាំទ្រដល់កម្មវិធីអប់រំដែលបានផ្តល់ជូន (កៅអី និងធ្នើរខ្លះលេចឡើងដោយសង្ខេប) .`,
      fr: `Prenons un exemple. Ici, nous avons une école avec un score d'évaluation de l'adéquation éducative de 90 (la boîte noire sur le sac à dos ESA indique 90), ce qui signifie que l'installation est bien conçue pour fournir et soutenir les programmes éducatifs proposés (certaines chaises et étagères apparaissent brièvement) .`,
      ar: `لنلقي نظرة على مثال. هنا ، لدينا مدرسة بدرجة تقييم ملاءمة تعليمية 90 (يظهر الصندوق الأسود فوق حقيبة الظهر ESA 90) ، مما يعني أن المرفق مصمم جيدًا لتوفير ودعم البرامج التعليمية المقدمة (تظهر بعض الكراسي والرفوف لفترة وجيزة) .`,
      en: `Let’s look at an example. Here, we have a school with an Educational Suitability Assessment score of 90 (the black box over the ESA backpack shows 90), which means the facility is well designed to provide for and support the educational programs offered (some chairs and shelving appear briefly).`,
    },
    text43: {
      es: `El puntaje de la evaluación de la condición de la instalación es 75 (el recuadro negro sobre la tubería FCA muestra 75), por lo que el edificio y/o algunos de sus sistemas están en buenas condiciones y requieren reparaciones menores a moderadas (los iconos aparecen brevemente para mostrar rociadores, luces, cableado). , un termostato y ventanas por las que entra el viento).`,
      "zh-CN": `设施状况评估得分为 75（FCA 管道上方的黑框显示为 75），因此建筑物和/或其某些系统状况良好，需要进行小到中度维修（图标短暂地显示喷水器、灯、接线，恒温器和有风流入的窗户）。`,
      vi: `Điểm Đánh giá Tình trạng Cơ sở là 75 (hộp đen trên đường ống FCA hiển thị 75), vì vậy tòa nhà và / hoặc một số hệ thống của nó đang ở trong tình trạng tốt và yêu cầu sửa chữa từ nhỏ đến trung bình (các biểu tượng xuất hiện nhanh chóng để hiển thị vòi phun nước, đèn chiếu sáng, hệ thống dây điện , máy điều nhiệt và cửa sổ có gió thổi vào).`,
      ru: `Оценка состояния объекта составляет 75 баллов (черный ящик над трубой FCA показывает 75 баллов), поэтому здание и/или некоторые его системы находятся в удовлетворительном состоянии и требуют ремонта от незначительного до среднего (на короткое время появляются значки, показывающие спринклеры, освещение, электропроводку). , термостат и окна с ветром).`,
      sq: `Rezultati i vlerësimit të gjendjes së objektit është 75 (kutia e zezë mbi tubin FCA tregon 75), kështu që ndërtesa dhe/ose disa nga sistemet e saj janë në gjendje të rregullt dhe kërkojnë riparime të vogla deri në mesatare (ikonat shfaqen shkurtimisht se tregojnë spërkatës, drita, instalime elektrike , një termostat dhe dritare me erë që rrjedh).`,
      km: `ពិន្ទុវាយតម្លៃលក្ខខណ្ឌនៃគ្រឿងបរិក្ខារគឺ 75 (ប្រអប់ខ្មៅនៅលើបំពង់ FCA បង្ហាញ 75) ដូច្នេះអគារ និង/ឬប្រព័ន្ធមួយចំនួនរបស់វាស្ថិតក្នុងស្ថានភាពសមរម្យ ហើយត្រូវការការជួសជុលតិចតួចដល់កម្រិតមធ្យម (រូបតំណាងបង្ហាញយ៉ាងខ្លីដើម្បីបង្ហាញឧបករណ៍បាញ់ទឹក ភ្លើង ខ្សែភ្លើង។ ទែម៉ូស្ដាត និងបង្អួចដែលមានខ្យល់បក់ចូល)។`,
      fr: `Le score d'évaluation de l'état des installations est de 75 (la boîte noire au-dessus du tuyau FCA indique 75), de sorte que le bâtiment et/ou certains de ses systèmes sont en bon état et nécessitent des réparations mineures à modérées (des icônes apparaissent brièvement pour montrer les gicleurs, les lumières, le câblage , un thermostat et des fenêtres dans lesquelles le vent entre).`,
      ar: `درجة تقييم حالة المنشأة هي 75 (يظهر الصندوق الأسود فوق أنبوب FCA 75) ، وبالتالي فإن المبنى و / أو بعض أنظمته في حالة جيدة ويتطلب إصلاحًا بسيطًا إلى متوسط ​​(تظهر الرموز لفترة وجيزة لإظهار الرشاشات والأضواء والأسلاك ، وترموستات ، ونوافذ تتدفق فيها الرياح).`,
      en: `The Facility Condition Assessment score is 75 (the black box over the FCA pipe shows 75), so the building and/or some of its systems are in fair condition and require minor to moderate repair (icons briefly appear to show sprinklers, lights, wiring, a thermostat, and windows with wind flowing in).`,
    },
    text44: {
      es: `(Las matemáticas parecen mostrar 60 % por 90 más 40 % por 75 para dar como resultado 84).`,
      "zh-CN": `（数学似乎显示 60% 乘以 90 加上 40% 乘以 75 等于 84。）`,
      vi: `(Phép toán hiển thị 60% nhân với 90 cộng với 40% nhân với 75 để bằng 84.)`,
      ru: `(Математика показывает, что 60% умножить на 90 плюс 40% умножить на 75, чтобы получить 84.)`,
      sq: `(Matematika duket se tregon 60% herë 90 plus 40% herë 75 deri në 84.)`,
      km: `(គណិតវិទ្យាបង្ហាញ 60% គុណ 90 បូក 40% គុណ 75 ស្មើ 84 ។ )`,
      fr: `(Les maths semblent montrer 60 % fois 90 plus 40 % fois 75 pour égaler 84.)`,
      ar: `(يبدو أن الرياضيات تظهر 60٪ مضروبة في 90 زائد 40٪ مضروبة في 75 لتساوي 84.)`,
      en: `(Math appears to show 60% times 90 plus 40% times 75  to equal 84.)`,
    },
    text45: {
      es: `El puntaje combinado ponderado de las instalaciones es 84, lo que clasifica a la escuela como en buenas condiciones (Marcela ahora está de pie junto a una escuela en el cuadro gris con el número 84 en verde con una marca de verificación verde).`,
      "zh-CN": `加权综合设施得分为 84，将学校归类为状况良好（Marcela 现在站在灰色框中的一所学校旁边，绿色数字 84 带有绿色复选标记）。`,
      vi: `Điểm Cơ sở vật chất Kết hợp Trọng số là 84, xếp loại trường học là trong tình trạng Tốt (Marcela hiện đang đứng cạnh một trường học trong ô màu xám với số 84 màu xanh lục với dấu kiểm màu xanh lá cây).`,
      ru: `Взвешенная комбинированная оценка составляет 84, что означает, что школа находится в хорошем состоянии (сейчас Марсела стоит рядом со школой в сером поле с номером 84 в зеленом цвете с зеленой галочкой).`,
      sq: `Rezultati i ponderuar i objekteve të kombinuara është 84, i cili e klasifikon shkollën si në gjendje të mirë (Marcela tani qëndron pranë një shkolle në kutinë gri me numrin 84 në të gjelbër me një shenjë të gjelbër).`,
      km: `ពិន្ទុគ្រឿងបរិក្ខាររួមបញ្ចូលគ្នាដែលមានទម្ងន់គឺ 84 ដែលចាត់ថ្នាក់សាលារៀនថាស្ថិតក្នុងស្ថានភាពល្អ (ឥឡូវនេះ Marcela កំពុងឈរក្បែរសាលារៀនក្នុងប្រអប់ពណ៌ប្រផេះដែលមានលេខ 84 ជាពណ៌បៃតងដែលមានសញ្ញាធីកពណ៌បៃតង)។`,
      fr: `Le score pondéré combiné des installations est de 84, ce qui classe l'école comme étant en bon état (Marcela se tient maintenant à côté d'une école dans la case grise avec le numéro 84 en vert avec une coche verte).`,
      ar: `تبلغ نتيجة المرافق المجمعة الموزونة 84 ، مما يصنف المدرسة على أنها في حالة جيدة (تقف مارسيلا الآن بجوار مدرسة في المربع الرمادي والرقم 84 باللون الأخضر مع علامة اختيار خضراء).`,
      en: `The Weighted Combined Facilities Score is 84, which classifies the school as being in Good condition (Marcela is now standing next to a school in the gray box with the number 84 in green with a green check mark).`,
    },
    text46: {
      es: `Clasificar nuestras escuelas de esta manera nos permite identificar qué escuelas necesitan renovación o se debe considerar su reemplazo (Aparece una tabla que muestra las calificaciones de puntaje combinado ponderado. Los puntajes de 90 a 100 son Excelentes, Bueno es 80-89, Regular es 70- 79, Pobre es 60-69 e Insatisfactorio es menos de 60). Luego, la pantalla cambia a un fondo blanco y aparece el logotipo del Distrito Escolar de Filadelfia).`,
      "zh-CN": `以这种方式对我们的学校进行分类使我们能够确定哪些学校需要翻新或应该考虑更换（出现一个显示加权综合评分等级的表格。90-100 分是优秀，80-89 分是好，70 分是一般 79，Poor 为 60-69，Unsatisfactory 小于 60）。 然后屏幕变为白色背景，并出现费城学区标志。）`,
      vi: `Việc phân loại các trường của chúng tôi theo cách này cho phép chúng tôi xác định những trường nào cần được cải tạo hoặc cần được xem xét để thay thế (Một bảng xuất hiện hiển thị xếp hạng Điểm Kết hợp Có Trọng số. Điểm từ 90-100 là Xuất sắc, Tốt là 80-89, Khá là 70- 79, Kém là 60-69 và Không đạt là dưới 60). Sau đó, màn hình chuyển sang nền trắng và logo của Học khu Philadelphia xuất hiện.)`,
      ru: `Классификация наших школ таким образом позволяет нам определить, какие школы нуждаются в ремонте или должны быть рассмотрены для замены (появляется таблица, в которой показаны рейтинги взвешенных комбинированных баллов. Баллы от 90 до 100 — отлично, хорошо — 80–89, удовлетворительно — 70–70). 79, Плохо — 60-69, Неудовлетворительно — меньше 60). Затем экран становится белым и появляется логотип школьного округа Филадельфии.)`,
      sq: `Klasifikimi i shkollave tona në këtë mënyrë na lejon të identifikojmë se cilat shkolla kanë nevojë për rinovim ose duhet të merren parasysh për zëvendësim (Shfaqet një tabelë që tregon vlerësimet e ponderuara të rezultateve të kombinuara. Rezultatet nga 90-100 janë të shkëlqyera, mirë është 80-89, e drejtë është 70- 79, Dobët është 60-69, dhe E pakënaqshme është më pak se 60). Ekrani më pas kthehet në një sfond të bardhë dhe shfaqet logoja e Distriktit Shkollor të Filadelfias.)`,
      km: `ការចាត់ថ្នាក់សាលារបស់យើងតាមរបៀបនេះអនុញ្ញាតឱ្យយើងកំណត់ថាសាលាណាដែលត្រូវការការជួសជុល ឬគួរត្រូវបានពិចារណាជំនួស (តារាងមួយលេចឡើងដែលបង្ហាញពីការវាយតម្លៃពិន្ទុរួម។ ពិន្ទុពី 90-100 គឺល្អឥតខ្ចោះ ល្អគឺ 80-89 យុត្តិធម៌គឺ 70- 79, ក្រគឺ 60-69, និង Unsatisfactory គឺតិចជាង 60) ។ បន្ទាប់មកអេក្រង់ប្រែទៅជាផ្ទៃខាងក្រោយពណ៌ស ហើយស្លាកសញ្ញា School District of Philadelphia លេចឡើង។)`,
      fr: `Classer nos écoles de cette manière nous permet d'identifier les écoles qui ont besoin d'être rénovées ou dont le remplacement devrait être envisagé (un tableau apparaît qui montre les notes pondérées combinées. Les notes de 90 à 100 sont excellentes, bonnes de 80 à 89, acceptables de 70 à 79, Pauvre est 60-69 et Insatisfaisant est inférieur à 60). L'écran passe alors à un fond blanc et le logo du district scolaire de Philadelphie apparaît.)`,
      ar: `يتيح لنا تصنيف مدارسنا بهذه الطريقة تحديد المدارس التي تحتاج إلى تجديد أو التي يجب التفكير في استبدالها (يظهر جدول يوضح تصنيفات الدرجات المجمعة. 79 ، ضعيف هو 60-69 ، وغير المرضي أقل من 60). تتحول الشاشة بعد ذلك إلى خلفية بيضاء ويظهر شعار مقاطعة فيلادلفيا التعليمية.)`,
      en: `Classifying our schools in this manner allows us to identify which schools need renovation or should be considered for replacement (A table appears that shows the Weighted Combined Score ratings. Scores from 90-100 are Excellent, Good is 80-89, Fair is 70-79, Poor is 60-69, and Unsatisfactory is less than 60). The screen then turns to a white background and the School District of Philadelphia logo appears.)`,
    },
    title4: {
      es: `Inscripción de estudiantes`,
      "zh-CN": `学生入学`,
      vi: `Ghi danh cho sinh viên`,
      ru: `Зачисление студентов`,
      sq: `Regjistrimi i studentëve`,
      km: `ការចុះឈ្មោះសិស្ស`,
      fr: `Inscription des étudiants`,
      ar: `تسجيل الطلاب`,
      en: `Student Enrollment`,
    },
    text47: {
      es: `(En letra grande en el centro de una pantalla blanca, aparece el texto Proceso de planificación de instalaciones. Arriba está el logotipo del Distrito Escolar de Filadelfia. La siguiente pantalla muestra el texto Medición de la calidad de una instalación escolar con cuatro íconos debajo del texto. Uno hay una mochila rosa con una Evaluación de idoneidad educativa escrita debajo, una es un tubo curvo azul con una Evaluación de la condición de las instalaciones debajo, la tercera es una pizarra verde con símbolos matemáticos con un puntaje combinado ponderado debajo y, por último, hay una animación rosa de un edificio escolar con el Pronóstico de Inscripción y la Utilización debajo de él. Todos los íconos se atenuarán, excepto el Pronóstico de Inscripción y la Utilización.`,
      "zh-CN": `（在白色屏幕中央以大字体显示设施规划过程文本。其上方是费城学区徽标。下一个屏幕显示文本测量学校设施的质量，文本下方有四个图标。一个一个粉红色的背包，下面写着教育适宜性评估，一个是蓝色的弯管，下面是设施状况评估，第三个是绿色的黑板，上面有数学符号，下面是加权综合分数，最后是一个粉红色的动画学校建筑，其下方有入学预测和利用率。除入学预测和利用率外，所有图标都显示为灰色。`,
      vi: `(Bằng phông chữ lớn ở giữa màn hình trắng, dòng chữ Quy trình Lập kế hoạch Cơ sở vật chất xuất hiện. Phía trên là biểu trưng của Học khu Philadelphia. Màn hình tiếp theo hiển thị văn bản Đo lường Chất lượng của Cơ sở Trường học với bốn biểu tượng bên dưới văn bản. Một là một chiếc ba lô màu hồng với phần Đánh giá Mức độ Phù hợp Giáo dục được viết bên dưới, một cái là một cái ống cong màu xanh lam với Đánh giá Tình trạng Cơ sở vật chất bên dưới, cái thứ ba là một bảng đen màu xanh lá cây với các ký hiệu toán học với Điểm Kết hợp Có Trọng số bên dưới, và cuối cùng là hình ảnh động màu hồng của một tòa nhà trường học với Dự báo tuyển sinh và Sử dụng bên dưới. Tất cả các biểu tượng sau đó sẽ chuyển sang màu xám, ngoại trừ Dự báo tuyển sinh và Sử dụng.`,
      ru: `(В центре белого экрана крупным шрифтом отображается текст «Процесс планирования объектов». Над ним находится логотип школьного округа Филадельфии. На следующем экране отображается текст «Измерение качества школьного объекта» с четырьмя значками под текстом. Один представляет собой розовый рюкзак с надписью «Оценка пригодности для образования», под ним одна представляет собой синюю изогнутую трубу с оценкой состояния объектов под ней, третья — зеленая классная доска с математическими символами на ней и взвешенной комбинированной оценкой под ней, и, наконец, есть розовая анимация школьное здание с прогнозом регистрации и использованием под ним Все значки затемнены серым цветом, кроме прогноза регистрации и использования.`,
      sq: `(Me font të madh në qendër të një ekrani të bardhë, shfaqet teksti Facilities Planning Process. Mbi të është logoja e Distriktit Shkollor të Filadelfisë. Ekrani tjetër tregon tekstin Matja e cilësisë së një objekti shkollor me katër ikona poshtë tekstit. Njëra është një çantë shpine rozë me Vlerësimin e Përshtatshmërisë Arsimore të shkruar poshtë saj, njëra është një tub i lakuar blu me Vlerësimin e Gjendjes së Objekteve poshtë, i treti është një dërrasë e gjelbër me simbole matematikore mbi të me Rezultatin e Kombinuar të Ponderuar poshtë, dhe së fundi ka një animacion rozë të një godina e shkollës me Parashikimin e Regjistrimit dhe Përdorimin poshtë saj. Më pas të gjitha ikonat janë gri, me përjashtim të Parashikimit të Regjistrimit dhe Përdorimit.`,
      km: `(នៅក្នុងពុម្ពអក្សរធំនៅកណ្តាលអេក្រង់ពណ៌ស អត្ថបទដំណើរការរៀបចំផែនការគ្រឿងបរិក្ខារនឹងលេចចេញមក។ ខាងលើវាជានិមិត្តសញ្ញាសាលាក្រុង Philadelphia។ អេក្រង់បន្ទាប់បង្ហាញអត្ថបទ វាស់គុណភាពនៃសម្ភារៈសាលាដែលមានរូបតំណាងបួននៅខាងក្រោមអត្ថបទមួយ។ កាបូបស្ពាយពណ៌ផ្កាឈូកជាមួយនឹងការវាយតម្លៃភាពសមស្របនៃការអប់រំដែលសរសេរនៅខាងក្រោមវា មួយគឺជាបំពង់កោងពណ៌ខៀវដែលមានការវាយតម្លៃលក្ខខណ្ឌគ្រឿងបរិក្ខារនៅពីក្រោម ទីបីគឺជាក្ដារខៀនពណ៌បៃតងដែលមាននិមិត្តសញ្ញាគណិតវិទ្យានៅលើវាជាមួយនឹងពិន្ទុរួមបញ្ចូលគ្នានៅខាងក្រោម ហើយចុងក្រោយមានចលនាពណ៌ផ្កាឈូកនៃ អគារសិក្សាដែលមានការព្យាករណ៍ចុះឈ្មោះចូលរៀន និងការប្រើប្រាស់នៅខាងក្រោមវា។ រូបតំណាងទាំងអស់ត្រូវបានដាក់ជាពណ៌ប្រផេះ លើកលែងតែការព្យាករណ៍ការចុះឈ្មោះ និងការប្រើប្រាស់។`,
      fr: `(En gros caractères au centre d'un écran blanc, le texte Processus de planification des installations apparaît. Au-dessus se trouve le logo du district scolaire de Philadelphie. L'écran suivant affiche le texte Mesurer la qualité d'une installation scolaire avec quatre icônes sous le texte. Une est un sac à dos rose avec une évaluation d'aptitude éducative écrite en dessous, l'un est un tuyau incurvé bleu avec une évaluation de l'état des installations en dessous, le troisième est un tableau vert avec des symboles mathématiques dessus avec un score combiné pondéré en dessous, et enfin il y a une animation rose d'un bâtiment scolaire avec Prévision d'inscription et Utilisation en dessous. Toutes les icônes sont alors grisées, à l'exception de Prévision d'inscription et Utilisation.`,
      ar: `(بخط كبير في وسط شاشة بيضاء ، يظهر النص "عملية تخطيط المرافق". وفوقه يوجد شعار مقاطعة فيلادلفيا التعليمية. تُظهر الشاشة التالية النص "قياس جودة مرفق المدرسة" مع وجود أربعة أيقونات أسفل النص. واحد هي حقيبة ظهر وردية اللون مكتوبًا تحتها تقييم الملاءمة التعليمية ، إحداها عبارة عن أنبوب منحني أزرق تحته تقييم حالة المرافق ، والثالثة عبارة عن سبورة خضراء عليها رموز رياضية مع مجموع نقاط مرجحة تحتها ، وأخيرًا هناك رسم متحرك وردي اللون مبنى المدرسة مع توقع التسجيل والاستخدام تحته. يتم بعد ذلك إظهار جميع الرموز باللون الرمادي ، باستثناء توقعات التسجيل والاستخدام.`,
      en: `(In large font in the center of a white screen, the text Facilities Planning Process appears. Above it is the School District of Philadelphia logo.The next screen shows the text Measuring the Quality of a School Facility with four icons below the text.  One is a pink backpack with Educational Suitability Assessment written below it, one is a blue curved pipe with Facilities Condition Assessment underneath, the third is a green chalkboard with math symbols on it with Weighted Combined Score underneath, and lastly there is a pink animation of a school building with Enrollment Forecast and Utilization below it. All icons are then grayed out, except for Enrollment Forecast and Utilization.`,
    },
    text48: {
      es: `La pantalla se vuelve blanca y aparece una animación de una mujer con una camisa rosa claro y pantalones celestes. Se detiene frente a un edificio escolar animado con dos árboles al fondo.)`,
      "zh-CN": `屏幕变白，一个身穿浅粉色衬衫和浅蓝色裤子的女人的动画出现在屏幕上。她停在一座以两棵树为背景的动画教学楼前。）`,
      vi: `Màn hình chuyển sang màu trắng và hình ảnh động của một người phụ nữ mặc áo sơ mi hồng nhạt và quần xanh nhạt đi trên màn hình. Cô ấy dừng lại trước một tòa nhà hoạt hình với hai cái cây ở phía sau.)`,
      ru: `Экран становится белым, и по экрану ходит анимация женщины в светло-розовой рубашке и голубых брюках. Она останавливается перед анимированным школьным зданием с двумя деревьями на заднем плане.)`,
      sq: `Ekrani bëhet i bardhë dhe një animacion i një gruaje me një këmishë rozë të lehtë dhe pantallona blu të lehta shfaqet në ekran. Ajo ndalon përpara një ndërtese shkolle të animuar me dy pemë në sfond.)`,
      km: `អេក្រង់ប្រែជាពណ៌ស ហើយគំនូរជីវចលរបស់នារីម្នាក់ក្នុងអាវពណ៌ផ្កាឈូកស្រាល និងខោពណ៌ខៀវស្រាលដើរលើអេក្រង់។ នាងឈប់នៅមុខអគារសិក្សាដែលមានចលនាដែលមានដើមឈើពីរនៅខាងក្រោយ។ )`,
      fr: `L'écran devient blanc et une animation d'une femme vêtue d'une chemise rose clair et d'un pantalon bleu clair apparaît à l'écran. Elle s'arrête devant un bâtiment scolaire animé avec deux arbres en arrière-plan.)`,
      ar: `تتحول الشاشة إلى اللون الأبيض ، وتظهر صورة متحركة لامرأة ترتدي قميصًا زهريًا فاتحًا وبنطالًا أزرق فاتح تمشي على الشاشة. تتوقف أمام مبنى مدرسة متحرك مع شجرتين في الخلفية).`,
      en: `The screen turns white, and an animation of a woman in a light pink shirt and light blue pants walks on screen. She stops in front of an animated school building with two trees in the background.)`,
    },
    text49: {
      es: `¡Hola! Mi nombre es Yvette y estoy aquí para compartir con ustedes el papel fundamental que desempeña la inscripción de estudiantes en el proceso de planificación de instalaciones para el distrito escolar de Filadelfia (la escena cambia para mostrar a Yvette en un círculo gris de pie junto a una caja curva gris que muestra un edificio escolar con calles a su alrededor y estudiantes de pie repartidos por diferentes calles).`,
      "zh-CN": `你好！我的名字是 Yvette，我在这里与您分享学生注册在费城学区的设施规划过程中所扮演的关键角色（场景转换为 Yvette 站在灰色曲线框旁边的灰色圆圈上这显示了一所学校建筑，周围有街道，学生们分散在不同的街道上）。`,
      vi: `Xin chào! Tên tôi là Yvette và tôi ở đây để chia sẻ với bạn vai trò quan trọng của việc Ghi danh cho Học sinh trong Quy trình Lập kế hoạch Cơ sở vật chất cho Học khu Philadelphia (Cảnh chuyển đổi để hiển thị Yvette trên một vòng tròn màu xám đứng cạnh một hộp cong màu xám điều đó cho thấy một tòa nhà trường học với các đường phố xung quanh nó và học sinh đứng tản ra qua các đường phố khác nhau).`,
      ru: `Привет! Меня зовут Иветт, и я здесь, чтобы поделиться с вами важной ролью, которую набор учащихся играет в процессе планирования объектов для школьного округа Филадельфии (сцена переходит, чтобы показать Иветт в сером круге, стоящем рядом с серым изогнутым прямоугольником). на котором изображено здание школы с улицами вокруг него и учащиеся, рассредоточенные по разным улицам).`,
      sq: `Përshëndetje! Emri im është Yvette dhe jam këtu për të ndarë me ju rolin kritik që luan Regjistrimi i Studentëve në Procesin e Planifikimit të Faciliteteve për Distriktin Shkollor të Filadelfisë (Skena kalon për të treguar Yvette në një rreth gri që qëndron pranë një kutie të lakuar gri që tregon një ndërtesë shkolle me rrugë rreth saj dhe nxënës të shtrirë nëpër rrugë të ndryshme).`,
      km: `សួស្តី! ឈ្មោះរបស់ខ្ញុំគឺ Yvette ហើយខ្ញុំនៅទីនេះដើម្បីចែករំលែកជាមួយអ្នកនូវតួនាទីសំខាន់ដែលការចុះឈ្មោះសិស្សដើរតួក្នុងដំណើរការរៀបចំផែនការសម្រាប់សាលាស្រុក Philadelphia (ឈុតឆាកផ្លាស់ប្តូរទៅបង្ហាញ Yvette នៅលើរង្វង់ពណ៌ប្រផេះឈរក្បែរប្រអប់កោងពណ៌ប្រផេះ ដែលបង្ហាញពីអគារសិក្សាដែលមានផ្លូវជុំវិញនោះ ហើយសិស្សកំពុងឈរនៅតាមដងផ្លូវផ្សេងៗគ្នា)។`,
      fr: `Salut! Je m'appelle Yvette et je suis ici pour partager avec vous le rôle essentiel que joue l'inscription des élèves dans le processus de planification des installations pour le district scolaire de Philadelphie (la scène se transforme pour montrer Yvette sur un cercle gris debout à côté d'une boîte incurvée grise qui montre un bâtiment scolaire avec des rues autour et des étudiants debout répartis dans différentes rues).`,
      ar: `أهلاً! اسمي إيفيت ، وأنا هنا لأشارككم الدور الحاسم الذي يلعبه تسجيل الطلاب في عملية تخطيط المرافق لمنطقة مدارس فيلادلفيا (ينتقل المشهد لإظهار إيفيت على دائرة رمادية بجانب صندوق رمادي منحني التي تُظهر مبنى مدرسة مع شوارع حوله ووقوف الطلاب منتشرين في شوارع مختلفة).`,
      en: `Hi! My name is Yvette, and I’m here to share with you the critical role that Student Enrollment plays in the Facilities Planning Process for the School District of Philadelphia (The scene transitions to show Yvette on a gray circle standing next to a gray curved box that shows a school building with streets around it and students standing spread out through different streets).`,
    },
    text50: {
      es: `La cantidad de estudiantes que una escuela puede albergar de manera segura y razonable depende del tamaño del edificio (las calles y los estudiantes desaparecen y las palabras School Capacity se ven con una escuela animada debajo. Junto a la escuela hay un cuadrado blanco que muestra el número 800 con un pequeño icono de silla). Esta es la capacidad del edificio, que fue medida en cada escuela por un equipo de profesionales de Parsons Environmental and Infrastructure Group (La escena en el cuadro gris cambia a un salón de clases donde dos profesionales de la construcción están parados). La inscripción de estudiantes es el número de estudiantes que asisten a cada escuela del distrito. La cantidad de fondos que recibe una escuela está determinada principalmente por la inscripción de estudiantes (ya no se ve el salón de clases, la escuela reaparece con el número 450 en un cuadro blanco con un ícono pequeño de un estudiante y una flecha de 450 a íconos de signo de dólar).`,
      "zh-CN": `一所学校可以安全合理地容纳的学生人数取决于建筑物的大小（街道和学生消失了，并且可以看到学校容量字样，其下方有一个动画学校。学校旁边是一个显示数字的白色方块800 带有一个小椅子图标）。这是建筑物的容量，由帕森斯环境和基础设施集团的一组专业人士在每所学校进行测量（灰色框中的场景切换到两名建筑专业人士站着的教室）。学生入学人数是每所学区学校就读的学生人数。学校获得的资助金额主要由学生注册决定（不再查看教室，学校以数字 450 重新出现在白色框中，带有学生的小图标和从 450 到美元符号图标的箭头）。`,
      vi: `Số lượng học sinh mà một trường có thể tiếp nhận một cách an toàn và hợp lý tùy thuộc vào quy mô của tòa nhà (Đường phố và học sinh biến mất và dòng chữ School Capacity được nhìn thấy với một trường hoạt hình bên dưới. Bên cạnh trường là một hình vuông màu trắng hiển thị số 800 với một biểu tượng cái ghế nhỏ). Đây là sức chứa của tòa nhà, được đo lường tại mỗi trường học bởi một nhóm chuyên gia từ Nhóm Cơ sở hạ tầng và Môi trường Parsons (Cảnh trong hộp màu xám chuyển sang một lớp học nơi có hai chuyên gia xây dựng đang đứng). Số học sinh ghi danh là số học sinh đang theo học tại mỗi trường của Học khu. Số tiền tài trợ mà một trường nhận được chủ yếu được xác định bởi Đăng ký học sinh (Không còn xem lớp học, trường xuất hiện lại với con số 450 trong một hộp màu trắng với biểu tượng nhỏ của một sinh viên và một mũi tên từ 450 đến biểu tượng ký hiệu đô la).`,
      ru: `Количество учеников, которое школа может безопасно и разумно удерживать, зависит от размера здания (улицы и ученики исчезают, а слова «Вместимость школы» отображаются с анимированной школой под ними. Рядом со школой находится белый квадрат с числом). 800 с маленьким значком стула). Это мощность здания, которая была измерена в каждой школе командой профессионалов Parsons Environmental and Infrastructure Group (сцена в сером прямоугольнике переключается на классную комнату, где стоят два специалиста-строителя). Набор учащихся — это количество учащихся, посещающих каждую школу округа. Сумма финансирования, которую получает школа, в первую очередь определяется набором учащихся (больше не просматривая класс, школа снова появляется с номером 450 в белом поле с маленьким значком ученика и стрелкой от 450 до значков со знаком доллара).`,
      sq: `Numri i nxënësve që një shkollë mund të mbajë në mënyrë të sigurt dhe të arsyeshme varet nga madhësia e ndërtesës (Rrugët dhe nxënësit zhduken dhe fjalët "Kapaciteti i shkollës" shihen me një shkollë të animuar poshtë saj. Pranë shkollës është një katror i bardhë që tregon numrin 800 me një ikonë të vogël karrige). Ky është kapaciteti i ndërtesës, i cili është matur në çdo shkollë nga një ekip profesionistësh nga Parsons Environmental and Infrastructure Group (Skena në kutinë gri kalon në një klasë ku qëndrojnë dy profesionistë të ndërtimit). Regjistrimi i nxënësve është numri i nxënësve që ndjekin çdo shkollë të rrethit. Shuma e financimit që merr një shkollë përcaktohet kryesisht nga Regjistrimi i Studentëve (Pa e parë klasën, shkolla rishfaqet me numrin 450 në një kuti të bardhë me një ikonë të vogël të një studenti dhe një shigjetë nga 450 në ikonat e shenjës së dollarit).`,
      km: `ចំនួនសិស្សសាលាអាចរក្សាបានដោយសុវត្ថិភាព និងសមហេតុផលអាស្រ័យលើទំហំអាគារ (ផ្លូវ និងសិស្សបាត់ ហើយពាក្យ School Capacity ត្រូវបានគេមើលឃើញថាមានសាលារៀនមានចលនានៅខាងក្រោម។ នៅក្បែរសាលាគឺជាការ៉េពណ៌សដែលបង្ហាញលេខ 800 ជាមួយនឹងរូបតំណាងកៅអីតូចមួយ) ។ នេះគឺជាសមត្ថភាពរបស់អគារ ដែលត្រូវបានវាស់នៅសាលានីមួយៗដោយក្រុមអ្នកជំនាញមកពី Parsons Environmental and Infrastructure Group (ឈុតឆាកក្នុងប្រអប់ពណ៌ប្រផេះប្តូរទៅថ្នាក់រៀនដែលអ្នកជំនាញសំណង់ពីរនាក់កំពុងឈរ)។ ការចុះឈ្មោះសិស្ស គឺជាចំនួនសិស្សដែលចូលរៀននៅសាលាស្រុកនីមួយៗ។ ចំនួនមូលនិធិដែលសាលាទទួលបានត្រូវបានកំណត់ជាចម្បងដោយការចុះឈ្មោះសិស្ស (លែងមើលថ្នាក់រៀនទៀតហើយ សាលានឹងបង្ហាញលេខ 450 ឡើងវិញក្នុងប្រអប់ពណ៌សដែលមានរូបតំណាងសិស្សតូច និងសញ្ញាព្រួញពី 450 ទៅរូបតំណាងសញ្ញាដុល្លារ)។`,
      fr: `Le nombre d'élèves qu'une école peut raisonnablement et en toute sécurité accueillir dépend de la taille du bâtiment (les rues et les élèves disparaissent et les mots Capacité de l'école apparaissent avec une école animée en dessous. À côté de l'école se trouve un carré blanc qui indique le nombre 800 avec une petite icône représentant une chaise). Il s'agit de la capacité du bâtiment, qui a été mesurée dans chaque école par une équipe de professionnels du Parsons Environmental and Infrastructure Group (la scène dans la boîte grise passe à une salle de classe où se tiennent deux professionnels de la construction). L'inscription des élèves est le nombre d'élèves fréquentant chaque école du district. Le montant du financement qu'une école reçoit est principalement déterminé par l'inscription des élèves (ne visualisant plus la salle de classe, l'école réapparaît avec le nombre 450 dans une boîte blanche avec une petite icône d'un élève et une flèche de 450 aux icônes du signe dollar).`,
      ar: `يعتمد عدد الطلاب الذين يمكن للمدرسة استيعابهم بأمان وبشكل معقول على حجم المبنى (تختفي الشوارع والطلاب وتظهر الكلمات School Capacity مع مدرسة متحركة تحتها. بجوار المدرسة يوجد مربع أبيض يوضح الرقم 800 مع أيقونة كرسي صغير). هذه هي قدرة المبنى ، التي تم قياسها في كل مدرسة من قبل فريق من المحترفين من Parsons Environmental and Infrastructure Group (المشهد في المربع الرمادي يتحول إلى فصل دراسي حيث يقف اثنان من متخصصي البناء). تسجيل الطلاب هو عدد الطلاب الملتحقين بكل مدرسة من مدارس المنطقة. يتم تحديد مقدار التمويل الذي تتلقاه المدرسة بشكل أساسي من خلال تسجيل الطلاب (لم تعد مشاهدة الفصل الدراسي ، تظهر المدرسة مرة أخرى بالرقم 450 في مربع أبيض مع أيقونة صغيرة للطالب وسهم من 450 إلى رموز علامة الدولار).`,
      en: `The number of students a school can safely and reasonably hold depends on the size of the building (The streets and students disappear and the words School Capacity are seen with an animated school below it. Next to the school is a white square that shows the number 800 with a small chair icon). This is the building’s capacity, which was measured at each school by a team of professionals from Parsons Environmental and Infrastructure Group (The scene in the gray box switches to a classroom where two construction professionals are standing). Student Enrollment is the number of students attending each District school. The amount of funding a school receives is primarily determined by the Student Enrollment (No longer viewing the classroom, the school reappears with the number 450 in a white box with a small icon of a student and an arrow from 450 to dollar sign icons).`,
    },
    text51: {
      es: `(Los íconos del signo de dólar desaparecen y aparecen 450 con el ícono de estudiante y 800 con el ícono de silla) Es importante que observemos la inscripción de estudiantes de una escuela (la 450 con el ícono de estudiante tiembla levemente) en comparación con el tamaño de su edificio ( el 800 con el icono de la silla tiembla ligeramente). Esta es la tasa de utilización de una escuela. Por ejemplo, si una escuela tiene una inscripción baja en comparación con el número de cupos disponibles, digamos 450 estudiantes de 800 cupos disponibles, entonces el edificio se considera infrautilizado e ineficiente (la pantalla muestra 450 dividido por 800 es igual al 56 % de la escuela). utilización y un triángulo de riesgo para mostrar que está infrautilizado).`,
      "zh-CN": `（美元符号图标消失，显示学生图标的 450 和椅子图标的 800）重要的是，我们查看学校的学生注册（学生图标的 450 略有晃动）与其建筑物的大小（带有椅子图标的 800 会轻微晃动）。这是学校的利用率。例如，如果一所学校的入学人数与可用座位数量相比较低，假设 800 个可用座位中有 450 名学生，那么该建筑被认为未充分利用且效率低下（屏幕显示 450 除以 800 等于 56% 学校利用率和一个危险三角形以表明它未得到充分利用）。`,
      vi: `(Các biểu tượng ký hiệu đô la biến mất và cả 450 với biểu tượng sinh viên và 800 với biểu tượng cái ghế xuất hiện) Điều quan trọng là chúng tôi phải xem xét tuyển sinh của một trường (450 với biểu tượng sinh viên hơi lắc) so với kích thước của tòa nhà ( 800 với biểu tượng chiếc ghế rung nhẹ). Đây là tỷ lệ sử dụng của một trường học. Ví dụ, nếu một trường học có số lượng học sinh nhập học thấp so với số lượng chỗ ngồi có sẵn, giả sử 450 sinh viên trong số 800 chỗ ngồi còn trống, thì tòa nhà được coi là sử dụng kém và kém hiệu quả (màn hình hiển thị 450 chia cho 800 bằng 56% trường học. sử dụng và một tam giác nguy hiểm để cho thấy nó đang được sử dụng kém).`,
      ru: `(Значки доллара исчезают, и появляются как 450 со значком ученика, так и 800 со значком стула). 800 со значком стула слегка дрожит). Это коэффициент использования школы. Например, если в школе низкий набор учащихся по сравнению с количеством доступных мест, скажем, 450 учеников из 800 доступных мест, то здание считается недостаточно используемым и неэффективным (на экране показано, что 450 делят на 800, равно 56% школы). использование и треугольник опасности, чтобы показать, что он используется недостаточно).`,
      sq: `(Ikonat e shenjës së dollarit zhduken dhe shfaqen të dyja 450 me ikonën e studentit dhe 800 me ikonën e karriges) Është e rëndësishme që të shikojmë regjistrimin e nxënësve të një shkolle (450 me ikonën e studentit dridhet pak) në krahasim me madhësinë e ndërtesës së saj ( 800 me ikonën e karriges dridhet pak). Kjo është shkalla e përdorimit të një shkolle. Për shembull, nëse një shkollë ka një regjistrim të ulët në krahasim me numrin e vendeve të disponueshme, le të themi 450 nxënës nga 800 vende të disponueshme, atëherë ndërtesa konsiderohet e pashfrytëzuar dhe joefikase (ekrani tregon 450 pjesëtuar me 800 është e barabartë me 56% të shkollës shfrytëzimi dhe një trekëndësh rreziku për të treguar se është i pashfrytëzuar).`,
      km: `(រូបតំណាងសញ្ញាដុល្លារបានបាត់ ហើយទាំង 450 ជាមួយរូបតំណាងសិស្ស និង 800 ដែលមានរូបតំណាងកៅអីលេចឡើង) វាជាការសំខាន់ដែលយើងពិនិត្យមើលការចុះឈ្មោះសិស្សរបស់សាលា (លេខ 450 ដែលមានរូបតំណាងសិស្សញ័របន្តិច) បើប្រៀបធៀបទៅនឹងទំហំអគាររបស់វា ( 800 ដែលមានរូបតំណាងកៅអីញ័របន្តិច) ។ នេះគឺជាអត្រាប្រើប្រាស់របស់សាលា។ ឧទាហរណ៍ ប្រសិនបើសាលារៀនមួយមានការចុះឈ្មោះទាប បើធៀបនឹងចំនួនកៅអីដែលមាន ឧបមាថាសិស្ស 450 នាក់ក្នុងចំណោម 800 កៅអីដែលអាចប្រើបាន នោះអគារនេះត្រូវបានគេចាត់ទុកថាមិនសូវប្រើប្រាស់ និងគ្មានប្រសិទ្ធភាព (អេក្រង់បង្ហាញ 450 ចែកនឹង 800 ស្មើនឹង 56% សាលា ការប្រើប្រាស់ និងត្រីកោណគ្រោះថ្នាក់ ដើម្បីបង្ហាញថាវាមិនត្រូវបានប្រើប្រាស់)។`,
      fr: `(Les icônes du signe dollar disparaissent et 450 avec l'icône étudiant et 800 avec l'icône chaise apparaissent) le 800 avec l'icône de chaise tremble légèrement). Il s'agit du taux d'utilisation d'une école. Par exemple, si une école a un faible effectif par rapport au nombre de places disponibles, disons 450 élèves sur 800 places disponibles, alors le bâtiment est considéré comme sous-utilisé et inefficace (l'écran affiche 450 divisé par 800 égale 56 % utilisation et un triangle de danger pour montrer qu'il est sous-utilisé).`,
      ar: `(تختفي أيقونات علامة الدولار ويظهر كلاهما 450 مع أيقونة الطالب و 800 مع أيقونة الكرسي) من المهم أن ننظر إلى التحاق الطالب بالمدرسة (450 مع أيقونة الطالب يهتز قليلاً) مقارنة بحجم المبنى ( 800 مع رمز الكرسي يهتز قليلاً). هذا هو معدل استخدام المدرسة. على سبيل المثال ، إذا كان معدل الالتحاق بالمدرسة منخفضًا مقارنة بعدد المقاعد المتاحة ، فلنفترض أن 450 طالبًا من أصل 800 مقعد متاح ، فإن المبنى يعتبر غير مستغل وغير فعال (تُظهر الشاشة 450 مقسومة على 800 تساوي 56٪ مدرسة الاستخدام ومثلث الخطر لتوضيح أنه غير مستغل بشكل كافٍ).`,
      en: `(The dollar sign icons disappear and both 450 with the student icon and 800 with the chair icon appear) It is important that we look at a school’s student enrollment (the 450 with the student icon shakes slightly) compared to the size of its building (the 800 with the chair icon shakes slightly). This is a school’s utilization rate. For example, if a school has a low enrollment compared to the number of seats available, let’s say 450 students out of 800 seats available, then the building is considered under-utilized and inefficient (the screen shows 450 divided by 800 equals 56% school utilization and a hazard triangle to show it is under-utilized).`,
    },
    text52: {
      es: `(Aparece una tabla junto a Yvette que muestra las clasificaciones de utilización actual. Más del 100 % es inadecuado, 95-110 % se acerca a inadecuado, 75-95 % es adecuado, 60-75 % se acerca a ineficiente y menos del 60 % de utilización es Ineficiente.)`,
      "zh-CN": `（Yvette 旁边会弹出一个表格，显示当前利用率等级。大于 100% 是不充分的，95-110% 是接近不充分的，75-95% 是足够的，60-75% 是接近低效的，小于 60% 的利用率是效率低下。）`,
      vi: `(Một bảng bật lên bên cạnh Yvette hiển thị Xếp hạng mức sử dụng hiện tại. Hơn 100% là Không phù hợp, 95-110% là Đang tiếp cận Không đầy đủ, 75-95% là Đủ, 60-75% là Đang tiếp cận không hiệu quả và mức sử dụng dưới 60% là Không hiệu quả.)`,
      ru: `(Рядом с Иветт появляется таблица, показывающая текущие рейтинги использования. Более 100% — неадекватно, 95–110% — приближается к неадекватному, 75–95% — удовлетворительно, 60–75% — приближается к неэффективному, и менее 60% — к неэффективному использованию. Неэффективно.)`,
      sq: `(Një tabelë shfaqet pranë Yvette që tregon vlerësimet aktuale të përdorimit. Më e madhe se 100% është e pamjaftueshme, 95-110% po afrohet joadekuate, 75-95% është adekuate, 60-75% është afërsisht joefikase dhe më pak se 60% është përdorimi Joefikase.)`,
      km: `(តារាងមួយលេចឡើងនៅជាប់នឹង Yvette ដែលបង្ហាញពីការវាយតម្លៃការប្រើប្រាស់បច្ចុប្បន្ន។ ធំជាង 100% គឺមិនគ្រប់គ្រាន់ 95-110% កំពុងខិតជិតមិនគ្រប់គ្រាន់ 75-95% គឺគ្រប់គ្រាន់ 60-75% គឺជិតដល់គ្មានប្រសិទ្ធភាព ហើយការប្រើប្រាស់តិចជាង 60% គឺ គ្មានប្រសិទ្ធភាព។ )`,
      fr: `(Un tableau apparaît à côté d'Yvette indiquant les taux d'utilisation actuels. Plus de 100 % est insuffisant, 95-110 % est presque insuffisant, 75-95 % est adéquat, 60-75 % est presque inefficace et moins de 60 % d'utilisation est Inefficace.)`,
      ar: `(ينبثق جدول بجوار Yvette يوضح تقييمات الاستخدام الحالية. أكبر من 100٪ غير كافية ، 95-110٪ تقترب غير كافية ، 75-95٪ كافية ، 60-75٪ تقترب غير فعال ، وأقل من 60٪ استخدام غير فعال.)`,
      en: `(A table pops up next to Yvette showing Current Utilization Ratings. Greater than 100% is Inadequate, 95-110% is Approaching Inadequate, 75-95% is Adequate, 60-75% is Approaching Inefficient, and less than 60% utilization is Inefficient.)`,
    },
    text53: {
      es: `Tener escuelas infrautilizadas dificulta tener suficientes fondos para pagar maestros, recursos y otros apoyos (aparece una escuela animada con una utilización del 56 % y aparecen brevemente íconos que representan maestros, recursos y otros apoyos). También puede ser un desafío cuando se considera que una escuela está sobreutilizada porque los edificios abarrotados pueden afectar negativamente el rendimiento académico y la seguridad de los estudiantes, obstaculizar la capacidad de la administración para programar las aulas de manera efectiva y sobrecargar los equipos y sistemas del edificio. (La escena muestra a un maestro angustiado en un salón de clases con demasiados asientos. Luego, el salón de clases se atenúa y aparecen íconos para representar cada uno de los desafíos, incluido un trabajo calificado, una cruz de asistencia médica, un calendario y una silla en mal estado.)`,
      "zh-CN": `学校利用率低下，很难有足够的资金来支付教师、资源和其他支持（一个动画学校出现，利用率为 56%，代表教师、资源和其他支持的图标短暂出现）。当学校被认为过度使用时，这也可能是一个挑战，因为拥挤的建筑物会对学生的学习成绩和安全产生负面影响，阻碍政府有效安排教室的能力，并使建筑物的设备和系统负担过重。`,
      vi: `Việc có các trường học chưa được sử dụng đầy đủ gây khó khăn cho việc có đủ kinh phí để trả cho giáo viên, nguồn lực và các hỗ trợ khác (một trường học hoạt hình xuất hiện với tỷ lệ sử dụng 56% và các biểu tượng đại diện cho giáo viên, tài nguyên và các hỗ trợ khác xuất hiện nhanh chóng). Nó cũng có thể là một thách thức khi một trường học được coi là sử dụng quá mức vì các tòa nhà đông đúc có thể ảnh hưởng tiêu cực đến kết quả học tập và sự an toàn của học sinh, cản trở khả năng sắp xếp lịch học hiệu quả của ban quản lý và làm quá tải thiết bị và hệ thống của tòa nhà.`,
      ru: `Недостаточно загруженные школы затрудняют получение достаточного финансирования для оплаты учителей, ресурсов и другой поддержки (появляется анимированная школа с использованием 56%, и на короткое время появляются значки, представляющие учителей, ресурсы и другую поддержку). Это также может быть проблемой, когда школа считается перегруженной, потому что переполненные здания могут негативно повлиять на академическую успеваемость и безопасность учащихся, помешать администрации эффективно планировать классные комнаты и перегрузить оборудование и системы здания.`,
      sq: `Të paturit e shkollave të pashfrytëzuara e bën të vështirë të kesh fonde të mjaftueshme për të paguar mësuesit, burimet dhe mbështetje të tjera (shfaqet një shkollë e animuar me 56% përdorim dhe ikona që përfaqësojnë mësuesit, burimet dhe mbështetje të tjera shfaqen shkurtimisht). Mund të jetë gjithashtu një sfidë kur një shkollë konsiderohet e mbi-shfrytëzuar sepse ndërtesat e mbushura me njerëz mund të ndikojnë negativisht në performancën akademike dhe sigurinë e studentëve, të pengojnë aftësinë e administratës për të planifikuar në mënyrë efektive klasat dhe të mbingarkojnë pajisjet dhe sistemet e ndërtesës.`,
      km: `ការមានសាលារៀនដែលប្រើប្រាស់មិនគ្រប់គ្រាន់ធ្វើឱ្យមានការលំបាកក្នុងការមានថវិកាគ្រប់គ្រាន់ដើម្បីចំណាយសម្រាប់គ្រូបង្រៀន ធនធាន និងការគាំទ្រផ្សេងទៀត (សាលារៀនដែលមានចលនាលេចឡើងជាមួយនឹងការប្រើប្រាស់ 56% និងរូបតំណាងតំណាងឱ្យគ្រូបង្រៀន ធនធាន និងជំនួយផ្សេងទៀតលេចឡើងដោយសង្ខេប)។ វាក៏អាចជាបញ្ហាប្រឈមផងដែរ នៅពេលដែលសាលារៀនត្រូវបានចាត់ទុកថាប្រើប្រាស់ហួសកម្រិត ដោយសារអគារដែលមានមនុស្សច្រើនអាចប៉ះពាល់អវិជ្ជមានដល់ដំណើរការសិក្សា និងសុវត្ថិភាពរបស់សិស្ស រារាំងដល់សមត្ថភាពរបស់រដ្ឋបាលក្នុងការកំណត់កាលវិភាគថ្នាក់រៀនប្រកបដោយប្រសិទ្ធភាព និងធ្វើឱ្យលើសទម្ងន់នៃសម្ភារៈ និងប្រព័ន្ធរបស់អគារ។`,
      fr: `Le fait d'avoir des écoles sous-utilisées rend difficile l'obtention de fonds suffisants pour payer les enseignants, les ressources et les autres supports (une école animée apparaît avec 56 % d'utilisation et des icônes représentant les enseignants, les ressources et les autres supports apparaissent brièvement). Cela peut également être un défi lorsqu'une école est considérée comme surutilisée, car les bâtiments surpeuplés peuvent affecter négativement les performances scolaires et la sécurité des élèves, entraver la capacité de l'administration à planifier efficacement les salles de classe et surcharger les équipements et les systèmes du bâtiment.`,
      ar: `إن وجود مدارس غير مستغلة بشكل كاف يجعل من الصعب الحصول على تمويل كاف لدفع تكاليف المعلمين والموارد وغيرها من أشكال الدعم (تظهر مدرسة متحركة مع استخدام 56٪ وتظهر الرموز التي تمثل المعلمين والموارد وغيرها من أشكال الدعم لفترة وجيزة). يمكن أن يمثل تحديًا أيضًا عندما يتم اعتبار المدرسة مستغلة بشكل مفرط لأن المباني المزدحمة يمكن أن تؤثر سلبًا على الأداء الأكاديمي للطلاب وسلامتهم ، وتعيق قدرة الإدارة على جدولة الفصول الدراسية بشكل فعال ، وتثقل كاهل معدات وأنظمة المبنى.`,
      en: `Having under-utilized schools makes it difficult to have enough funding to pay for teachers, resources, and other supports (an animated school appears with 56% utilization and icons representing teachers, resources and other supports briefly appear). It can also be a challenge when a school is considered over-utilized because crowded buildings  can negatively affect students’ academic performance and safety, hinder the administration’s ability to schedule classrooms effectively, and overburden the building's equipment and systems.`,
    },
    text54: {
      es: `(La escena muestra a un maestro angustiado en un salón de clases con demasiados asientos. Luego, el salón de clases se atenúa y aparecen íconos para representar cada uno de los desafíos, incluido un trabajo calificado, una cruz de asistencia médica, un calendario y una silla en mal estado.)`,
      "zh-CN": `（场景显示一位老师在一个座位太多的教室里陷入困境。然后教室变灰，图标似乎代表了每一个挑战，包括分级纸、健康援助十字架、日历和一张年久失修的椅子。 )`,
      vi: `(Cảnh quay cho thấy một giáo viên gặp nạn trong lớp học có quá nhiều chỗ ngồi. Sau đó, lớp học bị chuyển sang màu xám và các biểu tượng xuất hiện để đại diện cho từng thử thách, bao gồm giấy phân loại, chữ thập hỗ trợ sức khỏe, lịch và một chiếc ghế hư hỏng). `,
      ru: `(Сцена показывает учителя в бедственном положении в классе со слишком большим количеством мест. Затем класс становится серым, и появляются значки, представляющие каждую из проблем, включая оцениваемую работу, крестик медицинской помощи, календарь и стул в аварийном состоянии. )`,
      sq: `(Skena tregon një mësues në vështirësi në një klasë me shumë vende. Më pas klasa është gri dhe ikonat duket se përfaqësojnë secilën nga sfidat, duke përfshirë një letër të vlerësuar, kryqin e ndihmës shëndetësore, një kalendar dhe një karrige në gjendje të keqe. )`,
      km: `(ឈុតបង្ហាញពីគ្រូបង្រៀនដែលមានទុក្ខព្រួយនៅក្នុងថ្នាក់រៀនដែលមានកៅអីច្រើនពេក។ បន្ទាប់មកថ្នាក់រៀនត្រូវបានលាបពណ៌ ហើយរូបតំណាងបង្ហាញតំណាងឱ្យបញ្ហាប្រឈមនីមួយៗ រួមទាំងក្រដាសដាក់ពិន្ទុ ឈើឆ្កាងជំនួយសុខភាព ប្រតិទិន និងកៅអីដែលខូច។ )`,
      fr: `(La scène montre un enseignant en détresse dans une salle de classe avec trop de sièges. La salle de classe est alors grisée et des icônes apparaissent pour représenter chacun des défis, y compris un papier noté, une croix d'aide à la santé, un calendrier et une chaise en mauvais état. )`,
      ar: `(يُظهر المشهد مدرسًا في محنة في فصل دراسي به عدد كبير جدًا من المقاعد. ثم يتم تعتيم الفصل الدراسي وتظهر الرموز لتمثل كل من التحديات ، بما في ذلك ورقة متدرجة وصليب مساعدات صحية وتقويم وكرسي في حالة سيئة. )`,
      en: `(The scene shows a teacher in distress in a classroom with too many seats. The classroom is then grayed out and icons appear to represent each of the challenges, including a graded paper, health aid cross, a calendar, and a chair in disrepair.)`,
    },
    text55: {
      es: `A efectos de planificación, también tenemos en cuenta cuál será la utilización probable de la escuela en el futuro (la pantalla muestra un reloj que gira con las palabras "Inscripción hoy" en la parte superior de la pantalla); los expertos de FLO Analytics brindan pronósticos de inscripción para todo el distrito (un hombre con una camisa a cuadros azul y pantalones celestes está sentado en una silla rodeado de símbolos matemáticos, sillas y estudiantes).`,
      "zh-CN": `出于规划目的，我们还考虑了未来学校的利用率（屏幕显示时钟在旋转，屏幕顶部显示“今日入学”字样）； FLO Analytics 的专家提供整个学区的入学率预测（一名身穿蓝色格子衬衫和浅蓝色裤子的男子坐在椅子上，周围环绕着数学符号、椅子和学生）。`,
      vi: `Vì mục đích lập kế hoạch, chúng tôi cũng tính đến việc sử dụng trường học nào trong tương lai (màn hình hiển thị đồng hồ quay với dòng chữ ‘Ghi danh ngay hôm nay’ ở đầu màn hình); các chuyên gia tại FLO Analytics cung cấp dự báo tuyển sinh cho toàn bộ học khu (một người đàn ông mặc áo sơ mi ca rô xanh và quần xanh nhạt đang ngồi trên ghế được bao quanh bởi các ký hiệu toán học, ghế và học sinh).`,
      ru: `В целях планирования мы также учитываем, какой будет загрузка школ в будущем (на экране показаны вращающиеся часы со словами «Зачисление сегодня» в верхней части экрана); эксперты FLO Analytics дают прогнозы набора для всего округа (мужчина в синей клетчатой ​​рубашке и голубых брюках сидит на стуле в окружении математических символов, стульев и учеников).`,
      sq: `Për qëllime planifikimi marrim gjithashtu parasysh se cili do të jetë përdorimi i shkollës në të ardhmen (ekrani tregon një orë që rrotullohet me fjalët "Regjistrohu sot" në krye të ekranit); ekspertët në FLO Analytics ofrojnë parashikime regjistrimi për të gjithë distriktin (një burrë me një këmishë blu me kuadrate dhe pantallona blu të çelët është ulur në një karrige të rrethuar me simbole matematikore, karrige dhe studentë).`,
      km: `សម្រាប់គោលបំណងធ្វើផែនការ យើងក៏គិតផងដែរអំពីការប្រើប្រាស់សាលារៀនដែលទំនងជានាពេលអនាគត (អេក្រង់បង្ហាញនាឡិកាវិលជាមួយនឹងពាក្យ 'ចុះឈ្មោះថ្ងៃនេះ' នៅផ្នែកខាងលើនៃអេក្រង់); អ្នកជំនាញនៅ FLO Analytics ផ្តល់ការព្យាករណ៍សម្រាប់ការចុះឈ្មោះសម្រាប់ស្រុកទាំងមូល (បុរសម្នាក់ពាក់អាវពណ៌ខៀវ និងខោពណ៌ខៀវខ្ចីកំពុងអង្គុយលើកៅអីដែលហ៊ុំព័ទ្ធដោយនិមិត្តសញ្ញាគណិតវិទ្យា កៅអី និងសិស្ស)។`,
      fr: `À des fins de planification, nous tenons également compte de l'utilisation probable de l'école à l'avenir (l'écran affiche une horloge qui tourne avec les mots « Inscription aujourd'hui » en haut de l'écran) ; les experts de FLO Analytics fournissent des prévisions d'inscriptions pour l'ensemble du district (un homme vêtu d'une chemise à carreaux bleus et d'un pantalon bleu clair est assis sur une chaise entouré de symboles mathématiques, de chaises et d'étudiants).`,
      ar: `لأغراض التخطيط ، نأخذ في الاعتبار أيضًا الاستخدام المدرسي المحتمل في المستقبل (تُظهر الشاشة ساعة تدور مع عبارة "التسجيل اليوم" في الجزء العلوي من الشاشة) ؛ يقدم الخبراء في FLO Analytics توقعات التسجيل للمنطقة بأكملها (رجل يرتدي قميصًا أزرق متقلب وسروالًا أزرق فاتح يجلس على كرسي محاط برموز الرياضيات والكراسي والطلاب).`,
      en: `For planning purposes we also take into account what school utilization is likely to be in the future (the screen shows a clock spinning with the words ‘Enrollment today’ at the top of the screen); experts at FLO Analytics provide enrollment forecasts for the entire district (a man in a blue checkered shirt and light blue pants is sitting in a chair surrounded by math symbols, chairs, and students).`,
    },
    text56: {
      es: `Por ejemplo, si durante los próximos cinco años se proyecta que una escuela tenga 900 estudiantes, pero la escuela solo puede albergar de manera segura a 800 estudiantes, entonces la tasa de utilización del edificio proyectada es del 113 %, lo que se clasificaría como Inadecuado. (El título 'Proyecciones de inscripción' aparece en la pantalla y una línea verde con pendiente ascendente muestra el aumento de la inscripción de estudiantes de 700 a 900 estudiantes. En la línea aparece un ícono que muestra 800 es la capacidad. Luego aparece la misma tabla anterior que muestra las calificaciones de utilización proyectadas , y que la utilización del 113 % del ejemplo se considera inadecuada).`,
      "zh-CN": `例如，如果未来五年一所学校预计有 900 名学生，但学校只能安全地容纳 800 名学生，那么预计的建筑利用率为 113%，这将被归类为不足。 （标题“入学预测”出现在屏幕上，向上倾斜的绿线显示学生入学人数从 700 名增加到 900 名学生。显示 800 的图标表示容量出现在线上。然后出现之前的同一张表，显示预计利用率等级，并且示例中 113% 的利用率被认为是不够的。）`,
      vi: `Ví dụ, nếu trong năm năm tới, một trường dự kiến ​​có 900 sinh viên, nhưng trường đó chỉ có thể giữ 800 sinh viên một cách an toàn, thì tỷ lệ sử dụng tòa nhà dự kiến ​​là 113%, sẽ được xếp vào loại Không phù hợp. (Tiêu đề 'Dự báo tuyển sinh' xuất hiện trên màn hình và một đường thẳng màu xanh lá cây dốc lên hiển thị số sinh viên đăng ký tăng từ 700 đến 900 sinh viên. Một biểu tượng hiển thị 800 là khả năng xuất hiện trên dòng. Sau đó, cùng một bảng từ trước đó xuất hiện hiển thị Xếp hạng Sử dụng Dự kiến và việc sử dụng 113% từ ví dụ được coi là không đủ.)`,
      ru: `Например, если в течение следующих пяти лет прогнозируется, что в школе будет обучаться 900 учеников, но школа может безопасно вместить только 800 учеников, то прогнозируемый уровень использования здания составляет 113%, что будет классифицироваться как неудовлетворительное. (На экране появляется заголовок «Прогнозы зачисления», а восходящая зеленая линия показывает увеличение набора студентов с 700 до 900 студентов. На линии появляется значок, показывающий, что 800 — это вместимость. Затем появляется та же таблица, что и ранее, показывающая прогнозируемые рейтинги использования. , и что использование 113% из примера считается недостаточным.)`,
      sq: `Për shembull, nëse gjatë pesë viteve të ardhshme një shkollë parashikohet të ketë 900 nxënës, por shkolla mund të mbajë të sigurt vetëm 800 nxënës, atëherë shkalla e parashikuar e shfrytëzimit të ndërtesave është 113%, e cila do të klasifikohej si e pamjaftueshme. (Titulli 'Projeksionet e regjistrimit' shfaqet në ekran dhe një vijë jeshile e pjerrët lart tregon regjistrimin e studentëve në rritje nga 700 në 900 studentë. Një ikonë që tregon 800 është kapaciteti shfaqet në rresht. Më pas shfaqet e njëjta tabelë nga më parë duke treguar Vlerësimet e Përdorimit të Projektuar , dhe se përdorimi 113% nga shembulli konsiderohet i pamjaftueshëm.)`,
      km: `ជាឧទាហរណ៍ ប្រសិនបើក្នុងរយៈពេល 5 ឆ្នាំខាងមុខ សាលារៀនមួយត្រូវបានព្យាករណ៍ថាមានសិស្ស 900 នាក់ ប៉ុន្តែសាលាអាចផ្ទុកសិស្សបានត្រឹមតែ 800 នាក់ប៉ុណ្ណោះ នោះអត្រាប្រើប្រាស់អគារដែលបានព្យាករណ៍គឺ 113% ដែលនឹងត្រូវបានចាត់ថ្នាក់ថាមិនគ្រប់គ្រាន់។ (ចំណងជើង 'ការព្យាករអំពីការចុះឈ្មោះ' បង្ហាញនៅលើអេក្រង់ ហើយបន្ទាត់ពណ៌បៃតងដែលមានជម្រាលឡើងលើបង្ហាញពីការកើនឡើងនៃការចុះឈ្មោះសិស្សពី 700 ទៅ 900 សិស្ស។ រូបតំណាងដែលបង្ហាញថា 800 គឺជាសមត្ថភាពបង្ហាញនៅលើបន្ទាត់។ បន្ទាប់មកតារាងដូចគ្នាពីមុននឹងបង្ហាញការវាយតម្លៃការប្រើប្រាស់ដែលបានគ្រោងទុក។ ហើយការប្រើប្រាស់ 113% ពីឧទាហរណ៍ត្រូវបានចាត់ទុកថាមិនគ្រប់គ្រាន់។ )`,
      fr: `Par exemple, si au cours des cinq prochaines années, une école devrait avoir 900 élèves, mais que l'école ne peut accueillir en toute sécurité que 800 élèves, alors le taux d'utilisation prévu du bâtiment est de 113 %, ce qui serait classé comme Inadéquat. (Le titre "Projections d'inscriptions" apparaît à l'écran et une ligne verte inclinée vers le haut montre l'augmentation des inscriptions d'étudiants de 700 à 900 étudiants. Une icône indiquant 800 est la capacité apparaît sur la ligne. Ensuite, le même tableau que précédemment apparaît montrant les estimations d'utilisation projetées , et qu'une utilisation de 113 % de l'exemple est considérée comme inadéquate.)`,
      ar: `على سبيل المثال ، إذا كان من المتوقع على مدار السنوات الخمس المقبلة أن تضم المدرسة 900 طالب ، ولكن يمكن للمدرسة استيعاب 800 طالب فقط بأمان ، فإن معدل استخدام المبنى المتوقع هو 113٪ ، والتي سيتم تصنيفها على أنها غير كافية. (يظهر العنوان "توقعات التسجيل" على الشاشة ويظهر الخط الأخضر المنحدر لأعلى زيادة التحاق الطلاب من 700 إلى 900 طالب. يظهر الرمز الذي يظهر 800 هو السعة على السطر. ثم يظهر الجدول نفسه من قبل يعرض تقييمات الاستخدام المتوقعة ، وأن 113٪ استخدام من المثال يعتبر غير كاف.)`,
      en: `For example, if over the next five years a school is projected to have 900 students, but the school can only safely hold 800 students, then the projected building utilization rate is 113%, which would be classified as Inadequate. (The title ‘Enrollment projections’ appears on screen and an upward sloping green line shows increasing student enrollment from 700 to 900 students. An icon showing 800 is the capacity appears on the line. Then the same table from earlier appears showing the Projected Utilization Ratings, and that 113% utilization from the example is considered inadequate.)`,
    },
    text57: {
      es: `Cualquier escuela con una Utilización de inscripción proyectada para el año escolar 2025 hasta 2026 de menos del 50 % o más del 110 % es una prioridad clave que debe abordar el Distrito. (Aparece una escuela con el título 'Utilización proyectada para 2025-26' arriba. Aparecen dos rectángulos rojos sobre la escuela con menos del 50 % y más del 110 % escritos dentro. Aparece un triángulo de riesgo sobre ellos para señalar su importancia. La pantalla luego cambia a un fondo blanco y aparece el logotipo del Distrito Escolar de Filadelfia).`,
      "zh-CN": `任何 2025 至 2026 学年预计入学率低于 50% 或高于 110% 的学校都是学区需要解决的关键优先事项。 （一所学校出现在其上方，标题为“预计 2025-26 年利用率”。学校上方出现两个红色矩形，其中写有小于 50% 和大于 110% 的内容。它们上方出现一个危险三角形以表示重要性。屏幕然后变成白色背景，出现费城学区标志。）`,
      vi: `Bất kỳ trường nào có Tỷ lệ Sử dụng Ghi danh Dự kiến ​​cho Năm học 2025 đến 2026 dưới 50% hoặc lớn hơn 110% là ưu tiên chính mà Học khu phải giải quyết. (Một trường học xuất hiện với tiêu đề 'Dự kiến ​​sử dụng 2025-26' 'phía trên nó. Hai hình chữ nhật màu đỏ xuất hiện trên ngôi trường với ít hơn 50% và lớn hơn 110% được viết bên trong chúng. Một tam giác nguy hiểm xuất hiện trên chúng để báo hiệu mức độ quan trọng. Màn hình sau đó chuyển sang nền trắng và biểu trưng của Học khu Philadelphia xuất hiện.)`,
      ru: `Любая школа с прогнозируемым коэффициентом охвата учащихся на 2025–2026 учебный год менее 50 % или более 110 % является ключевым приоритетом для округа. (Появляется школа с заголовком «Прогнозируемое использование на 2025-2026 годы» над ней. Над школой появляются два красных прямоугольника, внутри которых написано менее 50% и более 110%. Над ними появляется треугольник опасности, чтобы показать важность. Экран затем становится белым фон и появляется логотип школьного округа Филадельфии.)`,
      sq: `Çdo shkollë me një shfrytëzim të parashikuar të regjistrimit për vitin shkollor 2025 deri në 2026 prej më pak se 50% ose më shumë se 110% është një prioritet kyç për të trajtuar Distriktin. (Një shkollë shfaqet me titullin "Përdorimi i parashikuar 2025-26" sipër saj. Dy drejtkëndësha të kuq shfaqen mbi shkollë me më pak se 50% dhe më shumë se 110% të shkruar brenda tyre. Një trekëndësh rreziku shfaqet mbi to për të sinjalizuar rëndësinë. Ekrani pastaj kthehet në një sfond të bardhë dhe shfaqet logoja e Distriktit Shkollor të Filadelfias.)`,
      km: `សាលារៀនណាក៏ដោយដែលមានគម្រោងប្រើប្រាស់ការចុះឈ្មោះចូលរៀនសម្រាប់ឆ្នាំសិក្សា 2025 ដល់ឆ្នាំ 2026 តិចជាង 50% ឬលើសពី 110% គឺជាអាទិភាពសំខាន់សម្រាប់ស្រុកដើម្បីដោះស្រាយ។ (សាលារៀនមួយលេចឡើងជាមួយនឹងចំណងជើង 'Projected 2025-26 Utilization' នៅពីលើវា។ ចតុកោណកែងពណ៌ក្រហមពីរលេចឡើងនៅលើសាលារៀនដែលមានតិចជាង 50% និងធំជាង 110% សរសេរនៅខាងក្នុងពួកគេ។ ត្រីកោណគ្រោះថ្នាក់លេចឡើងនៅលើពួកវាដើម្បីបង្ហាញសារៈសំខាន់។ អេក្រង់ បន្ទាប់មកប្រែទៅជាផ្ទៃខាងក្រោយពណ៌ស ហើយស្លាកសញ្ញា School District of Philadelphia លេចឡើង។)`,
      fr: `Toute école dont l'utilisation prévue des inscriptions pour l'année scolaire 2025 à 2026 est inférieure à 50 % ou supérieure à 110 % est une priorité clé que le district doit traiter. (Une école apparaît avec le titre "Utilisation projetée 2025-26" au-dessus. Deux rectangles rouges apparaissent au-dessus de l'école avec moins de 50 % et plus de 110 % écrits à l'intérieur. Un triangle de danger apparaît au-dessus d'eux pour signaler l'importance. L'écran passe ensuite à un fond blanc et le logo du district scolaire de Philadelphie apparaît.)`,
      ar: `تعتبر أي مدرسة ذات استخدام تسجيل متوقع للعام الدراسي 2025 حتى 2026 أقل من 50٪ أو أكثر من 110٪ من الأولويات الرئيسية للمنطقة التعليمية. (تظهر مدرسة بعنوان "الاستخدام المتوقع 2025-26" فوقه. يظهر مستطيلان أحمر اللون فوق المدرسة مكتوبًا بداخلهما أقل من 50٪ وأكبر من 110٪. يظهر فوقها مثلث خطر للإشارة إلى الأهمية. الشاشة ثم يتحول إلى خلفية بيضاء ويظهر شعار منطقة فيلادلفيا التعليمية.)`,
      en: `Any school with a Projected Enrollment Utilization for School Year 2025 through 2026 of less than 50% or greater than 110% is a key priority for the District to address. (A school appears with the title ‘Projected 2025-26 Utilization’ above it. Two red rectangles appear over the school with less than 50% and greater than 110% written inside them. A hazard triangle appears over them to signal importance.The screen then turns to a white background and the School District of Philadelphia logo appears.)`,
    },
  },
};

export function setText(page, cat, lang) {
  // // console.log(Text[page]);
  return Text[page][cat][lang];
}
